import React from "react";
import FocusError from "../../../../../../components/FocusError";
import { Button } from "@material-ui/core";
import QuestionsListing from "./QuestionsListing";
import { FormikValues, FormikWrapper } from "../../../../../../components/formikFormInputs";
import { isFunc, sleep } from "../../../../../../utils/commonUtils";
import { EnrollmentFinancialAgreementProps } from "../../../../../../model/enrollment";

type PropsType = {
	buttonRef: any,
	clientSigned: boolean,
	disableSaveButton: Function,
	enableSaveButton: Function
	getPolicyGrids: Function,
	initialValues: any,
	insuredQuestionPayload?: Function,
	insuredQuestions?: any,
	insureRef: any,
	isFromClientDetail: boolean,
	pageChecked: boolean
	saveMe?: Function
	setActionItem: Function | undefined,
	setAutoSaveTrigger?: Function
	setInsuranceError: Function,
	setIsSubmittedQuestions: Function,
	setNextStep: Function,
	setOpenInforceLifeAlertPopup: Function,
	setSaveApplicationQuestions?: Function
	showComponent: Function,
	updateClientFields: Function,
	user: any,
	validationSchema: any,
}

function InsuredQuestionsForm(props: PropsType) {
	const {
		buttonRef,
		clientSigned,
		enableSaveButton,
		getPolicyGrids,
		initialValues,
		insuredQuestions,
		isFromClientDetail,
		pageChecked,
		saveMe,
		setAutoSaveTrigger,
		setIsSubmittedQuestions,
		setNextStep,
		setOpenInforceLifeAlertPopup,
		setSaveApplicationQuestions,
		showComponent,
		user,
		validationSchema,
	} = props;

	const callingAutoSave = () => {
		sleep()
			.then(() => {
				if (setAutoSaveTrigger && isFunc(setAutoSaveTrigger)) {
					setAutoSaveTrigger(Math.random());
				}
			});
	};
	const InsuranceQuestionsFormChild = (fProps: any) => {
		const {
			errors,
			handleBlur,
			handleChange,
			handleSubmit,
			setErrors,
			setFieldTouched,
			setFieldValue,
			touched,
			values,
		} = fProps;
		return (
			<form className="floatLeft w100 mt60" onBlur={callingAutoSave} onSubmit={handleSubmit}>
				<FocusError offset={true} />
				<QuestionsListing
					clientSigned={clientSigned}
					enableSaveButton={enableSaveButton}
					errors={errors}
					getPolicyGrids={getPolicyGrids}
					handleBlur={handleBlur}
					handleChange={handleChange}
					isFromClientDetail={isFromClientDetail}
					setErrors={setErrors}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					setOpenInforceLifeAlertPopup={setOpenInforceLifeAlertPopup}
					setSaveApplicationQuestions={setSaveApplicationQuestions}
					showComponent={showComponent}
					touched={touched}
					user={user}
					values={values}
				/>
				<Button
					variant="contained"
					color="primary"
					className="detailPageSubmitBtn"
					type={"submit"}
					onClick={() => setIsSubmittedQuestions(true)}
					ref={buttonRef}
					style={{ display: "none" }}
				>
				</Button>
			</form>
		);
	};
	const onSubmit = async (values: FormikValues) => {
		if (!pageChecked && !isFromClientDetail) {
			return false;
		}

		if (saveMe && isFunc(saveMe)) {
			saveMe(values);
		}

		if (!isFromClientDetail) {
			setNextStep();
		}
	};
	const formProps = {
		children: InsuranceQuestionsFormChild,
		enableReinitialize: true,
		initialValues: initialValues,
		innerRef: insuredQuestions,
		onSubmit,
		validateOnChange: true,
		validationSchema,
	};

	return (
		<FormikWrapper {...formProps} />
	);
}

export default InsuredQuestionsForm;