import React from 'react';
import {createStyles, makeStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    IconButton,
    Typography,
    Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Actions from "../../../../../../actions/account";
import * as Aws from "../../../../../../actions/aws";
import { useActions } from "../../../../../../actions";
import ViewDocument from "./ViewDocuments";
import {asDbDateFormat} from "../../../../../../utils/dateUtils";
import GetAppIcon from "@material-ui/icons/GetApp";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../reducers";
import { AccountType } from '../../../../../../constants';
import ClientDocments from '../../../../../../components/client/ClientDocumentsTable';

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="body1" component='strong'>{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
interface ViewFilesListProps {
    isReportRequestCompleted:boolean,
    setIsReportRequestCompleted:Function,
    userId: string | number | undefined,
    callingFrom: string
}
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            margin: {
                margin: theme.spacing(1),
            },
        }),
);

export default function ViewFilesList(props:ViewFilesListProps) {
    const { isReportRequestCompleted, setIsReportRequestCompleted, userId, callingFrom } = props;
    const accountActions = useActions(Actions);
    const awsActions = useActions(Aws);


    const [reportData, setReportData] = React.useState<any>([]);
    const [title, setTitle] = React.useState<string>("");
    const [openDocument, setOpenDocument] = React.useState<boolean>(false);
    const [documentUrl, setDocumentUrl] = React.useState<string>("");
    const [isDemoMode, setIsDemoMode] = React.useState<boolean>(false);
    const classes = useStyles();
    const { user } = useSelector((state: RootState) => state.auth);

    React.useEffect(()=>{
        getEstimationFiles().then(()=>{});
    }, []);

    React.useEffect(()=>{
        if(user && user.accessType === "client") {
            setIsDemoMode(true);
        } else {
            setIsDemoMode(false);
        }
    },[user && user.accessType])

    const getEstimationFiles = async () => {
        let reports = await accountActions.exportEstimationFile(userId, callingFrom);
        setReportData(reports);
        setIsReportRequestCompleted(true);
    }

    const handleClose = () => {
        setIsReportRequestCompleted(false);
    };

    const handleCloseViewDocument = () => {
        setOpenDocument(false);
        setDocumentUrl("")
    }

    const getSignedUrl = async (objData:any) => {
        const {path, bucket, name} = objData;
        let fileDetails = path.split('/');
        let directory = fileDetails[0];
        let fileName = fileDetails[1];
        let file = fileName.split('.');
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ('text/pdf'):("image/jpg, image/jpeg, image/png, application/pdf");
        let queryObj = {
            objectName: fileName,
            contentType: contentType,
            bucketName: bucket
        };
        setTitle(name)
        let pdfFile = await awsActions.getSecureUrl(directory, queryObj, true);
        if(!!pdfFile) {
            showPdfFile(pdfFile);
        }
    }

    const getSignedUrlAndDownload = async (objData:any) => {
        const {path, bucket, name} = objData;
        let fileDetails = path.split('/');
        let directory = fileDetails[0];
        let fileName = fileDetails[1];
        let file = fileName.split('.');
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ('text/pdf'):("image/jpg, image/jpeg, image/png, application/pdf");
        let queryObj = {
            objectName: fileName,
            contentType: contentType,
            bucketName: bucket
        };
        const s3url = await awsActions.getSecureUrlAndDownload(directory, queryObj, true);
        let blob = new Blob([new Buffer(s3url.Body, "binary")]
                , { type: s3url.ContentType });
        console.log("blob", blob);
        const url = window.URL.createObjectURL(blob);
        const link = window.document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        window.document.body.appendChild(link);
        link.click();
    }

    const showPdfFile = (pdfurl:string) => {
        if(!isDemoMode){
            setDocumentUrl(`${pdfurl}#toolbar=0`);
            setOpenDocument(true);
        } else {
            window.open(pdfurl);
        }
    }

    let titleText:string = isDemoMode ? "Click to View and Download Documents":"Click to view Documents"
    return (
            <div>
                <Dialog onClose={handleClose} fullWidth={true} maxWidth={"sm"} aria-labelledby="customized-dialog-title" open={isReportRequestCompleted}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {titleText}
                    </DialogTitle>
                    <DialogContent dividers>
                    <ClientDocments
                                    reportData={reportData && reportData.userIllustrations}
                                    onClickDocument={(_:number,data)=>{
                                        getSignedUrl(data).then(()=>{})
                                    }}
                                    showDownloadOption={isDemoMode || user.role === AccountType.MasterAdmin}
                                    onClickDownload={(_:number,data)=>{
                                        getSignedUrl(data).then(()=>{})
                                    }}
                                    
                                
                                />
                        {/* <Table aria-label="simple table">
                            <TableHead style={{background:'rgb(212 226 233)'}}>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Date Created</TableCell>
                                    {isDemoMode && <TableCell>Download</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                {reportData && reportData.userIllustrations && reportData.userIllustrations.map((data, index)=>{
                                    return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Button onClick={()=>{
                                                        getSignedUrl(data).then(()=>{})
                                                    }} color={"primary"} variant="text">{data.name}</Button>
                                                </TableCell>
                                                <TableCell>
                                                    {asDbDateFormat(data.updatedAt)}
                                                </TableCell>
                                                {(isDemoMode || user.role === AccountType.MasterAdmin) &&
                                                <TableCell>
                                                    <IconButton aria-label="delete" className={classes.margin}
                                                                       size="small"
                                                                       onClick={() => getSignedUrl(data).then(()=>{})}>
                                                        <GetAppIcon/>
                                                    </IconButton>
                                                </TableCell>}
                                            </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table> */}
                    </DialogContent>
                </Dialog>
                <ViewDocument openDocument={openDocument} title={title} documentUrl={documentUrl} fileType={"pdf"} handleClose={handleCloseViewDocument} />
            </div>
    );
}
