import {Button, Dialog, DialogContent, Grid, Typography} from "@material-ui/core";
import {Formik} from "formik";
import React, {useRef} from "react";
import * as Yup from "yup";

import {SelectField} from "../formikFormInputs";

import {useActions} from "../../actions";
import * as AccountActions from "../../actions/account";
import * as CarrierAction from "../../actions/carrier";
import * as ClientActions from "../../actions/client";
import {FormInputs} from "../../pages/approvedAgents/ApprovedAgentsPage";
import {DialogTitle} from "../ConfirmEnrollment";
import {Label} from "../formInputs";
import {CarrierStrategiesForm} from "../AddImoAccount";
import differenceBy from "lodash/differenceBy";
import {noop} from "../../utils/commonUtils";
import {RequiredField} from "../../utils/validation";
import { pleaseSelectOption } from "../../constants";
import { INVOpt } from "../../model/misc";

export enum DialogCarriersType {
    ADD = "Add Carriers and Ilia Product Type",
    EDIT = "Edit Carriers and Ilia Product Type"
}

type DialogImoProps = {
	open: boolean;
	handleClose: () => void;
	onSubmit: (values: any) => void;
	initialImo: CarrierStrategiesForm;
	title: DialogCarriersType | null;
	existingCarriers: any[]
  }

export const DialogCarrierAndStrategies = ({open, handleClose, onSubmit, initialImo, title, existingCarriers}: DialogImoProps) => {
	const accountActions = useActions(AccountActions);
	const carrierAction = useActions(CarrierAction);
	const clientActions = useActions(ClientActions);

	const [carriers, setCarriers] = React.useState<any[]>([]);
	const [strategies, setStrategies] = React.useState<any[]>([]);
	const [offerTypes, setOfferTypes] = React.useState<any[]>([]);
  
	const getCarriers = async () =>{ 
		const response = await carrierAction.getCarrierByUserId()
		return response
	}
  
	React.useEffect(() => {
	  getCarriers().then(setCarriers);
	}, [])

	React.useEffect(() => {
		if(initialImo.carrier) {
			onSelectCarrier(initialImo.carrier)
		}
	}, [initialImo.carrier])

	const getPossibleCarriers = () => {
		let toRemove = existingCarriers;

		if(initialImo.carrier) { // notice carrier is it's id, a number
			toRemove = existingCarriers.filter(x => x.carrier.id != initialImo.carrier)
		}
		
		return differenceBy(
			carriers, 
			toRemove.map(x => ({ id: x.carrier.id})), 
			"id"
		)
	}

	const carrierList = getPossibleCarriers().map(c => {
	  return {
		id: c.id,
		value: c.value,
		name: c.name,
	  };
	});

	const onSelectCarrier = async (carrierId: number) => {
		await updateStrategies(carrierId)
		await updateOfferTypes(carrierId)
	}

	const updateStrategies = async (carrierId: number) => {
		const strategies = await accountActions.getStrategyByUserId(carrierId)
		setStrategies(strategies)
	}

	const updateOfferTypes = async (carrierId: number) => {
		const offerTypes = await clientActions.getOfferByCarrierId(carrierId)
		setOfferTypes(offerTypes.payload)
	}
  
	const submit = (values: any, {setSubmitting, resetForm}: any) => {
		onSubmit({
			carrier: carriers.find(x => x.id === values.carrier),
			offerTypes,
            imoStrategies: values.strategies.map(strategyId => ({
                strategies: strategies.find(x => x.id === strategyId)
			})),
		} )
		
		setSubmitting(false);
		resetForm();
		setStrategies([]);
		setOfferTypes([]);
		handleClose();
	}

	const formRef: any = useRef();

	const imoFields = [
	  {
		name: "carrier",
		label: <Label label={"Select a Carrier"} required />,
		component: SelectField,
          options: [pleaseSelectOption].concat(carrierList) as INVOpt[],
		rowSize: 12,
		disabled: title === DialogCarriersType.EDIT,
		onSelectChange: (carrierId: number) => {
			formRef.current.setFieldValue("strategies", initialImo.strategies);
			return onSelectCarrier(carrierId);
		},

	  },
	  {
		name: "strategies",
		label: <Label label={"Select Ilia Product Type"} required />,
		component: SelectField,
		multiple: true,
		options: strategies,
		rowSize: 12,
		onSelectChange: noop
	  },
	]
  
	return (
	  <Dialog open={open} fullWidth maxWidth="sm">
		<DialogTitle onClose={handleClose} id="dialog-imo">
		  {title}
		</DialogTitle>
		<Formik
		  innerRef={formRef}
		  initialValues={initialImo}
		  onSubmit={submit}
		  validationSchema={Yup.object({
			carrier: Yup.number().moreThan(0, RequiredField).required(RequiredField),
			strategies: Yup.array().required(RequiredField).min(1)
		  })}
		>
		  {({errors, values, touched, handleChange, handleBlur, handleSubmit}) => (
			<form onSubmit={handleSubmit}>
			  <DialogContent dividers>
				<Grid container spacing={3}>
				  <FormInputs
					fields={imoFields}
					{...{errors, values, touched, handleChange, handleBlur}}
				  />
				  <Grid item xs={12}>
				  	{
						offerTypes.length > 0 &&
						<>
                            <Typography gutterBottom variant="body1"><strong>Offer types:</strong> {offerTypes.map(x => x.name).join(", ")} </Typography>
						</>
					}

                      <Button
                              size="small"
                              className="floatLeft mt20 mb20"
                              type="button"
                              variant="contained"
                              onClick={handleClose}
                      >
                          Cancel
                      </Button>
                      <Button
                              size="small"
                              className="floatRight mt20 mb20"
                              type="submit"
                              variant="contained"
                              color="primary"
                      >
                          Save
                      </Button>
				  </Grid>
				</Grid>
			  </DialogContent>
			</form>
		  )}
		</Formik>
	  </Dialog>
	)
  }