import React, { useEffect } from "react";
import { Checkbox, Grid, Tooltip, Typography, withStyles } from "@material-ui/core";
import AttestationOfTruth from "../FinancialAndLiabilities/AttestationOfTruth";
import * as Yup from "yup";
import { EnrollmentFinancialAgreementProps } from "../../../../../../model/enrollment";
import Info from "@material-ui/icons/Info";
import { Label } from "../../../../../../components/formInputs";
import {
    DatePicker,
    ErrorCommon,
    NumberTextField,
    SelectField,
    TextField,
    TextFieldCommon,
} from "../../../../../../components/formikFormInputs";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useActions } from "../../../../../../actions";
import * as AccountActions from "../../../../../../actions/account";
import { getYesNo, noop, pez, sleep, unmaskCurrency } from "../../../../../../utils/commonUtils";
import moment from "moment";
import { scroller } from "react-scroll";
import InforceLifeAlertPopup from "./InforceLifeAlertPopup";
import InsuredQuestionsForm from "./InsuredQuestionsForm";
import InsuredPageCheckBox from "./component/InsuredPageCheckBox";
import InsuredPageRadioButton from "./component/InsuredPageRadioButton";
import * as Actions from "../../../../../../actions/enrollment";

import { getValidations } from "./insuranceValidations";
import { isLongForm, isWorksheet } from "../Utilities";
import { PIQ } from "./InsuredQuestions";
import { simpleRequired } from "../../../../../../utils/validation";
import ExistingPolicy from "./ExistingPolicy";
import { GWT_GEN2_STRATEGY } from "../../../../../../constants";


const getNumberOfPolicies = () => {
    const policies: any = [];
    for (let i = 1; i <= 10; i++) {
        policies.push({
            id: i,
            key: i,
            value: i,
            name: i,
        });
    }
    return policies;
};
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
}));

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: "#2f2f2f",
        maxWidth: "500px",
        minWidth: "100px",
        color: "#ffccc",
        lineHeight: "18px",
        fontWeight: "normal",
        padding: "20px",
        margin: "0",
        boxShadow: "0 0 15px 0px #8c8c8c",
    },
}))(Tooltip);

type Props = {
    isFromClientDetail: boolean,
    setIsError: Function,
    user: any,
    setQuestionButtonRef: Function,
    setNextStep: Function,
    offerType?: string,
    setActionItem?: Function
}

const InsuredQuestionsContent = (props: Props) => {
    const classes = useStyles();
    const { isFromClientDetail, setQuestionButtonRef, user, setNextStep, setActionItem, offerType = "" } = props;
    const { updateClientFields, getNationalLifeDetails } = useActions(AccountActions);
    const enrollmentActions = useActions(Actions);
    const [isSubmittedQuestions, setIsSubmittedQuestions] = React.useState<boolean>(false);
    const [attestRef, setAttestRef] = React.useState<any>(null);
    const [insuranceError, setInsuranceError] = React.useState<any>(false);
    const [nationalLifeId, setNationalLifeId] = React.useState(0);
    const [openInforceLifeAlertPopup, setOpenInforceLifeAlertPopup] = React.useState(false);
    const [insuranceTypeVal, setInsuranceTypeVal] = React.useState<string>('personalInsurance');
    const [isSaving, setIsSaving] = React.useState(false);
    const [autoSaveTrigger, setAutoSaveTrigger] = React.useState(0);
    // const [personalInsuranceType, setPersonalInsuranceType] = React.useState<string | null>(null);
    const insureRef: any = React.useRef<any>();
    const insuredQuestions: any = React.useRef<any>(null);
    const buttonRef = React.useRef<any>();
    let enrollment: any = (user && user.enrollment) || undefined;
    let clientSigned: boolean = (enrollment && (enrollment.clientSigned || enrollment.envelopeId)) || false;
    let isChecked: boolean = false;
    if (enrollment) {
        isChecked = `${enrollment.enrollmentStatus}.${enrollment.enrollmentSubStatus}` > "1.2";
    }
    const [pageChecked, setPageChecked] = React.useState<boolean>(isChecked);
    let clientDetail = (user && user.client && user.client) || undefined;
    let enrollmentDetails = (user && user.enrollment) || undefined;
    let answers: any = enrollmentDetails.answers ? JSON.parse(enrollmentDetails.answers) : {};
    React.useEffect(() => {
        setPageChecked(!clientDetail.insuredAgreed);
    }, [clientDetail.insuredAgreed]);
    React.useEffect(() => {
        //Keyman Exceptions
        if(!pageChecked && (offerType !== "gi" || isKeyman) && isSubmittedQuestions && attestRef && attestRef.current){
            setIsSubmittedQuestions(false);
            scroller.scrollTo(attestRef.current.id, {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: -200,
            });
        }
    }, [isSubmittedQuestions === true, pageChecked]);

    const isAllianzLife = clientDetail.carrier.carrierName === "Allianz Life";
    // GWT Gen2 Great Wealth Transfer
    const isGWTGen2 = clientDetail.strategyId === GWT_GEN2_STRATEGY;

    const strategySlug: string = clientDetail && clientDetail.strategy && clientDetail.strategy.slug ? clientDetail.strategy.slug : "";
    const isCorporation = (user && user.client && user.client.company && user.client.company.groupType && user.client.company.groupType === "Corporation") || false;
    const isAssociation = (user && user.client && user.client.company && user.client.company.groupType && user.client.company.groupType === "Association") || false;
    const isKeyman = (user && user.client && user.client.company && user.client.company.groupType && user.client.company.groupType === "Keyman") || false;

    const proposedInsuredQuestions70Xfa: any = [
        {
            "Tplan": "",
            "Rlen": "",
            "Con": [],
            "Dan": null,
        },
    ];

    React.useEffect(() => {
        const getNatioanlLifeCarrierDetails = async () => {
            const nationalLifeDate = await getNationalLifeDetails();
            if (nationalLifeDate && nationalLifeDate.nationalLife) {
                setNationalLifeId(nationalLifeDate.nationalLife.id);
            }
        };
        //Wealth Gift Transfer case
        // setInsuranceTypeVal(clientDetail && clientDetail.insuranceType);
        // if(clientDetail && clientDetail.currentlyEmployed && clientDetail.currentlyEmployed.toLowerCase() === "other"){
        //     if(clientDetail && clientDetail.parentsAreIncomeEarner){  //WT
        //         setInsuranceTypeVal("personalInsurance");
        //         setPersonalInsuranceType("Wealth-gift Transfer");
        //     }
        // }
        getNatioanlLifeCarrierDetails();
    }, []);

    const autoSaveEffect = () => {
        // sleep().then(() => saveMe(insuredQuestions && insuredQuestions.current && insuredQuestions.current.values, true));
    };
    React.useEffect(autoSaveEffect, [insuredQuestions && insuredQuestions.current && insuredQuestions.current.dirty && autoSaveTrigger]);

    const initialValues = {
        noOfPolicies: clientDetail.noOfPolicies ? clientDetail.noOfPolicies + "" : "",
        insuredAgreed: false,
        insuranceType: isKeyman ? "businessInsurance" : clientDetail.insuranceType || "",
        personalInsurance: isGWTGen2 ? "Wealth-gift Transfer" : clientDetail.personalInsurance || (offerType === 'gi' || offerType === 'si' || strategySlug === "esd" ? "Other" : ""),//Other
        businessInsurance: isKeyman ? "Key Person" : clientDetail.businessInsurance || (offerType === 'gi' || offerType === 'si' ? "" : ""),//Split Dollar
        mortgageAmount: clientDetail.mortgageAmount || "",
        insuranceDescription: pez(clientDetail.insuranceDescription, isGWTGen2 ? "This is a Great Wealth Transfer Case." : ""),
        inforcePolicy: clientDetail.inforcePolicy ? "Yes" : clientDetail.inforcePolicy === 0 ? "No" : null,
        noOfInforcePolicies: clientDetail.noOfInforcePolicies ? clientDetail.noOfInforcePolicies + "" : "",
        inforceAmount: clientDetail.inforceAmount || "",
        liAmount: clientDetail.liAmount || "",
        inforceLifeDesc: clientDetail.inforceLifeDesc || "",
        existPolicies: getYesNo(clientDetail.existPolicies),
        isInforceLife: getYesNo(clientDetail.isInforceLife),
        "inforceAmountNone": clientDetail.inforceAmount ? false : null,
        "liAmountNone": clientDetail.liAmount ? false : null,
        "Insured_Questions-2": "",
        "Insured_Questions-3": "",
        "Insured_Questions-4": "",
        "Insured_Questions-5": "",
        "Insured_Questions-6": "",
        "Insured_Questions-7": "",
        "Insured_Questions-8": "",
        "Insured_Questions-9": "",
        "Insured_Questions-6-checkbox": false,
        "Insured_Questions-9-checkbox": false,
        "racingValidation":"",
        "inForcePolicies": clientDetail.ClientInforcePolicies && clientDetail.ClientInforcePolicies.length > 0 ?
                clientDetail.ClientInforcePolicies.map(policy => ({
                    id: policy.id,
                    nameOfCompany: policy.nameOfCompany,
                    policyNumber: policy.policyNumber,
                    dateIssuedOrAppliedFor: policy.dateIssuedOrAppliedFor ? policy.dateIssuedOrAppliedFor : moment().format("YYYY"),
                    yearsIssuedOrAppliedFor: policy.yearsIssuedOrAppliedFor ? policy.yearsIssuedOrAppliedFor : moment().format("YYYY"),
                    coverageFaceAmount: policy.coverageFaceAmount,
                    adbCoverage: policy.adbCoverage,
                    type: policy.type,
                    willThisBeReplaced: policy.willThisBeReplaced || null,
                    surrenderChargePercentage: policy.surrenderChargePercentage || null,
                    willBothPoliciesBeTaken: policy.willBothPoliciesBeTaken,
                    status: policy.status,
                })) : [],
        /* NIWC-2335 */

        "Proposed_Insured_Questions-83XCee": (answers && answers[`${PIQ}-83XCee`]) || false,
        "Proposed_Insured_Questions-70Xfa": answers && answers.hasOwnProperty("Proposed_Insured_Questions-70Xfa")
        && answers["Proposed_Insured_Questions-70Xfa"] && answers["Proposed_Insured_Questions-70Xfa"].length > 0 ? answers["Proposed_Insured_Questions-70Xfa"] : proposedInsuredQuestions70Xfa,

        ...answers,
    };

        if (isKeyman) {
            setInsuranceTypeVal("businessInsurance");
        }
    const keysValidation = getValidations(isAssociation, isCorporation, isKeyman, isFromClientDetail, offerType, user, insuredQuestions);
    const validationSchema = Yup.object().shape(keysValidation);



    const policiesObj = {
        label: <Label label={"Number of Policies"} required={!isFromClientDetail} />,
        isRequired: !isFromClientDetail,
        filedType: "selectbox",
        helperFieldText: "How many policies do you have with us?",
        component: SelectField,
        className: "SelectFieldWidth",
        variant: "outlined",
        options: getNumberOfPolicies(),
        disabled: clientSigned,
        onSelectChange: noop,
        getValuesOnChange: async (val: number, setFieldValue: Function, values: any) => {
            let inForcePolicies: any = [];
            if (values && values.hasOwnProperty('inForcePolicies')) {
                for (let i = 1; i <= Number(val); i++) {
                    inForcePolicies.push({
                        nameOfCompany: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].nameOfCompany) || "",
                        policyNumber: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].policyNumber) || "",
                        dateIssuedOrAppliedFor: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].dateIssuedOrAppliedFor && values["inForcePolicies"][i - 1].dateIssuedOrAppliedFor) || moment().format("YYYY"),
                        yearsIssuedOrAppliedFor: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].yearsIssuedOrAppliedFor && moment(values["inForcePolicies"][i - 1].yearsIssuedOrAppliedFor).format("YYYY-MM-DD HH:mm:ss")) || moment().format("YYYY-MM-DD HH:mm:ss"),
                        coverageFaceAmount: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].coverageFaceAmount) || "",
                        adbCoverage: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].adbCoverage) || "",
                        type: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].type) || "",
                        willThisBeReplaced: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].willThisBeReplaced) || null,
                        willBothPoliciesBeTaken: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].willBothPoliciesBeTaken) || "",
                        surrenderChargePercentage: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].surrenderChargePercentage) || null,
                        status: (values && values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].status) || "",
                    });
                }
                await setFieldValue("inForcePolicies", inForcePolicies);
            }
            enableSaveButton();
        }
    };

    const secQuesTextField = {
        id: "insuranceDescription",
        name: "insuranceDescription",
        helperFieldText: "",
        label: <Label label={"Please Explain"} />,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
        visible: isKeyman ? false : true
    };

    const mortgageProtectionTextField = {
        className: "mt10 TextFieldWidth dollerIcon",
        component: NumberTextField,
        disabled: clientSigned,
        filedType: "string",
        helperFieldText: "",
        id: "mortgageAmount",
        InputProps: {
            startAdornment: (<InputAdornment className="margin0 dollerIcon" position="start">$</InputAdornment>),
        },
        isRequired: !isFromClientDetail,
        label: <Label label={"Mortgage Amount"} required={!isFromClientDetail} />,
        name: "mortgageAmount",
        placeholder: "Please enter Mortgage Amount",
        size: { md: 12, lg: 12 },
        variant: "outlined",
        validate: simpleRequired,
    };

    const faceAmountExplanation = {
        id: "faceAmountExplanation",
        name: "faceAmountExplanation",
        helperFieldText: "",
        label: <Label label={"Please provide an explanation on how the face amount was determined"} />,
        placeholder: "Please provide an explanation on how the face amount was determined",
        isRequired: !isFromClientDetail,
        disabled: clientSigned,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
        visible: isKeyman ? false : true
    };

    const lifeInsurancePurpose = [
        {
            label: "Income Replacement",
            value: "Income Replacement",
        }, {
            label: "Final Expenses",
            value: "Final Expenses",
        }, {
            label: "Estate Conservation",
            value: "Estate Conservation",
        }, {
            label: "College Funding",
            value: "College Funding",
        }, {
            label: "Retirement Planning",
            value: "Retirement Planning",
        }
    ]

    const personalRadio = [
        {
            label: "Wealth-gift Transfer",
            value: "Wealth-gift Transfer",
        },
        {
            label: "Other",
            value: "Other",
        }
    ];

    const personalRadioLongForm = [
        {
            label: "Charitable Giving",
            value: "Charitable Giving"
        },
        {
            label: "Mortgage Protection",
            value: "Mortgage Protection"
        },
        {
            label: "Other",
            value: "Other"
        }
    ];

    const personalRadioWorksheet = [
        {
            label: "Mortgage Protection",
            value: "Mortgage Protection"
        }
    ];

    const personalRadiolist = isLongForm(offerType) ? [...lifeInsurancePurpose, ...personalRadioLongForm] : isWorksheet(offerType) ? [...lifeInsurancePurpose, ...personalRadioWorksheet] : [...lifeInsurancePurpose, ...personalRadio];

    const businessInsurance = [
        {
            label: "Deferred Compensation",
            value: "Deferred Compensation",
        }, {
            label: "Buy / Sell",
            value: "Buy / Sell",
        }, {
            label: "Key Person",
            value: "Key Person",
        }, {
            label: "Business Continuation",
            value: "Business Continuation",
        }, {
            label: "Split Dollar",
            value: "Split Dollar",
        }, {
            label: "Executive Bonus",
            value: "Executive Bonus",
        },
    ];

    const textFieldCommon = {
        helperFieldText: "",
        label: <Label label={"Please Provide Detail"} required={!isFromClientDetail} />,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    };

    const numberTextFieldCommon = {
        isRequired: !isFromClientDetail,
        helperFieldText: "",
        component: NumberTextField,
        variant: "outlined",
        className: "TextFieldWidth dollerIcon",
        dollarSign: true,
        InputProps: {
            startAdornment: <InputAdornment
                    className="margin0 dollerIcon" position="start"
            >$</InputAdornment>,
        },
    };

    const getLast30Years = () => {
        const currentYear = new Date().getFullYear();

        const years: any = [];
        for (let index = 0; index < 30; index++) {
            const year = currentYear - index;

            years.push({
                id: year,
                key: year,
                name: year,
            });
        }

        return years;
    };

    const policy1Obj = [
        {
            fixName: "nameOfCompany",
            label: <Label label={"Name of company"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: clientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            show:true,
        },
        {
            fixName: "policyNumber",
            label: <Label label={"Policy Number"} />,
            isRequired: false,
            disabled: clientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            show:true,
        },
        {
            fixName: "yearsIssuedOrAppliedFor",
            component: DatePicker,
            disableFuture:true,
            show: isAllianzLife,
            isRequired: !isFromClientDetail,
            className: "TextFieldWidth",
            variant: "outlined",
            label: <Label label={"Date Issued or Applied For"} required={!isFromClientDetail} />,
            disabled: clientSigned,
        },
        {
            fixName: "dateIssuedOrAppliedFor",
            component: SelectField,
            className: "TextFieldWidth",
            variant: "outlined",
            show: !isAllianzLife,
            isRequired: !isFromClientDetail,
            label: <Label label={"Year Issued or Applied For"} required={!isFromClientDetail} />,
            options: getLast30Years(),
            disabled: clientSigned,
            onSelectChange: noop,
        },
        {
            fixName: "coverageFaceAmount",
            label: <Label label={"Coverage / Face Amount"} required={!isFromClientDetail} />,
            isRequired: !isFromClientDetail,
            disabled: clientSigned,
            filedType: "string",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon" position="start">$</InputAdornment>,
            },
            show:true,
        },
        {
            fixName: "adbCoverage",
            label: <Label label={"ADB Coverage"} />,
            isRequired: !isFromClientDetail,
            filedType: "string",
            disabled: clientSigned,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            show:true,
        },
    ];

	// const callingAutoSave = () => {
	// 	if (setAutoSaveTrigger && isFunc(setAutoSaveTrigger)) {
	// 		setAutoSaveTrigger(Math.random());
	// 	}
	// };
    const handleAutoSaveBlur = (e: any, handleBlur: Function) => {
		// callingAutoSave();
        handleBlur(e);
    };

    const showComponent = (question: EnrollmentFinancialAgreementProps, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors) => {
        switch (question.additionalFieldType) {
            case "selectBox":
                if (values[`${question.additionalFieldName}`] && errors[`${question.additionalFieldName}`]) {
                    delete errors[`${question.additionalFieldName}`];
                    setErrors({ ...errors });
                }
                return <>
                    {
                            question && values[question.name] === "Yes" &&
                            <Grid item xs={8} md={4}>
                                <TextFieldCommon
                                        {...policiesObj} name={question.additionalFieldName || ""}
                                        values={values}
                                        onChange={(e) => {
                                            handleChange(e);
                                            enableSaveButton();
                                        }}
                                        onBlur={(e: any) => handleAutoSaveBlur(e, handleBlur)}
                                        helperText={question.additionalHelperText ? question.additionalHelperText : ""}
                                />
                                <ErrorCommon
                                        errors={errors} name={question.additionalFieldName} touched={touched}
                                /></Grid>
                    }
                </>;
                break;
            case "multi-radio":
                let insuranceTypeValue = values["insuranceType"] || insuranceTypeVal;
                return (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RadioGroup
                                        name="insuranceType" onChange={(e) => {
                                    setFieldValue("insuranceType", e.target.value);
                                }} value={values["insuranceType"]} style={{ display: "inline" }}
                                >
                                    <Grid container spacing={2} className="checkGroupEnrollment">
                                        {[
                                            {
                                                label: "Personal Insurance",
                                                value: "personalInsurance",
                                            },
                                            {
                                                label: "Business Insurance",
                                                value: "businessInsurance",
                                            },
                                        ].map((option: any, idx: number) => (
                                                <Grid key={idx} item xs={12} md={6} lg={6}>
                                                    <FormControlLabel
                                                            className="mb0 formikRadio ml0"
                                                    disabled={!!clientSigned || isGWTGen2 || isKeyman}
                                                            value={option.value}
                                                            control={
                                                                <Radio className="mb0" />}
                                                            label={<><Typography
                                                                    variant="body1" className="mb0"
                                                            >{option.label}</Typography></>}
                                                    />
                                                </Grid>
                                        ))}
                                    </Grid>
                                    {errors[`insuranceType`] && (
                                            <ErrorCommon
                                                    errors={errors} name={`insuranceType`} touched={touched}
                                            />
                                    )}
                                </RadioGroup>
                            </Grid>
                            {values["insuranceType"] === "personalInsurance" &&
                                    <Grid item xs={12}>
                                        <FormControl
                                                component="fieldset"
                                                error={false}
                                                className={classes.formControl + " mb20 "}
                                        >
                                            <Typography
                                                    ref={insureRef}
                                                    id={"insureRef"}
                                                    variant="body1"
                                                    className="mt30 mb20"
                                                    component="strong"
                                            ><Label
                                                    label={"Personal Insurance"} required={!isFromClientDetail}
                                            /></Typography>
                                            <RadioGroup
                                                    aria-label="personalInsurance"
                                                    name="personalInsurance"
                                                    value={values["personalInsurance"]}
                                                    onChange={(e) => {
                                                        if (values["businessInsurance"] !== "") {
                                                            setFieldValue("businessInsurance", "");
                                                        }
                                                        setFieldValue("personalInsurance", e.target.value);
                                                        enableSaveButton();
                                                        setInsuranceError(false);
                                                    }}
                                            >
                                                <Grid container spacing={2}>
                                                    {
                                                        personalRadiolist.map((item, index) => {
                                                            return (
                                                                    <Grid item xs={12} md={12} lg={6}>
                                                                        <FormControlLabel
                                                                                className="formikRadio ml0 mb0"
                                                                                value={`${item.value}`}
                                                                                  disabled={!!clientSigned || isKeyman || isGWTGen2}
                                                                                control={<Radio
                                                                                        color={"primary"}
                                                                                        className="padding0 mb0"
                                                                                        onChange={e => {
                                                                                            setFieldValue("mortgageAmount", "");
                                                                                        }}
                                                                                />}
                                                                                label={<label className="displayF">
                                                                                    <Typography variant="body1" className="mb0">{item.label}
                                                                                </Typography> {
                                                                                        item.label == "Wealth-gift Transfer" &&
                                                                                        <HtmlTooltip
                                                                                                title={
                                                                                                    <React.Fragment>
                                                                                                        <Typography
                                                                                                                className="f16 txtWhite mb0"
                                                                                                        >
                                                                                                            A gift tax is a tax on the transfer of property by one individual to another while receiving nothing, or less than full value, in return. If you are paying premiums for another party other than your spouse, it may be considered a gift or transfer of wealth and may be subject to tax, unless it is within the annual exclusion amount or under the lifetime wealth transfer amount. Any amounts beyond the approved exclusion amounts would be considered a gift, and the tax applied whether or not the donor intends the transfer to be a gift. NIW does not give tax advice or guidance, please consult with a tax specialist for advice.
                                                                                                        </Typography>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                        >
                                                                                            <Info
                                                                                                    className="icon"
                                                                                                    color="primary"
                                                                                            />
                                                                                        </HtmlTooltip>
                                                                                }</label>}
                                                                        />


                                                                    </Grid>);
                                                        })
                                                    }
                                                    {
                                                            (touched && errors) &&
                                                            <ErrorCommon
                                                                    errors={errors}
                                                                    name="personalInsurance"
                                                                    touched={touched}
                                                            />
                                                    }
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>

                                        {/*<TextFieldCommon*/}
                                        {/*        {...mortgageTextField}*/}
                                        {/*        values={values}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*        onBlur={handleBlur}*/}
                                        {/*/>*/}
                                        {/*<ErrorCommon errors={errors} name={mortgageTextField.name}*/}
                                        {/*             touched={touched}/>*/}
                                        {insuranceError &&
                                                <p style={{ color: "#FF1744", fontSize: "12px" }}> Personal Insurance or
                                                    Business Insurance from both one is required </p>
                                        }
                                    </Grid>
                            }

                            {(offerType === "gi" || offerType === "si") && isAssociation ?
                                    <React.Fragment></React.Fragment>
                                    :
                                    <React.Fragment>
                                        {values["insuranceType"] === "businessInsurance" &&
                                                <Grid item xs={12} lg={10} xl={8} className="mb20">
                                                    <FormControl
                                                            component="fieldset"
                                                            error={false}
                                                            className={classes.formControl}
                                                    >
                                                        <Typography
                                                                variant="body1"
                                                                className="mt30 mb20"
                                                                component="strong"
                                                        >
                                                            <Label
                                                                    label={"Business Insurance"}
                                                                    required={!isFromClientDetail}
                                                            /></Typography>
                                                        <RadioGroup
                                                                aria-label="businessInsurance"
                                                                name="businessInsurance"
                                                                value={values["businessInsurance"]}
                                                                onChange={(e) => {
                                                                    if (values["personalInsurance"] !== "") {
                                                                        setFieldValue("personalInsurance", "");
                                                                    }
                                                                    setFieldValue("businessInsurance", e.target.value);
                                                                    enableSaveButton();
                                                                    setInsuranceError(false);
                                                                }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                {
                                                                    businessInsurance.map((item, index) => {
                                                                        return <Grid
                                                                                item
                                                                                xs={12}
                                                                                md={12}
                                                                                lg={6}
                                                                                style={{ display: "flex" }}
                                                                        ><FormControlLabel
                                                                                className="ml0 mb0 formikRadio"
                                                                                value={`${item.value}`}
                                                                disabled={clientSigned || isKeyman ? true : false}
                                                                                control={<Radio
                                                                                        color={"primary"}
                                                                                        className="padding0 mb0"
                                                                                />}
                                                                                label={<span
                                                                                        className="mb0"
                                                                                >{item.label}</span>}
                                                                        />
                                                                        </Grid>;
                                                                    })
                                                                }
                                                                {
                                                                        (touched && errors) &&
                                                                        <ErrorCommon
                                                                                errors={errors}
                                                                                name="businessInsurance"
                                                                                touched={touched}
                                                                        />
                                                                }
                                                            </Grid>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                        }

                                        {
                                                (
                                                        (values && values["personalInsurance"] && values["personalInsurance"] === "Other" && isLongForm(offerType)
                                                        ) || (!isLongForm(offerType) && !isKeyman)) &&
                                                <Grid item xs={12}>
                                                    <TextFieldCommon
                                                            {...secQuesTextField}
                                                            values={values}
                                                            onChange={handleChange}
                                                            onBlur={e => handleAutoSaveBlur(e, handleBlur)}
                                                    />
                                                    <ErrorCommon
                                                            errors={errors}
                                                            name={secQuesTextField.name}
                                                            touched={touched}
                                                    />
                                                </Grid>
                                        }
                                        {
                                                (
                                                        values && values["personalInsurance"] &&
                                                        values["personalInsurance"] === "Mortgage Protection" &&
                                                        (isLongForm(offerType) || isWorksheet(offerType))
                                                ) &&
                                                <Grid item xs={12}>
                                                    <TextFieldCommon
                                                            {...mortgageProtectionTextField}
                                                            values={values}
                                                            onChange={handleChange}
                                                            onBlur={e => handleAutoSaveBlur(e, handleBlur)}
                                                    />
                                                    <ErrorCommon
                                                            errors={errors}
                                                            name={mortgageProtectionTextField.name}
                                                            touched={touched}
                                                    />
                                                </Grid>
                                        }

                                        { faceAmountExplanation.visible &&
                                            <Grid item xs={12}>
                                                <TextFieldCommon
                                                        {...faceAmountExplanation} values={values} onChange={(e) => {
                                                    handleChange(e);
                                                    enableSaveButton();
                                                }} onBlur={handleBlur}
                                                />
                                                <ErrorCommon
                                                        errors={errors} name={faceAmountExplanation.name}
                                                        touched={touched}
                                                />
                                            </Grid>
                                        }

                                    </React.Fragment>
                            }

                        </Grid>
                );
                break;
            case "textField":
                return (
                        <>
                            {
                                    question && values[question.name] === "Yes" &&
                                    <Grid
                                            item
                                            xs={question.additionalFieldName === "Insured_question-7-text" || question.additionalFieldName === "inforceLifeDesc" ? 12 : 9}
                                    >
                                        <TextFieldCommon
                                                {...textFieldCommon} id={`${question.additionalFieldName}`}
                                                name={`${question.additionalFieldName}`}
                                                values={values}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    enableSaveButton();
                                                }}
                                                onBlur={e => handleAutoSaveBlur(e, handleBlur)}
                                        />
                                        <ErrorCommon
                                                errors={errors} name={question.additionalFieldName} touched={touched}
                                        />
                                        {
                                                question.additionalFieldName === "Insured_question-7-text" &&
                                                <Typography className="f12 mt10">
                                                    Note: You can’t do a 1035 exchange. If you are using money from
                                                    another
                                                    policy you
                                                    would have to surrender the policy first.
                                                </Typography>
                                        }
                                    </Grid>
                            }
                        </>
                );
                break;
            case "numberTextField":
                let disabled: any = {};
                if (question.additionalFieldName === "inforceAmount") {
                    disabled = {
                        disabled: !!(values["inforceAmountNone"] || clientSigned),
                    };
                }
                if (question.additionalFieldName === "liAmount") {
                    disabled = {
                        disabled: !!(values["liAmountNone"] || clientSigned),
                    };
                }
                return (
                        <>
                            <Grid item xs={12} lg={6} xl={4}>
                                <TextFieldCommon
                                        {...numberTextFieldCommon}
                                        {...disabled} id={`${question.additionalFieldName}`}
                                        name={`${question.additionalFieldName}`}
                                        label={<Label label={"Enter Amount"} required={!isFromClientDetail} />}
                                        values={values}
                                        onChange={(e) => {
                                            handleChange(e);
                                            enableSaveButton();
                                        }}
                                        onBlur={e => handleAutoSaveBlur(e, handleBlur)}
                                />
                                <ErrorCommon
                                        errors={errors} name={question.additionalFieldName} touched={touched}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{ display: "flex" }} className="mt15 ml35">
                                    <Checkbox
                                            style={{ height: "28px", marginRight: "10px" }}
                                            disabled={!!clientSigned}
                                            className="floatLeft pd0 mr10"
                                            color={"primary"}
                                            value={values[`${question.additionalCheckBoxName}`]}
                                            name={`${question.additionalCheckBoxName}`}
                                            checked={values[`${question.additionalCheckBoxName}`]}
                                            onChange={(e) => {
                                                setFieldValue(`${question.additionalFieldName}`, "");
                                                setFieldValue(`${question.additionalCheckBoxName}`, e.target.checked);
                                                enableSaveButton();
                                            }}
                                            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                                    />
                                    <Typography variant="caption" style={{ marginTop: 2 }}>
                                        <span> None </span>
                                    </Typography>
                                </div>
                            </Grid>
                        </>
                );
            case "checkBoxGroup":
                return <InsuredPageCheckBox question={question} clientSigned={clientSigned} />;
                break;
            case "radioBoxGroup":
                return <InsuredPageRadioButton question={question} clientSigned={clientSigned} />;
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (buttonRef.current) {
            setQuestionButtonRef(buttonRef);
        }
    }, [buttonRef.current]);

    const enableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "block";
        }
    };

    const disableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "none";
        }
    };

    /*let scp:any = {
        label: <Label label={"Surrender Charge Percentage"}/>,
        placeHolder: "Surrender Charge Percentage",
        component: NumberTextFieldWithCb,
        variant: "outlined",
        allowMax100: true,
    };*/

    const getPolicyGrids = (values, handleChange, handleBlur, errors, touched, setFieldValue) => {
        let noOfInforcePolicies: number[] = [];
        for (let i = 1; i <= Number(values["noOfInforcePolicies"]); i++) {
            noOfInforcePolicies.push(i);
        }

        return noOfInforcePolicies.map((val, inforceIndex) => {
            return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                        variant="body1" className="mb0 floatLeft mt20" component="strong"
                                >Policy
                                    {`  ${inforceIndex + 1}`}</Typography>
                            </Grid>
                            {
                                policy1Obj.map(( item, index) => {
                                    let fieldProps: any = {
                                        id: `inForcePolicies[${inforceIndex}].${item.fixName}`,
                                        name: `inForcePolicies[${inforceIndex}].${item.fixName}`,
                                        values: values,
                                    };
                                    if (values && values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex][item.fixName]) {
                                        if (item.filedType !== "date") {
                                            fieldProps = {
                                                ...fieldProps,
                                                value: (values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex][item.fixName] + "") || "",
                                            };
                                        } else {
                                            fieldProps = {
                                                ...fieldProps,
                                                value: (values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && moment(values.inForcePolicies[inforceIndex][item.fixName])
                                                        .format("MM-DD-YYYY") + "") || moment().format("MM-DD-YYYY"),
                                            };

                                        }
                                    }
                                    if (item.filedType === "date" && !values.inForcePolicies[inforceIndex]) {
                                        setFieldValue(`inForcePolicies[${inforceIndex}][${item.fixName}]`, moment()
                                                .format("MM-DD-YYYY"));
                                    }
                                    return (
                                            <>
                                            { item["show"] &&  <Grid key={index} item xs={12} md={6} xl={3}>
                                                <TextFieldCommon
                                                        {...item}
                                                        {...fieldProps}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            enableSaveButton();
                                                        }}
                                                />
                                                {errors.inForcePolicies && errors.inForcePolicies[inforceIndex] && errors.inForcePolicies[inforceIndex][item.fixName] && (
                                                        <ErrorCommon
                                                                isMultiLevel={true}
                                                                errors={errors.inForcePolicies[inforceIndex][item.fixName]}
                                                                name={`inForcePolicies[${inforceIndex}].${item.fixName}`}
                                                        />
                                                )}
                                            </Grid>
                                            }
                                            </>
                                    );
                                })
                            }
                        </Grid>
                        <ExistingPolicy inforceIndex={inforceIndex} clientSigned={clientSigned} enableSaveButton={enableSaveButton} />
                    </>
            );
        });
    };

    const insuredQuestionPayload = (values: any) => {
        let inForcePolPayload = values.inForcePolicies && values.inForcePolicies.length > 0 ? values.inForcePolicies.map((item: any) => {
            return {
                ...item,
                status: item.status ? item.status : "In Force",
                type: item.type ? item.type : "Life",
                willBothPoliciesBeTaken: item.status === "In Force" ? "No" : item.willBothPoliciesBeTaken ? item.willBothPoliciesBeTaken : "Yes",
                willThisBeReplaced: item.willThisBeReplaced ? item.willThisBeReplaced : null,
                dateIssuedOrAppliedFor: (item.dateIssuedOrAppliedFor + "") || moment().format("YYYY"),
                yearsIssuedOrAppliedFor: ((item.yearsIssuedOrAppliedFor && moment(item.yearsIssuedOrAppliedFor).format("YYYY-MM-DD")) || moment().format("YYYY-MM-DD")),
                coverageFaceAmount: Number(unmaskCurrency(item.coverageFaceAmount)),
                nameOfCompany: item.nameOfCompany ? item.nameOfCompany + "" : "",
                policyNumber: item.policyNumber ? item.policyNumber + "" : "",
                adbCoverage: item.adbCoverage ? item.adbCoverage + "" : "",
                surrenderChargePercentage: item.willThisBeReplaced === "Yes" ? item.surrenderChargePercentage : null
            };
        }) : [];
        let payload: any = {
            insuredAgreed: pageChecked ? 1 : 0,
            noOfPolicies: values.noOfPolicies || 0,
            existPolicies: values.existPolicies === "Yes" ? 1 : 0,
            insuranceType: values.insuranceType || null,
            personalInsurance: values.personalInsurance || null,
            businessInsurance: values.businessInsurance || null,
            mortgageAmount: values.mortgageAmount ? Number(unmaskCurrency(values.mortgageAmount)) : null,
            insuranceDescription: values.insuranceDescription,
            inforcePolicy: values.inforcePolicy === "Yes" ? 1 : 0,
            noOfInforcePolicies: values.noOfInforcePolicies || 0,
            inforceAmount: values.inforceAmount ? Number(unmaskCurrency(values.inforceAmount)) : null,
            liAmount: values.liAmount ? Number(unmaskCurrency(values.liAmount)) : null,
            inforceLifeDesc: values.inforceLifeDesc ? values.inforceLifeDesc : null,
            isInforceLife: values.isInforceLife === "Yes" ? 1 : 0,
            inForcePolicies: offerType !== "gi" && values.inforcePolicy === "Yes" && values.noOfInforcePolicies ? inForcePolPayload : [],
        };
        return payload;
    };

    function prepareDataForEnrollmentAnswers(values: any, payload: any) {
        for (const [key] of Object.entries(payload)) {
            delete values[key];
        }
        return values;
    }

    const applicationQuestionPayload = async (values: any, showMessage: boolean = true) => {
        if (!!(enrollment && enrollment.id)) {
            let payload: any = {
                ...values,
                id: enrollment && enrollment.id,
                userId: enrollment && enrollment.userId ? enrollment.userId : clientDetail && clientDetail.userId,
            };
            await enrollmentActions.updateEnrollment(payload, showMessage);
        }
        return true;
    };

    const saveMe = async (values: any, isAutoSave?: boolean) => {
        if (isSaving) {
            return;
        }
        setIsSaving(true);
        let payload: any = {};
        let enrollmentAnswersPayload: any = {};
        // We have to choose only 1 from Personal and business insurance
        let isInsuranceValidation = !((offerType === "gi" || offerType === "si") && isCorporation);
        if (!!(!values.personalInsurance && !values.businessInsurance && insureRef && insureRef.current && isInsuranceValidation && !isFromClientDetail)) {
            setInsuranceError(true);
            setIsSubmittedQuestions(false);
            scroller.scrollTo(insureRef.current.id, {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: -200,
            });
            return false;
        }
        payload = insuredQuestionPayload({ ...values });
        enrollmentAnswersPayload = prepareDataForEnrollmentAnswers({ ...values }, payload);
        await applicationQuestionPayload(enrollmentAnswersPayload);
        if (!isAutoSave) {
            await updateClientFields({
                ...payload,
                formType: "INSURED_QUESTIONS",
            }, (user && user.client && user.client.id) || "", isFromClientDetail);
        }
        disableSaveButton();
        if (typeof setActionItem === "function" && isFromClientDetail) {
            setActionItem();
        }


        sleep().then(() => setIsSaving(false));
    };

    return (
            <>
                {openInforceLifeAlertPopup &&
                        <InforceLifeAlertPopup
                                open={openInforceLifeAlertPopup} setOpen={(open) => setOpenInforceLifeAlertPopup(open)}
                        />
                }
                {!isFromClientDetail &&
                        <Grid container spacing={2}>
                            {/*START GI CONDITION*/}
                    {(offerType !== 'gi' || isKeyman) &&
                                    <AttestationOfTruth
                                            checked={!!clientDetail.insuredAgreed}
                                            setPageChecked={setPageChecked}
                                            setAttestRef={setAttestRef}
                                    />
                            }
                        </Grid>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <InsuredQuestionsForm
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                pageChecked={pageChecked}
                                isFromClientDetail={isFromClientDetail}
                                offerType={offerType}
                                insureRef={insureRef}
                                setInsuranceError={setInsuranceError}
                                setIsSubmittedQuestions={setIsSubmittedQuestions}
                                updateClientFields={updateClientFields}
                                enableSaveButton={enableSaveButton}
                                showComponent={showComponent}
                                getPolicyGrids={getPolicyGrids}
                                buttonRef={buttonRef}
                                user={user}
                                setActionItem={setActionItem}
                                setNextStep={setNextStep}
                                disableSaveButton={disableSaveButton}
                                isAssociation={isAssociation}
                                isKeyman={isKeyman}
                                clientSigned={clientSigned}
                                isCorporation={isCorporation}
                                nationalLifeId={nationalLifeId}
                                setOpenInforceLifeAlertPopup={setOpenInforceLifeAlertPopup}
                                insuredQuestions={insuredQuestions}
                                insuredQuestionPayload={insuredQuestionPayload}
                                saveMe={saveMe}
								setAutoSaveTrigger={setAutoSaveTrigger}
                        />
                    </Grid>
                </Grid>
            </>
    );
};

export default InsuredQuestionsContent;