import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import theme from "./theme";

const TriZenTheme = createMuiTheme({

    palette: {
        primary: {
            light: "#63c756",
            main: "#3A9E2D",
            dark: "#107903",
            contrastText: "#fff",
        },
        secondary: {
            light: "#FFFFFF",
            main: "#FFFFFF",
            dark: "#FFFFFF",
            contrastText: "#000000",
        },
    },

    typography: {
        h1: {
            fontFamily: "'Playfair Display', serif",
            marginBottom: "1.3rem",
            [theme.breakpoints.up("xs")]: {
                fontSize: "34px",
                fontWeight: "900",
                lineHeight: "42px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "42px ",
                fontWeight: "900 ",
                lineHeight: "63px ",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "60px",
                fontWeight: "900 ",
                lineHeight: "80px",
                letterSpacing: "0 ",
            },

        },
        h2: {
            fontFamily: "'Playfair Display', serif ",
            marginBottom: "1.3rem ",
            color: "#424242",
            [theme.breakpoints.up("xs")]: {
                fontSize: "30px ",
                fontWeight: "900 ",
                letterSpacing: "-0.25px ",
                lineHeight: "45px ",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "42px ",
                fontWeight: "900 ",
                letterSpacing: "0 ",
                lineHeight: "63px ",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "48px",
                letterSpacing: "-0.4px",
                lineHeight: "62px",
            },
        },
        h3: {
            fontFamily: "'Playfair Display', serif ",
            marginBottom: "1.3rem ",
            [theme.breakpoints.up("xs")]: {
                fontSize: "26px ",
                fontWeight: "900",
                lineHeight: "39px ",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "42px",
                fontWeight: "900 ",
                lineHeight: "55px",
            },
        },
        h4: {
            fontFamily: "'Roboto', sans-serif ",
            marginBottom: "1.3rem ",
            [theme.breakpoints.up("xs")]: {
                fontSize: "24px ",
                lineHeight: "36px ",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "34px ",
                letterSpacing: "0.07px ",
                lineHeight: "39px ",
            },
        },
        h5: {
            [theme.breakpoints.up("xs")]: {
                fontSize: "22px",
                fontFamily: "'Roboto', sans-serif",
                letterSpacing: "0",
                lineHeight: "33px",
                marginBottom: "1.3rem",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "22px",
                fontFamily: "'Roboto', sans-serif",
                letterSpacing: "0",
                lineHeight: "33px",
                marginBottom: "1.3rem",
            },
        },
        h6: {
            fontFamily: "'Roboto', sans-serif",
            [theme.breakpoints.up("xs")]: {
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: "500",
                letterSpacing: "0.07px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "20px ",
                fontWeight: "500 ",
                letterSpacing: "0.07px ",
                lineHeight: "32px ",
            },
        },
        body1: {
            fontFamily: "'Roboto', sans-serif ",
            marginBottom: "1.3rem ",
            [theme.breakpoints.up("xs")]: {
                fontSize: "16px",
                lineHeight: "24px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "18px",
                lineHeight: "27px",
            },
        },
        body2: {
            fontFamily: "'Roboto', sans-serif ",
            [theme.breakpoints.up("xs")]: {
                fontSize: "14px",
                lineHeight: "21px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "16px",
                letterSpacing: "0 ",
                lineHeight: "24px",
            },
        },
        subtitle1: {
            fontFamily: "'Roboto', sans-serif",
            [theme.breakpoints.up("xs")]: {
                fontSize: "18px",
                lineHeight: "27px",
                letterSpacing: "0.1px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "20px",
                letterSpacing: "0.11px",
                lineHeight: "30px",
                marginBottom: "1.3rem",
            },
        },
        subtitle2: {
            fontFamily: "'Roboto', sans-serif",
            [theme.breakpoints.up("xs")]: {
                fontSize: "18px",
                lineHeight: "27px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "18px",
                letterSpacing: "0.12px",
                lineHeight: "27px",
                marginBottom: "1.3rem",
            },
        },
        caption: {
            [theme.breakpoints.up("xs")]: {
                fontSize: "14px",
                letterSpacing: "0.4px",
                lineHeight: "21px",
                fontFamily: "'Roboto', sans-serif",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "16px",
                letterSpacing: "0.4px",
                lineHeight: "24px",
                fontFamily: "'Roboto', sans-serif",
            },

        },
        overline: {
            [theme.breakpoints.up("xs")]: {
                fontSize: "14px",
                fontWeight: "bold",
                letterSpacing: "0.97px",
                lineHeight: "21px",
                marginBottom: "1.3rem",
                fontFamily: "'Roboto', sans-serif",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "16px",
                fontWeight: "bold",
                letterSpacing: "1.11px",
                lineHeight: "24px",
                marginBottom: "1.3rem",
                fontFamily: "'Roboto', sans-serif",
            },
        },
    },

    overrides: {
        MuiFormHelperText: {
            root: {
                fontSize: "12px !important",
                lineHeight: "14px !important",
            },
        },
        MuiListItemText: {
            primary: {
                marginBottom: "0",
            },
        },
        MuiFormControl: {
            root: {
                display: "flex",
            },
        },
        MuiDialog: {
            paper: {
                margin: 10,
            },
        },
        MuiDialogActions: {
            root: {
                display: "flex",
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: "#2f2f2f",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#ffffff",
                fontWeight: "normal",
                margin: "0",
                boxShadow: "0 0 15px 0px #8c8c8c",
            },
            arrow: {
                color: "#2f2f2f",
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "rgb(255 255 255 / 70%)",
            },
        },
        MuiIconButton: {
            root: {
                padding: "0 4px",
            },
        },

        MuiListItem: {
            secondaryAction: {
                paddingBottom: "0",
                paddingTop: "0",
                paddingRight: "192px",
            },
        },
        MuiButton: {
            root: {
                textTransform: "none",
                padding: "10px 50px",
                color: "#ffffff",
                borderRadius: 50,
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "normal",
                fontFamily: "'Roboto', sans-serif",
                whiteSpace: "pre",
            },
            containedSizeSmall: {
                padding: "6px 25px",
                fontSize: "16px",
            },
            containedSizeLarge: {
                padding: "15px 25px",
            },
            containedSecondary: {
                backgroundColor: "#cfcfcf",
            },
            contained: {
                boxShadow: "none",
            },
            outlined: {
                paddingTop: "15px",
                paddingBottom: "15px",
                fontWeight: "bold",
            },
            outlinedSizeSmall: {
                padding: "6px 25px",
                minWidth: "200px",
                fontSize: "18px",
                fontWeight: 500,
            },
            fullWidth: {
                maxWidth: "100%",
            },
        },

        MuiInputBase: {
            root: {
                marginBottom: 0,
                background: "#ffffff",
            },
            input: {
                height: "18px",
                fontSize: "18px !important",
                marginBottom: 0,
                lineHeight: "20px",
                background: "#ffffff",
            },
        },
        MuiSelect: {
            selectMenu: {
                marginBottom: 0,
                height: "18px",
                fontSize: "16px",
                lineHeight: "20px",
                width: "100%",
            },
        },
        MuiFormLabel: {
            root: {
                color: "#000 !important",
                fontSize: "16px",
                lineHeight: "14px !important",
                whiteSpace: "pre",
                marginBottom: "0 !important",
            },
        },
        MuiTab: {
            wrapped: {
                fontSize: 16,
            },
            wrapper: {
                display: "inline-block",
            },

        },
        MuiListItemIcon: {
            root: {
                minWidth: "auto",
            },
        },

        MuiCardHeader: {
            content: {
                tittle: {
                    color: "#2C2C2C",
                    fontSize: 20,
                    fontWeight: "bold",
                    letterSpacing: 0,
                    lineHeight: 30,
                },
            },
        },
        MuiMenuItem: {
            root: {
                marginBottom: 0,
                minHeight: 20,
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 16,
                color: "#263238",
            },
            formControl: {
                color: "#263238 !important",
                lineHeight: "18px !important",
            },
            shrink: {
                transform: "translate(14px, -6px) scale(0.90) !important",
                background: "#ffffff !important ",
                padding: "0 5px",
            },
        },
        MuiAppBar: {
            colorDefault: {
                backgroundColor: "#FFFFFF",
            },
        },
        MuiStepLabel: {
            label: {
                fontSize: "18px !important",
            },
        },
        MuiTableCell: {
            root: {
                whiteSpace: "pre",
            },
        },
    },
});


export const TriZenThemeGlobalCss = withStyles({
    "@global": {
        " .colorRed": {
            color: "red !important",
        },
        ".MuiAppBar-colorPrimary ": {
            color: "#323232 !important",
            backgroundColor: "#fafafa !important",
            padding: 15,
            boxShadow: "0 15px 20px -22px #8c8c8c !important",
            [theme.breakpoints.up(992)]: {
                padding: "0 10px !important",
            },
        },
        ".react-multiple-carousel__arrow": {
            background: "#4CAF50 !important",
        },
        "body": {
            background: "#FFFFFF",
            fontFamily: "Roboto, Arial, sans-serif !important",
            fontSize: "16px",
            overflowX: "hidden",
        },
        ".graphMap": {
            width: "100%",
            margin: "0 auto",
            height: "400px",
        },
        ".textHighLight": {
            color: "#4CAF50",
        },
        "button.Mui-selected.active,.drawerClose:hover": {
            background: "#4CAF50",
            color: "#ffffff",
        },
        "button.active:hover": {
            background: "#4CAF50",
            color: "#ffffff",
        },
        ".textHighLightGreen": {
            color: "#3DDB93",
        },
        ".scrollRight, .scrollRightMini,.scrollRight:hover, .scrollRightMini:hover": {
            backgroundColor: "#4CAF50",
        },
        ".closeicon": {
            background: "#4CAF50",
        },
        ".marginNone p": {
            margin: "0",
        },
        ".FormForMobile": {
            background: "#3A9E2D",
        },
        ".estimationTabs .estimationHeader": {
            background: "#3A9E2D",
        },
        ".estimationTabs .estimationHeader button": {
            background: "#3A9E2D",
            opacity: "1",
            color: "#ffffff",
        },
        ".estimationTabs .estimationHeader button .tabsIcons ": {
            border: "2px solid transparent !important",
        },
        ".estimationTabs .estimationHeader button.Mui-selected .tabsIcons ": {
            background: "transparent",
            border: "2px solid #3A9E2D !important",
            color: "#3A9E2D",
        },
        ".estimationTabs .estimationHeader button.Mui-selected ": {
            color: "#3A9E2D !important",
        },
        ".tabsIcons": {
            color: "#3A9E2D",
        },
        ".strategyAndAlternatives table thead tr th:nth-child(2)": {
            background: "#3A9E2D",
        },
        ".requiredHighlight": {
            color: "#FF1744 !important",
        },
        ".backBtn svg": {
            color: "#3A9E2D !important",
        },
        ".dollerIcon p": {
            margin: "0",
        },
        ".LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active, .LeftNavigation .menuHead.MuiListItem-gutters.active": {
            color: "#3A9E2D !important",
            background: "rgba(0, 0, 0, 0.07) !important",
            borderLeft: "4px solid #3A9E2D",
        },
        ".enrollmentServicing": {
            background: "url(\"../images/landing_page_v2/Shape.png\"),linear-gradient(135deg, #4CAF50 0%,#107A49 100%)",
            minHeight: "350px",
            backgroundPosition: "bottom right",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "50px 15px 0",
            borderRadius: "8px",
            margin: "0 15px 50px",
        },
        ".MuiLink-underlineHover": {
            cursor: "pointer",
        },
        ".enrollmentTrizen .pulsing-shadow ": {
            background: "var(--greentrizen)",
        },
        ".enrollmentTrizen .first-marker, .enrollmentTrizen .last-marker, .enrollmentTrizen .section-marker": {
            border: "10px solid var(--greentrizen)",
        },
        "@media(max-width:1456px)": {
            ".dashBoardHero": {
                backgroundPosition: "bottom right",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
            },
            ".clientClasses .heroBgFooter": {
                background: "#FFFFFF",
            },
        },
        "@media (max-width: 992px)": {
            ".graphMap": {
                width: "1200px",
                margin: "0 auto",
                height: "250px",
                paddingBottom: "10px",
            },
        },
        "@media (max-width: 767px)": {
            ".estimationTabs .estimationHeader": {
                background: "transparent",
            },
        },


        //ClientCSS====== 7/Dec/2021
        ".highlightTxt": {
            color: "#3A9E2D !important",
        },
        ".clientClasses .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary": {
            color: "#323232 !important",
        },
        ".clientClasses .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active svg.MuiSvgIcon-root": {
            color: "#3A9E2D !important",
        },
        ".clientClasses .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary, .menuHead.active .MuiListItemText-root .MuiListItemText-primary span": {
            color: "#3A9E2D  !important",
        },
        ".clientClasses .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active": {
            background: "#ededed !important",
            borderLeft: "4px solid #3A9E2D",
        },
        ".clientClasses .LeftNavigation .menuHead.MuiListItem-gutters.active": {
            background: "#ededed !important",
        },
        ".clientClasses .subList .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active svg.MuiSvgIcon-root": {
            color: "#ecb339 !important",
        },
        ".chatBtn,.scroll-downs,.clientClasses .btnBack:hover,.clientClasses .plansCard.hover,.clientClasses .plansCard.hover:before,.clientClasses .plansCard.hover:after,.participantsTabs button.Mui-selected,.chatHeader": {
            background: "#3A9E2D",
        },
        ".clientClasses.LeftNavigation.MuiPaper - root.MuiDrawer - paper.MuiDrawer - paperAnchorLeft.MuiDrawer - paperAnchorDockedLeft,.clientClasses.LeftNavigation.MuiPaper - root.MuiDrawer - paper.MuiDrawer - paperAnchorLeft": {
            backgroundColor: "#FFFFFF",
        },
        ".clientClasses .LeftNavigation .MuiListItem-button:hover,": {
            backgroundColor: "#ededed !important",
        },
        ".clientClasses .subList .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:hover": {
            backgroundColor: "#ededed !important",
        },
        ".clientClasses .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button svg.MuiSvgIcon-root,": {
            color: "#323232 !important",
        },
        // ".testimonials .muiCard:hover p, .testimonials .muiCard:hover h4": {
        //     color: "#ffffff !important",
        // },
        ".clientClasses .ulStyling ul.blueDot li:before, ul.groupLink.blueDot li:before": {
            border: "4px solid #3A9E2D",
        },
        ".chatHeader button": {
            background: "#3A9E2D ",
        },
        ".chatHeader button:hover,.chatBtn:hover": {
            background: "#107903 ",
        },
        // ".testimonials .muiCard:hover": {
        //     background: "#3A9E2D !important",
        //     border: "1px solid #3A9E2D;",
        // },
        ".clientClasses .annualContributions input,.cardHeader h4,.clientClasses .plansCard strong.icon svg": {
            color: "#3A9E2D !important",
        },
        ".clientClasses .annualContributions": {
            color: "#3A9E2D !important",
        },
        ".clientClasses button.btnPrimary,.clientClasses button.btnPrimaryBig": {
            background: "#3A9E2D",
        },
        ".clientClasses button.btnPrimary:hover,.clientClasses button.btnPrimaryBig:hover,button.compareBtn span + span:after": {
            background: "#107903 !important",
        },
        ".clientClasses button.btnPrimary.Mui-disabled.Mui-disabled,.clientClasses button.btnPrimaryBig.Mui-disabled.Mui-disabled": {
            background: "rgba(0, 0, 0, 0.12)",
        },
        ".animated-box:after": {
            background: "linear-gradient(120deg, #ECB339, #107903, #ECB339)",
            backgroundSize: "300% 300%",
            clipPath: "polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%)",
        },
        ".clientClasses .bordertop": {
            borderTop: "1px solid #63c756",
        },
        ".clientClasses .customeTabs .MuiTab-textColorPrimary.Mui-selected": {
            background: "#3A9E2D",
        },
        ".clientClasses .listView:hover li,.clientClasses .listView:hover:before,.clientClasses .listView:hover:after,.clientClasses .listView:hover li:nth-child(2)": {
            background: "#3A9E2D !important",
        },
        ".clientClasses .listView:hover li div h6 span.highlightTxt,.clientClasses .listView:hover li div h6 span.textBlack ": {
            color: "#FFFFFF !important",
        },
        ".clientClasses .listView li div svg": {
            color: "#3A9E2D !important",
        },
        ".clientClasses .listViewhead li,.clientClasses .listView li,.clientClasses .graphView li": {
            background: "#dbffd6",
        },
        ".clientClasses .listView:hover li div svg": {
            color: "#ffffff !important",
        },
        ".clientClasses .modalCSS button.slideBtn": {
            background: "#3A9E2D !important",
        },
        ".clientClasses .modalCSS": {
            borderBottom: "5px solid #3A9E2D !important",
        },
        ".clientClasses .listView li h6": {
            color: "#3A9E2D",
        },
        ".clientClasses .customStyleTabs button.Mui-selected": {
            // background: "#3A9E2D",
            // color:'#ffffff',
        },
        ".customStyleTabs .btn.Mui-selected span:nth-child(2)": {
            borderBottom: "5px solid #3A9E2D !important", color:"#ffffff",
        },

        ".clientClasses .muiCardLightBlue, .clientClasses .muiCardLightBlue": {
            background: "#E2F0D9",
        },
        ".clientClasses .customStyleTabs button": {
            // background: "#E2F0D9",
            color:'#000'
        },
        ".imgBox": {
            background: "url('../images/user-pic-holder-testimonial-hover.png')",
            backgroundRepeat: "no-repeat",
        },
        ".clientClasses h2": {
            color: "#3A9E2D",
        },
        ".muiCardContent.userImg:hover .userImgDelt": {
            background: "#99e18f",
        },
        ".muiCardContent.userImg:hover .editIcon": {
            background: "#99e18f",
        },
        ".muiCardContent.userImg .userImgDelt:hover": {
            background: "#3A9E2D",
        },
        ".txtWhite": {
            color: "#ffffff !important",
        },
        ".Mui-checked": {
            color: "#3A9E2D !important",
        },
        ".colorBlue": {
            color: "#3A9E2D",
        },
        ".enrollNow .RadioGroup label span svg": {
            color: "#3A9E2D",
        },
        ".customModalContent strong": {
            color: "#3A9E2D",
        },
        ".remarks label": {
            color: "#3A9E2D !important",
        },
        ".remarks input": {
            background: "#dbffd6",
        },
        ".textBlue": {
            color: "#3A9E2D",
        },
        ".menuHead span": {
            color: "#323232",
        },
        ".active.menuHead": {
            borderLeft: "4px solid #3A9E2D",
        },
        ".menuHead.active span": {
            color: "#3A9E2D !important",
        },
        ".menuHead svg": {
            color: "#323232 !important",
        },
        ".menuHead.active svg": {
            color: "#3A9E2D !important",
        },
        ".clientClasses .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button svg": {
            color: "#323232 !important",
        },
        ".dashBoardHero": {
            background: "url(\"../images/landing_page_v2/welcome-card-imageTriZEN.png\"),linear-gradient(135deg, #4CAF50 0%,#107A49 100%)",
            width: "100%",
            minHeight: "350px",
            backgroundPosition: "top right",
            backgroundSize: "cover",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "15px",
            borderRadius: "8px",
        },
        ".dashBoardHero .tittle:before": {
            top: "-20px !important",
        },
        ".clientClasses .dashBoardHero h3": {
            color: "#FFFFFF !important",
        },
        ".clientClasses .dashBoardHero p": {
            color: "#FFFFFF !important",
        },
        ".clientClasses .dashBoardCard": {
            minHeight: "350px",
            position: "relative",
            textAlign: "left",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "0",
            borderRadius: "8px",
        },
        ".clientClasses .dashBoardCard p": {
            minHeight: "130px",
            color: "#2C2C2C",
            fontFamily: "'Roboto', sans-serif !important",
            fontSize: "18px",
            letterSpacing: "0",
            lineHeight: "27px !important",
        },
        ".clientClasses .dashBoardCard span": {
            color: "#2C2C2C !important",
            fontFamily: "'Roboto', sans-serif !important",
            fontSize: "22px !important",
            fontWeight: "bold !important",
            letterSpacing: "0 !important",
            lineHeight: "33px !important",
        },
        ".clientClasses .dashBoardCard button": {
            padding: "10px 30px !important",
            borderRadius: "21px !important",
            textTransform: "capitalize !important",
        },
        ".clientClasses .dashBoardCard button span": {
            color: "#FFFFFF !important",
            fontFamily: "'Roboto', sans-serif !important",
            fontSize: "18px !important",
            fontWeight: "500 !important",
            letterSpacing: "0.36px !important",
            lineHeight: "26px !important",
            textAlign: "center !important",
        },
        ".clientClasses .heroBg": {
            position: "relative !important",
            top: "-39px !important",
            backgroundSize: "cover !important",
            width: "80% !important",
            right: "-184px !important",
        },
        ".clientClasses .heroBgFooter": {
            background: "url(\"../images/landing_page_v2/woman-at-desk-with-phone.jpg\") no-repeat",
            width: "100%",
            minHeight: "300px",
            backgroundPosition: "top right",
            backgroundSize: "cover",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "15px",
            borderRadius: "8px",
        },
        ".clientClasses .heroBgFooter h6": {
            color: "#2C2C2C",
            fontfamily: "'Roboto', sans-serif !important",
            fontSize: "22px",
            fontWeight: "bold",
            letterSpacing: "0",
            lineHeight: "33px",
        },
        ".clientClasses .heroBgFooter p": {
            color: "#2C2C2C",
            fontFamily: "'Roboto', sans-serif !important",
            fontSize: "18px",
            letterSpacing: "0",
            lineHeight: "27px",
        },
        ".clientClasses .customeTabs button": {
            border: '3px solid #3A9E2D',
            color: '#3A9E2D',
        },
        " .clientClasses .customeTabs button:hover": {
            border: "3px solid #3A9E2D",
            color: "#3A9E2D",
            background:'#d1fbcb !important'
        },
        ".clientClasses .customeTabs button.Mui-selected": {
            color: "#ffffff !important",
            background: "#3A9E2D !important",
        },
        "@media(max-width:767px)": {
            ".dashBoardHero": {
                background: "linear-gradient(135deg, #4CAF50 0%,#107A49 100%) !important",
            },
        },

        ".badgeItem": {
            background: "rgb(255, 255, 255)",
            color: "#3A9E2D",
        },
        ".customLinks .Mui-selected .badgeItem": {
            background: "#3A9E2D",
            color: "rgb(255, 255, 255)",
        },
        ".customLinks .Mui-selected": {
            background: "#FFFFFF",
            color: "#3A9E2D",
        },
    },
})
(() => null);
export default TriZenTheme;
