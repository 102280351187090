import React from "react";
import {
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
    Typography,
    Link
} from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from "@material-ui/icons/Create";
import ListIcon from "@material-ui/icons/List";
import { dateUtils } from "../../utils";
import {AccountType, ImoClass, AccountTypeByFilter, ROLES, showUserLogs} from "../../constants";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import GetInviteStatus from "../GetInviteStatus";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {maskPhoneNumber} from "../../utils/commonUtils";
import {Link as RouterLink} from 'react-router-dom';
import { getImoNameByValue, PermissionTypes } from "../../constants";
import {Restore} from '@material-ui/icons';
import {history} from "../../configureStore";
import * as Admin_Actions from "../../actions/admin";
import { can } from "../navigation/AdminNavigation";


function ImoTableRow(props: any) {
	const { account, setInviteTokenId, inviteTokenId, deletedUser, handleRefresh, includeAction = true } = props;
	const adminActions = useActions(Admin_Actions);
	const accountActions = useActions(AccountActions);
	const user:any = useSelector((state: RootState) => state.auth.user);
	let { index } = props;
    const showDivisionName = (user.role === 18 || user.role === 19 || (history.location.pathname.search("/aggregator/detail/") > -1)) ? true : false;
	const refreshTable = () =>{
		handleRefresh();
	}
    
    function valHandler(accountId: string) {
		setInviteTokenId(accountId);
		accountActions.copyInvitationLink(
			"Invite Link Has Been Copied to Your Clipboard"
		);
	}

    return(
        <TableRow hover key={account.id}>
            <TableCell>{index}</TableCell>
            <TableCell padding="default">
              <Link
                component={RouterLink}
                to={'/new-imo/detail/'+ account.id}>
                {account["imo.groupName"]}
              </Link>
            </TableCell>
            {
                    showDivisionName &&
                    <TableCell padding="default">{account["imo.division.divisionName"]}</TableCell>
            }
            <TableCell padding="default">{getImoNameByValue(account["imo.imoClass"])}</TableCell>
            <TableCell padding="default">{account["imo.region"]}</TableCell>
            <TableCell padding="default">{maskPhoneNumber(account["imo.officeNumber"])}</TableCell>
            <TableCell padding="default">{account.firstName}</TableCell>
            <TableCell padding="default">{account.lastName}</TableCell>
            <TableCell padding="default">{account.email}</TableCell>
            <TableCell padding="default">{account["imo.carrierName"]}</TableCell>
            <TableCell padding="default">
                <Typography>
                    <Link
                            variant="body2"
                            className={'clickableLink'}
                            onClick={ () => props.history.push(`/accounts/imo/${account["imo.id"]}/agent/list/${account.firstName} ${account.lastName}`)}
                    >
                        {account["imo.agentCount"] || 0}
                    </Link>
                </Typography>
            </TableCell>
            <GetInviteStatus
                role={user.role}
                account={account}
                refreshTable={()=>refreshTable()}
            />
            <TableCell padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
            <TableCell padding="default">
                {account.lastLogin ? dateUtils.asDbDateFormat(account.lastLogin) : 'Never Logged In'}
                <br />
                {account.lastLogin ? dateUtils.timeCalculator(account.lastLogin) : ""}
            </TableCell>
            {includeAction &&<TableCell padding="default" align="right" className="w230">
                {(user && user.role <= 2 && showUserLogs) &&
                <Tooltip title="Logs">
                    <IconButton
                            color="default"
                            onClick={() => props.history.push('/activity/' + ROLES[account.role] + '/' + account.id)}
                    >
                        <Restore/>
                    </IconButton>
                </Tooltip>
                }
              {/* {account.inviteStatus <= 1 && <Button size="small" className="btnSmall" onClick={ () => handleClickReInvite(account.id)}>Re-Invite</Button>} */}
                <Tooltip title="List">
                    <IconButton
                            color="default"
                            onClick={ () => props.history.push(`/accounts/imo/${account["imo.id"]}/agent/list/${account.firstName} ${account.lastName}`)}
                    >
                        <ListIcon />
                    </IconButton>
                </Tooltip>
              {
                account.inviteLink &&
                <Tooltip placement="left-start" title={ (account.id === inviteTokenId)
                    ?  <React.Fragment>Invite Link Has Been <br/>Copied to Your Clipboard</React.Fragment>
                    : "Copy Invite Link"}>
                  <CopyToClipboard text={account.inviteLink}
                                   onCopy={()=>valHandler(account.id)}>
                    <IconButton color="default">
                      <LinkIcon />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              }
              <Tooltip title="Edit">
                <IconButton
                  color="default"
                  component={RouterLink}
                  to={'/new-imo/detail/'+ account.id}>
                  <CreateIcon />
                </IconButton>
              </Tooltip>
                {
                    user && (user.role === 1 || can(user, PermissionTypes.DELETE_USER)) && ((account["imo.agentCount"] === 0) || (account["imo.agents.participants.participantCount"] === 0)) &&
                    <Tooltip title="Delete">
                        <IconButton
                                color="default"
                                onClick={() => deletedUser(account.id)}
                        >
                            <DeleteOutlineIcon className="colorRed"/>
                        </IconButton>
                    </Tooltip>
                }
            </TableCell>
            }
          </TableRow>
    )
}
export default ImoTableRow;