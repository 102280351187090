import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { useFormikContext } from "formik";
import {Grid} from "@material-ui/core";
import {Label} from "../../../../../components/formInputs";
import {ErrorCommon, SelectField, TextField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import {defaultOfferTypes, strategies} from "../../../../../constants";
import {RootState} from "../../../../../reducers";
import {useActions} from "../../../../../actions";
import * as ClientActions from "../../../../../actions/client";


const defaultOfferTypesOpn = defaultOfferTypes.map((offer: any) => ({
    id: offer.id,
    key: offer.id,
    name: offer.name,
    value: offer.value
}));

const StrategyCarrierImoAdvisor = () => {
    const detail: any = useSelector((state: RootState) => state.clientAccount);
    const carrierOfferTypes: any = useSelector((state: RootState) => state.carrierOfferTypes);
    const [offerTypes, setOfferTypes] = useState<any>(defaultOfferTypesOpn);
    const {getOfferByCarrierId} = useActions(ClientActions);
    const {values, handleChange, errors, touched} = useFormikContext<any>();
    useEffect(() => {
        setOfferTypes(defaultOfferTypesOpn);
        if (carrierOfferTypes.length > 0) {
            let carrierOfferTypesobj = carrierOfferTypes.map((offer) => {
                return {
                    id: offer.value,
                    key: offer.value,
                    name: offer.name,
                    value: offer.value,
                }
            });
            setOfferTypes(carrierOfferTypesobj);
        }
    }, [carrierOfferTypes]);

    useEffect(() => {
        let ClientCarrierId = detail && detail.client && detail.client.carrier && detail.client.carrier.id;
        if (ClientCarrierId > 0) {
            getOfferByCarrierId(ClientCarrierId, false);
        }
    }, [detail]);

    const fieldObj: any = [
        {
            label: <Label label={"ilia Product Type"}/>,
            id: "strategyName",
            name: "strategyName",
            isRequired: false,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: true //disabled for every role
        },
        {
            label: <Label label={"Carrier"}/>,
            id: "carrierName",
            name: "carrierName",
            isRequired: false,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            InputLabelProps:{
                disabled : false,
                shrink: true
            },
            disabled: true //disabled for every role
        },
        {
            label: <Label label={"Offer Type"}/>,
            id: "offerType",
            name: "offerType",
            placeholder: "Select Offer Type",
            component: SelectField,
            options: offerTypes,
            onSelectChange: (val: number | string) => {

            },
            disabled: true //disabled for every role
        },
        {
            id: "advisor",
            name: "advisor",
            label: <Label label={"Agent"}/>,
            isRequired: false,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: true //disabled for every role
        },
        {
            label: <Label label={"Imo"}/>,
            id: "imo",
            name: "imo",
            isRequired: false,
            placeholder: "IMO",
            component: TextField,
            options: strategies,
            onSelectChange: (val: number | string) => {
            },
            disabled: true //disabled for every role
        },
        {
            id: "policyNumber",
            name: "policyNumber",
            label: <Label label={"Policy Number"}/>,
            isRequired: false,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: true //disabled for every role
        },
        {
            id: "tranceNo",
            name: "tranceNo",
            label: <Label label={"Tranche Number"}/>,
            isRequired: false,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: true //disabled for every role
        },
        {
            name: "trancheApplicationDeadline",
            className: "trancheApplicationDeadline",
            label: <Label label={"Tranche Application Deadline"}/>,
            component: TextField,
            variant: "outlined",
            disabled: true //disabled for every role
        },
        {
            name: "trancheFundingDeadline",
            className: "trancheFundingDeadline",
            label: <Label label={"Tranche Funding Deadline"}/>,
            component: TextField,
            variant: "outlined",
            disabled: true //disabled for every role
        },
        {
            name: "projectFundingDeadline",
            className: "projectedFundingDate",
            label: "Projected Funding Date",
            component: TextField,
            variant: "outlined",
            disabled: true //disabled for every role
        }
    ];

    return (
            <Grid container spacing={3}>
                {fieldObj.map((field, index) => {
                    return (
                            <Grid item xs={12} sm={6} md={4} lg={6} xl={4}
                                  key={index} className="mb15 fullWidthcustomFields">
                                <TextFieldCommon
                                        values={values}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            disabled : false
                                        }}
                                        {...field}
                                />
                                <ErrorCommon errors={errors} name={field.name}
                                             touched={touched}/>
                            </Grid>
                    );
                })
                }
            </Grid>
    );

};
export default StrategyCarrierImoAdvisor;