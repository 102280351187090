import React, {ComponentClass, FunctionComponent} from 'react';
import {AppBar, Tabs, Tab as MuiTab} from "@material-ui/core";
import {Link, useRouteMatch} from "react-router-dom";
import {AppBarProps} from "@material-ui/core/AppBar/AppBar";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import {TabsTypeMap} from "@material-ui/core/Tabs/Tabs";
import {NS} from "../../model/misc";
import {pez} from "../../utils/commonUtils";

interface LinkTabProps {
    label: string;
    value: NS;
}

interface TabsMainProps extends Partial<OverridableComponent<TabsTypeMap>> {
    children?: any;
    component?: any;
    value?: any;
}

type TabPanelProps = {
    as?: FunctionComponent<any> | ComponentClass<any> | string;
    children: React.ReactNode | any;
    classnames?: string;
    index: NS;
    value: NS;
    id?: string;
    label?: string;
}

type TabProps = {
    asLink?: boolean;
    appBarProps: AppBarProps;
    parentTabProps: TabsMainProps | any; // TODO ts fix needed
    tabsProps: LinkTabProps[];
};

export const LinkTab: React.FC<LinkTabProps> = ({label, value}) => {
    const match: any = useRouteMatch();
    const url = `${match.url}/${value}`;
    const tabProps = {
        component: Link,
        label,
        value,
        href: url,
        to: url,
        // ...a11yProps(value) // TODO add accessibility features
    }

    return (
            <MuiTab {...tabProps} />
    );
};

export const TabPanel = (props: TabPanelProps) => {
    const {children, classnames, value, index, ...other} = props;
    const panelProps = {
        className: `divAnimation ${pez(classnames)}`,
        hidden: value !== index,
        ...other
    };

    return (
            <div {...panelProps}>
                {
                        value === index && children
                }
            </div>
    );
};

const Tab: FunctionComponent<TabProps> = (props) => {
    return (
            <AppBar {...props.appBarProps}>
                <Tabs {...props.parentTabProps}>
                    {
                        props.tabsProps.map((tabProps, idx) => {
                            if (props.asLink) {
                                return (
                                        <LinkTab key={idx} {...tabProps} />
                                )
                            }
                            return (
                                    <MuiTab key={idx} {...tabProps} />
                            )
                        })
                    }
                </Tabs>
            </AppBar>
    );
};

export default Tab;

