import React from "react";
import { EnrollmentFinancialAgreementProps, ResetFieldsProps } from "../../../../../../model/enrollment";
import { avocationType, checkAllApplied } from "../Utilities";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import {
	getInsuredQuestions,
} from "./InsuredQuestions";
import { useFormikContext } from "formik";
import { ErrorHelper } from "../../../../../../components/formikFormInputs";
import InsuredToggleButtonGroup from "./component/InsuredToggleButtonGroup";

type PropsType = {
	user: any,
	isFromClientDetail: boolean,
	values: any,
	setFieldValue: Function,
	setOpenInforceLifeAlertPopup: Function,
	enableSaveButton: any,
	clientSigned: boolean,
	showComponent: Function,
	touched: any,
	errors: any,
	setFieldTouched: Function,
	handleChange: Function,
	handleBlur: Function,
	setErrors: Function,
	getPolicyGrids: Function,
	setSaveApplicationQuestions?: Function
}

function QuestionsListing(props: PropsType) {
	const {
		isFromClientDetail,
		setOpenInforceLifeAlertPopup,
		enableSaveButton,
		clientSigned,
		showComponent,
		touched,
		setFieldTouched,
		handleChange,
		handleBlur,
		setErrors,
		getPolicyGrids,
	} = props;

	const questionList = getInsuredQuestions();

    questionList.sort(function(a, b) {
        return a.position - b.position;
    });

	const { values, errors, setFieldValue }: any = useFormikContext();
	const resetFields = async (name: string, rfs: ResetFieldsProps[]) => {
		if (rfs.length > 0) {
			for (let rf in rfs) {
				await setFieldValue(rfs[rf].name, rfs[rf].value);
			}
		}
	};

	return <>{
		questionList.map((question: EnrollmentFinancialAgreementProps, idx: number) => {
            return (
                <>
                    <Grid item xs={12} sm={12} md={12} key={question.key || idx}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={question.type === "radio" ? 8 : 12}
                                lg={question.type === "radio" ? 9 : 12}
                            >
                                <Typography variant="body1" className="mb15 floatLeft" component="strong">
                                    {question.label ? <Label label={`${question.label}`}
                                                             required={!isFromClientDetail} /> : <br />}
                                </Typography>
                                {
                                    question.infoTooltip &&
                                    <Tooltip
                                        title={<Typography
                                            className="f16 txtWhite mb0"
                                        > {question.infoTooltip} </Typography>}
                                    >
                                        <IconButton>
                                            <Info color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                            {
                                question.type === "radio" &&
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <InsuredToggleButtonGroup
                                        id={question.name}
                                        onChangeNo={() => {

                                            if (question.name === "Proposed_Insured_Questions-32") {
                                                setFieldValue("Insured_ParticipationDetails", []);
                                                avocationType.forEach((ele: any) => {
                                                    setFieldValue(`${ele.name}`, null);
                                                });
                                                checkAllApplied.forEach((ele: any) => {
                                                    setFieldValue(`${ele.name}`, null);
                                                });
                                            }

                                            if (question && question.resetFields && question.resetFields.length > 0) {
                                                resetFields(question.name, question.resetFields).then();
                                            }
                                        }}
                                        onChangeYes={() => {
                                            if (question.name === "isInforceLife") {
                                                // Show Popup for info
                                                setOpenInforceLifeAlertPopup(true);
                                            }
                                        }}
                                        onChangeNull={() => console.info("onChange for Null value")}
                                        onChange={() => {
                                            enableSaveButton();
                                            if (question.name === "inforcePolicy") {
                                                setFieldValue("inForcePolicies", [],false);
                                                setFieldValue("noOfInforcePolicies", "",false);
                                            }
                                        }}
                                        clientSigned={clientSigned}
                                    />
                                    <span style={{ float: "right", clear: "both" }}>
                                            <ErrorHelper errors={errors} name={question.name} />
                                        </span>
                                </Grid>
                            }
                            {
                                question && question.additionalRemarks &&
                                showComponent({clientSigned, question})
                            }
                        </Grid>
                    </Grid>
                    {
                        question.name === "inforcePolicy" && Number(values["noOfInforcePolicies"]) > 0 && values["inforcePolicy"] === "Yes" &&
                        <>
                            {getPolicyGrids(values, handleChange, handleBlur, errors, touched, setFieldValue)}
                        </>
                    }
                    {
                        (!(question && question.notShowHrLine)) &&
                        <span className="hLine" />
                    }
                </>
            );

		})
	}
	</>;
}

export default React.memo(QuestionsListing);