import React from "react";
import {useFormikContext} from "formik";
import {Grid, IconButton, Tooltip, Typography, withStyles} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import MinimumContribution from "../../../kaizen/MinimumContribution";
import Info from "@material-ui/icons/Info";
import {Theme} from "@material-ui/core/styles";
export const ANNUALLY = "Annual";
export const MONTHLY = "Monthly";

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#2f2f2f",
        maxWidth: "500px",
        minWidth: "100px",
        color: "#ffccc",
        lineHeight: "18px",
        fontWeight: "normal",
        padding: "20px",
        margin: "0",
        boxShadow: "0 0 15px 0px #8c8c8c",
    },
}))(Tooltip);
export default function ContributionFrequency(props: any) {
    const text: string = "Contribution Frequency";
    const {values, setFieldValue} : any = useFormikContext();
    const {user}: any = useSelector((state: RootState) => state.auth);
    let carrierId: number;
    if (props.carrierId) {
        carrierId = props.carrierId;
    } else {
        carrierId = user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.carrier.id);
    }

   if (carrierId === 3) {
       return (<>
           <Grid container spacing={2} justify="center" className="mb10">
               <Grid item xs={12} md={7} className="displayF">
                   <Typography variant="caption" className="displayF">{props.text ? props.text : text}
                       <HtmlTooltip
                               title={
                                   <React.Fragment>
                                       <Typography className="f16 txtWhite mb10">
                                           Annual: the entire year’s contribution will be due up front before the policy
                                           is issued. In subsequent years, the entire year’s contribution is due 30 days
                                           before the policy anniversary date.
                                       </Typography>
                                       <Typography className="f16 txtWhite mb0">
                                           Monthly: Contributions are divided by 12 and due each month. <strong><u>Two
                                           months are due up front before the policy is issued.</u></strong> After that,
                                           each month has an equal payment. Monthly contributions must be made by
                                           electronic ACH on a recurring schedule through ILIA's payment system.
                                       </Typography>
                                   </React.Fragment>
                               }
                       >
                           <Info className="f30 icon mr15" color="primary"/>
                       </HtmlTooltip>
                   </Typography>
               </Grid>
               <Grid item xs={12} md={5}>
                   <ToggleButtonGroup
                           className="toggleButtonGroup floatRight"
                           size="medium"
                           color={"primary"}
                           exclusive
                           id={"contributionFrequency"}
                           value={values["contributionFrequency"]}
                           onChange={(_e: any, val: string) => {
                               if (val) {
                                   setFieldValue("contributionFrequency", val, false);
                                   if(props.onChange){
                                       props.onChange(val)
                                   }
                                   if (props.setContributionFrequency) {
                                       props.setContributionFrequency(val);
                                   }
                               }
                           }}
                   >
                       <ToggleButton value={ANNUALLY} color={"primary"} disabled={props.monthlyDisabled}
                                     className={values["contributionFrequency"] === ANNUALLY ? "active" : ""}>
                           {ANNUALLY}
                       </ToggleButton>
                       <ToggleButton value={MONTHLY} disabled={props.monthlyDisabled}
                                     className={values["contributionFrequency"] === MONTHLY ? "active" : ""}>
                           {MONTHLY}
                       </ToggleButton>
                       { props.monthlyDisabled &&
                               <div className="floatLeft">
                                   <Tooltip title="Monthly Option Coming Soon">
                                       <Info className="mt15" color="primary" />
                                   </Tooltip>
                               </div>
                       }
                   </ToggleButtonGroup>
               </Grid>
           </Grid></>);
   } else {
       return <></>;
   }

}