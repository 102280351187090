import * as React from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Card, CardContent, Grid, Link, Typography} from "@material-ui/core";
import {Button as SubmitButton, DeleteModel, PageTitle} from "../components/formInputs";
import {formValueSelector, getFormValues, reduxForm} from "redux-form";
import {RootState} from "../reducers";
import {useActions} from "../actions";
import * as Actions from "../actions/account";
import * as CarrierActions from "../actions/carrier";
import * as AggregatorActions from "../actions/aggregator";
import PrimaryContactDetail from "../components/PrimaryContactDetail";
import AddCarrierAccount from "../components/AddCarrier";
import {unmaskPhoneNumber} from "../utils/commonUtils";
import {AccountType} from "../model";
import {User} from "../model/user";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {AccountTypeByName} from "../constants";
import {SimpleBreadcrumbs} from "../components";
import AddTrustee from "../components/AddTrustee";
import AddBank from "../components/AddBank";
import Button from "@material-ui/core/Button";
import {asDbDateFormat, DATE_FORMAT_FOR_ALL_ACC_DB_SAVE} from "../utils/dateUtils";
import CustomPopup from "../components/CustomPopup";
import AddAggregator from "../components/AddAggregator";

const validate = (values: any) => {
    const errors: any = {};
    if (!values.password) {
        errors.password = "Required";
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
    } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Password mismatched";
    }
    return errors;
};

const selector = formValueSelector("EditAccountPage");

function EditAccountPage(props: any) {
    const accountActions = useActions(Actions);
    const carrierActions = useActions(CarrierActions);
    const aggregatorActions = useActions(AggregatorActions);
    const auth: User = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const { handleSubmit, pristine, submitting, initialValues, match } = props;
    const userId: string = match.params.id;
    const role: number = AccountTypeByName[match.params.role];
    const [notes, setNotes] = React.useState("");
    const { formValues } = props;

    React.useEffect(() => {
        accountActions.getAccount(userId, role);
        if (role && role === 3) {
            carrierActions.getAllCarriers();
            accountActions.getAllStrategy();
        } else if (role && role === 7) {
            accountActions.getAllStrategy();
        }
    }, [userId, role]);

    // Admin -- Call Toggle on Update IMO - Primary Contact, ImoEmail
    const imoAccount = useSelector((state: RootState) => state.account);
    const currentImoEmail: string = useSelector((state: any) => selector(state, "email"));
    const currentPhoneNumber: string = useSelector((state: any) => selector(state, "phoneNumber"));
    const [isUpdated, setIsUpdated] = React.useState<boolean>(false);
    const [isToggle, setIsToggle] = React.useState<boolean>(false);
    const [openConfirmPopup, setOpenConfirmPopup] = React.useState<boolean>(false);

    React.useEffect(() => {
        if ((imoAccount.phoneNumber && (imoAccount.phoneNumber !== unmaskPhoneNumber(currentPhoneNumber))) ||
                (imoAccount.email !== currentImoEmail)) {
            setIsUpdated(true);
        } else {
            setIsUpdated(false);
        }

    }, [currentPhoneNumber, currentImoEmail]);

    const updateAccount = async (data: any) => {
        delete data.id;
        if (data.phoneNumber) {
            data.phoneNumber = unmaskPhoneNumber(data.phoneNumber);
        }

        if (data.carrier && data.carrier.carrierPhone) {
            data.carrier.carrierPhone = unmaskPhoneNumber(data.carrier.carrierPhone);
        }

        if (data.imo && data.imo.officeNumber) {
            data.imo.officeNumber = unmaskPhoneNumber(data.imo.officeNumber);
        }
        if (role === 3) {
            data = updateImoForm(data, initialValues);
            await accountActions.updateAdminImoDetails(data, userId);
        } else if (role === 4) {
            if (auth.user && auth.user.role === 3) {
                await accountActions.updateImoAgentDetails(data, userId);
            } else {
                await accountActions.updateAdminAgentDetails(data, userId);
            }
        } else if (role === 7) {
            let deleteCarrierStrategies: number[] = [];
            if (initialValues.carrier && initialValues.carrier.carrierStrategies && initialValues.carrier.carrierStrategies.length > 0) {
                initialValues.carrier.carrierStrategies.forEach((val: any) => {
                    if (data.carrier.carrierStrategies && !data.carrier.carrierStrategies.find((p: any) => p.id === val.strategies && val.strategies.id)) {
                        deleteCarrierStrategies.push(val.id);
                    }
                });
            }
            data.carrier = Object.assign(data.carrier, { deleteCarrierStrategies });
            data.carrier.onBoardDate = data.carrier.onBoard ? asDbDateFormat(new Date(), DATE_FORMAT_FOR_ALL_ACC_DB_SAVE) : null;
            await carrierActions.updateCarrier(data, userId);
        } else if (role === 12) {
            await accountActions.updateAdminTrusteeDetails(data, userId);
        } else if (role === 13) {
            await accountActions.updateAdminBankDetails(data, userId);
        } else if (role === 18) {
            delete data.imo;
            await aggregatorActions.updateAggregatorDetails(data, userId);
        } else {
            const updateAccountData: object = {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                image: data.image,
            };
            await accountActions.updateAccount(updateAccountData, userId);
        }
        role === 7 ? props.history.push("/accounts/carrier") : props.history.goBack();
    };

    if (!userId) {
        return <React.Fragment/>;
    }

    let title: string = `Update ${AccountType[role]}`;
    if (role === 4 && imoAccount) {
        title = `${imoAccount.firstName} ${imoAccount.lastName} - Agent Detail`;
    }

    const handleConfirm = async () => {
        setOpenConfirmPopup(false);
        await accountActions.updateUserBlockStatus(imoAccount.id, notes);
        accountActions.getAccount(userId, role);
        setNotes("");
    };

    const handleNotes = (val: string) => {
        setNotes(val);
    };

    return (
            <>
                <Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={11} lg={10} xl={8}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <PageTitle title={title}/>
                                <Link className="backBtn" color="primary" onClick={() => {
                                    role === 7 ? props.history.push("/accounts/carrier") :
                                            props.history.goBack();
                                }}>
                                    <ArrowBackIosIcon/>Back
                                </Link>
                                {
                                    role !== 7 &&
                                    <SimpleBreadcrumbs
                                            history={props.history}
                                            crumbObject={{ [title]: "" }}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    role === 7 &&
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} className="mt15">
                                            <Typography variant="h3" className="floatLeft"> Carrier
                                                Details </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} className="mt15 mb10">
                                            <Button
                                                    className="btnPrimary floatRight"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        props.history.push(`/accounts/carrier/edit/${userId}/offer_types`);
                                                    }}
                                            >
                                                UNDERWRITING OFFER TYPES
                                            </Button>

                                            <Button
                                                    className="btnPrimary mr10  floatRight"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        props.history.push(`/accounts/carrier/edit/${userId}/products`);
                                                    }}
                                            >
                                                MANAGE PRODUCTS
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    auth.user && auth.user.role === 1 && [3, 4].indexOf(role) > -1 && <Button
                                            className={`floatRight mb15 ${imoAccount.inviteStatus === -2 ? "bgRed" : ""}`}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setOpenConfirmPopup(true);
                                            }}
                                    >
                                        {imoAccount.inviteStatus === -2 ? "Currently locked out of ilia" : "Deny Access to ilia"}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <Card className="muiCard">
                            <CardContent className="muiCardContent">
                                <form onSubmit={handleSubmit(updateAccount)}>
                                    <Grid container spacing={2}>
                                        {
                                            (role === 2 || role === 4 || role === 5 || role === 6) &&
                                            <PrimaryContactDetail
                                                    role={role}
                                                    disabledEmail={auth.user.role === 2 ? true : false}
                                                    disabledPhone={auth.user.role === 2 ? true : false}
                                            />
                                        }
                                        {/* 
                                        NOT used anymore, we are using imo and agent details page
                                        {role === 3 &&
                                        <AddImoAccount
                                                page={"EditAccountPage"}
                                                carriers={initialValues.imo ? initialValues.imo.carriers : []}
                                                initialReBrand={initialValues.imo && initialValues.imo.reBrand}
                                                dispatch={dispatch}
                                        />
                                        }
                                        {role === 4 &&
                                        <AddAgentAccount
                                                page={"EditAccountPage"}
                                                formValues={formValues}
                                                updateAccount={()=>{dispatch(handleSubmit(updateAccount))}}
                                                dispatch={dispatch}
                                        />
                                        } */}

                                        {role === 7 &&
                                        <AddCarrierAccount
                                                dispatch={props.dispatch}
                                                page={"EditAccountPage"}
                                        />
                                        }
                                        {
                                            role === 12 &&
                                            <AddTrustee
                                                    dispatch={props.dispatch}
                                                    page={"EditAccountPage"}
                                            />
                                        }
                                        {
                                            role === 13 &&
                                            <AddBank
                                                    dispatch={props.dispatch}
                                                    page={"EditAccountPage"}
                                            />
                                        }
                                        {
                                            role === 18 &&
                                                <AddAggregator
                                                        page={"EditAccountPage"}
                                                        dispatch={props.dispatch}
                                                        brandLogo={(v: string) => {
                                                        }}
                                                />
                                        }
                                        <UpdateButton isUpdated={isUpdated}
                                                        pristine={pristine}
                                                        submitting={submitting} 
                                                        setIsToggle={setIsToggle} />
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                        <CustomPopup
                                open={openConfirmPopup}
                                handleConfirm={() => handleConfirm()}
                                handleClosePopup={() => setOpenConfirmPopup(false)}
                                content={() => getDialogContent(imoAccount.inviteStatus === -2)}
                                addNotes={true}
                                handleNotes={handleNotes}
                                notes={notes}
                        />
                    </Grid>
                    {isToggle &&
                    <DeleteModel
                            open={true}
                            title="Confirmation"
                            content={`Are you sure you want to update the details of this ${AccountType[role]}?`}
                            handleClose={() => setIsToggle(false)}
                            handleYes={() => dispatch(handleSubmit(updateAccount))}
                    />
                    }
                </Grid>
            </>
    );
}

export function updateImoForm(data: any, initialValues: any) {
    data.imo.additionalMembers.forEach((v: any) => {
        v.imoId = data.imo && data.imo.id;
        if (v.memberPhoneNumber) {
            v.memberPhoneNumber = unmaskPhoneNumber(v.memberPhoneNumber);
        }
    });
    let deleteAdditionalMembers: number[] = [];
    if (initialValues.imo.additionalMembers && initialValues.imo.additionalMembers.length) {
        initialValues.imo.additionalMembers.forEach((member: any) => {
            if (!data.imo.additionalMembers.find((p: any) => p.id === member.id)) {
                deleteAdditionalMembers.push(member.id);
            }
        });
    }
    data.imo = Object.assign(data.imo, { deleteAdditionalMembers });
    if (!data.imo.reBrand) {
        data.imo.reBrandLogo = "";
    }
    data.imo.reBrandStrategy = "";
    data.imo.strategyId = 0;
    return data;
}

function mapStateToProps(state: RootState) {
    return {
        initialValues: state.account,
        formValues: getFormValues("EditAccountPage")(state),
    };
}

export const UpdateButton = ({ label = "UPDATE", isUpdated, pristine , submitting, setIsToggle, disabled = false}) => {
    return (
        <Grid item xs={12}>
        {
            isUpdated?
            <SubmitButton
                label={label}
                className="mt15 floatRight"
                variant="contained"
                color="primary"
                onClick={() => setIsToggle(true)}
            />
            : <SubmitButton
                label={label}
                className="mt15 floatRight"
                type="submit"
                variant="contained"
                color="primary"
                disabled={pristine || submitting || disabled}
                loading={submitting}
            />
        }</Grid>
        
    )
}

export const getDialogContent = (isBlocked: boolean) => {
    return (
            <Typography className="f16 mt15 mb15">
                {
                    isBlocked ?
                            "Please confirm, you are allowing this user access to ilia?" :
                            "Are you sure you want to remove this users ability to access ilia?"
                }
            </Typography>
    );
};

const reduxConnetedPage = reduxForm({
    form: "EditAccountPage",  // a unique identifier for this form
    validate: validate,
    enableReinitialize: true,
})(EditAccountPage);

export default connect(mapStateToProps)(reduxConnetedPage);
