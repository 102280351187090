import * as React from "react";
import {
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import { AccountHeadRow } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import FormatPhoneNumber from "../format/PhoneNumberFormat";
import ImoTableRow from "../tableRow/ImoTableRow";
import { DeleteModelWithNotes } from "../formInputs";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {history} from "../../configureStore";

const headRows: AccountHeadRow[] = [
    { id: "sNo", label: "S.No" },
    { id: "imo.groupName", label: "IMO Company Name" },
    { id: "imo.imoClass", label: "IMO Type" },
    { id: "imo.region", label: "IMO Region" },
    { id: "imo.officeNumber", label: "Office Number" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "imo.carriers.carrier.carrierName", label: "Carrier(s)", hasSort: false },
    { id: "imo.agentCount", label: "# of Agents" },
    { id: "inviteStatus", label: "Status" },
    { id: "createdAt", label: "Date Created" },
    { id: "lastLogin", label: "Last Login" },
];
const rowWithDivision : AccountHeadRow[] = [
    { id: "sNo", label: "S.No" },
    { id: "imo.groupName", label: "IMO Company Name" },
    {id: "imo.division.divisionName", label: "IMO Division"},
    { id: "imo.imoClass", label: "IMO Type" },
    { id: "imo.region", label: "IMO Region" },
    { id: "imo.officeNumber", label: "Office Number" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "imo.carriers.carrier.carrierName", label: "Carrier(s)", hasSort: false },
    { id: "imo.agentCount", label: "# of Agents" },
    { id: "inviteStatus", label: "Status" },
    { id: "createdAt", label: "Date Created" },
    { id: "lastLogin", label: "Last Login" }
]
export function ImoTable(props: any) {
    const { accountList, order, orderBy, handleRequestSort, handleClickOpen, handleClickReInvite, handleRefresh, page, rowsPerPage, includeAction = true } = props;
    const {user: {role}} = useSelector((state: RootState) => state.auth);
    const [inviteTokenId, setInviteTokenId] = React.useState<string>("");
    let sNo = page * rowsPerPage;
    const accountActions = useActions(AccountActions);
    const [isDeleteModel, setIsDeleteModel] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [message, setMessage] = React.useState<string>("");
    const [notesValue, setNotesValue] = React.useState("");
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const userType = "USER";
    const [headingRow, setHeadingRow] = React.useState(headRows);


    React.useEffect(()=>{
        if (role === 18 || role === 19 || (history.location.pathname.search("/aggregator/detail/") > -1)) {
            setHeadingRow(rowWithDivision);
        }
    }, [role])
    const deletedUser = (id: string) => {
        let userDetails: any = accountList.rows.filter((val: any) => (id === val.id));
        if(userDetails.length > 0){
            setMessage("Are you sure you want to delete this imo permanently from ilia ?")
            setTitle(userDetails[0]["firstName"] + " " +userDetails[0]["lastName"])
        }
        setIsDeleteModel(id)
    };
    const setNotes = (value) => {
        setNotesValue(value)
    }
    const deleteImo  = async () => {
        setButtonLoader(true);
        await accountActions.deleteAccount(isDeleteModel, userType, notesValue);
        setIsDeleteModel("");
        setNotesValue("");
        setButtonLoader(false);
        handleRefresh();
    };
    return (
            <React.Fragment>
                <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headRows={headingRow}
                        includeAction={includeAction}
                />
                <TableBody>
                    {
                        (!accountList.rows || accountList.rows.length === 0)
                        && <TableRow>
                            <TableCell align="center" colSpan={11}>
                                <Typography align="left">
                                    No IMO Accounts
                                </Typography>
                            </TableCell>
                        </TableRow>
                    }
                    {accountList.rows && accountList.rows.map((account: any, index: number) => {
                        return (
                                <ImoTableRow key={index} account={account} index={++sNo}
                                             setInviteTokenId={setInviteTokenId}
                                             inviteTokenId={inviteTokenId} handleClickOpen={handleClickOpen}
                                             deletedUser={deletedUser}
                                             history={props.history} handleClickReInvite={handleClickReInvite}
                                             handleRefresh={handleRefresh} includeAction={includeAction}/>
                        );
                    })
                    }
                </TableBody>
                {
                    isDeleteModel ?
                            (
                                    <DeleteModelWithNotes
                                            open={true}
                                            title={title}
                                            content={message}
                                            handleClose={() => {setIsDeleteModel(""); setNotesValue("")}}
                                            handleYes={deleteImo}
                                            setNotes={setNotes}
                                            buttonLoader={buttonLoader}
                                            notes={notesValue}
                                    />
                            ):null
                }
            </React.Fragment>
    );
}

export default ImoTable;
