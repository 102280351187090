import React from "react";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useActions } from "../../../../../actions";
import * as Clients from "../../../../../actions/client";
import { dateUtils } from "../../../../../utils";
import { downloadDocument } from "../../../../../utils/commonUtils";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

import * as Aws from "../../../../../actions/aws";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { AccountType } from "../../../../../constants";


const useStyles = makeStyles({
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
    pd10: {
        padding: 10
    },
    mb20: {
        marginBottom: 20
    },
    mt20: {
        marginTop: 20
    }
});

export default function TsvDocuments(props :{clientId : any}) {
    const [documentList , setDocumentList ] = React.useState();
    const { clientId } = props
    const clientActions = useActions(Clients);
    const awsActions = useActions(Aws);
    const { user:{role}, accessToken } = useSelector((state: RootState) => state.auth);
    React.useEffect(()=>{
        (async ()=>{
            const docs = await clientActions.getClientTsv(clientId);
            setDocumentList(docs)
        })()
    },[])

    if(![AccountType.Admin, AccountType.MasterAdmin].includes(role as number)){
        return <></>
    }

    return (
            <React.Fragment>
                <Typography variant="subtitle1" component="strong" className="mb20 mt30 floatLeft w100">Tsv
                    Files</Typography>
                { documentList && documentList.length > 0 &&
                        documentList.map((document: any) => {
                            document.fileType = "ext/x-csv"
                            return (
                                    <TableRow key={Math.random()}>
                                        <TableCell component="td" scope="row">
                                            <a className="colorBlue" onClick={() => {
                                                // handleDocument(document);
                                                // setDocumentToView(document);
                                            }}>{document.name}</a> {document.name === "Other" && " (" + document.otherDocName + ")"}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {dateUtils.asDbDateFormat(document.updatedAt)}
                                        </TableCell>
                                        <TableCell align="center">

                                            <Link className="colorBlue" onClick={async () => {
                                                await downloadDocument(document, document.enrollmentId, awsActions);
                                            }}><GetAppOutlinedIcon/></Link>

                                        </TableCell>
                                    </TableRow>
                            );
                        })
                }

            </React.Fragment>);
}