import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import React from "react";
import {Label} from "../../../../../../../components/formInputs";
import {ErrorCommon} from "../../../../../../../components/formikFormInputs";
import {useFormikContext} from "formik";

let obj10Options = [
    {
        label: "Increased by more than 10 pounds.",
        value: "INCREASE_10",
    },
    {
        label: "Increased by more than 20 pounds.",
        value: "INCREASE_20",
    },
    {
        label: "Decreased by more than 10 pounds.",
        value: "DECREASE_10",
    },
    {
        label: "Decreased by more than 20 pounds.",
        value: "DECREASE_20",
    },
    {
        label: "Did not increase or decrease by more than 10 pounds",
        value: "NONE"
    }
];

const ChangeWeightAmount = (props: {fieldObj11:any, CommonField:Function}) => {
    const {fieldObj11, CommonField} = props;
    const { values, errors, touched, handleChange, handleBlur, dirty }:any = useFormikContext();
    return(
            <Grid item xs={12} md={8} className="mt35">
                <Grid container spacing={2}>
                    {fieldObj11.map((field, index) => {
                        return (
                            <Grid item xs={12} xl={6} key={index}>
                                {CommonField(
                                    field, {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        dirty,
                                    },
                                )
                                }
                            </Grid>
                        );
                    })
                    }
                </Grid>
            </Grid>
    )
}

type RWCProps = {
    classes: any;
    fieldObj12: any;
    handleRadioChange: Function;
    isFromClientDetail: boolean;
}
const ReasonWeightChange = (props: RWCProps) => {
    const { classes, fieldObj12, handleRadioChange, isFromClientDetail } = props;
    const { values, errors, touched }:any = useFormikContext();
    return (
            <>
                <Grid item xs={12} md={12}>
                    <Typography variant="body2"
                                component="strong"
                                className="floatLeft mt15 mb10">
                        <Label className="mb0" label={`Reason for ${values.weightChangeQuantity.split("_")[0].toLowerCase()}`}
                               required={!isFromClientDetail}/>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    {fieldObj12.map((field, index) => {
                        return (
                                <div className={classes.radioGroup + " w100"}
                                     key={index + field["name"]}>
                                    <RadioGroup
                                            className="mb0 checkGroup checkGroupEnrollment"
                                            name={field.name}
                                            onChange={e => handleRadioChange(e, field.name)}
                                            value={values[field.name] || null}
                                            style={{ display: "inline" }}
                                    >
                                        {[
                                            {
                                                label: "Pregnancy",
                                                value: "pregnancy",
                                            },
                                            {
                                                label: "Intentional dieting / exercise",
                                                value: "exercise",
                                            },
                                            {
                                                label: "Other",
                                                value: "other",
                                            },
                                        ].map((option: any, idx: number) => (
                                                <FormControlLabel
                                                        key={idx}
                                                        className="mb10"
                                                        disabled={field.disabled || false}
                                                        value={option.value}
                                                        control={
                                                            <Radio
                                                                    className="mb0"
                                                                    color="primary" />}
                                                        label={option.label} />
                                        ))}
                                    </RadioGroup>
                                    {touched[field.name] && errors[field.name] && (
                                            <ErrorCommon
                                                    errors={errors}
                                                    name={field.name}
                                                    touched={touched}/>
                                    )}
                                </div>
                        );
                    })
                    }
                </Grid>
            </>
    )
}

const OtherReasonForWeightChange = (props: { fieldObj13:any, CommonField:Function }) => {
    const {fieldObj13, CommonField} = props;
    const { values, errors, touched, handleChange, handleBlur, dirty }:any = useFormikContext();
    return(
            <Grid item xs={12} md={8} className="mt35">
                <Grid container spacing={2}>
                    {fieldObj13.map((field, index) => {
                        return (
                                <Grid item xs={12} xl={6} key={index}>
                                    {CommonField(
                                            field, {
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                dirty,
                                            },
                                    )
                                    }
                                </Grid>
                        );
                    })
                    }
                </Grid>
            </Grid>
    )
}

type DKWCProps = {
    fieldObj14: any;
    classes: any;
    handleRadioChange: Function;
}
const DoctorKnownWeightChange = (props: DKWCProps) => {
    const {fieldObj14, classes, handleRadioChange } = props;
    const { values, errors, touched }:any = useFormikContext();
    return (
            <Grid item xs={12} md={12}>
                {
                    fieldObj14.map((field:any, index:number) => {
                        return (
                                <div className={classes.radioGroup + " w100"} key={index + field["name"]}>
                                    <RadioGroup
                                            className="mb0 checkGroup checkGroupEnrollment"
                                            name={field.name}
                                            onChange={e => handleRadioChange(e, field.name)}
                                            value={(values[field.name] == 1 || values[field.name] == 0) ? Number(values[field.name]) : null}
                                            style={{ display: "inline" }}
                                    >
                                        {[
                                            { label: "No", value: 0 },
                                            { label: "Yes", value: 1 },
                                        ].map((option: any, idx: number) => (
                                                <FormControlLabel
                                                        control={(
                                                                <Radio
                                                                        className="mb0"
                                                                        color="primary"
                                                                />
                                                        )}
                                                        className="mb10"
                                                        disabled={field.disabled || false}
                                                        key={idx}
                                                        label={option.label}
                                                        value={option.value}
                                                />
                                        ))}
                                    </RadioGroup>
                                    {
                                            touched[field.name] && errors[field.name] && (
                                                    <ErrorCommon errors={errors}
                                                                 name={field.name}
                                                                 touched={touched}/>
                                            )
                                    }
                                </div>
                        );
                    })
                }
            </Grid>
    )
}

const weightFluctuation = (values:any) => {
    let wf:boolean = false;
    if(values && values.hasOwnProperty('weightChangeQuantity')) {
        if(values['weightChangeQuantity'] === "DECREASE_20" || values['weightChangeQuantity'] === "INCREASE_20") {
            wf = true;
        }
    }
    return wf
}

type WeightChangeProps = {
    classes: any;
    CommonField: Function;
    fieldObj10: any;
    fieldObj11: any;
    fieldObj12: any;
    fieldObj13: any;
    fieldObj14: any;
    handleRadioChange: Function;
    isFromClientDetail: boolean;
    offerType: string;
}
const WeightChange = (props: WeightChangeProps) => {
    const {
        classes,
        CommonField,
        fieldObj10,
        fieldObj11,
        fieldObj12,
        fieldObj13,
        fieldObj14,
        handleRadioChange,
        isFromClientDetail,
    } = props;
    const { values, errors, touched, setFieldValue }:any = useFormikContext();

    return (
            <React.Fragment>
                <Grid item xs={12} md={12}>
                    <Typography variant="body1" component="strong" className="floatLeft mt15">
                        <Label label={`Which of the following describes how your weight has changed over the past 12 months?`} required={!isFromClientDetail}/>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    {
                        fieldObj10.map((field, index) => {
                            return (
                                    <div key={index + field["name"]}>
                                        <RadioGroup
                                                className="mb0 checkGroup checkGroupEnrollment"
                                                name={field.name}
                                                onChange={(e) => {
                                                    if (e.target.value === "INCREASE_10" || e.target.value === "DECREASE_10" || e.target.value === "NONE") {
                                                        setFieldValue("changedWeightAmount", null)
                                                        setFieldValue("weightLossDesc", null)
                                                        setFieldValue("reasonWeightChange", null)
                                                        setFieldValue("doctorKnowWeightChange", null)
                                                        setFieldValue("reasonWeightChangeOther", "")
                                                    }
                                                    handleRadioChange(e, field.name)
                                                }}
                                                value={values[field.name]}
                                                style={{ display: "inline" }}
                                            >
                                            <Grid container spacing={2}>
                                                {
                                                    obj10Options.map((option: any, idx: number) => (
                                                            <Grid className={classes.radioGroup} item key={idx} xs={12} md={6}>
                                                                <FormControlLabel
                                                                        className="mb0 RadioFontSize"
                                                                        disabled={field.disabled || false}
                                                                        value={option.value}
                                                                        control={(
                                                                                <Radio
                                                                                        className="mb0"
                                                                                        color="primary"
                                                                                />
                                                                        )}
                                                                        label={option.label}
                                                                />
                                                            </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </RadioGroup>
                                        {
                                                touched[field.name] && errors[field.name] && (
                                                        <ErrorCommon
                                                                errors={errors}
                                                                name={field.name}
                                                                touched={touched}
                                                        />
                                                )
                                        }
                                    </div>
                            );
                        })
                    }
                </Grid>

                {
                        weightFluctuation(values) &&
                        <ChangeWeightAmount fieldObj11={fieldObj11} CommonField={CommonField} />
                }

                {
                        weightFluctuation(values) &&
                        <ReasonWeightChange
                                classes={classes}
                                fieldObj12={fieldObj12}
                                handleRadioChange={handleRadioChange}
                                isFromClientDetail={isFromClientDetail}
                        />
                }

                {
                    values.reasonWeightChange === 'other' &&  weightFluctuation(values) &&
                    <OtherReasonForWeightChange fieldObj13={fieldObj13} CommonField={CommonField} />
                }

                {
                    weightFluctuation(values) &&
                    <>
                        <Grid item xs={12} md={12}>
                            <Typography variant="body1" component="strong" className="floatLeft mt15 mb10">
                                <Label label={`Is your doctor aware of your weight change?`} required={!isFromClientDetail}/>
                            </Typography>
                        </Grid>
                        <DoctorKnownWeightChange
                                classes={classes}
                                fieldObj14={fieldObj14}
                                handleRadioChange={handleRadioChange}
                        />
                    </>
                }
            </React.Fragment>
    )
}
export default WeightChange;