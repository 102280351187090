import React, { useEffect } from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import { NumberTextField, TextField } from "../../../../../../../components/formikFormInputs";
import * as Yup from "yup";
import { Formik } from "formik";
import GetFormContent from "../../Beneficiaries/BeneficiariesList/GetFormContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import { payloadDataHandler } from "../index";
import { isLongForm, isWorksheet } from "../../Utilities";

const Assets = (props: any) => {
    const {
        assetRef,
        autoSaveTrigger,
        financialAndLiabilitiesArr,
        formType,
        isFromClientDetail, /*setIsError,*/
        offerType,
        setAssetsVerified,
        setAssetsVerifyRef,
        setFinancialAndLiabilitiesArr,
        setFormType,
        setSaveFormContent,
        user,
    } = props;
    const clientSigned = user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? 1 : 0;
    const { updateClientFields } = useActions(AccountActions);
    const assetsVerifyRef = React.useRef<any>();
    const [isConfirmCheckbox, setIsConfirmCheckbox] = React.useState(user && user.client && user.client.verifyAssets ? true : false);

    useEffect(() => {
        if (assetsVerifyRef.current && typeof setAssetsVerifyRef === 'function') {
            setAssetsVerifyRef(assetsVerifyRef);
        }
    }, [assetsVerifyRef.current]);

    useEffect(()=>{
        setAssetsVerified(isConfirmCheckbox);
    },[isConfirmCheckbox]);
    const fieldObj = [
        {
            id: "cashInBank",
            name: "cashInBank",
            helperFieldText: "",
            // label: <Label label={"Cash in Banks/Bank Products"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Cash in Bank" : "Cash in Banks/Bank Products" } required={false}/>,
            isRequired: true,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "primaryRealEstate",
            name: "primaryRealEstate",
            helperFieldText: "",
            // label: <Label label={"Real Estate"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Primary Real Estate" : "Real Estate" } required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "otherRealEstateHoldings",
            name: "otherRealEstateHoldings",
            helperFieldText: "",
            label: <Label label={"Other Real Estate Holdings"} required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: (isLongForm(offerType) || isWorksheet(offerType))
        },
        {
            id: "receivables",
            name: "receivables",
            helperFieldText: "",
            label: <Label label={"Receivables"} required={false}/>,
            // label: <Label label={ isLongForm(offerType) ? "" : "Receivables" } required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: !(isLongForm(offerType))
        },
        {
            id: "stockBondAndSecurity",
            name: "stockBondAndSecurity",
            helperFieldText: "",
            // label: <Label label={"Stock, Bonds and Mutual Funds"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Stocks, Bonds, Securities" : "Stock, Bonds and Mutual Funds" } required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "personalProperty",
            name: "personalProperty",
            helperFieldText: "",
            label: <Label label={"Personal Property"} required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "businessEquity",
            name: "businessEquity",
            helperFieldText: "",
            // label: <Label label={"Business Interest"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Business Equity" : "Business Interest" } required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true

        },
        {
            id: "lifeInsuranceCashValue",
            name: "lifeInsuranceCashValue",
            helperFieldText: "",
            // label: <Label label={"Cash Value Life Insurance"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Life Insurance Cash Value" : offerType + "Cash Value Life Insurance" } required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "iraOrSep",
            name: "iraOrSep",
            helperFieldText: "",
            label: <Label label={"401K, IRA, or SEP"} required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: (isLongForm(offerType) || isWorksheet(offerType))
        },
        {
            id: "annuities",
            name: "annuities",
            helperFieldText: "",
            label: <Label label={"Annuities"} required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "otherAssets",
            name: "otherAssets",
            helperFieldText: "",
            label: <Label label={ (isLongForm(offerType) || isWorksheet(offerType)) ? "Other Assets - Provide Details" : "Other Assets Total Amount" } required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "otherAssetDetails",
            name: "otherAssetDetails",
            helperFieldText: "",
            label: <Label label={"Other Assets Details"} required={false} />,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 12,
                xl: 12,
            },
            disabled: clientSigned ? true : false,
            show: !(isLongForm(offerType) || isWorksheet(offerType))
        },
    ];

    /*Set initial values*/
    const initialValues = {};

    fieldObj.forEach((field) => {
        initialValues[field.name] = user && user.client && user.client[field.name] || "";
    });

    let keysValidation = {
    };
    const validationSchema = Yup.object().shape(keysValidation);

    return (

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Formik
                            innerRef={assetRef}
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            validateOnChange={true}
                            onSubmit={async (values: any, { setSubmitting }) => {
                                try {
                                    let payload:any = payloadDataHandler(values, "ASSETS", isConfirmCheckbox);
                                    //if (financialAndLiabilitiesArr.filter((item) => item.formType === "ASSETS").length == 0) {
                                    const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "ASSETS")
                                    if(index > -1) {
                                        financialAndLiabilitiesArr.splice(index, 1);
                                    }
                                        setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
                                            ...payload
                                        }])
                                    //}
                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 400);
                                } catch (e) {
                                    return false;
                                }
                            }}
                    >
                        {
                            (formikProps) => {
                                /*if (Object.keys(formikProps.errors).length > 0) {
                                    setTimeout(() => setIsError(true), 500);
                                } else {
                                    if (formikProps.dirty) {
                                        setTimeout(() => setIsError(false), 500);
                                    }
                                }*/

                                let totalValues: number = 0;
                                Object.keys(formikProps.values).map((key) => {
                                    if (key === "otherAssetDetails") {
                                        return;
                                    }
                                    const value = formikProps.values[key];
                                    totalValues = totalValues + (numeral(value).value() || 0);
                                    return true;
                                });
                                return (
                                        <form className="floatLeft w100" onBlur={autoSaveTrigger} onSubmit={formikProps.handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" component="strong"
                                                                className="floatLeft mt30 w100 textBlueDark mb20">
                                                        Assets
                                                        <span className="floatRight">
                                                            Total Assets:
                                                            <NumberFormat
                                                                    value={totalValues || 0}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    prefix={"$"}
                                                            />
                                                        </span>
                                                    </Typography>
                                                    <Typography variant="caption"
                                                                className="textLight mb15 floatLeft w100">Fill out or
                                                        verify any assets you have
                                                        below making any necessary changes. If a particular field
                                                        doesn’t apply you can leave it blank.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <GetFormContent isFromClientDetail={isFromClientDetail} fieldObj={fieldObj} {...formikProps}
                                                            enableOnChangeSubmit={false} setSaveFormContent={setSaveFormContent}
                                                            formType={formType} setFormType={setFormType}
                                            />
                                            {!isFromClientDetail &&
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div style={{ display: "flex", alignItems:'center' }} className="mt20" ref={assetsVerifyRef} id="assetsVerifyRef">
                                                        <Checkbox style={{ height: "28px", marginRight: "10px" }}
                                                                  className="floatLeft pd0 mr10" color={"primary"}
                                                                  value={isConfirmCheckbox ? true : false}
                                                                  checked={isConfirmCheckbox ? true : false}
                                                                  onChange={(e, v) => {
                                                                      console.log("v -=-=-==-=-", v);
                                                                      //setIsError(!v);
                                                                      setIsConfirmCheckbox(v);
                                                                      if(!!formType && typeof setFormType === "function") {
                                                                          setFormType(formType);
                                                                      }
                                                                      // updateClientFields({
                                                                      //     verifyAssets: v ? 1 : 0,
                                                                      //     apiType: "ASSETS",
                                                                      // }, user && user.client && user.client.id || "", isFromClientDetail);
                                                                  }}
                                                                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}/>
                                                        <Typography variant="caption">
                                                            <span> I verify that my total assets are </span>
                                                            <NumberFormat
                                                                    value={totalValues || 0}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    prefix={"$"}
                                                            />.
                                                            <span className="requiredHighlight">*</span>
                                                        </Typography>
                                                    </div>
                                                    {
                                                        !isConfirmCheckbox &&
                                                        <p style={{margin: 0}}>
                                                            <span style={{color: "#FF1744", fontSize: 12}}> Required Field </span>
                                                        </p>
                                                    }
                                                </Grid>
                                            </Grid>
                                            }
                                        </form>
                                );
                            }}
                    </Formik>
                </Grid>
            </Grid>
    );
};

export default Assets;