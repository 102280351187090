import * as React from "react";
import {
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Field } from "redux-form";
import { TextField, Button, PhoneNumberField } from "../../../../../components/formInputs";
import { required, email, validatePhoneNumber } from "../../../../../utils/validation";
import { reduxForm, Form, reset, getFormValues } from "redux-form";
import * as Actions from "../../../../../actions/account";
import { useActions } from "../../../../../actions";
import { unmaskPhoneNumber } from "../../../../../utils/commonUtils";
import { connect } from "react-redux";
import { RootState } from "../../../../../reducers/index";
import { getMaskedNumber } from "../../../../../constants";

const LandingContactForm = (props: any) => {
    const accountActions = useActions(Actions);
    const { handleSubmit, dirty, submitting, open, accountType } = props;
    const isIndividual = accountType === "INDIVIDUAL";

    const handleClose = () => {
        props.setOpen(false);
    };

    const addContact = async (data: any, dispatch: any) => {
        if (data.phone) {
            data.phone = unmaskPhoneNumber(data.phone || "");
        }
        const agentEmail: string = props.agent && props.agent.user ? props.agent.user.email : "";
        await accountActions.sendContactInfoFromLandingPage({ ...data, agentEmail });
        dispatch(reset("LandingContactFormDialog"));
        handleClose();
    };

    return (
            <Dialog style={{zIndex:999999}} fullScreen open={open} onClose={handleClose}>
                <DialogContent className="pdT8">
                    <IconButton edge="end" className="closeicon" color="primary" onClick={handleClose}
                                aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <Form onSubmit={handleSubmit(addContact)}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={6}>
                                <Typography align="center" variant="h3"><strong>Get in Touch</strong></Typography>
                                <Typography align="center" variant="body1" className="mt30 mb30">
                                    If you have questions or need help with accessing your benefits please contact your agent at {props.agent && props.agent.user ? getMaskedNumber(props.agent.user.phoneNumber + ',') : ""} {(props.agent && props.agent.user.email + ',') || ''} or leave a message below.
                               </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={4} lg={4} className="estimateReturnsForm paddingTop10">
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12}>
                                        <Field
                                                fullWidth
                                                className="estimateReturnsField"
                                                color="primary"
                                                variant="outlined"
                                                name="name"
                                                disabled={isIndividual}
                                                validate={required}
                                                placeholder="First and last name*"
                                                component={TextField}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                                fullWidth
                                                className="estimateReturnsField"
                                                color="primary"
                                                variant="outlined"
                                                name="email"
                                                disabled={isIndividual}
                                                placeholder="Email*"
                                                validate={[required, email]}
                                                component={TextField}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                                fullWidth
                                                className="estimateReturnsField"
                                                color="primary"
                                                variant="outlined"
                                                name="phone"
                                                disabled={isIndividual}
                                                placeholder="Phone*"
                                                component={PhoneNumberField}
                                                validate={[required, validatePhoneNumber]}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                                fullWidth
                                                className="estimateReturnsField"
                                                color="primary"
                                                variant="outlined"
                                                name="howCanWeHelp"
                                                placeholder="How can we help?"
                                                validate={required}
                                                component={TextField}
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                                size="medium"
                                                type="submit"
                                                label={"Send"}
                                                className="floatRight"
                                                variant="contained"
                                                color="primary"
                                                disabled={!dirty || submitting}
                                                loading={submitting}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </DialogContent>
            </Dialog>
    );
};

function mapStateToProps(state: RootState, ownProps: any) {
    if (ownProps.accountType === "INDIVIDUAL") {
        let verifyData: any = {};
        if (ownProps.firstName) {
            verifyData.firstName = ownProps.firstName;
            verifyData.lastName = ownProps.client && ownProps.client.user ? ownProps.client.user.lastName : "";
            verifyData.email = ownProps.email;
            verifyData.phoneNumber = ownProps.phoneNumber;
        } else if (state.verify.firstName) {
            verifyData.firstName = state.verify.firstName;
            verifyData.lastName = state.verify.lastName;
            verifyData.email = state.verify.email;
            verifyData.phoneNumber = state.verify.phoneNumber;
        }

        return {
            formValues: getFormValues("LandingContactFormDialog")(state),
            initialValues: {
                name: `${verifyData.firstName} ${verifyData.lastName}`,
                email: verifyData.email,
                phone: verifyData.phoneNumber,
            },
        };
    }
    return {
        formValues: getFormValues("LandingContactFormDialog")(state),
        initialValues: { name: ``, email: "", phone: "" },
    };
}

const reduxConatctFormPage = reduxForm({
    form: "LandingContactFormDialog",
})(LandingContactForm);
export default connect(mapStateToProps)(reduxConatctFormPage);
