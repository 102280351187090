import * as React from "react";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { IconButton, TableBody, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { AccountHeadRow } from "../../model/account";
import { AccountStatusArr } from "../../model";
import EnhancedTableHead from "./EnhancedTableHead";
import { dateUtils } from "../../utils";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormatPhoneNumber from "../format/PhoneNumberFormat";

import { DeleteModelWithNotes } from "../formInputs";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import {maskPhoneNumber} from "../../utils/commonUtils";

const headRowsAccountList: AccountHeadRow[] = [
	{ id: 'sNo', label: 'S.No' },
	{ id: 'firstName', label: 'First Name' },
	{ id: 'lastName', label: 'Last Name' },
	{ id: 'email', label: 'Email' },
	{ id: 'phoneNumber', label: 'Phone' },
	{ id: 'client.strategy.name', label: 'ILIA Product Type' },
	{ id: 'carrier.carrierName', label: 'Carrier' },
	{ id: 'status', label: 'Status' },
	{ id: 'createdAt', label: 'Date Created' }
];

function ClientListParticipants(props: any) {
    const accountActions = useActions(AccountActions);
	const { accountList, order, orderBy, handleRequestSort, handleRefresh, page, rowsPerPage } = props;
    const [isDeleteModel, setIsDeleteModel] = React.useState<string|number>("");
    const [message, setMessage] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [notesValue, setNotesValue] = React.useState("")
	const [buttonLoader, setButtonLoader] = React.useState(false);
    const [userType, setUserType] = React.useState("");
    let sNo = page * rowsPerPage;
    const refreshTable = () =>{
        handleRefresh();
    }

    const deletedUser = (id: string|number, type: string) => {
        let invitationStatus: number = 0;
        let userDetails:any = [];
        if(type === 'PARTICIPANT'){
            userDetails = accountList.rows.filter((val: any) => (id === val.id));
        } else {
            userDetails = accountList.rows.filter((val: any) => (id === val.userId));
        }
        if(userDetails.length > 0){
            invitationStatus = userDetails[0]["user.inviteStatus"];
            if(invitationStatus >= 4){
                setMessage("This client has started or completed their enrollment do you still want to delete their account? If yes it will be permanently deleted from ilia ?")
            } else {
                setMessage("Are you sure you want to delete this client permanently from ilia ?")
            }
            setTitle(userDetails[0]["firstName"] + " " +userDetails[0]["lastName"])
        }
        setIsDeleteModel(id)
    };

    const deleteParticipants = async () => {
        setButtonLoader(true);
        await accountActions.deleteAccount(isDeleteModel, userType, notesValue);
        setIsDeleteModel("");
        setNotesValue("");
        setButtonLoader(false);
        refreshTable();
    };

    const setNotes = (value) => {
        setNotesValue(value)
    }

    return (
		<React.Fragment>
			<EnhancedTableHead
				order={order}
				orderBy={orderBy}
				onRequestSort={handleRequestSort}
				headRows={headRowsAccountList}
			/>
			<TableBody>
				{
					(!accountList.rows || accountList.rows.length === 0)
					&& <TableRow>
						<TableCell align="center" colSpan={8} className="textLeft">
							No Client-List Participants
						</TableCell>
					</TableRow>
				}
				{accountList.rows && accountList.rows.map((account: any, index:number) =>  {
					return (
						<TableRow hover key={account.id}>
							<TableCell>{++sNo}</TableCell>
							<TableCell padding="default">{account.firstName}</TableCell>
							<TableCell padding="default">{account.lastName}</TableCell>
							<TableCell padding="default">{account.email}</TableCell>
							<TableCell padding="default">{maskPhoneNumber(account.phone)}</TableCell>
							<TableCell padding="default">{account["strategy.strategyName"] ? account["strategy.strategyName"]:account["strategyName"]}</TableCell>
							<TableCell padding="default">{account["carrier.carrierName"] ? account["carrier.carrierName"]:account["carrierName"]}</TableCell>
							<TableCell padding="default">{AccountStatusArr[account['user.inviteStatus'] ? account['user.inviteStatus'] : 0 ]}</TableCell>
							<TableCell padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
							<TableCell padding="default">
								{ account['user.id'] &&  <Tooltip title="View">
									<Link to={'/new-client/detail/' + account['user.client.id']}>
										<IconButton color="default">
											<VisibilityIcon/>
										</IconButton>
									</Link>
								</Tooltip> }
								<Tooltip title="Delete">
									<IconButton
										color="default"
										onClick={() => {
										    const deleteId: string|number = account.userId || account.id;
										    const type:string = (account.userId) ? ("USER"):("PARTICIPANT");
                                            setUserType(type);
                                            deletedUser(deleteId, type)
                                        }}
									>
										<DeleteOutlineIcon className="colorRed" />
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					)
				})
				}
			</TableBody>
            {
                isDeleteModel ?
                        (
                                <DeleteModelWithNotes
                                        open={true}
                                        title={title}
                                        content={message}
                                        handleClose={() => {setIsDeleteModel(""); setNotesValue(""); setUserType("")}}
                                        handleYes={deleteParticipants}
                                        setNotes={setNotes}
                                        buttonLoader={buttonLoader}
                                        notes={notesValue}
                                />
                        ):null
            }
		</React.Fragment>
	)
}
export default ClientListParticipants;
