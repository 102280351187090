import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    withStyles, Tooltip, Link,
} from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import EditIcon from "@material-ui/icons/Edit";
import EstimationResultToolTip from "./EstimationResultToolTip";
import NumberFormat from "react-number-format";
import { blue } from "@material-ui/core/colors";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { IllustrationButton } from "../../../pages/client/Estimation/V1/EstimationTabs";
import { useActions } from "../../../actions";
import * as Actions from "../../../actions/estimation";
import { Info } from "@material-ui/icons";
import GenericDialog from "../../dialog/GenericDialog";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import * as UserActions from "../../../actions/account";
import { useHistory } from "react-router-dom";
import { ATLANTIC_HEALTH } from "../../../constants";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
const useStyles1 = makeStyles((theme) => ({
    root: {
        display: "flex",
        marginTop: 40,
        width: 500,
        border: "3px solid #3DDB93",
        float: "right",
        [theme.breakpoints.down(767)]: {
            width: "100%",
        },
        [theme.breakpoints.down(1292)]: {
            marginTop: 0,
            float: "left",
        },
    },
    buttonProgress: {
        color: blue[500],
        position: "absolute",
        top: "10px",
        left: "35%",
        marginTop: "0px",
        width: "100%",
    },
}));

function createData(name, value) {
    return { name, value };
}

export function StartNewEstimateButton(props: { setOpenEstimationDialog: Function | undefined, callingFrom?: string, clientDetail?: any }) {
    const {setOpenEstimationDialog, callingFrom, clientDetail} = props;
    let envelopeId = clientDetail && clientDetail.enrollment && clientDetail.enrollment.envelopeId || "";
    const disableButton = !!(callingFrom && callingFrom === "ENROLLMENT_TAB" && envelopeId);
    return <>{!disableButton ? <Button
            className="floatLeft"
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<BorderColorIcon/>}
            onClick={() => {
                if (typeof setOpenEstimationDialog === "function") {
                    setOpenEstimationDialog(true);
                }
            }}
    >
        Start New Estimate
    </Button> :
       <Typography variant="body1" className="floatLeft" style={{display: "flex",alignItems:'center'}}> <WarningIcon className="colorOrg mr10 f18"/> Client has begun enrollment - contribution cannot be edited.</Typography>}</>;
}

export function CancelEstimation(props: { setShowResults: Function | undefined }) {
    const estimationActions = useActions(Actions);
    const { setShowResults } = props;
    return <Button
            className={"mb15 mr10 floatRight"}
            variant="outlined"
            size="small"
            color="primary"
            onClick={async () => {
                await estimationActions.clearEstimation("INVITATION");
                if (typeof setShowResults === "function") {
                    setShowResults(false);
                }
                console.log("Cancel Estimation");
            }}
    >
        Cancel Estimation
    </Button>;
}

const NonFinanced = (props: { setNonFinancedPopUp: Function }) => {
    const { setNonFinancedPopUp } = props;
    return <Link className="backBtn mt10 floatRight" color="primary"
                 onClick={() => {
                     setNonFinancedPopUp(true);
                 }}> Click here for non-financed option </Link>;
};

type NonFinancedOptionProps = {
    nonFinancedPopUp: boolean,
    setNonFinancedPopUp: Function,
    userId: string
};

const NonFinancedOption: React.FC<NonFinancedOptionProps> = (props) => {
    const { nonFinancedPopUp, setNonFinancedPopUp, userId } = props;
    const [formSucess, setFormSucess] = useState(false);
    const accountActions = useActions(UserActions);
    const nav = useHistory();
    const onSign = async () => {
        let payload = {};
        payload["nonFinance"] = 1;
        if (userId) {
            await accountActions.updateUserBasicDetails(payload, userId, "");
            setFormSucess(true);
        }
    };
    const onClose = () => {
        setNonFinancedPopUp(false);
        if (formSucess) {
            nav.replace(nav.location.pathname);
        }
    };
    const content = (
            formSucess ?
                    <>
                        <Typography variant="body1">
                            Your request has been submitted a member of the NIW processing team will contact you shortly
                            about your non-finance options. For any questions, please call 800-294-9940 or email <a
                                href="mailto:info@kaizenplan.com">info@kaizenplan.com</a>
                        </Typography>
                        <Button size="small"
                                variant="contained"
                                className="mt20 mb20 floatRight"
                                color="secondary"
                                onClick={onClose}
                        >
                            CLOSE
                        </Button>
                    </> :
                    <>
                        <Typography variant="body1">
                            Confirm your interest in non-financed options.
                        </Typography>
                        <Button
                                size="small"
                                variant="contained"
                                className="mt20 mb20 floatRight"
                                color="secondary"
                                onClick={onClose}>
                            NO
                        </Button>
                        <Button
                                size="small"
                                variant="contained"
                                className="mt20 mb20 floatRight mr15"
                                color="primary"
                                onClick={onSign}>
                            YES
                        </Button>
                    </>
    );
    return (
            <GenericDialog
                    aria-describedby="non-financed-dialog"
                    content={content}
                    fullWidth
                    keepMounted
                    maxWidth="sm"
                    onClose={onClose}
                    open={nonFinancedPopUp}
                    title="NON-FINANCE OPTION"
            />
    );
};


export function SampleIllustrationButton(props: { isReportDataLoading: boolean | undefined, setIsReportRequestCompleted?: Function }) {
    const classes = useStyles1();
    const { isReportDataLoading, setIsReportRequestCompleted } = props;
    return <IllustrationButton isReportDataLoading={isReportDataLoading}
                               classes={classes} clientEstimationPage={false}
                               setIsReportRequestCompleted={setIsReportRequestCompleted} />;
}

export function EstimationResultCard(props: {
    setEditCalculationInputs?: Function, setEditLifeTimeDeathBenefitAmount?: Function, stressSolveFor?: string,
    contributionAmountFiveYears: number, common: any, isEditable: boolean, deathBenefitLessLoanYear1: number,
    income: number, setIsReportRequestCompleted?: Function, callingFrom?: string, clientDetail?: any
}) {
    const {
        setEditLifeTimeDeathBenefitAmount,
        stressSolveFor,
        contributionAmountFiveYears,
        isEditable,
        deathBenefitLessLoanYear1,
        income,
        clientDetail,
        callingFrom
    } = props;

    const HtmlTooltip = withStyles(() => ({
        tooltip: {
            backgroundColor: "#2f2f2f",
            maxWidth: "500px",
            minWidth: "100px",
            color: "#ffccc",
            lineHeight: "18px",
            fontWeight: "normal",
            padding: "20px",
            margin: "0",
            boxShadow: "0 0 15px 0px #8c8c8c",
        },
    }))(Tooltip);

    const envelopeId = clientDetail && clientDetail.enrollment && clientDetail.enrollment.envelopeId || "";
    const editDisable = !!(callingFrom && callingFrom === "ENROLLMENT_TAB" && envelopeId);
    return <Grid item xs={12}>
        <div className="benefitsCard mt40 mb20">
            <div className="benefitsCardContent">
                <Typography variant="subtitle1" component="strong" className="mb15 floatLeft w100">Potential Benefit
                    Totals</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <Typography variant="caption" className="floatLeft">My Total Contributions <span style={{display:'inline-flex'}}>(5 years) <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Typography className="f16 txtWhite">The sum of your 5 years of contributions including annual trust fees.</Typography>
                                    </React.Fragment>
                                }
                        >
                            <Info className="f18 icon" color="primary"/>
                        </HtmlTooltip></span>
                        </Typography>
                        <Typography variant="subtitle1" className="textHighLight floatLeft w100"
                                    component="strong"><NumberFormat
                                value={contributionAmountFiveYears}
                                displayType={"text"}
                                decimalScale={0}
                                thousandSeparator={true}
                                prefix={"$"} /></Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <Typography variant="caption" className="floatLeft w100">Lifetime Estimated Death
                            Benefit</Typography>
                        <Typography variant="subtitle1" className="textHighLight"
                                    component="strong">
                            <NumberFormat
                                    value={deathBenefitLessLoanYear1}
                                    displayType={"text"}
                                    thousandSeparator={true} prefix={"$"}
                                    renderText={value => <Box className="f18 floatLeft"
                                    >{value}</Box>} />&nbsp;&nbsp;
                            {
                                    (stressSolveFor === "Contribution_Amount" && isEditable) && !editDisable &&
                                    <span onClick={() => {
                                        if (typeof setEditLifeTimeDeathBenefitAmount === "function") {
                                            setEditLifeTimeDeathBenefitAmount(true);
                                        }
                                    }}>
                                        <IconButton aria-label="Info" color="primary" size="small">
                                                <EditIcon className="f18"/>Edit
                                        </IconButton>
                                    </span>
                            }
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <Typography variant="caption" className="floatLeft w100">My Potential Annual
                            Distributions</Typography>
                        <Typography variant="subtitle1" className="textHighLight" component="strong">
                            <NumberFormat
                                    value={income}
                                    displayType={"text"}
                                    thousandSeparator={true} prefix={"$"}
                                    renderText={value => <Box className="f18 floatLeft"
                                    >{value}</Box>}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    </Grid>;
}

export default function ClientEstimationResult(props: {
    setEditCalculationInputs?: Function,
    setEditLifeTimeDeathBenefitAmount?: Function, responseData?: any
    setOpenEstimationDialog?: Function, isReportDataLoading?: boolean,
    isEditable?: boolean, handleClose?: Function, handleReset?: Function, setIsReportRequestCompleted?: Function,
    callingFrom?: string, setShowResults?: Function, clientDetail?: any, estimationConfirmation?:Function
}) {
    const classes = useStyles();
    const {
        setEditCalculationInputs,
        setEditLifeTimeDeathBenefitAmount,
        responseData,
        setOpenEstimationDialog,
        isReportDataLoading,
        handleClose,
        isEditable = true,
        setIsReportRequestCompleted,
        callingFrom,
        setShowResults,
        clientDetail,
        estimationConfirmation
    } = props;
    const user = useSelector((state: RootState) => state.profile);
    const userId: string = user && user.client && user.client.userId || "";
    const companyLinkUrl = user && user.client && user.client.company && user.client.company.companyLinkUrl || "";
    const [stressSolveFor, setStressSolveFor] = useState("");
    const { stress, common, illustration } = responseData || "";
    const deathBenefitLessLoanYear1 = illustration && illustration.data && illustration.data.deathbenefitlessloanyear1 && Math.round(illustration.data.deathbenefitlessloanyear1) || 0;
    let contributionFrequency = common && common.paymentfrequency.toLowerCase() || "annual";
    let income: number = illustration && illustration.income && Math.round(illustration.income) || 0;
    let employerContribution: number = 0;
    let contributionAmountForOneYear: number = (stress && stress.contributionamount) || 0;
    let contributionAmountFiveYears: number = (stress && (stress.contributionamount * 5)) || 0;
    if (common && common.contributionamountemployer) {
        employerContribution = common.contributionamountemployer;
        contributionAmountForOneYear = stress.contributionamount - employerContribution;
        contributionAmountFiveYears = contributionAmountForOneYear*5;
    }
    //const { gender, healthType, tobacco } = useSelector((state: RootState) => state.clientDetailsWhileEstimate);
    const { gender, rateclass } = common || "";
    let rc: any = rateclass && rateclass.split("_") || [];
    let healthType: string = rc[0];
    let tobacco: string = rc[1];

    const envelopeId = clientDetail && clientDetail.enrollment && clientDetail.enrollment.envelopeId || "";
    const editDisable = !!(callingFrom && callingFrom === "ENROLLMENT_TAB" && envelopeId);
    const [nonFinancedPopUp, setNonFinancedPopUp] = useState(false);

    useEffect(() => {
        if (responseData && responseData.run && responseData.run.stresssolvefor) {
            setStressSolveFor(responseData.run.stresssolvefor);
        }
    }, [responseData && responseData.run && responseData.run.stresssolvefor]);

    const rows = [
        createData(
            <>
                Annual Contribution Amount for 5 Years
                <EstimationResultToolTip>
                    The amount you’d like to contribute toward your policy premium each year (includes an annual trust fee).
                </EstimationResultToolTip>
            </>,
            <>
            {(stressSolveFor === "Death_Benefit" && isEditable) && !editDisable &&
                <Button variant="text" color="primary" onClick={() => {
                    if (typeof setEditCalculationInputs === "function") {
                        setEditCalculationInputs(true);
                    }
                }} startIcon={<EditIcon/>}>
                    Edit Contribution
                </Button>
            }
            <NumberFormat
                value={contributionAmountForOneYear}
                displayType={"text"}
                decimalScale={0}
                thousandSeparator={true}
                prefix={"$"} />
            {contributionFrequency === "monthly" && <Typography className={"f14 textBlackLight"}><NumberFormat
                value={(contributionAmountForOneYear/12)}
                displayType={"text"}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$"} /> Monthly. For monthly designs, two months of payments are due at signing.</Typography> }
        </>),
        /* createData(<>Employer Annual Contribution <EstimationResultToolTip>Tool
             Tip</EstimationResultToolTip></>, "$10,000"),*/
        createData(<>Gender</>, gender),
        createData(<>Health <EstimationResultToolTip>{healthType.toLowerCase() === "preferred" ?
                "You are generally healthy with little or no history of medical issues.": "You are generally healthy with maybe some history of medical issues."
        }</EstimationResultToolTip></>, `${healthType.toLowerCase() === "preferred" ? "Excellent" : "Average"}`),
        createData(<>Tobacco and Marijuana Use<EstimationResultToolTip>Includes chewing, smoking, vaping, and other forms of use.</EstimationResultToolTip></>, tobacco === "NT" ? "I don’t use tobacco or marijuana products" : "I Use Tobacco or Marijuana Products"),
    ];

    if (false) { // check if Employer annual contribution exists
        rows.splice(1, 0, createData(<>Employer Annual Contribution <EstimationResultToolTip>Tool
            Tip</EstimationResultToolTip></>, "$10,000"));
    }

    return (
            <>
                <TableContainer className="mb10">
                    <Table className={`${classes.table} + "mb10"`} aria-label="simple table">
                        <TableBody>
                            {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="body1" className="mt10 mb10"> {row.name} </Typography>
                                        </TableCell>
                                        <TableCell align="right"> <Typography variant="body1" component="strong"
                                                                              className="mt10 mb10"> {row.value} </Typography></TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <EstimationResultCard setEditCalculationInputs={setEditCalculationInputs}
                                      setEditLifeTimeDeathBenefitAmount={setEditLifeTimeDeathBenefitAmount}
                                      stressSolveFor={stressSolveFor}
                                      contributionAmountFiveYears={contributionAmountFiveYears}
                                      common={common}
                                      isEditable={isEditable}
                                      deathBenefitLessLoanYear1={deathBenefitLessLoanYear1}
                                      income={income}
                                      setIsReportRequestCompleted={setIsReportRequestCompleted}
                                      callingFrom={callingFrom} clientDetail={clientDetail}
                />
                <Grid container spacing={2}>
                    {isEditable ?
                        <>
                            <Grid item xs={12}>
                                <StartNewEstimateButton setOpenEstimationDialog={setOpenEstimationDialog} callingFrom={callingFrom} clientDetail={clientDetail} />
                                {
                                        companyLinkUrl && (ATLANTIC_HEALTH.includes(companyLinkUrl)) &&
                                        <NonFinanced setNonFinancedPopUp={setNonFinancedPopUp} />
                                }

                                {
                                        nonFinancedPopUp &&
                                        <NonFinancedOption nonFinancedPopUp={nonFinancedPopUp}
                                                           setNonFinancedPopUp={setNonFinancedPopUp}
                                                           userId={userId} />
                                }

                                {(callingFrom === "INVITATION" || callingFrom === "ENROLLMENT_TAB") &&
                                        <>
                                            <SampleIllustrationButton
                                                    isReportDataLoading={isReportDataLoading}
                                                    setIsReportRequestCompleted={setIsReportRequestCompleted}
                                            />
                                            {
                                                    callingFrom === "INVITATION" &&
                                                    <CancelEstimation setShowResults={setShowResults} />
                                            }
                                        </>
                                }
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                            <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className="ml15 flo atRight mt20 mb15 floatLeft"
                                    onClick={() => {
                                        if (typeof handleClose === "function") {
                                            handleClose();
                                        }
                                    }}
                            >
                                Cancel
                            </Button>
                            <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className="ml15 flo atRight mt20 mb15 floatRight"
                                    onClick={() => {
                                        if(typeof estimationConfirmation === 'function'){
                                            estimationConfirmation()
                                        }
                                        }
                                    }
                            >
                                Confirm Details
                            </Button>
                        </Grid>
                    }
                </Grid>
            </>
    );
}
