import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Button as CustomButton, Label, PageTitle } from "./formInputs";
import { Card, CardContent, Grid } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import { ErrorCommon, PhoneNumberField, TextField, TextFieldCommon } from "../components/formikFormInputs";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKey } from "../constants";
import { useActions } from "../actions";
import * as AuthActions from "../actions/auth";
import * as AccountActions from "../actions/account";
import { unmaskPhoneNumber } from "../utils/commonUtils";
import { isEmpty } from "../utils/validation";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition: any = React.forwardRef(function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props}></Slide>;
});


const initialValues = {
    name: "",
    email: "",
    phoneNo: "",
    message: "",

};

const fieldObj = [
    {
        id: "name",
        name: "name",
        label: <Label label={"Enter Your Name"} required={true}/>,
        isRequired: true,
        component: TextField,
        variant: "outlined",
        className: "TextFieldWidth",
        disabled: false,
    },
    {
        id: "email",
        name: "email",
        label: <Label label={"Enter Your Email"} required={true}/>,
        isRequired: true,
        component: TextField,
        variant: "outlined",
        className: "TextFieldWidth",
        disabled: false,
    },
    {
        id: "phoneNo",
        name: "phoneNo",
        label: <Label label={"Enter Your Phone Number"} required={true}/>,
        isRequired: true,
        component: PhoneNumberField,
        variant: "outlined",
        className: "TextFieldWidth",
        disabled: false,
    },
    {
        id: "message",
        name: "message",
        label: <Label label={"Message"} required={true}/>,
        isRequired: true,
        component: TextField,
        multiline: true,
        rows: 5,
        variant: "outlined",
        className: "TextFieldWidth",
        disabled: false,
    },
];

const validate = ((value: any) => {
    let errors: any = {};
    if (!value.name || !value.name.trim()) {
        errors.name = "Required";
    }
    if (!value.message || !value.message.trim()) {
        errors.message = "Required";
    }
    if (!value.phoneNo) {
        errors.phoneNo = "Required";
    }
    if (!isEmpty(unmaskPhoneNumber(value.phoneNo)) && unmaskPhoneNumber(value.phoneNo).length < 10) {
        errors.phoneNo = "Invalid phone number";
    }
    if (!value.email) {
        errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
        errors.email = "Invalid email address";
    }
    return errors;
});


export const HelpForm = (props: { validCaptcha?: boolean, setValidCaptcha?: Function, publicForm: boolean }) => {
    const refCaptcha = useRef<ReCAPTCHA>("");
    const authAction = useActions(AuthActions);
    const accAction = useActions(AccountActions);
    const { validCaptcha, setValidCaptcha, publicForm } = props;
    const [token, setToken] = useState<string>("");

    const setCaptchaVal = (val: boolean): any => {
        if (typeof setValidCaptcha === "function") {
            setValidCaptcha(val);
        }
    };

    const submitContactUs = async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
        let phoneNumber = unmaskPhoneNumber(values.phoneNo)
        let payload = Object.assign({}, values);
        payload.phone = phoneNumber;
        payload.name = payload.name.trim();
        payload.message = payload.message.trim();
        setSubmitting(true);
        if (token && publicForm) {
            payload.token = token;
            await authAction.publicContactUs(payload);
            setCaptchaVal(false);
            setToken("");
            refCaptcha.current.reset(); // reset recaptcha token after submit.
        } else {
            await accAction.contactUs(payload);
        }
        setTimeout(() => setSubmitting(false), 500);
        resetForm();
    };

    return (
            <Formik
                    initialValues={initialValues}
                    validate={values => validate(values)}
                    onSubmit={submitContactUs}
                    enableReinitialize={true}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      handleChange,
                      isValid,
                      isValidating,
                        dirty
                  }) => {
                    return (
                            <form onSubmit={handleSubmit}>
                                {fieldObj.map((field, index) => {
                                    return (
                                            <Grid item xs={12}
                                                  key={index}
                                                  className="mb15">
                                                <TextFieldCommon
                                                        {...field}
                                                        values={values}
                                                        onChange={handleChange}
                                                        InputLabelProps={{
                                                            disabled: false,
                                                        }}
                                                />
                                                <ErrorCommon errors={errors} name={field.name}
                                                             touched={touched}/>
                                            </Grid>
                                    );
                                })
                                }
                                {
                                    publicForm &&
                                    <ReCAPTCHA
                                            sitekey={captchaSiteKey}
                                            onChange={(value) => {
                                                if (value) {
                                                    setToken(value);
                                                    setCaptchaVal(true);
                                                } else {
                                                    setToken("");
                                                    setCaptchaVal(false);
                                                }
                                            }}
                                            ref={refCaptcha}
                                    />
                                }
                                <Grid item container>
                                    <Grid item xs={12}>
                                        <CustomButton
                                                fullWidth={false}
                                                type="submit"
                                                label="SUBMIT"
                                                variant="contained"
                                                color="primary"
                                                className="btnPrimaryBig floatLeft mt15"
                                                disabled={publicForm ? !validCaptcha || isSubmitting : !dirty || isSubmitting}
                                                loading={isSubmitting}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                    );
                }}

            </Formik>
    );
};
export default function NeedHelp(props: { needHelp: boolean, setNeedHelp: Function }) {
    const classes = useStyles();
    const { needHelp, setNeedHelp } = props;
    const [validCaptcha, setValidCaptcha] = React.useState(false);
    const handleClose = () => {
        setNeedHelp(false);
    };

    return (
            <div>
                <Dialog fullScreen open={needHelp} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} color="secondary">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title} color="primary">
                                Contact Us
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={3} justify="center" className="mt20">
                        <PageTitle title="Contact Us"/>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" className="textCenter mt60">
                                Please contact the following for support
                                Email: Support@myilia.com
                                Phone: 972-645-7755(9pm-5pm cst)
                                or complete this support form
                            </Typography>
                            <Card className="muiCard">
                                <CardContent className="muiCardContent">
                                    <HelpForm validCaptcha={validCaptcha} setValidCaptcha={setValidCaptcha}
                                              publicForm={true}/>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
    );
}