import { Grid } from "@material-ui/core";
import React from "react";
import * as Yup from "yup";
import {
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_ONE_SPECIAL_CHAR,
} from "../../constants";
import { required } from "../../utils/validation";
import { ErrorCommon, TextField, TextFieldCommon } from "../formikFormInputs";
import { Label } from "../formInputs";
import PasswordRequired from "../PasswordRequired";
import PasswordRequiredWithStrength from "../PasswordStrength";


export function FormField(props: any) {
    return (
      <Grid item
            xs={12}
            md={props.rowSize === 12 ? 12 : props.rowSize === 6 ? 6 : props.rowSize === 4 ? 4 : props.rowSize}>
        <TextFieldCommon
          {...props}
          values={props.values}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
        <ErrorCommon errors={props.errors} name={props.name} touched={props.touched}/>
      </Grid>
    );
  }

export const PasswordInputs = ({ errors, values, touched, handleChange, handleBlur }: any) => {
    const [oneLetter, setOneLetter] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const onPassWordChange = (e: any): void => {
        const val = e.target.value;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false)
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
    }

    const passwordField = {
        name: "password",
        label: <Label label={"Create a Strong Password"} />,
        type: "password",
        required: true,
        component: TextField,
        validate: required,
        className: "",
        rowSize: 12
    };
    const confirmPasswordField = {
        name: "confirmPassword",
        label: <Label label={"Confirm Password"} />,
        type: "password",
        required: true,
        component: TextField,
        validate: required,
        className: "",
        rowSize: 12
    };

    const onChange = (event: any) => {
        handleChange(event);
        onPassWordChange(event);
    }

    return (
        <>
            <FormField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={onChange}
                handleBlur={handleBlur}
                {...passwordField}
            />
            <FormField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                {...confirmPasswordField}
            />
            <Grid item xs={12}>
                <PasswordRequired oneLetter={oneLetter} oneNumber={oneNumber} oneCapitalLetter={oneCapitalLetter} eightCharacter={eightCharacter} oneSpecialChar={oneSpecialChar} />
                <PasswordRequiredWithStrength password={values.password} />
            </Grid>
        </>
    )
}

const regexMessage = "Password don't meet password requirements, see below"

export const validationPasswordInputs = {
    password: Yup.string().required()
        .matches(PWD_ONE_LETTER_REGEX, regexMessage)
        .matches(PWD_ONE_CAPITAL_LETTER_REGEX, regexMessage)
        .matches(PWD_ONE_NUMBER_REGEX, regexMessage)
        .matches(PWD_MIN_EIGHT_CHAR_REGEX, regexMessage)
        .matches(PWD_ONE_SPECIAL_CHAR, regexMessage),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null, ''], "Passwords don't match"),
}

export const initialValuesPasswordInputs = {
    password: '',
    confirmPassword: '',
}
