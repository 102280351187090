import {countryDD, healthDropdown, stateDropDown} from "../../../../../constants";

type placeObj = {
	id: number,
	key?: string,
	name: string,
	value: string
};
export let stateDropDownArr: placeObj[] = stateDropDown.map((state: any) => {
	return {
		id: state.name,
		title: state.name,
		key: state.name,
		name: state.name,
		value: state.name,
	};
});

export let countryDropDownArr: placeObj[] = countryDD.map((country: any) => {
    return {
        id: country.description,
        title: country.description,
        key: country.description,
        name: country.description,
        value: country.description,
    };
});

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const getMonths = () => {
	return months.map((month: string, index: number) => {
		return {
			id: index + 1,
			key: index + 1,
			name: month,
		};
	});
};

export const getNextYears = () => {
	const years: any = [];
	for (let year = new Date().getFullYear(); year <= new Date().getFullYear() + 50; year++) {
		years.push({
			id: year,
			key: year,
			name: year,
		});
	}
	return years;
};


export const healthDropdownOtpions = healthDropdown.map((item: any, index) => {
	return {
		id: item.value,
		value: item.value,
		name: item.name,
	};
});

export const employedTimeArr: any = [];
export const getEmployedTime = () => {
	for (let i = 1; i <= 65; i++) {
		employedTimeArr.push({
			id: i + "",
			key: i + "",
			name: i + `${i === 1 ? " year" : " years"}`,
		});
	}
	return employedTimeArr;
};
export const employedTimeDropDown = getEmployedTime();

export const formatSSN = (ssn: any) => {
	if (!ssn) {
		return "";
	}
	if (ssn.length < 9) {
		return "";
	}

	let pattern = new RegExp("\d{3}[\-]\d{2}[\-]\d{4}");
	let value = ssn;
	let res = pattern.test(value);
	if (!res) {
		value = value
			.match(/\d*/g).join("")
			.match(/(\d{0,3})(\d{0,2})(\d{0,4})/).slice(1).join("-")
			.replace(/-*$/g, "");
	}
	return value;
};