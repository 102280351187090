import React, { useEffect } from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import {makeStyles} from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Card, CardContent, FormHelperText, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useFormikContext } from "formik";
import {ErrorCommon, NumberTextField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import EstimationFormContext from "../Context/EstimationFormContext";
import {Label} from "../../../../../components/formInputs";
import contributionWhite from "../../../kaizen/images/newEstimation/icon-employer-contribution-white.svg";
import StepThreeTwo from "./StepThreeTwo";
import { maskCurrency, unmaskCurrency } from "../../../../../utils/commonUtils";
import {desireLifeTimeBenefits} from "../../../../../constants";
import {numberToThousandSeparator, getMinimumDesireLifeTimeBenefits} from "../Steppers";
import EmployerContribution from "./EmployerContribution";
import { useCheckAtlantic } from "../../../../../hooks/useAuth";
import ContributionFrequency, { ANNUALLY, MONTHLY } from "./ContributionFrequency";
import NewEmployerContribution from "./NewEmployerContribution";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
}));

export default function StepThree(props: any) {
    const classes = useStyles();
    const {callingFrom} = props;
    const { values, errors, touched, handleChange, setFieldValue, setFieldTouched }:any = useFormikContext();
    const [showLifeTimeBenefits, setShowLifeTimeBenefits] = React.useState<boolean>(false);
    const [annualContribution, setAnnualContribution] = React.useState<boolean>(false);
    const minCon = useSelector((state: RootState) => state.minimumContribution) || 21350;
    const showNewContribution = useCheckAtlantic()
    const atlantic = useCheckAtlantic();

    let minDesireLifeTimeBenefitsCont:number = getMinimumDesireLifeTimeBenefits(values);
    const minContribution:string = numberToThousandSeparator(minDesireLifeTimeBenefitsCont);
    const lifeTimeBenefitsHelperText:string = `Minimum $${minContribution} required`;

    let labelOne = "I know the initial death benefit I’m seeking";
    let labelTwo = `I know the amount I’d like to contribute ${values["contributionFrequency"] === ANNUALLY ? "annually" : "monthly"} (for 5 years)`;
    let contributionLabel = `Total Amount You Want Each ${values["contributionFrequency"] === ANNUALLY ? "Year" : "Month"}`;
    let deathBenefitLabel = "Total Lifetime Benefits You Want";
    const newemployerContribution: string = "Employer Contribution";

    switch(callingFrom) {
        case 'CUSTOM_DESIGN':
            labelOne = "I know the desired death benefit.";
            labelTwo = `I know the ${values["contributionFrequency"] === ANNUALLY ? "annual" : "monthly"} contribution amount (for 5 years).`;
            contributionLabel = `Enter ${values["contributionFrequency"] === ANNUALLY ? "Yearly" : "Monthly"} Contribution Amount`;
            deathBenefitLabel = "Enter Desired Lifetime Benefit Amount";
            break;
    }

    const helperText = `Minimum ${maskCurrency(minCon + "")} contribution required`

    const field1: any = [
        {
            id: "desireLifeTimeBenefits",
            name: "desireLifeTimeBenefits",
            label: <Label label={deathBenefitLabel} required={true}/>,
            component: NumberTextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment
                        className="margin0 dollerIcon"
                        position="start">$</InputAdornment>,
            },
            helperText: lifeTimeBenefitsHelperText,
        }
    ];

    const field2: any = [
        {
            id: "totalContribution",
            name: "totalContribution",
            label: <Label label={contributionLabel} required={true}/>,
            component: NumberTextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment
                        className="margin0 dollerIcon"
                        position="start">$</InputAdornment>,
            },
            helperText: helperText,
        }
    ];

    const onChangeFrequency = (frequency) => {
        let totalContributionAmmount = Number(unmaskCurrency(values["totalContribution"].toString()));
        if (frequency === ANNUALLY && values["totalContribution"]) {
            totalContributionAmmount = Number(unmaskCurrency(values["totalContribution"].toString())) * 12
        }
        if (frequency === MONTHLY && values["totalContribution"]) {
            totalContributionAmmount = Number(unmaskCurrency(values["totalContribution"].toString())) / 12
        }
        setFieldValue("totalContribution", Math.round(totalContributionAmmount));
    };

    useEffect(() => {
        setFieldTouched("totalContribution", true, true);
    }, [minCon]);

    const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue) => {
        if ((event.target as HTMLInputElement).value === "lifeTimeDeathBenefit") {
            await setFieldValue("deathBenefitCalculationOptions", (event.target as HTMLInputElement).value);
            setShowLifeTimeBenefits(true);
            setAnnualContribution(false);
            await setFieldValue("totalContribution", "");
        } else if ((event.target as HTMLInputElement).value === "amountToContribute") {
            await setFieldValue("deathBenefitCalculationOptions", (event.target as HTMLInputElement).value);
            setShowLifeTimeBenefits(false);
            setAnnualContribution(true);
            await setFieldValue("desireLifeTimeBenefits", "");
        }
    };

    return (
            <>
                <EstimationFormContext.Consumer>
                    {
                        (estimatorContextvalue: any) => {
                            const {
                                issueAge, setIssueAge
                            } = estimatorContextvalue;

                            return (
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        {values && values.primaryFocusPlan === "maximizeMyDeathBenefit" &&
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Card className="estimateRectangleBox">
                                                            <CardContent className="estimateRectangleBoxContent">
                                                                <img src={contributionWhite}
                                                                     style={{height: "30px", marginRight: "15px"}}
                                                                     alt=""/>
                                                                <Typography variant="body1" className="mb0">
                                                                    Your Employer is contributing $10,000 per year for
                                                                    you
                                                                    in addition to your contribution.
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                        }
                                        {values && values.primaryFocusPlan === "deathBenefit" &&
                                                <Grid container spacing={2} className="mt10">
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" component="strong"
                                                                    className="mt20 mb15 floatLeft w100">Choose a
                                                            Calculation Option<sup className="colorRed">*</sup></Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <RadioGroup aria-label="deathBenefitCalculationOptions"
                                                                    name="deathBenefitCalculationOptions"
                                                                    value={values && values.deathBenefitCalculationOptions}
                                                                    onChange={(e) => handleRadioChange(e, setFieldValue)}>
                                                            <Card className="borderRadius mb20 boxShadowNone border1px">
                                                                <CardContent>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}>
                                                                        <FormControlLabel className="mb0 mrSmall5"
                                                                                          disabled={atlantic}
                                                                                          value="lifeTimeDeathBenefit"
                                                                                          control={<Radio
                                                                                                  className="pd0 widthSmall"/>}
                                                                                          label=""/>
                                                                        <Typography variant="body1"
                                                                                    className="mb0">
                                                                            {labelOne}
                                                                            <Tooltip color="primary"
                                                                                     title="This is the amount of life insurance the life carrier underwrites for your policy and does not take into account the repayment of the bank loan. The net of bank loan death benefit is shown in your proposal." arrow>
                                                                                <IconButton aria-label="Info">
                                                                                    <InfoIcon/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Typography>
                                                                    </div>
                                                                    {
                                                                            (values && values.deathBenefitCalculationOptions === "lifeTimeDeathBenefit" || showLifeTimeBenefits) && field1.map((field, index) => {
                                                                                return (<>
                                                                                        <Grid item xs={12} key={index}
                                                                                              className="mt25">
                                                                                            <TextFieldCommon
                                                                                                    {...field}
                                                                                                    name={field.name}
                                                                                                    values={values}
                                                                                                    onChange={handleChange}
                                                                                                    //onBlur={(e) => handleBlur(e, field.name)}
                                                                                            />
                                                                                            <Typography
                                                                                                    variant="caption"
                                                                                                    className="f12 colorLightGray">{field.helperFieldText}</Typography>
                                                                                            <ErrorCommon errors={errors}
                                                                                                         name={field.name}
                                                                                                         touched={touched}/>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} key={index}
                                                                                              className="mt25">
                                                                                            <ContributionFrequency monthlyDisabled={showNewContribution}/>
                                                                                        </Grid>
                                                                                        </>
                                                                                );
                                                                            })
                                                                    }

                                                                </CardContent>
                                                            </Card>
                                                            <Card className="borderRadius mb20 boxShadowNone border1px">
                                                                <CardContent>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}>
                                                                        <FormControlLabel className="mb0"
                                                                              value="amountToContribute"
                                                                              control={<Radio
                                                                                      className="pd0 ml15"/>}
                                                                              label=""/>
                                                                        <Typography variant="body1" className="mb0">
                                                                            {labelTwo}
                                                                        </Typography>
                                                                    </div>
                                                                    {
                                                                            ((values && values.deathBenefitCalculationOptions === "amountToContribute") || annualContribution) &&
                                                                        <>
                                                                        <Grid item xs={12} className="mt25">
                                                                                <ContributionFrequency onChange={(frequency) => {
                                                                                    onChangeFrequency(frequency)
                                                                                }} monthlyDisabled={showNewContribution}/>
                                                                                {
                                                                                    field2.map((field, index) => {
                                                                                        return (
                                                                                            <React.Fragment key={index}>
                                                                                                <TextFieldCommon
                                                                                                        {...field}
                                                                                                        name={field.name}
                                                                                                        values={values}
                                                                                                        onChange={handleChange}
                                                                                                        //onBlur={(e) => handleBlur(e, field.name)}
                                                                                                />
                                                                                                <Typography
                                                                                                        variant="caption"
                                                                                                        className="f12 colorLightGray">{field.helperFieldText}</Typography>
                                                                                                <ErrorCommon
                                                                                                        errors={errors}
                                                                                                        name={field.name}
                                                                                                        touched={touched}/>
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {/*{*/}
                                                                                {/*        ((values && values.employerContribution === "1") || showLifeTimeBenefits) &&*/}
                                                                                {/*        <EmployerContribution helperText={helperText} />*/}
                                                                                {/*}*/}
                                                                            </Grid>
                                                                            {atlantic && <>
                                                                                <Typography
                                                                                className="mb20 mt15 floatLeft w100"
                                                                                component="strong"
                                                                                variant="body1"
                                                                                >
                                                                            {newemployerContribution}
                                                                            </Typography>
                                                                            <NewEmployerContribution
                                                                                atlantic={atlantic}
                                                                                calculationDependOn={"totalContribution"} />
                                                                            </>
                                                                        }
                                                                        </>
                                                                    }
                                                                </CardContent>
                                                            </Card>
                                                            {errors && errors.deathBenefitCalculationOptions &&
                                                                    <FormHelperText className="f12"
                                                                                    style={{color: "red",}}>{errors.deathBenefitCalculationOptions}</FormHelperText>}
                                                        </RadioGroup>
                                                    </Grid>
                                                </Grid>
                                        }
                                        {
                                            values && values.primaryFocusPlan === "contributionAmount" &&
                                            <StepThreeTwo issueAge={issueAge} callingFrom={callingFrom} />
                                        }
                                    </FormControl>
                            );
                        }
                    }
                </EstimationFormContext.Consumer>
            </>
    );
}
