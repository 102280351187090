// prettier-ignore
import React, {createContext} from "react";
import {createStyles, makeStyles} from "@material-ui/styles";
import {CssBaseline} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {Router} from "react-router-dom";
import {history} from "./configureStore";
import AlertContainer from "./components/AlertContainer";
import {RootState} from "./reducers";
import withRoot from "./withRoot";
import LeftPanel from "./components/LeftPanel";
import Routes from "./Routes";
import ClientDemoBar from "./components/ClientDemoBar";
import DemoModeSelectionPopup from "./components/DemoMode/DemoModeSelectionPopup";
import {Account} from "./model/account";
import LoaderGif from "./styles/images/mainloader_ilia_new.gif";
// import { isBigScreen } from "./utils/commonUtils";
import {AccountType, cssPaths, JsEvents, ROLES} from "./constants";
import MainHeader from "./components/MainHeader";
import {ActionType} from "./model";
import ReactGA from 'react-ga'
import {isDebug, isTouchScreen} from "./utils/commonUtils";
import {useActions} from "./actions";
import * as AlertActions from "./actions/alert";
import * as Actions from "./actions/auth";

const getCustomCss = (user: Account, invitationLayout: boolean, specificCss: boolean) => {
    let importedCss: any = cssPaths.layout;
    if (invitationLayout) {
        let importedCss: any = cssPaths.landingPage;
        return <link rel="stylesheet" type="text/css" href={importedCss}/>;
    }

    if (user && (user.role === 5 || user.role === 6 || user.role === 11 || user.accessType === "client")) {

        importedCss = cssPaths.clientLayout;

    } else {
        if (specificCss) {
            return;
        }
        importedCss = cssPaths.layout;
    }

    return (
            <link id={"frontendCss"} rel="stylesheet" type="text/css" href={importedCss}/>
    );
};

const getClientNavCss = (pathName: string) => {
    if (pathName.search("/kaizen") > -1 || pathName.search("/agent-link/") > -1) {
        return;
    }
    return <link rel="stylesheet" href={`${process.env.PUBLIC_URL + cssPaths.clientNavigation}`}/>;
};

export const UrlCheck: any = createContext(null);
export const GlobalLoader: any = createContext(null);

function App() {
    const {setWarning, setAlert} = useActions(AlertActions);
    const debug = isDebug();
    const [invitationLayout, setInvitationLayout] = React.useState(history.location.pathname.search("/accept/new-invitation/") > -1);
    const classes = useStyles();
    const authActions = useActions(Actions);
    const { user, accessToken } = useSelector((state: RootState) => state.auth);
    // const [leftPanelOpen, setLeftPanelOpen] = React.useState<boolean>(!isMobile);
    const [showDemoSelectionPopup, setShowDemoSelectionPopup] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<boolean>(true);
    const [isEstimation, SetIsEstimation] = React.useState<boolean>(false);
    const [urlValue, setUrlValue] = React.useState<string>("");
    const [specificCss, setSpecificCss] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const leftPanelOpen = useSelector((state: RootState) => state.leftPanelOpen);
    let showLeftPanel = !!accessToken;
    const isTermsOfUsePage = user && (user.role === 3 || user.role === 4) && (user.touDuration === undefined || user.touDuration === null || Number(user.touDuration) >= 3);

    if (user &&
            (user.role === 3 || user.role === 4) &&
            (user.touDuration === null || Number(user.touDuration) >= 3)) {
        showLeftPanel = false;
    }

    if (((user && (user.role === 5 || user.role === 6 || user.role === 11 || user.accessType === "client")))) {
        document.body.classList.remove("adminClasses");
        document.body.classList.add("clientClasses");
    } else {
        document.body.classList.add("adminClasses");
        document.body.classList.remove("clientClasses");
    }
    React.useEffect(() => {
        if ((user && user.role === 8) && (history.location.pathname === "/accept/invitation/accounts")) {
            history.push("/group-dashboard");
        }
    }, [history.location.pathname, user]);

    React.useEffect(() => {
        let enrollmentThanksPage: number = history.location.pathname.search("/new-enrollment/thanks");

        let clientDetailThanksPage: number = history.location.pathname.search("/new-client-detail/thanks");
        console.log("**history.location.pathname**", history.location.pathname);

        let grpUrl: number = history.location.pathname.search("/group/");
        let indexStart: number = history.location.pathname.search("/agent/");
        let isInvitationLayout: boolean =
                history.location.pathname.search("/accept/new-invitation/") > -1 ||
                history.location.pathname.search("/email-unsubscribe/") > -1 ||
                history.location.pathname.search("/kaizen") > -1 ||
                history.location.pathname.search("/agent-link/") === 0 ||
                (grpUrl === 0) || (indexStart === 0) || (enrollmentThanksPage === 0) || (clientDetailThanksPage === 0) || false;
        setInvitationLayout(isInvitationLayout);

        setTimeout(() => {
            const rootElement: any = document.querySelector("#rootElement");
            if (rootElement) {
                rootElement.style["max-height"] = "none";
            }
            setProgress(false);
        }, 3000);

        //Tracking for page views
        let hostname = window.location.hostname;
        if(hostname === "localhost"){
            ReactGA.initialize('UA-208550397-1', {debug: true})
        } else
        if(hostname === "staging.myilia.com"){
            ReactGA.initialize('G-0W26CMVYH7', {debug: true})
        } else{
            ReactGA.initialize('UA-171555644-1')
        }


    }, []);

    React.useEffect(() => {
        if (urlValue && history.location.pathname.search(urlValue) > -1) {
            setSpecificCss(true);
        } else {
            setSpecificCss(false);
        }
    }, [urlValue]);

    React.useEffect(() => {
        if (specificCss) {
            document.body.classList.remove("adminClasses");
        }
    }, [specificCss]);

    React.useEffect(() => {
        const onUpdate = () => {
            ReactGA.set({ page: window.location.pathname })
            ReactGA.pageview(window.location.pathname)
        }
        onUpdate();
    }, [window.location.pathname]);
    
    React.useEffect(() => {
        login()
    }, [])

    const login = async () => {
        const islogin: any = await sessionStorage.getItem("login");
        if(!islogin) {
            authActions.LogOut();
        }
    }
    React.useEffect(() => {
        const checkOrientation = () => {
            dispatch({ type: ActionType.LEFTPANEL, payload: !isTouchScreen });
        };

        // NIWC-1984
        if (process.env.NODE_ENV !== 'development' && isTouchScreen()) {
            // Add event listeners only if not in development mode or debug mode
            window.addEventListener(JsEvents.Resize, checkOrientation, false);
            window.addEventListener(JsEvents.OrientationChange, checkOrientation, false);
        }

        return () => {
            // Remove event listeners when the component is unmounted
            window.removeEventListener(JsEvents.Resize, checkOrientation, false);
            window.removeEventListener(JsEvents.OrientationChange, checkOrientation, false);
        };
    }, []);

    React.useEffect(() => {
        // first run to check if page opened is a mobile layout or not...
        dispatch({ type: ActionType.LEFTPANEL, payload: !isTouchScreen() });
        // document.body.style.overflow = leftPanelOpen && isMobile ? "hidden" : null;
    }, []);


    React.useEffect(() => {
        if (!debug) {
            return;
        }

        // when internet is offline
        window.addEventListener(JsEvents.OffLine, () => {
            setWarning('The network connection has been lost.');
        });

        // when internet is back online
        window.addEventListener(JsEvents.OnLine, () => {
            setAlert('Your connection has been restored.');
        });
    }, [debug]);

    /**
     * Prevent user selection (any user, any text, any data) - un-select-able
     */
    React.useLayoutEffect(() => {
        document.body.addEventListener(JsEvents.SelectStart, (event) => {
            event.preventDefault();
        });
    }, []);

    const loadingProgressStyle:any = {
        position: "fixed" as "fixed",
        zIndex: "9999999",
        width: "100%",
        height: "100%",
        background: "#ffffff",
        top:0,
    };
    const imgStyle = {
        position: "absolute" as "absolute",
        left: "0",
        right: "0",
        margin: "0 auto",
        top: "30%",
    };

    const [oldInvitationPage, leadInvitationPage] = [
        history.location.pathname.search("/accept/invitation/") > -1,
        history.location.pathname.includes("/accept/new-lead-client-invitation/")
    ];
    const [rootClasses, rootStyles] = [
        !accessToken ?
                (oldInvitationPage ? "bgInvitationBlue" : (!leadInvitationPage ? "bgBlue" : classes.root)) :
                classes.root,
        { maxHeight: "100vh" },
    ];

    let demoBarText: string = "CURRENTLY IN DEMO";
    let demoBarLink: string = "Return to My Dashboard";
    let fallbackLink: string = "/intro";

    if (user && user.accessType === "client" && user.demoClientId) {
        const userText: string = user.role ? ROLES[user.role] : "USER";
        demoBarText = "Clients Servicing Portal";
        demoBarLink = `Return to ${userText} Portal`;
        fallbackLink = "/client/dashboard";
    }


    return (
            <React.Fragment>
                <GlobalLoader.Provider value={{ setProgress, progress }}>
                    {progress &&
                            <div style={loadingProgressStyle}>
                                <img style={imgStyle} src={LoaderGif} alt='Loading...'/>
                            </div>
                    }
                    {user && user.accessType === "client" && <ClientDemoBar demoBarText={demoBarText} demoBarLink={demoBarLink} fallbackLink={fallbackLink}/>}
                    {getCustomCss(user, invitationLayout, specificCss)}
                    {(user && (user.role !== 5 && user.role !== 6)) &&
                            getClientNavCss(history.location.pathname)
                    }
                    <Router history={history}>
                        <UrlCheck.Provider value={{ setUrlValue, urlValue }}>
                            <div id='rootElement' className={rootClasses} style={rootStyles}>
                                <CssBaseline/>
                                <AlertContainer/>
                                {
                                         user && !!accessToken && !isTermsOfUsePage &&
                                        <MainHeader
                                                accessType={user && user.accessType ? user.accessType : ""}
                                                setShowDemoSelectionPopup={setShowDemoSelectionPopup}
                                                SetIsEstimation={SetIsEstimation}
                                                setProgress={setProgress}
                                        />
                                }
                                {
                                        user && !!accessToken && !invitationLayout && showLeftPanel && !isTermsOfUsePage &&
                                        <LeftPanel setIsEstimation={SetIsEstimation}/>
                                }
                                <Routes leftPanelOpen={leftPanelOpen} isEstimation={isEstimation}
                                        setProgress={setProgress}/>
                                {
                                	    user &&
                                        (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 4 || user.role === 15 || user.role === AccountType.Aggregator || user.role === AccountType.AggregatorMember) &&
                                        user.accessType !=="client" &&
                                        <DemoModeSelectionPopup
                                                showDemoSelectionPopup={showDemoSelectionPopup}
                                                setShowDemoSelectionPopup={() => {
                                                    setProgress(true);
                                                    setShowDemoSelectionPopup(false);
                                                    setTimeout(() => setProgress(false), 3000);
                                                }}
                                                role={user && user.role}
                                        />}
                            </div>
                        </UrlCheck.Provider>
                    </Router>
                </GlobalLoader.Provider>
            </React.Fragment>
    );
}

const useStyles = makeStyles(() =>
                createStyles(
                        {
                            root: {
                                display: "flex",
                                boxShadow: "none",
                            },
                        },
                ),
        );

export default withRoot(App);
