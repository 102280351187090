import { Action, ActionType } from "../model";
import {
	Carrier,
	CarrierList,
	Strategy,
	StrategyList,
	carrierRequests,
	CarrierRequestList,
	AgentCarriers,
	CarrierStrategyList
} from "../model/account";
import { CarrierProduct, CarrierProductObject, CarrierOfferTypeObject, CarrierOfferType } from "../model/carrier";
import createReducer from "./createReducer";

export const carriers = createReducer([], {
	[ActionType.GET_ALL_CARRIER](state: Carrier[], action: Action<CarrierList>) {
		return action.payload;
	}
});

export const strategies = createReducer([], {
	[ActionType.GET_ALL_STRATEGY](state: Strategy[], action: Action<StrategyList>) {
		return action.payload;
	}
});

export const carrierAndStrategy = createReducer([], {
	[ActionType.LIST_CARRIERS_STRATEGY](state: AgentCarriers[], action: Action<CarrierStrategyList>) {
		return action.payload;
	},

    [ActionType.FLUSH_CARRIERS_STRATEGY](state: Function, action: Action<void>) {
        return action.payload;
    }
});

export const carrierRequest = createReducer([], {
	[ActionType.CARRIER_REQUEST](state: CarrierRequestList[], action: Action<carrierRequests>) {
		return action.payload;
	},
	[ActionType.UPDATE_CARRIER_REQUEST](state: carrierRequests, action: Action<CarrierRequestList>) {
		if (state) {
			const { rows } = state;
			state.rows = rows.filter((v: any) => v.id !== action.payload.id);
		}
		return state;
	}
});


export const strategy = createReducer([],{
	[ActionType.GET_STRATEGY_BY_ID](state: Strategy[], action: Action<StrategyList>) {
		return action.payload;
	}
})


export const carrierProducts = createReducer([],{
	[ActionType.GET_CARRIER_PRODUCTS_BY_ID](state: CarrierProductObject, action: Action<CarrierProductObject>) {
		return action.payload;
	}
})

export const product = createReducer([],{
	[ActionType.GET_PRODUCT_BY_ID](state: CarrierProduct, action: Action<CarrierProduct>) {
		return action.payload;
	}
})

export const carrierOfferTypes = createReducer([],{
	[ActionType.GET_CARRIER_OFFERTYPE_BY_ID](state: CarrierOfferTypeObject, action: Action<CarrierOfferTypeObject>) {
		return action.payload;
	}
})

export const offerType = createReducer([],{
	[ActionType.GET_OFFERTYPE_BY_ID](state: CarrierOfferType, action: Action<CarrierOfferType>) {
		return action.payload;
	}
})
