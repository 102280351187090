import * as React from "react";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ListIcon from "@material-ui/icons/List";
import { Chip, IconButton, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { AccountHeadRow } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from '@material-ui/icons/Link';
import { dateUtils } from "../../utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import {PageTitle} from "../formInputs";
import { GWT_GEN2_STRATEGY } from "../../constants";

const headRows: AccountHeadRow[] = [
	{ id: 'sNo', label: 'S.No' },
	{ id: 'clientListName', label: 'Client List Name' },
	{ id: 'participants', label: 'Participants' },
	{ id: 'agent.user.name', label: 'Agent' },
	{ id: 'agent.imo.groupName', label: 'IMO' },
	{ id: 'agent.imo.region', label: 'Region' },
	{ id: 'createdAt', label: 'Date Created' },
];

const headRowsForAgent: AccountHeadRow[] = [
	{ id: 'sNo', label: 'S.No' },
	{ id: 'clientListName', label: 'Client List Name' },
	{ id: 'participants', label: 'Participants' },
	{ id: 'participants', label: '#Invited' },
	{ id: 'accountsCount', label: '#Accounts' },
	{ id: 'startedEnrollmentCount', label: '#Started Enrollment' },
	{ id: 'completedEnrollmentCount', label: '#Completed Enrollment' },
	{ id: 'createdAt', label: 'Date Created' },
];

function ClientListTable(props: any) {
	const { accountList, order, orderBy, handleRequestSort, handleClickOpen, callingFrom, page, rowsPerPage, customPageTitle } = props;
	const [copyLinkid, setCopyLinkid] = React.useState<number>(0);
	const accountActions = useActions(AccountActions);
	const user = useSelector((state: RootState) => state.auth.user );
    let sNo = page * rowsPerPage;
	function valHandler(accountId: number) {
		setCopyLinkid(accountId);
		accountActions.copyInvitationLink("Invite Link Has Been Copied to Your Clipboard")
	}

	return (
		<React.Fragment>
            {
                customPageTitle &&
                <PageTitle title={`${customPageTitle}`}/>
            }
			<EnhancedTableHead
				order={order}
				orderBy={orderBy}
				onRequestSort={handleRequestSort}
				headRows={ callingFrom === 'accountList' ?  headRows : headRowsForAgent }
			/>
			<TableBody>
				{
					(!accountList.rows || accountList.rows.length === 0)
					&& <TableRow>
						<TableCell align="left" colSpan={9}>
							<Typography variant="caption" align="left" className="floatLeft">No ClientList Accounts</Typography>
						</TableCell>
					</TableRow>
				}
				{accountList.rows && accountList.rows.map((account: any, index:number) => {
					const clientListUrl = `${window.location.origin}/agent/${account.clientListUrl}`;
					return (
						<TableRow hover key={account.id}>
							<TableCell>{++sNo}</TableCell>
							<TableCell padding="default">
								<Tooltip title="View" className="floatLeft" arrow={true}>
									<IconButton className="floatLeft" color="default"
															onClick={() => props.history.push(user.role === 3 || user.role === 15 ? "client-list/"+ account.id +"/participants/"+account.clientListName : user.role === 4 ? "client-list/"+ account.id +"/participants/list/" + account.clientListName : "client-list/"+ account.id +"/participants/list/"+ account.clientListName ) }>
										<VisibilityIcon/>
									</IconButton>
								</Tooltip>
                                {account.clientListName}
                                {account["client.strategy.id"] == GWT_GEN2_STRATEGY && <Chip className={"ml5"} label="GWT" size="small" />}
							</TableCell>
							<TableCell padding="default">{account.participants}
								<Tooltip title="List" arrow={true}>
									<IconButton color="default"
															onClick={() => props.history.push(user.role === 3 || user.role === 15 ? "client-list/"+ account.id +"/participants/"+account.clientListName : user.role === 4 ? "client-list/"+ account.id +"/participants/list/" +account.clientListName : "client-list/"+ account.id +"/participants/list/"+ account.clientListName )}>
										<ListIcon/>
									</IconButton>
								</Tooltip>
							</TableCell>
							{callingFrom === 'accountList' ?
							<>
							<TableCell padding="default">{account.agent && account.agent.user && account.agent.user.name}</TableCell>
								<TableCell padding="default">{account.agent && account.agent.imo && account.agent.imo.groupName}</TableCell>
								<TableCell padding="default">{account.agent && account.agent.imo && account.agent.imo.region}</TableCell>
							</>
							:
								<>
								<TableCell padding="default">{account.participants}</TableCell>
								<TableCell padding="default">{account.accountsCount}</TableCell>
								<TableCell padding="default">{account.startedEnrollmentCount}</TableCell>
								<TableCell padding="default">{account.completedEnrollmentCount}</TableCell>
								</>
							}
							<TableCell padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
							<TableCell padding="default">
								{
									clientListUrl &&
									<Tooltip placement="left-start" arrow={true} title={(account.id === copyLinkid)
										? <React.Fragment>Client List Link Has Been <br/> Copied to Your
											Clipboard</React.Fragment>
										:"Copy Client List Link"}>
										<CopyToClipboard text={clientListUrl}
																		 onCopy={() => valHandler(account.id)}>
											<IconButton color="default">
												<LinkIcon />
											</IconButton>
										</CopyToClipboard>
									</Tooltip>
								}
								{user && user.role === 1 &&
								<Tooltip title="Delete" arrow={true}>
									<IconButton
										color="default"
										onClick={() => handleClickOpen(account.id)}
									>
										<DeleteOutlineIcon className="colorRed" />
									</IconButton>
								</Tooltip>
								}
							</TableCell>
						</TableRow>
					)
				})
				}
			</TableBody>

		</React.Fragment>
	)
}

export default ClientListTable;
