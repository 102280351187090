import * as React from "react";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Chip, IconButton, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { AccountHeadRow } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import { dateUtils } from "../../utils";
import { Link } from "react-router-dom";
import { RootState } from "../../reducers";
import { connect, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import GetInviteStatusAllParticipants from "../GetInviteStatusAllParticipants";
import {DeleteModelWithNotes, PageTitle} from "../formInputs";
import { AccountType, ROLES, showUserLogs, PermissionTypes, GWT_GEN2_STRATEGY } from "../../constants";
import { Restore } from "@material-ui/icons";
import { can } from "../navigation/AdminNavigation";
import * as Admin_Actions from "../../actions/admin";
import {BS} from "../../model/misc";

const headRowsAccountList: AccountHeadRow[] = [
	{ id: "sNo", label: "S.No" },
	{ id: "firstName", label: "First Name" },
	{ id: "lastName", label: "Last Name" },
    { id: "trancheNo", label: "Tranche #" },
	{ id: "email", label: "Email" },
    { id: "client.purpose", label: "Application Type" },
	{ id: "participantType", label: "Participant Type" },
	{ id: "agent.user.name", label: "Agent" },
	{ id: "imo.groupName", label: "IMO" },
	{ id: "imo.region", label: "Region" },
	{ id: "strategyName", label: "ilia product Type" },
    { id: "carrier.carrierName", label: "Carrier" },
	{ id: "inviteStatus", label: "Status" },
	{ id: "createdAt", label: "Date Created" },
];

const headRowsClientManagement: AccountHeadRow[] = [
	{ id: "sNo", label: "S.No" },
	{ id: "firstName", label: "First Name" },
	{ id: "lastName", label: "Last Name" },
    { id: "trancheNo", label: "Tranche #" },
	{ id: "email", label: "Email" },
	{ id: "participantType", label: "Participant Type" },
	{ id: "strategyName", label: "ilia Product Type" },
	{ id: "carrier.carrierName", label: "Carrier" },
	{ id: "inviteStatus", label: "Status" },
];

function AllParticipantTable(props: any) {
	const { accountList, order, orderBy, handleRequestSort, callingFrom, handleRefresh, page, rowsPerPage, customPageTitle } = props;
	const [linkId, setLinkId] = React.useState<string | undefined>("");
    const [isDeleteModel, setIsDeleteModel] = React.useState<string|number>("");
    const [message, setMessage] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [confirmOnboarding, setConfirmOnboarding] = React.useState<BS>(false);
    const adminActions = useActions(Admin_Actions);
    const accountActions = useActions(AccountActions);
	const user = useSelector((state: RootState) => state.auth.user);
	const role: any = user && user.role;
	const [notesValue, setNotesValue] = React.useState("")
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const [userType, setUserType] = React.useState("");

    let sNo = page * rowsPerPage;
    const refreshTable = () =>{
        handleRefresh();
    }

	function valHandler(accountId: string) {
		setLinkId(accountId);
		accountActions.copyInvitationLink("Link Has Been Copied to Your Clipboard");
	}
	let baseUrl: string = "accounts";
	if(role === 4) // for agent base url is client
		baseUrl = "client";
	if(role === 3 || role === 15)
		baseUrl = "imo";
	const getParticipantTypeLink = (participant) => {
		let returnObj = [];
		switch (participant.participantType) {
			case "ClientList" :
				returnObj["view"] = user && (role === 3 || role === 15) ? baseUrl + "/client-list/" + participant["clientList.id"] + "/participants/" + participant["clientList.clientListName"] :  user && (role === 4) ? baseUrl + "/client-list/" + participant["clientList.id"] + "/participants/list/" + participant["clientList.clientListName"] : baseUrl + "/client-list/" + participant["clientList.id"] + "/participants/list/"+ participant["clientList.clientListName"];
				returnObj["link"] = `${window.location.origin}/agent/${participant["clientList.clientListUrl"]}`;
				return returnObj;
			case "Group" :
				returnObj["view"] = user && role === 4 ?
					baseUrl + "/groups/"+participant["company.id"]+"/individual/" + participant["company.companyName"] :
					(user && (role === 3 || role === 15) ? baseUrl + "/groups/" + participant["company.id"]+"/participants/"+participant["company.companyName"]  : baseUrl + "/groups/" + participant["company.id"] + "/" + participant["company.companyName"] + "/individual/list");
				returnObj["link"] = `${window.location.origin}/group/${participant["company.companyLinkUrl"]}`;
				return returnObj;
			case "GroupLink":
				returnObj["view"] = "accounts/group-link/" + participant["groupLinks.id"] + "/" + participant["groupLinks.groupName"] + "/individual/list";
				return returnObj;
			case "Individual":
            case "Ghost":
				returnObj["view"] = "new-client/detail/" + participant["clientId"];
				return returnObj;
			default :
				returnObj["view"] = "participants";
				return returnObj;
		}
	};

    const deletedUser = (id: string|number, type:string) => {
        let invitationStatus: number = 0;
        let userDetails:any = [];
        setUserType(type);
        userDetails = accountList.rows.filter((val: any) => (id === val.userId));
        if(userDetails.length === 0){
            userDetails = accountList.rows.filter((val: any) => (id === val.id));
        }

        if(userDetails.length > 0){
            invitationStatus = userDetails[0]["user.inviteStatus"];
            if(invitationStatus >= 4){
                setMessage("This client has started or completed their enrollment do you still want to delete their account? If yes it will be permanently deleted from ilia ?")
            } else {
                setMessage("Are you sure you want to delete this client permanently from ilia ?")
            }
            setTitle(userDetails[0]["firstName"] + " " +userDetails[0]["lastName"])
        }
        setIsDeleteModel(id)
    };

    const deleteParticipants = async () => {
        setButtonLoader(true);
		await accountActions.deleteAccount(isDeleteModel, userType, notesValue);
        setIsDeleteModel("");
        setNotesValue("");
        setButtonLoader(false);
        refreshTable();
	};

	const setNotes = (value) => {
		setNotesValue(value)
	}

	return (
		<React.Fragment>
            {
                customPageTitle &&
                <PageTitle title={`${customPageTitle}`}/>
            }
			<EnhancedTableHead
				order={order}
				orderBy={orderBy}
				onRequestSort={handleRequestSort}
				headRows={(callingFrom === "accountList") ? headRowsAccountList : headRowsClientManagement}
			/>
			<TableBody>
				{
					(!accountList.rows || accountList.rows.length === 0)
					&& <TableRow>
						<TableCell align="left" colSpan={(callingFrom === "accountList") ? 9 : 9}>
							<Typography align="left">No Participants Accounts</Typography>
						</TableCell>
					</TableRow>
				}
				{accountList.rows && accountList.rows.map((account: any, index: number) => {
					const participantTypeLink: any = getParticipantTypeLink(account);
					return (
						<TableRow hover key={account.id}>
							<TableCell>{++sNo}</TableCell>
							<TableCell padding="default">{ account["clientId"] ?
								<Link to={"/new-client/detail/" + account["clientId"]}>{account.firstName}</Link>
								: account.firstName}
                                {account["user.client.strategy.id"] == GWT_GEN2_STRATEGY && <Chip className={"ml5"} label="GWT" size="small" />}
							</TableCell>
							<TableCell padding="default">{ account["clientId"] ?
								<Link to={"/new-client/detail/" + account["clientId"]}>{account.lastName}</Link>
								: account.lastName}
							</TableCell>
                            <TableCell padding="default">{account["user.client.tranche.trancheNumber"]}</TableCell>
                            <TableCell padding="default">{account.email}</TableCell>
                            <TableCell padding="default">{account["user.client.purpose"]}</TableCell>
							<TableCell padding="default">
								<Link to={`/${participantTypeLink["view"]}`}>
									{account.participantType}
								</Link>
							</TableCell>
							{callingFrom === "accountList" &&
							<>
								<TableCell padding="default">{account["agent.user.name"]}</TableCell>
								<TableCell padding="default">{account["imo.groupName"]}</TableCell>
								<TableCell padding="default">{account["imo.region"]}</TableCell>
							</>
							}
                            <TableCell padding="default">{account["user.client.strategy.displayName"] !== "" ? account["user.client.strategy.displayName"] : account["strategy.strategyName"]}</TableCell>
							<TableCell padding="default">{account["carrier.carrierName"] ? account["carrier.carrierName"] : account["carrierName"]}</TableCell>
                            <GetInviteStatusAllParticipants
                                role={user.role}
                                account={account}
                                refreshTable={()=>refreshTable()}
                            />
							{
								callingFrom === "accountList" &&
								<TableCell padding="default">{account.createdAt && dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
							}
							<TableCell padding="default" align="right" className="w230">
                                {(user && role <= 2 && showUserLogs) &&
                                <Tooltip title="Logs">
                                    <IconButton
                                            color="default"
                                            onClick={() => props.history.push('/activity/' + ROLES[account.role] + '/' + account.id)}
                                    >
                                        <Restore/>
                                    </IconButton>
                                </Tooltip>
                                }
								{
									participantTypeLink["link"] && !account['user.id'] &&
									<Tooltip placement="left-start" title={(account.id === linkId)
										? <React.Fragment>Link Has Been <br/> Copied to Your
											Clipboard</React.Fragment>
										: "Copy Link"}>
										<CopyToClipboard text={participantTypeLink["link"]}
																		 onCopy={() => valHandler(account.id)}>
											<IconButton color="default">
												<LinkIcon/>
											</IconButton>
										</CopyToClipboard>
									</Tooltip>
								}
								{
									account["user.inviteLink"] &&
									<Tooltip placement="left-start" title={(account.id === linkId)
										? <React.Fragment>Invite Link Has Been <br/> Copied to Your Clipboard</React.Fragment>
										: "Copy Invite Link"}>
										<CopyToClipboard text={account["user.inviteLink"]}
																		 onCopy={() => valHandler(account.id)}>
											<IconButton color="default">
												<LinkIcon/>
											</IconButton>
										</CopyToClipboard>
									</Tooltip>
								}
								{account["clientId"] &&
								<Tooltip title="View">

									<Link to={"/new-client/detail/" + account["clientId"]}>
										<IconButton color="default">
											<VisibilityIcon/>
										</IconButton>
									</Link>
								</Tooltip>
								}

								{user && (role === 1 || can(user, PermissionTypes.DELETE_USER)) &&
								<Tooltip title="Delete">
									<IconButton
										color="default"
										onClick={() => {
										    console.log('all aprticipant')
                                            const deleteId: any = account["user.id"] || account.id;
                                            const type:string =  typeof deleteId == "number" ? "PARTICIPANT":"USER"
                                            deletedUser(deleteId, type)
                                        }}
									>
										<DeleteOutlineIcon className="colorRed"/>
									</IconButton>
								</Tooltip>
								}
                                {
                                        // may only show when invite token is not available NIWC-2448
										// no way to onboard client except this
                                        // user && user.role === AccountType.MasterAdmin && account.participantType === 'Ghost' &&
                                        // <Tooltip title="Onboarding to Servicing ">
                                        //     <IconButton color="default" onClick={() => setConfirmOnboarding(account.userId)}>
                                        //         <FastForwardIcon />
                                        //     </IconButton>
                                        // </Tooltip>
								}
							</TableCell>
						</TableRow>
					);
				})
				}
			</TableBody>
            {
                isDeleteModel ?
                (
                    <DeleteModelWithNotes
                        open={true}
                        title={title}
                        content={message}
                        handleClose={() => {setIsDeleteModel(""); setNotesValue(""); setUserType("")}}
						handleYes={deleteParticipants}
						setNotes={setNotes}
                        buttonLoader={buttonLoader}
                        notes={notesValue}
                    />
                ):null
            }
		</React.Fragment>
	);
}

function mapStateToProps(state: RootState) {
	return {
		auth: state.auth,
	};
}

export default connect(mapStateToProps)(AllParticipantTable);
