import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { usePlacesWidget } from "react-google-autocomplete";
import { googlePlacesFields, googleTypes, onPlaceSelectedCommon } from "../../hooks/usePlacesRef";
import { useFormikContext } from "formik";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { FormHelperText } from "@material-ui/core";
import { useActions } from "../../actions";
import * as AlertActions from "../../actions/alert";
import { isDebug } from "../../utils/commonUtils";

interface FieldProps {
    field: {
        name: string,
        onChange: Function,
    },
    placeholder: string,
    label: string,
    value: string,
    helperText?: string,
    onSelectAddress?: (place:any, formikContext: {setFieldValueC:Function}) => void
}
const GoogleAutoComplete = (props: FieldProps) => {
    const { field: { name }, placeholder, label, value, onSelectAddress , ...custom } = props;
    let  {setFieldValue , validateForm, values , handleChange} = useFormikContext<any>()
    let [helperText , setHelperText] = React.useState("");
    const alertActions = useActions(AlertActions);
    const {
        placePredictions,
        getPlacePredictions,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    });
    const setFieldValueC = (name, value) =>{
        setFieldValue(name, value);
        values[name]= value;
        validateForm(values)
    }
    const onPlaceSelected = (place) => {
        isDebug() && console.log(place,"place")
        const address = onPlaceSelectedCommon(place);

        if(Object.keys(address).length <= 0) {
            alertActions.setAlert('No match found', 'warning', 400);
            return
        }
        // Extract address components
        const formattedAddress = place.formatted_address;
        onSelectAddress && onSelectAddress(address, {setFieldValueC:setFieldValue})
    };
    const {ref} = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
        onPlaceSelected: onPlaceSelected,
        options: {fields: googlePlacesFields, types: googleTypes, componentRestrictions: { country: "us" }}
    });
    React.useEffect(()=>{
        const query = values[name];
        getPlacePredictions({ input: query, types: googleTypes, componentRestrictions: { country: "us" }  });
    },[values[name]])
    React.useEffect(()=>{
        if (placePredictions && placePredictions.length === 0) {
            setHelperText("No match found");
        } else {
            setHelperText("");
        }
    },[placePredictions])

    return <>
        <TextField
                onKeyDown={(e)=>{ (e.key === 'Enter') && e.preventDefault()}}
                fullWidth
                name={name}
                inputRef={ref}
                placeholder={placeholder}
                variant="outlined"
                label={label}
                value={value}
                {...custom}
        />
        {values[name] && <FormHelperText> {helperText} </FormHelperText>}
    </>
};

export default GoogleAutoComplete;