import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_ONE_SPECIAL_CHAR,
    PWD_TOW_SPECIAL_CHAR,
    PWD_TWO_CAPITAL_LETTER_REGEX,
    PWD_TWO_NUMBER_REGEX, PWD_TWO_SMALL_LETTER_REGEX,
} from "../constants";

interface PasswordRequiredProps {
    oneLetter: boolean;
    oneNumber: boolean;
    oneCapitalLetter: boolean;
    eightCharacter: boolean;
    oneSpecialChar: boolean;
    twoCapitalLetters: boolean; // New requirement
    twoNumbers: boolean; // New requirement
    twoSpecialChars: boolean; // New requirement
    twoSmallLetters: boolean; // New requirement
}

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            red: {
                color: "#ec0502",
            },
            green: {
                color: "#45ae37",
            },
            strengthLine: {
                width: 50,
                height: 6,
                borderRadius: 3,
                marginRight: 3,
            },
            weak: {
                backgroundColor: "#530100",
            },
            veryWeak: {
                backgroundColor: "#ec0502",
            },
            moderate: {
                backgroundColor: "#d39a00",
            },
            strong: {
                backgroundColor: "#106100",
            },
            veryStrong: {
                backgroundColor: "#45ae37",
            },
        })
);

export default function PasswordRequiredWithStrength(props:any) {
    const {password } = props
    const classes = useStyles();
    const totalRequirementNumber: number = 9;
    const [oneLetter, setOneLetter] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const [twoSpecialChars, setTwoSpecialChars] = React.useState(false);
    const [twoNumbers, setTwoNumbers] = React.useState(false);
    const [twoCapitalLetters, setTwoCapitalLetters] = React.useState(false);
    const [twoSmallLetters, setTwoSmallLetters] = React.useState(false);

    React.useEffect(()=>{
        onPassWordChange()
    },[password])
    const onPassWordChange = (): void => {
        const val = password;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);

        PWD_TOW_SPECIAL_CHAR.test(val) ? setTwoSpecialChars(true) : setTwoSpecialChars(false);
        PWD_TWO_NUMBER_REGEX.test(val) ? setTwoNumbers(true) : setTwoNumbers(false);
        PWD_TWO_CAPITAL_LETTER_REGEX.test(val) ? setTwoCapitalLetters(true) : setTwoCapitalLetters(false);
        PWD_TWO_SMALL_LETTER_REGEX.test(val) ? setTwoSmallLetters(true) : setTwoSmallLetters(false);


    };

    const requirements = [
        oneLetter,
        oneNumber,
        oneCapitalLetter,
        eightCharacter,
        oneSpecialChar,

        twoCapitalLetters,
        twoNumbers,
        twoSpecialChars,
        twoSmallLetters,
    ];

    const fulfilledRequirementsCount = requirements.filter((req) => req).length;
    const strengthPercentage = (fulfilledRequirementsCount / totalRequirementNumber) * 100;

    function getPasswordStrength(): string {
        if (password.length >= 12) {
            return "Very Strong";
        }
        else if (eightCharacter && fulfilledRequirementsCount <= 12) {
            return "Strong";
        }
        else if (strengthPercentage === 100) {
            return "Moderate";
        }
        else if (strengthPercentage >= 80) {
            return "Moderate";
        } else if (strengthPercentage >= 60) {
            return "Weak";
        } else if (strengthPercentage >= 30) {
            return "Weak";
        } else if (strengthPercentage >= 10) {
            return "Very Weak";
        } else {
            return "";
        }
    }

    function getStrengthColor(): string {
        const passwordStrength = getPasswordStrength();
        switch (passwordStrength) {
            case "Very Strong":
                return classes.veryStrong;
            case "Strong":
                return classes.strong;
            case "Moderate":
                return classes.moderate;
            case "Weak":
                return classes.weak;
            default:
                return classes.veryWeak;
        }
    }

    function getStrengthWidth(): string {
        return `${strengthPercentage}%`;
    }

    return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {password ? (
                            <p className="txtLeft mb10 mt0 f16 ">Password Strength: {getPasswordStrength()}</p>
                    ) : (
                            ""
                    )}
                    {password &&
                            <div style={{ display: "flex" }}>
                    <span
                            className={`${classes.strengthLine} ${getStrengthColor()}`}
                            style={{ width: getStrengthWidth() }}
                    />
                            </div>
                    }
                </Grid>
            </Grid>
    );
}
