import * as React from "react";
import {Button, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VideocamIcon from "@material-ui/icons/Videocam";
import DescriptionIcon from "@material-ui/icons/Description";
import SecurityIcon from "@material-ui/icons/Security";
import ExtensionIcon from "@material-ui/icons/Extension";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import ConfirmEnrollment from "../ConfirmEnrollment";
import EnrollmentSelection from "../EnrollmentSelection";
import {useActions} from "../../actions";
import * as Actions from "../../actions/enrollment";
import * as AccountActions from "../../actions/account";
import { NavLink, useHistory } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CommentIcon from "@material-ui/icons/Comment";
import { AccountType, cssPaths, isServicingAccessible, MARKETING_SITE_URL } from "../../constants";

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import {AccountStatus} from "../../model";
import { targetBlank } from "../../utils/commonUtils";


const myInformationTabs = [
	"/dashboard",
	"/intro",
	"/client/estimation",
	"/client/estimation-new",
    "/client/estimationV1",
	"/financialconcerns",
	"/howitwork",
	"/SplitDollar",
	"/product",
	"/leverage",
	"/minimumrisk",
	"/plandetails",
    "/news-social",
	"/participants",
	"/testimonials",
	"/testimonials-aon",
	"/news-social",
	"/faq",
];

const clientServiceTabs = [
    "/client/dashboard",
    "/client/payments",
    "/client/plandocuments",
    "/client/annualreview",
    "/client/mydetails",
    "/client/claims",
    "/client/questions",
    "/client/referralfeedback",
    "/client/surrenderplan",
    "/client/contactus",
    "/profile",
    "/account",
    "/bugs-feedback",
];

const enrollmentTabse = [
	"/new-enrollment",
	"/enrollment/start",
	"/enrollment/start/questionnaire",
	"/enrollment/start/complete-application",
	"/enrollment/start/final-illustration",
    "/enrollmentServicingComing"
];

export default function ClientNavigation(props: any) {
    let reBrand: any = 0;
    let strategyName: any = "Kai-Zen";
    let showMarketing: number | undefined = 1;

    const { user, handleNavClick, SetIsEstimation } = props;

    const [routeName, setRouteName] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [enrollmentSelection, setEnrollmentSelection] = React.useState(false);
	// const [loading, setLoading] = React.useState(false);
    const [showToggle, setShowToggle] = React.useState(true);
    const [estimationVersion, setEstimationVersion] = React.useState<number>(2);
    const [showServicingToggle, setShowServicingToggle] = React.useState(false);

    const accountActions = useActions(AccountActions);
	const enrollmentActions = useActions(Actions);

    const profile = useSelector((state: RootState) => state.profile);
    const isRefresh = useSelector((state: RootState) => state.isRefresh);
	const { user: { client, accessType } } = useSelector((state: RootState) => state.auth);
    const enrollment = useSelector((state: RootState) => state.enrollment);

    const profileEnrollment = (profile && profile.enrollment) || (user && user.enrollment);
    const isClientDemoAccess = (user.accessType === "client" && user.demoClientId);
    const isServiceDashboardAccessible = user && user.inviteStatus && (isServicingAccessible(user) || false);
    let strategySlug: string = client && client.strategy && client.strategy.slug ? client.strategy.slug : "kaizen";
    const estimationRoute = accessType === "client" ? "/client/estimation": "/client/estimationV1";
    const estimationRouteArray:any = ["/client/estimation", "/client/estimationV1"];
    const history = useHistory();
    const isThisLead = Boolean(client && client.isLead);
    const leadsData = client && client.agent && client.agent.leads || [];
    const checkLeads = leadsData.some(val => val.email === user.email);
    const isLead = isThisLead && checkLeads;

	if (client) {
        showMarketing = client.showMarketing || (strategySlug === 'cashstream' ? 0 : 1);
		reBrand = client && client.imo && client.imo.reBrand && client.strategy && client.strategy.reBrand === 2;
		if( client.strategy && client.strategy.reBrand === 1 ){
            reBrand = 1;
        }
	}

    if (user && user.role === 6) {
        if( user && user.client && user.client.company && user.client.company.id ) {
            showMarketing = user.client.company.showMarketing ? 1 : 0;
        }
    }

	if (client) {
		strategyName =  (client.strategy && client.strategy.name) || strategyName;
	} else if (accessType === "client" && (user.role === 1 || user.role === 2 || user.role === 3 ||user.role === 4 || user.role === 15 || user.role === AccountType.Aggregator || user.role === AccountType.AggregatorMember) && user.demoStrategy ) {
		strategyName = (user.demoStrategy && user.demoStrategy.name) || strategyName;
        strategySlug = (user.demoStrategy && user.demoStrategy.slug) || strategySlug;
        if( user.demoStrategy.reBrand === 1 ){
            reBrand = 1;
        }
        if( user.role === 3){
            if( user.imo && user.imo.reBrand && user.demoStrategy.reBrand === 2 ){
                reBrand = 1;
            }
        }
        if( user.role === 4 ){
            if( user.agent && user.agent.imo && user.agent.imo.reBrand && user.demoStrategy.reBrand === 2 ){
                reBrand = 1;
            }
        }
        if(user.role === 15){
            if(user.hasOwnProperty('ImoAdditionalMembers')) {
                if(user.ImoAdditionalMembers && user.ImoAdditionalMembers.imo && user.ImoAdditionalMembers.imo.reBrand === 1){
                    reBrand = 1;
                }
            }
        }
	}
	
	const showEstimator = !(strategySlug === 'trizen' || strategySlug === 'esd');

	React.useEffect(() => {
		setRouteName( history.location.pathname === '/accounts' ? '/intro' : history.location.pathname );
		if(history.location.pathname === '/accounts' && user && user.enrollment && [0,1,2].indexOf(user.enrollment.status) > -1){
			setRouteName('/enrollment/start');
		}
	},[isRefresh]);

    React.useEffect(() => {
        if ((history.location.pathname === "/accounts" || history.location.pathname === "/intro") && user && user.client && showMarketing == 0) {
            //MS: Servicing Visibility to client yet
            if(user.inviteStatus >= AccountStatus.TrusteeSignAndEnrollmentClosed){
                setRouteName("/client/dashboard");
                history.push("/client/dashboard");
            } else {
                setRouteName("/new-enrollment");
                history.push("/new-enrollment");
            }
        }
    }, [user && user.client]);

	React.useEffect(()=> {
		if (user.enrollment && history.location.pathname === "/enrollment"){
			history.push("/enrollment/start");
		}
		setRouteName(history.location.pathname);
		if (myInformationTabs.indexOf(routeName) > -1) {
			setShowToggle(true);
		}
        if (clientServiceTabs.indexOf(routeName) > -1) {
            setShowServicingToggle(true);
        } else {
            setShowServicingToggle(false);
        }
		if (history.location.pathname.search("/client/estimation-new") > -1){
            SetIsEstimation(true);
        } else {
            SetIsEstimation(false);
        }
	}, [user.enrollment, routeName, enrollment, history.location.pathname]);

	function handleNavigation(route: string) {
		setRouteName(route);
		if (handleNavClick) {
			handleNavClick();
		}
		history.push(route);
	}

	const enrollmentClick = async (user: any) => {
		if (!user || user.accessType === "client") {
			return false;
		}
		await accountActions.checkProfileDetailsCompleted("ClientNavigation");

		if (user && user.enrollment && user.enrollment.version === 1 && user.enrollment.clientSigned === 1) {
            history.push("/enrollmentServicingComing");
			return false;
		}

		if (user && ((user.enrollment && user.enrollment.version === 2) || !user.enrollment)) {
			history.push("/new-enrollment");
		}

		await enrollmentActions.getEnrollment();
	};

	const enrollmentStatus = () => {
	    if( profileEnrollment && profileEnrollment.version === 2){
	        if(profileEnrollment.enrollmentStatus === 0 && profileEnrollment.enrollmentSubStatus === -1){
	            return <span className="btnHilightBar glow" style={{color:"#ffffff"}}>
                            Get Started
                        </span>;
            }
            if( profileEnrollment && ((profileEnrollment.enrollmentStatus + "." + profileEnrollment.enrollmentSubStatus >= '4.0' || user.override === 1) || (profileEnrollment.enrollmentStatus + "." + profileEnrollment.enrollmentSubStatus >= '4.1') || (strategySlug === "cashstream" && profileEnrollment.enrollmentStatus + "." + profileEnrollment.enrollmentSubStatus >= '3.0')) ){
                return <span className="btnHilightBar glow" style={{color:"#ffffff", animation : 'none'}}>
                            Completed
                        </span>
            } else {
                return  <span className="btnHilightBar glow" style={{color:"#ffffff"}}>
                            In Progress
                        </span>
            }
        } else if (profileEnrollment && profileEnrollment.version === 1 && profileEnrollment.clientSigned === 1) {
                return <span className="btnHilightBar glow" style={{ color: "#ffffff", animation: "none" }}>
                            Completed
                        </span>
        }
    };

	return (
		<React.Fragment>
            <link rel="stylesheet" href={`${process.env.PUBLIC_URL + cssPaths.clientNavigation}`}/>
			<ConfirmEnrollment open={open} user={user} setOpen={setOpen}/>
			<EnrollmentSelection open={enrollmentSelection}  setOpen={(flag)=>setEnrollmentSelection( flag )} />
			<List>
                {
                    !(user.accessType === "client" || isServiceDashboardAccessible) &&
                    <ListItem
                            className={(routeName === "/dashboard") ? "active menuHead" : "menuHead"}
                            onClick={() => {
                                handleNavigation( "/dashboard")
                            }}>
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText
                                primary={"Home Dashboard"}/>
                    </ListItem>
                }

                {
                        showServicingToggle && (isClientDemoAccess || isServiceDashboardAccessible) &&
                        <ListItem
                                className={(routeName === "/dashboard" || routeName === "/client/dashboard") ? "active menuHead" : "menuHead"}
                                onClick={() => {
                                    setShowServicingToggle(!showServicingToggle);
                                    handleNavigation("/client/dashboard")
                                }}>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText
                                    primary={"Servicing Dashboard"}/>
                            <ListItemIcon className="upDownIcon">
                                {showServicingToggle ?
                                        <ExpandMoreIcon/> :
                                        <ChevronRightIcon/>
                                }
                            </ListItemIcon>
                        </ListItem>
                }

                {
                        showServicingToggle && (isClientDemoAccess || isServiceDashboardAccessible)  &&
                        <List component="div" disablePadding>
                            <ListItem button className={(routeName === "/client/payments") ? "active" : ""}
                                      onClick={() => handleNavigation("/client/payments")}>
                                <AttachMoneyIcon />
                                <ListItemText primary="Payments" />
                            </ListItem>
                            <ListItem button className={(routeName === "/client/plandocuments") ? "active" : ""}
                                      onClick={() => handleNavigation("/client/plandocuments")}>
                                <DescriptionIcon />
                                <ListItemText primary="Plan Documents" />
                            </ListItem>
                            <ListItem button className={(routeName === "/client/annualreview") ? "active" : ""}
                                      onClick={() => handleNavigation("/client/annualreview")}>
                                <AccessAlarmsIcon />
                                <ListItemText primary="Annual Review" />
                            </ListItem>
                            <ListItem button className={(routeName === "/client/mydetails") ? "active" : ""}
                                      onClick={() => handleNavigation("/client/mydetails")}>
                                <AccountCircleOutlinedIcon />
                                <ListItemText primary="My Details" />
                            </ListItem>
                            <ListItem button className={(routeName === "/client/claims") ? "active" : ""}
                                      onClick={() => handleNavigation("/client/claims")}>
                                <PlaylistAddCheckIcon />
                                <ListItemText primary="Claims & Distributions" />
                            </ListItem>
                            <ListItem button className={(routeName === "/client/questions") ? "active" : ""}
                                      onClick={() => handleNavigation("/client/questions")}>
                                <InfoOutlinedIcon />
                                <ListItemText primary="Questions (FAQ)" />
                            </ListItem>
                            <ListItem button className={(routeName === "/client/referralfeedback") ? "active" : ""}
                                      onClick={() => handleNavigation("/client/referralfeedback")}>
                                <SupervisorAccountOutlinedIcon />
                                <ListItemText primary="Feedback & Referrals" />
                            </ListItem>
                            <ListItem button className={(routeName === "/client/contactus") ? "active" : ""}
                                      onClick={() => handleNavigation("/client/contactus")}>
                                <EmailOutlinedIcon />
                                <ListItemText primary="Contact Us" />
                            </ListItem>
                        </List>
                }

                {
                    showMarketing && showMarketing === 1 && !isClientDemoAccess  && !showServicingToggle && !isServiceDashboardAccessible && !isLead &&
                    <ListItem className="menuHead" onClick={() => setShowToggle(!showToggle)} button>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary={<span>{strategyName} Details</span>} />
                        <ListItemIcon className="upDownIcon">
                            {showToggle ?
                                    <ExpandMoreIcon /> :
                                    <ChevronRightIcon />
                            }
                        </ListItemIcon>
                    </ListItem>
                }
				{showToggle && showMarketing === 1 && !isClientDemoAccess && !showServicingToggle && !isServiceDashboardAccessible && !isLead &&
				<List component="div" disablePadding>

					<ListItem button className={(routeName === "/intro") ? "active" : ""}
							  onClick={() => handleNavigation("/intro")}>
						<VideocamIcon/>
						<ListItemText primary="Introduction Video"/>
					</ListItem>

                    { showEstimator && (estimationVersion === 1 ?
                            <ListItem button className={routeName === "/client/estimation-new" ? "active" : ""}
                                      onClick={() => handleNavigation("/client/estimation-new")}>
                                <DescriptionIcon/>
                                <ListItemText primary="Estimate My Benefits"/>
                            </ListItem> :
                            <ListItem button className={
                                estimationRouteArray.includes(routeName) ? "active": ""
                            }
                                      onClick={() => handleNavigation(estimationRoute)}>
                                <DescriptionIcon/>
                                <ListItemText primary="Estimate My Benefits"/>
                            </ListItem>)
                    }

					<ListItem button className={routeName === "/financialconcerns" ? "active" : ""}
							  onClick={() => handleNavigation("/financialconcerns")}>
						<SecurityIcon/>
						<ListItemText primary="Financial Solutions"/>
					</ListItem>


						<NavLink
						style={{textDecoration:'none'}}
						activeClassName="active"
						to={'/howitwork'}
						isActive={(match:any, location:any) => {
							setRouteName( location.pathname );
							return !match ? false : true;
						}}
                        onClick={() => handleNavigation("/howitwork")}
						>
							<ListItem
								button
								className={routeName === "/howitwork" ? "active" : ""}
							>
								<ExtensionIcon/>
								<ListItemText primary="How It Works"/>
							</ListItem>
						</NavLink>

                    <List className="pl15 subList" component="div" disablePadding>
						{
							(strategySlug !== "kaizen" || (user && user.demoStrategy && user.demoStrategy.slug !== "kaizen" )) &&
							<ListItem button className={routeName === "/SplitDollar" ? "active" : ""}
									  onClick={() => handleNavigation("/SplitDollar")}>
								<FiberManualRecordIcon/>
								<ListItemText primary="Split Dollar"/>
							</ListItem>
						}
						<ListItem button className={routeName === "/product" ? "active" : ""}
								  onClick={() => handleNavigation("/product")}>
							<FiberManualRecordIcon/>
							<ListItemText primary="Product"/>
						</ListItem>
						<ListItem button className={routeName === "/leverage" ? "active" : ""}
								  onClick={() => handleNavigation("/leverage")}>
							<FiberManualRecordIcon/>
							<ListItemText primary="Leverage"/>
						</ListItem>
						<ListItem button className={routeName === "/minimumrisk" ? "active" : ""}
								  onClick={() => handleNavigation("/minimumrisk")}>
							<FiberManualRecordIcon/>
							<ListItemText primary="Minimizing Risk"/>
						</ListItem>
					</List>

					<ListItem button className={routeName === "/plandetails" ? "active" : ""}
							  onClick={() => handleNavigation("/plandetails")}>
						<SearchIcon/>
						<ListItemText primary="Plan Details"/>
					</ListItem>
                    {
                        strategySlug == "kaizen" && (Number(reBrand) == 1 ? (
                            <ListItem button className={routeName === "/testimonials-aon" ? "active" : ""}
                                      onClick={() => handleNavigation("/testimonials-aon")}>
                                <NotificationsActiveIcon/>
                                <ListItemText primary="Testimonials"/>
                            </ListItem>
                        ) : (
                            <>
                                <ListItem button className={routeName === "/testimonials" ? "active" : ""}
                                          onClick={() => handleNavigation("/testimonials")}>
                                    <NotificationsActiveIcon/>
                                    <ListItemText primary="Testimonials"/>
                                </ListItem>
                                <ListItem button className={routeName === "/news-social" ? "active" : ""}
                                          onClick={() => handleNavigation("/news-social")}>
                                    <CommentIcon/>
                                    <ListItemText primary="News & Social Posts"/>
                                </ListItem>
                            </>
                        ))
                    }
                    <ListItem button className={routeName === "/faq" ? "active" : ""}
							  onClick={() => handleNavigation("/faq")}>
						<LiveHelpIcon/>
						<ListItemText primary="FAQ"/>
					</ListItem>
                    {!(accessType === "client") && <ListItem button className={routeName === "/invite-kids" ? "active" : ""}
                               onClick={() => handleNavigation("/invite-kids")}>
                        <LiveHelpIcon />
                        <ListItemText primary="Invite Kid(s)" />
                    </ListItem>}
				</List>
				}

                {/*{*/}
                {/*        isLead &&*/}
                {/*        <>*/}
                {/*            <ListItem className="menuHead"*/}
                {/*                      onClick={() => targetBlank(MARKETING_SITE_URL)} button>*/}
                {/*                <ListItemIcon>*/}
                {/*                    <InfoIcon />*/}
                {/*                </ListItemIcon>*/}
                {/*                <ListItemText primary="Education Center" />*/}
                {/*            </ListItem>*/}
                {/*            <ListItem button className={estimationRouteArray.includes(routeName) ? "active" : ""}*/}
                {/*                      onClick={() => handleNavigation(estimationRoute)}>*/}
                {/*                <DescriptionIcon />*/}
                {/*                <ListItemText primary="Estimate My Benefits" />*/}
                {/*            </ListItem>*/}
                {/*        </>*/}
                {/*}*/}
			</List>

			<List className="listTwo">
                { user.inviteStatus === 10 && strategySlug !== "esd" ?  (<ListItem>
					<Button
						className="btnPrimary btnHilight animated-box in"
						variant="contained"
						color="primary"
						disabled={user.accessType === "client"}
					>
						<DescriptionIcon
							className="mr15"/>Enrollment
					</Button>
				</ListItem>) :
                (
                        !isServiceDashboardAccessible && !isClientDemoAccess &&
                <React.Fragment>
                <ListItem className={`menuHead ${(enrollmentTabse.indexOf(history.location.pathname) > -1) ? 'active' : ''}`} button  disabled={user.accessType === 'client'}>
                    <React.Fragment>
                        <ListAltIcon/>
                        <ListItemText
                            className={` ${user.accessType === "client" ? 'disabled' : ''} `}
                            onClick={() => {
                                enrollmentClick(user);
                                handleNavClick();
                            }}
                            primary={
                                <>
                                    <span style={{float:'left',marginTop:'6px'}}>Enrollment </span>
                                    {(user.inviteStatus >= 2 && (!user.enrollment)) &&
                                    <span className="btnHilightBar glow" style={{color:"#ffffff"}}>
                                        Get Started
                                    </span>
                                    }
                                    {enrollmentStatus()}
                                </>
                            }/>
                    </React.Fragment>
                </ListItem>
				</React.Fragment>) }
			</List>

		</React.Fragment>
	);
}
