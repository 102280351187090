import React from 'react';
import {FieldArrayConfig, FieldArray} from 'formik';

interface FAProps extends FieldArrayConfig {
    children?: React.ReactChildren | React.ReactElement | any
}

const FA: React.FC<FAProps> = (props: FAProps) => {
    const ChildComponent = props && props.children;
    return (
            <FieldArray {...props}>
                {
                    faChildProps => {
                        return (
                                <ChildComponent {...faChildProps} {...props} />
                        );
                    }
                }
            </FieldArray>
    );
};

export default FA;