import React from "react";
import { createStyles, makeStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
    Button,
    Card,
    CardContent,
    Dialog,
    IconButton,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { Pagination } from "../../components/table";
import { Link } from "react-router-dom";
import * as AccountActions from "../../actions/account";
import { useActions } from "../../actions";
import numeral from "numeral";
import { AccountStatusArr } from "../../model";
import { history } from "../../configureStore";
import useQueryString from "../../hooks/useQueryString";
import { cashStreamSlug } from "../../constants";
import TableWrap from "../TableWrap/TableWrap";
import {
    getEmployerAndParticipantCont
} from "../../pages/client/Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            Paper: {
                minWidth: 767,
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
    KpiModalRow: {
        textDecoration: "none",
    },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function KpiModal(props: any) {

    const [kpiData, setkpiData] = React.useState<any>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", 25);

    const { handleClose, modalTitle, type, companyId, companyStrategySlug, contributionType } = props;

    const classes = useStyles();
    const accountActions = useActions(AccountActions);
    const [pageData, setPageData] = React.useState<any>([]);
    const [isGiOfferType, setIsGiOfferType] = React.useState<boolean>(false);
    const getKpiData = async (query: any) => {
        let data: any = await accountActions.getGroupDashboardLists(query);
        setPageData(data);
        if (data && query.dataType === "json") {
            console.log("data", data);
            setIsGiOfferType(data && data.rows && data.rows.length > 0 && data.rows[0] && (data.rows[0].underWritingOfferType === "gi" || data.rows[0].underWritingOfferType === "consent") ? true : false);
            setkpiData(data && data.rows);
        }
    };

    const handleExport = () => {
        if (kpiData && kpiData.length > 0) {
            let query: any = {
                listType: type,
                dataType: "csv",
                companyId: companyId,
                page: page,
                rowsPerPage: rowsPerPage,
            };
            getKpiData(query);
        }
    };

    React.useEffect(() => {
        let query: any = {
            listType: type,
            dataType: "json",
            companyId: companyId,
            page: page,
            rowsPerPage: rowsPerPage,
        };
        getKpiData(query);
    }, [page, rowsPerPage]);

    React.useEffect(() => {
        let query: any = {
            listType: type,
            dataType: "json",
            companyId: companyId,
            page: page,
            rowsPerPage: rowsPerPage,
        };
        getKpiData(query);
    }, []);
    console.log("companyStrategySlug", companyStrategySlug);
    console.log("isGiOfferType", isGiOfferType);
    return (
            <div>
                <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={true}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {modalTitle}
                    </DialogTitle>
                    <DialogContent dividers className="pd0">
                        <Card>
                            <CardContent>
                                <TableWrap>
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                <TableCell align="left">Client Name</TableCell>
                                                <TableCell align="left">Client Email</TableCell>
                                                <TableCell align="left">Application Type</TableCell>
                                                <TableCell align="left">Status</TableCell>
                                                {
                                                    !(companyStrategySlug === cashStreamSlug && isGiOfferType) &&
                                                    <React.Fragment>
                                                        {
                                                            contributionType && contributionType === "EMPLOYER_ONLY" ? <></> :
                                                                    <TableCell align="left">Participant
                                                                        Contribution</TableCell>
                                                        }
                                                        <TableCell align="left">Combine Contribution</TableCell>
                                                        {
                                                            contributionType && contributionType === "PARTICIPANT_ONLY" ? <></> :
                                                                    <TableCell align="left">Employer
                                                                        Contribution</TableCell>
                                                        }
                                                    </React.Fragment>
                                                }
                                                <TableCell align="left">Death Benefit</TableCell>
                                                <TableCell align="left">Premium</TableCell>
                                                <TableCell align="left">Current Tranche</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                kpiData && kpiData.map((item, i) => {
                                                    let status: string = item.user ? AccountStatusArr[item.user.inviteStatus] : "Pending";
                                                    let firstName: string = item.user ? item.user.firstName : item.firstName;
                                                    let lastName: string = item.user ? item.user.lastName : item.lastName;
                                                    let email: string = item.user ? item.user.email : item.email;
                                                    let purpose: string = item.user && item.user.client && item.user.client.purpose;
                                                    let deathBenefit: string = item.user ? numeral(item.user.client.deathBenefit).format("$0,0") : numeral(item.deathBenefit).format("$0,0");
                                                    let annualPremium: string = item.user ? numeral(item.user.client.annualPremium).format("$0,0") : "N/A";
                                                    let contributionValue = item.user && item.user.client && item.user.client.contributionAmount;
                                                    let contributionAmount: string = item.user ? numeral(contributionValue).format("$0,0") : "N/A";
                                                    let contributionBreakUp: any = getEmployerAndParticipantCont(contributionValue, 50 , 15000);
                                                    let employerContribution: string = item.user ? numeral(contributionBreakUp && contributionBreakUp.employer).format("$0,0") : "N/A";
                                                    let participantContribution: string = item.user ? numeral(contributionBreakUp && contributionBreakUp.participant).format("$0,0") : "N/A";
                                                    let trancheNumber: string = (item.user && item.user.client.tranche) ? item.user.client.tranche.trancheNumber : "N/A";

                                                    return (
                                                            <TableRow key={i}>
                                                                <TableCell align="left">
                                                                    <Link className={classes.KpiModalRow}
                                                                          to={item.user ? `/new-client/detail/${item.user.client.id}` : history.location.pathname}>
                                                                        {firstName} {lastName}
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {email}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {purpose}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {status}
                                                                </TableCell>
                                                                {
                                                                    !(companyStrategySlug === cashStreamSlug && isGiOfferType) &&
                                                                    <React.Fragment>
                                                                        {
                                                                            contributionType && contributionType === "EMPLOYER_ONLY" ? <></> :
                                                                                    <TableCell align="left">
                                                                                        {participantContribution}
                                                                                    </TableCell>
                                                                        }
                                                                        <TableCell align="left">{contributionAmount}</TableCell>
                                                                        {
                                                                            contributionType && contributionType === "PARTICIPANT_ONLY" ? <></> :
                                                                                    <TableCell align="left">
                                                                                        {employerContribution}
                                                                                    </TableCell>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                <TableCell align="left">
                                                                    {deathBenefit}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {annualPremium}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {trancheNumber}
                                                                </TableCell>
                                                            </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                </TableWrap>
                            </CardContent>
                        </Card>

                    </DialogContent>
                    <DialogActions className="DialogActions textCenterSm">
                        <Pagination page={Number(page)} rowsPerPage={Number(rowsPerPage)}
                                    setPage={setPage}
                                    setRowsPerPage={setRowsPerPage}
                                    accountList={pageData}/>

                        <Button autoFocus className={"floatLeft floatNone mt15 mb20"} onClick={handleClose}
                                color="secondary"
                                variant="contained">
                            Cancel
                        </Button>
                        <Button autoFocus className={"floatRight floatNone mt15 mb20"} onClick={handleExport}
                                color="primary" variant="contained">
                            Export List
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
    );
}