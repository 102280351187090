import React from "react";
import {useLocation} from "react-router-dom";

type retObjT = { setValue: Function; value: string; };
type TWL = (tabs: string[], defaultTab?: string) => retObjT;

const useLocTab: TWL = (tabs, defaultTab = tabs[0]) => {
    const location = useLocation();
    const [value, setValue] = React.useState(defaultTab);

    React.useEffect(() => {
        const tabValue = location.pathname.split("/").pop();

        if (tabValue) {
            setValue(tabs.includes(tabValue) ? tabValue : defaultTab);
        }

    }, [location]);

    return {value, setValue};
};

export default useLocTab;