import * as React from "react";
import {isMobileOnly} from 'react-device-detect';
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { Grid, FormControlLabel, Checkbox, Typography, Link } from "@material-ui/core";
import { Button, Label, TextField } from "../formInputs";
import PasswordRequired from "../PasswordRequired";
import {
    PASSWORD_VALIDATE_REGEX,
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_SPECIAL_CHAR,
} from "../../constants";
import { Dialog as GenericDialog, GenericDialogProps } from "../dialog";
import {Viewer} from "../index";
import PasswordRequiredWithStrength from "../PasswordStrength";


export type LCIPTypes = {
    chkMarketing?: boolean;
    chkTerms: boolean;
    confirmPassword: string;
    email: string;
    password: string;
}

type Props = {
    onSetPassword: any,
    role?: number | undefined
}

interface eventType {
    target: {
        value: string
    };
}

const validate = (values: LCIPTypes): object => {
    const errors: any = {};
    if (!values.chkTerms) {
        errors.chkTerms = "Required";
    }
    if (!values.password) {
        errors.password = "Required";
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
    } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Password mismatched";
    }
    if (values.password && !PASSWORD_VALIDATE_REGEX.test(values.password)) {
        errors.password = true;
    }
    return errors;
};


const LeadClientInvitationForm: React.FC<Props & InjectedFormProps<LCIPTypes, Props>> = (props) => {
    const { change, handleSubmit, invalid, onSetPassword, submitting } = props;

    const [acceptTerms, setAcceptTerms] = React.useState(false);
    const [oneLetter, setOneLetter] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const [showTc, setShowTc] = React.useState(false);
    const [showPasswordValidations, setShowPasswordValidations] = React.useState(false);
    const [password,setPassword] = React.useState();

    const handleTerms = () => {
        change('chkTerms', !acceptTerms);
        setAcceptTerms(!acceptTerms);
    };
    const onPassWordChange = (e: eventType): void => {
        setShowPasswordValidations(true);
        const val = e.target.value;
        setPassword(val);
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
    };

    const dialogProps: GenericDialogProps = {
        actions: (<Button color="primary" label="I Agree" onClick={() => setShowTc(false)} variant="contained" />),
        'aria-labelledby': "terms-and-conditions-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (
                <Viewer url="https://niwcorp-staging.s3.amazonaws.com/Privacy-and-Security-Policy.pdf" />
        ),
        dividers: true,
        onClose: () => setShowTc(false),
        open: showTc,
        title: 'Terms and conditions'
    };

    return (
            <form className="loginForm invitationForm pt0" onSubmit={handleSubmit(onSetPassword)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field label="Your Email" name="email" fullWidth={true} component={TextField} type="email"
                               placeholder="Email" disabled={true} readOnly={true}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field label={<Label label="Create a Password" required />} name="password" fullWidth={true} component={TextField} type="password"
                               placeholder="Create a Password" onChange={(e: eventType) => onPassWordChange(e)}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field label={<Label label="Confirm Password" required />} name="confirmPassword" fullWidth={true} component={TextField} type="password"
                               placeholder="Confirm Your Password"/>
                    </Grid>
                    <Grid item xs={12}>
                        {
                                showTc &&
                                <GenericDialog {...dialogProps} />
                        }
                        <FormControlLabel
                                className="mt20 mb0"
                                control={
                                    <Checkbox className="pt0" name="chkTerms" color="primary" onChange={handleTerms} />
                                }
                                label={<Typography variant="body1" className="mb0" color="primary">I agree to the <Link className="itemHover"
                                        onClick={() => setShowTc(true)}
                                >terms and conditions</Link> of ilia.<span className="colorRed">*</span></Typography>}
                        />
                        <FormControlLabel control={
                            <Checkbox className="pt0" name="chkMkt" color="primary" />
                        } label={<Typography variant="body1" className="mb0" color="primary">I agree to be periodically contacted for marketing purposes. (Optional)</Typography>}
                        />
                        <div className={isMobileOnly ? "textCenter" : ""}>
                            <Button
                                    className={isMobileOnly ? "mt20 mb20" : "mt20 mb20 floatRight"}
                                    color="primary"
                                    disabled={!acceptTerms || invalid || submitting}
                                    label="Get Access"
                                    loading={submitting}

                                    type="submit"
                                    variant="contained"
                            />
                        </div>
                    </Grid>
                    {
                            showPasswordValidations &&
                            <Grid item xs={12}>
                                <PasswordRequiredWithStrength password={password} />

                                <PasswordRequired
                                        oneLetter={oneLetter}
                                        oneNumber={oneNumber}
                                        oneCapitalLetter={oneCapitalLetter}
                                        eightCharacter={eightCharacter}
                                        oneSpecialChar={oneSpecialChar}
                                />
                            </Grid>
                    }
                </Grid>
            </form>

    );
};

export default reduxForm<LCIPTypes, Props>({
    form: "LeadClientInvitationForm",
    validate,
})(LeadClientInvitationForm);
