import numeral from "numeral";

export const isIliaApp = (offerType: string) => {
    if (offerType === "ilia_app") {
        return true;
    }
    return false;
}

export const isLongForm = (offerType: string):boolean => {
    return (offerType === "longform")
}

export const isWorksheet = (offerType: string):boolean => {
    return (offerType === "worksheet")
}

export const isAllianzApp = (offerType: string): boolean => {
    return (offerType === "allianz_app")
}

export const convertToNumber =(val:String):number|null =>{
    return (val ? numeral(val).value() : null)
}

/**
 * Convert Numeric To Radio Value Yes | No | Null
 * @param val
 */
export const convertToRadioValue = (val: number | null): string | null => {
    let newVal: string | null = null;
    switch (val) {
        case 0:
            newVal = "No";
            break;
        case 1:
            newVal = "Yes";
            break;
    }
    return newVal;
}

export const iliaAppInsuredQuestionsNameArr = [
    "Proposed_Insured_Questions-3",
    "Remark-101",

    "Proposed_Insured_Questions-4",
    "Remark-102",

    "Proposed_Insured_Questions-5",


    "Proposed_Insured_Questions-10",

    "Proposed_Insured_Questions-12",
    "Remark-10-Remarks",
    "Remark-110",
    "Remark-105",
    "Remark-104",
    "Remark-130",
    //"Proposed_Insured_Questions-32",
    "Proposed_Insured_Questions-20",
    "Proposed_Insured_Questions-30",
    "Proposed_Insured_Questions-21",

    /*"Insured_ParticipationDetails",
    "Insured_Aviation",
    "Insured_ForeignTravel",
    "Insured_Avocation",

    "Insured_Automobile_Motorcycle_or_Motorboat_Racing",
    "Insured_Parachuting_and_Sky_Diving",
    "Insured_Underwater_Diving",
    "Insured_Other_Hazardous_Sports_or_Avocations"*/
];

const stagingAndDev = [
        {docValue:"4b34ac90-021e-4c5f-a4b8-18bac65e282b" ,value: "Insured_Aviation", label: "Aviation"},
        {docValue:"4b34ac90-021e-4c5f-a4b8-18bac65e282b" ,value: "Insured_ForeignTravel", label: "Foreign Travel"},
        {docValue:"4b34ac90-021e-4c5f-a4b8-18bac65e282b" ,value: "Insured_Avocation", label: "Avocation"}
];

const additionalFieldOptionsList = {
    development: stagingAndDev,
    production: [
            {docValue:"8dcebea6-3f5b-4e89-b4a2-674c82deadf3" ,value: "Insured_Aviation", label: "Aviation"},
            {docValue:"8dcebea6-3f5b-4e89-b4a2-674c82deadf3" ,value: "Insured_ForeignTravel", label: "Foreign Travel"},
            {docValue:"8dcebea6-3f5b-4e89-b4a2-674c82deadf3" ,value: "Insured_Avocation", label: "Avocation"}
    ],
};

console.log("Working ===>", process.env);
const environment = process.env.REACT_APP_ENV === "production" ? "production" : "development";

export const checkAllApplied: any = additionalFieldOptionsList[environment];

export const avocationType: any = [{
    name: "Insured_AvocationXC50",
    label: "Automobile, Motorcycle or Motorboat Racing",
}, {
    name: "Insured_AvocationXC51",
    label: "Parachuting and Sky Diving",
}, {
    name: "Insured_AvocationXC52",
    label: "Underwater Diving",
}, {
    name: "Insured_AvocationXC53",
    label: "Other Hazardous Sports or Avocations",
}];

export const aviationPilotCertificate = {
    label: "What type of Pilot Certificate do you hold?",
    checkBoxArr: [{
        name: "Insured_AviationXC1",
        label: "Student"
    }, {
        name: "Insured_AviationXC2",
        label: "Private"
    }, {
        name: "Insured_AviationXC3",
        label: "Commercial"
    }, {
        name: "Insured_AviationXC4",
        label: "Airline Transport Rating (ATR)"
    }, {
        name: "Insured_AviationXC5",
        label: "Instrument Flight Rating (IFGR)"
    }]
}

export const participationArr = [
    "Proposed_Insured_Questions-32",
    "Insured_Aviation",
    "Insured_ForeignTravel",
    "Insured_Avocation",
    "Insured_AviationXC1",
    "Insured_AviationXC2",
    "Insured_AviationXC3",
    "Insured_AviationXC4",
    "Insured_AviationXC5",
    "Insured_AviationXR6",
    "Insured_AviationXR7",
    //"Insured_AviationX8",
    "Insured_AviationXT9", // What type of aircraft do you fly? - text box
    "Insured_AviationXT10", // Crew position - text box
    "Insured_AviationXR11",
    "Insured_AviationXR12",
    "Insured_AviationXT13",
    "Insured_AviationXD14", // Date of last flight as a Pilot
    "Insured_AviationXT15",

    "Insured_AviationXR16",
    "Insured_AviationXR17",

    "Insured_AviationXC18",
    "Insured_AviationXT19",
    "Insured_AviationXC20",
    "Insured_AviationXT21",
    "Insured_AviationXC22",
    "Insured_AviationXT23",


    "Insured_AviationXT24",
    "Insured_AviationXT25",
    "Insured_AviationXT26",

    "Insured_AviationXT27",
    "Insured_AviationXT28",
    "Insured_AviationXT29",

    "Insured_AviationXT30",
    "Insured_AviationXT31",
    "Insured_AviationXT32",

    "Insured_AviationXT33",
    "Insured_AviationXT34",
    "Insured_AviationXT35",

    "Insured_AviationXT36",
    "Insured_AviationXT37",
    "Insured_AviationXT38",
    "Insured_AviationXT39",
    "Insured_AviationXR40",
    "Insured_AviationXT41",


    "Insured_Foreign_TravelXT1",
    "Insured_Foreign_TravelXT2",
    "Insured_Foreign_TravelXT3",

    "Insured_Foreign_TravelXC4",
    "Insured_Foreign_TravelXC5",
    "Insured_Foreign_TravelXR6",
    "Insured_Foreign_TravelXT7",



    "Insured_AvocationXC50",

    "Insured_AvocationXC1",
    "Insured_AvocationXC2",
    "Insured_AvocationXC3",
    "Insured_AvocationXT4",
    "Insured_AvocationXC5",
    "Insured_AvocationXT6",

    "Insured_AvocationXT7",
    "Insured_AvocationXT8",
    "Insured_AvocationXT9",
    "Insured_AvocationXT10",
    "Insured_AvocationXR11",
    "Insured_AvocationXT12",

    "Insured_AvocationXT13",
    "Insured_AvocationXT14",
    "Insured_AvocationXT15",
    "Insured_AvocationXT16",
    "Insured_AvocationXT17",

    "Insured_AvocationXR18",
    "Insured_AvocationXT19",
    "Insured_AvocationXT20",
    "Insured_AvocationXT21",
    "Insured_AvocationXC51",
    "Insured_AvocationXR22",
    "Insured_AvocationXR23",
    "Insured_AvocationXT24",

    "Insured_AvocationXT25",
    "Insured_AvocationXT26",
    "Insured_AvocationXT27",
    "Insured_AvocationXR28",
    "Insured_AvocationXT29",
    "Insured_AvocationXT30",
    "Insured_AvocationXT31",
    "Insured_AvocationXR32",
    "Insured_AvocationXT33",
    "Insured_AvocationXR34",
    "Insured_AvocationXT35",
    "Insured_AvocationXT36",
    "Insured_AvocationXC52",
    "Insured_AvocationXR37",
    "Insured_AvocationXT38",

    "Insured_AvocationXT39",
    "Insured_AvocationXT40",
    "Insured_AvocationXT41",
    "Insured_AvocationXT42",
    "Insured_AvocationXT43",
    "Insured_AvocationXT44",

    "Insured_AvocationXR45",
    "Insured_AvocationXT46",
    "Insured_AvocationXT47",
    "Insured_AvocationXT48",
    "Insured_AvocationXC53",
    "Insured_AvocationXT49",
]

/**
 * Get Employer and Participant Contribution using Combined Contribution Value
 * @param combined
 * @param percentage
 * @param maximum
 */
export const getEmployerAndParticipantCont = (combined: number, percentage: number, maximum: number) => {
    const maxValue = (combined / (1 + percentage / 100)) * (percentage / 100);
    const employer = maximum < maxValue ? maximum : maxValue;
    const participant = combined - employer;
    return {
        participant,
        employer,
    };
}