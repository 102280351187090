import React, { useEffect } from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import { NumberTextField } from "../../../../../../../components/formikFormInputs";
import * as Yup from "yup";
import { Formik } from "formik";
import GetFormContent from "../../Beneficiaries/BeneficiariesList/GetFormContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import numeral from "numeral";
import NumberFormat from "react-number-format";
import { payloadDataHandler } from "../index";
import { isLongForm } from "../../Utilities";

const UnearnedIncomePastYear = (props: any) => {
    const {
        autoSaveTrigger,
        financialAndLiabilitiesArr,
        formType,
        isFromClientDetail, /*setIsError,*/
        offerType,
        setFinancialAndLiabilitiesArr,
        setFormType,
        setSaveFormContent,
        setUnEarnedVerified,
        setUnEarnedVerifyRef,
        UnearnedIncomePastYear,
        unearnedIncomePastYearRef,
        user,
    } = props;
    const clientSigned = user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? 1 : 0;
    const { updateClientFields } = useActions(AccountActions);
    const [isConfirmCheckbox, setIsConfirmCheckbox] = React.useState(user && user.client && user.client.verifyUnEarnedIncome ? true : false);

    const unEarnedVerifyRef = React.useRef<any>();
    useEffect(() => {
        if (unEarnedVerifyRef.current && typeof setUnEarnedVerifyRef === 'function') {
            setUnEarnedVerifyRef(unEarnedVerifyRef);
        }
    }, [unEarnedVerifyRef.current]);

    useEffect(()=>{
        setUnEarnedVerified(isConfirmCheckbox);
    },[isConfirmCheckbox])

    const fieldObj = [
        {
            id: "pensionSocialSecurity",
            name: "pensionSocialSecurity",
            helperText: "",
            // label: <Label label={"Pension/Social Security"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Pension/Social Security" : "Pension/Social Security" } required={false}/>,
            isRequired: true,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "disabilityPayments",
            name: "disabilityPayments",
            helperText: <span className="f12 textLight">If Applicable</span>,
            // label: <Label label={"Disability Payments"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Disability Payments, if Applicable" : "Disability Payments" } required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "dividendsInterest",
            name: "dividendsInterest",
            helperText: "",
            label: <Label label={"Dividends / Interest"} required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "rentalsRoyalties",
            name: "rentalsRoyalties",
            helperText: "",
            label: <Label label={"Rentals / Royalties"} required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "otherUnEarnedIncome",
            name: "otherUnEarnedIncome",
            helperText: "",
            label: <Label label={"Other"} required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
    ];

    /*Set initial values*/
    const initialValues = {};
    /* Validations of fields set */
    let keysValidation = {};

    fieldObj.forEach((field) => {
        initialValues[field.name] = user && user.client && user.client[field.name] || "";
        // if (field.isRequired) {
        //     keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required("Required Field") : Yup.string().trim().required("Required Field");
        // } else {
        //     keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number() : Yup.string().trim();
        // }
    });

    const validationSchema = Yup.object().shape(keysValidation);

    return (

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Formik
                            innerRef={unearnedIncomePastYearRef}
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            validateOnChange={true}
                            onSubmit={async (values: any, { setSubmitting }) => {
                                try {
                                    let payload:any = payloadDataHandler(values, "UN_EARNED", isConfirmCheckbox);
                                    // await updateClientFields({  // alreay commented before NIWC-1830
                                    //     ...payload,
                                    //     verifyUnEarnedIncome: isConfirmCheckbox ? 1 : 0,
                                    //     formType: "UN_EARNED",
                                    // }, user && user.client && user.client.id || "", isFromClientDetail);

                                    const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "UN_EARNED")
                                    if(index > -1) {
                                        financialAndLiabilitiesArr.splice(index, 1);
                                    }
                                    setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
                                        ...payload
                                    }]);

                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 400);
                                } catch (e) {
                                    return false;
                                }
                            }}
                    >
                        {
                            (formikProps) => {
                                /*if (Object.keys(formikProps.errors).length > 0) {
                                    setTimeout(() => setIsError(true), 500);
                                } else {
                                    if (formikProps.dirty) {
                                        setTimeout(() => setIsError(false), 500);
                                    }
                                }*/
                                let totalValues: number = 0;
                                Object.values(formikProps.values).map((value) => totalValues = totalValues + (numeral(value).value() || 0));
                                return (
                                        <form className="floatLeft w100" onBlur={autoSaveTrigger} onSubmit={formikProps.handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <span className="hLine"/>
                                                    <Typography variant="body1" component="strong"
                                                                className="floatLeft w100 mb20">
                                                        Unearned Income - Past Year
                                                        <span className="floatRight">
                                                            Total Unearned Income:
                                                            <NumberFormat
                                                                    value={totalValues || 0}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    prefix={"$"}
                                                            />
                                                        </span>
                                                    </Typography>
                                                    <Typography variant="caption"
                                                                className="textLight mb15 floatLeft w100">
                                                        Fill out or verify any unearned income you have below making any
                                                        necessary changes. If a particular field doesn’t apply you can
                                                        leave it blank.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <GetFormContent isFromClientDetail={isFromClientDetail} fieldObj={fieldObj} {...formikProps}
                                                            enableOnChangeSubmit={false} setSaveFormContent={setSaveFormContent}
                                                            formType={formType} setFormType={setFormType}
                                            />
                                            {!isFromClientDetail &&
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div style={{ display: "flex", alignItems:'center' }} className="mt20" id="unEarnedVerifyRef" ref={unEarnedVerifyRef}>
                                                        <Checkbox style={{ height: "28px", marginRight: "10px" }}
                                                                  className="floatLeft pd0 mr10" color={"primary"}
                                                                  value={isConfirmCheckbox ? true : false}
                                                                  checked={isConfirmCheckbox ? true : false}
                                                                  onChange={(e, v) => {
                                                                      //setIsError(!v);
                                                                      setIsConfirmCheckbox(v);
                                                                      setFormType(formType)
                                                                      // updateClientFields({
                                                                      //     verifyUnEarnedIncome: v ? 1 : 0,
                                                                      //     apiType: "UN_EARNED",
                                                                      // }, user && user.client && user.client.id || "", isFromClientDetail);
                                                                  }}
                                                                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}/>
                                                        <Typography variant="caption">
                                                            <span>I verify that my total unearned income is </span>
                                                            <NumberFormat
                                                                    value={totalValues || 0}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    prefix={"$"}
                                                            />.
                                                            <span className="requiredHighlight">*</span>
                                                        </Typography>
                                                    </div>
                                                    {
                                                        !isConfirmCheckbox &&
                                                        <p style={{margin: 0}}>
                                                            <span style={{color: "#FF1744", fontSize: 12}}> Required Field </span>
                                                        </p>
                                                    }
                                                </Grid>
                                            </Grid>
                                            }
                                        </form>
                                );
                            }}
                            </Formik>
                            </Grid>
                            </Grid>
                            );
                        };

export default UnearnedIncomePastYear;