import React from 'react';
import {IconButton, InputAdornment as MuiInputAdornment, InputAdornmentProps} from '@material-ui/core';


export interface InpAdornmentProps extends InputAdornmentProps {
}

/**
 * MuiInputAdornment including IconButton
 * @param children
 * @param inputAdornmentProps
 * @constructor
 */
export const InpAdornment: React.FC<InpAdornmentProps> = ({children, ...inputAdornmentProps}) => {
    return (
            <MuiInputAdornment {...inputAdornmentProps}>
                <IconButton aria-label="search" className="iconBtn">
                    {
                        children
                    }
                </IconButton>
            </MuiInputAdornment>
    );
};

/**
 * Common MuiInputAdornment Component
 * @param children
 * @param inputAdornmentProps
 * @constructor
 */
const InputAdornment: React.FC<InpAdornmentProps> = ({children, ...inputAdornmentProps}) => {
    return (
            <MuiInputAdornment {...inputAdornmentProps}>
                {
                    children
                }
            </MuiInputAdornment>
    );
};

export default InputAdornment;
