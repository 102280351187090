import React from 'react';
import {DialogTitle as MuiDialogTitle, IconButton, Typography} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Variant} from '@material-ui/core/styles/createTypography';


export interface DialogTitleProps {
    title: string;
    titleVariant?: Variant | 'inherit';
    onClose?: () => void;
}

const Title: React.FC<DialogTitleProps> = ({onClose, title, titleVariant, ...other}) => {
    const useStyles = makeStyles((theme: Theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            color: theme.palette.grey[500],
            position: 'absolute',
            right: theme.spacing(1),
            top: 15,
        },
        heading: {
            marginBottom:0
        }
    }))
    const classes = useStyles();

    return (
            <MuiDialogTitle className={classes && classes.root} disableTypography {...other}>
                <Typography component="strong" variant={titleVariant} className="mb0">{title}</Typography>
                {
                        onClose &&
                        <IconButton aria-label="close" className={classes && classes.closeButton} onClick={onClose}>
                            <Close />
                        </IconButton>
                }
            </MuiDialogTitle>
    );
};

export default Title;
