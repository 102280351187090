import React from "react";
import {Button, Grid, LinearProgress, Typography} from "@material-ui/core";
import FinancialAndLiabilitiesContent from "./FinancialAndLiabilitiesContent";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../reducers";
import EnrollmentHelp from "../../basicInfo/EnrollmentHelp";
import { scroller } from "react-scroll";
import { useActions } from "../../../../../../actions";
import * as Actions from "../../../../../../actions/account";
import {makeAllFormValues} from "./financialAndLiabilitiesUtil";
import moment from "moment";
import numeral from "numeral";
import BackArrow from "../IliaApp/BackArrow";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {isLongForm, isWorksheet} from "../Utilities";
import { sleep } from "../../../../../../utils/commonUtils";
import { useOfferType } from "../../../../../../hooks/useOfferType";

export const payloadDataHandler = (values:any, type:string, isVerified:boolean) => {
    // commit id 65ba43433fe0fd0c694933aae48f072f6480aa72
    let payload:any = {};
    switch (type) {
        case "FINANCIAL_AGREEMENT":
            return {
                declareBankruptcy: values && values.declareBankruptcy === "Yes" ? 1 : 0,
                acceptFinancialCondition: values && values.acceptFinancialCondition === "Yes" ? 1 : values.acceptFinancialCondition === "No" ? 0 : null,
                pendingLawsuits: values && values.pendingLawsuits === "Yes" ? 1 : 0,
                pendingLawsuitsDesc:  values && values.pendingLawsuits === "Yes" ? values && values.pendingLawsuitsDesc : null,
                changesInIncomeDesc:  values && values.changesInIncome === "Yes" ? values && values.changesInIncomeDesc : null,
                changesInIncome: values && values.changesInIncome === "Yes" ? 1 : 0,
                financialAgreementDate: values && moment(values && values.financialAgreementDate).format('YYYY-MM-DDTHH:mm:ss'),
                bankruptcyDate: (values && values.declareBankruptcy === "Yes" && moment(values && values.bankruptcyDate).format('YYYY-MM-DDTHH:mm:ss')) || null,
                bankruptcyDischargeDate:  (values && values.declareBankruptcy === "Yes" && moment(values && values.bankruptcyDischargeDate).format('YYYY-MM-DDTHH:mm:ss')) || null,
                typeOfBankruptcy: values && values.declareBankruptcy === "Yes" ? values.typeOfBankruptcy ? values && values.typeOfBankruptcy : "Chapter 7 (debt forgiveness)" : null,
                bankruptcyDetails: values && values.bankruptcyDetails || "",
                currentBankruptcyStatus: values && values.declareBankruptcy === "Yes" ?  values && values.currentBankruptcyStatus ? values && values.currentBankruptcyStatus : "Open" : null,
                formType: "FINANCIAL_AGREEMENT"
            }
            break;

        case "ASSETS":
            values && Object.keys(values).map((key: string) => {
                if (key === "otherAssetDetails") {
                    payload[key] = values[key] ? values[key] + "" : "";
                } else {
                    payload[key] = values[key] ? numeral(values[key]).value() : null;
                }
            });
            Object.assign(payload, {formType: "ASSETS", verifyAssets: isVerified ? 1 : 0});
            /*payload["formType"] = "ASSETS";
            payload["verifyAssets"] = isVerified ? 1 : 0;
            console.log('ASSETS', payload)*/
            return payload;
            break;

        case "LIABILITIES":
            values && Object.keys(values).map((key: string) => {
                if (key === "otherLiabilitiesDetails") {
                    payload[key] = values[key] ? values[key] + "" : "";
                } else {
                    payload[key] = values[key] ? numeral(values[key]).value() : null;
                }
            });
            Object.assign(payload, {formType: "LIABILITIES", verifyLiabilities: isVerified ? 1 : 0});
            /*payload["formType"] = "LIABILITIES";
            payload["verifyLiabilities"] = isVerified ? 1 : 0;
            console.log('LIABILITIES', payload)*/
            return payload;
            break;

        case "EARNED":
            values && Object.keys(values).map((key: string) => {
                payload[key] = values[key] ? numeral(values[key]).value() : null;
            });
            Object.assign(payload, {formType: "EARNED", verifyEarnedIncome: isVerified ? 1 : 0});
           /* payload["formType"] = "EARNED";
            payload["verifyEarnedIncome"] = isVerified ? 1 : 0;
            console.log('EARNED', payload)*/
            return payload;
            break;

        case "UN_EARNED":
            values && Object.keys(values).map((key: string) => {
                payload[key] = values[key] ? numeral(values[key]).value() : null;
                return true;
            });
            Object.assign(payload, {formType: "UN_EARNED", verifyUnEarnedIncome: isVerified ? 1 : 0});
           /* payload["formType"] = "UN_EARNED";
            payload["verifyUnEarnedIncome"] = isVerified ? 1 : 0;
            console.log('UN_EARNED', payload)*/
            return payload;
            break;

        case "SOURCE_OF_FUNDS":
             return {
                annuityContract: values && values.annuityContract ? 1 : 0,
                earnedIncome: values && values.earnedIncome ? 1 : 0,
                liquidAssets: values && values.liquidAssets === "Yes" ? 1 : 0,
                liquidAssetsInheritance: values && values.liquidAssetsInheritance ? 1 : 0,
                liquidAssetsInheritanceDesc: values && values.liquidAssetsInheritanceDesc || "",
                liquidAssetsOther: values && values.liquidAssetsOther ? 1 : 0,
                liquidAssetsOtherDesc: values && values.liquidAssetsOtherDesc || "",
                loans:  values && values.loans ? 1 : 0,
                moneyMarketFund: values && values.moneyMarketFund ? 1 : 0,
                mutualFund: values && values.mutualFund ? 1 : 0,
                otherLifeInsurancePolicy: values && values.otherLifeInsurancePolicy ? 1 : 0,
                savings: values && values.savings ? 1 : 0,
                wealthGiftTransfer: values && values.wealthGiftTransfer ? 1 : 0,
                qualifiedFunds: values && values.qualifiedFunds,
                qualifiedFundsDesc: values && values.qualifiedFundsDesc || "",
                premiumFinancing: values && values.premiumFinancing,
                premiumFinancingDesc: values && values.premiumFinancingDesc || "",
                lenderFundsDesc: values && values.lenderFundsDesc || "",
                loanRadio: values && values.loanRadio || null,
                clientObligated: values && values.clientObligated && values.clientObligated === "Yes" ? true : values.clientObligated === "No" ? false : null,
                formType: "SOURCE_OF_FUNDS"
            }
            break;

        case "PREMIUM_FINANCING":
            return  {...values,  formType: "PREMIUM_FINANCING"};
            break;
    }
}

const BackButton = (props:{saveMe:Function, setPrevStep:Function}) => {
    const {saveMe, setPrevStep} = props;
    return (
            <Grid container>
                <Grid item xs={12} md={12} >
                    <Button className="backBtn enrollment" onClick={() => {
                        saveMe().then(setPrevStep())
                    }} variant="text"><ArrowBackIosIcon/>Back</Button>
                </Grid>
            </Grid>
    )
}

const RenderButtonFinancialPage = (props: {
    setIsSubmittedQuestions: Function,
    pageChecked: boolean,
    trigger: Function,
    btnTitle: string,
    disableButton: any
}) => {
    const { setIsSubmittedQuestions, pageChecked, trigger, btnTitle, disableButton } = props;
    return (
        <Button
            type={"button"}
            variant="contained"
                disabled={disableButton}
            color="primary"
            className="floatRight mb30"
            onClick={() => {
                setIsSubmittedQuestions(true);
                if (!pageChecked) {
                    return false;
                }
                let detailPageSubmitBtn: any = document.getElementsByClassName("detailPageSubmitBtn");
                if (detailPageSubmitBtn && detailPageSubmitBtn.length) {

                    for (let index in detailPageSubmitBtn) {
                        if (detailPageSubmitBtn[index] && detailPageSubmitBtn[index].click) {
                            detailPageSubmitBtn[index].click();
                        }
                    }
                    setTimeout(trigger, 1200)
                }}
            }
        >
            {btnTitle}
        </Button>
    )
}

const FinancialAndLiabilities = (props: any) => {
    const {isFromClientDetail, setPrevStep, setNextStep, strategySlug} = props;
    let userData = useSelector((state: RootState) => state.profile);
    const { user: { client, role } } = useSelector((state: RootState) => state.auth);
    let user = props.clientDetail || userData;
    const [isFinancialError, setIsFinancialError] = React.useState(true); // PLease make it TRUE when Enable financial module
    const [financialAgreementRef, setFinancialAgreementRef] = React.useState<any>(null);
    const [sourceOfFundsRef, setSourceOfFundsRef] = React.useState<any>(null);
    const [isSubmittedQuestions, setIsSubmittedQuestions] = React.useState<boolean>(false);
    const [attestRef, setAttestRef] = React.useState<any>(null);

    const [assetsVerifyRef, setAssetsVerifyRef] = React.useState<any>(null);
    const [assetsVerified, setAssetsVerified] = React.useState<any>(false);

    const [isSourceError, setIsSourceError] = React.useState<any>(false);

    const [liabilitiesVerifyRef, setLiabilitiesVerifyRef] = React.useState<any>(null);
    const [liabilitiesVerified, setLiabilitiesVerified] = React.useState<any>(null);

    const [earnedVerifyRef, setEarnedVerifyRef] = React.useState<any>(null);
    const [earnedVerified, setEarnedVerified] = React.useState<any>(null);

    const [unEarnedVerifyRef, setUnEarnedVerifyRef] = React.useState<any>(null);
    const [unEarnedVerified, setUnEarnedVerified] = React.useState<any>(null);

    const [realEstateVerifyRef, setRealEstateVerifyRef] = React.useState<any>(null);
    const [realEstateVerified, setRealEstateVerified] = React.useState<any>(null);

    const [businessEquityVerifyRef, setBusinessEquityVerifyRef] = React.useState<any>(null);
    const [businessEquityVerified, setBusinessEquityVerified] = React.useState<any>(null);
    const [isSaving, setIsSaving] = React.useState(false);
    const [disableButton, setDisableButton] = React.useState(false);

    const accountActions = useActions(Actions);
    let enrollment: any = (user && user.enrollment) || undefined;
    let envelopeId = enrollment && enrollment.envelopeId || "";
    let isChecked: boolean = false;
    if (enrollment) {
        isChecked = `${enrollment.enrollmentStatus}.${enrollment.enrollmentSubStatus}` > "1.21";
    }
    const [pageChecked, setPageChecked] = React.useState<boolean>(isChecked);

    const [financialAndLiabilitiesArr, setFinancialAndLiabilitiesArr] = React.useState<any>([]);
    const [saveRequest, sendSaveRequest] = React.useState<any>(Math.random());
    const formVal = makeAllFormValues();
    const [apiCounter, setApiCounter] = React.useState<number>(0);
    const financialAgreementFormRef = React.useRef<any>(null);
    const assetRef = React.useRef<any>(null);
    const liabilitiesRef = React.useRef<any>(null);
    const earnedIncomeRef = React.useRef<any>(null);
    const unearnedIncomePastYearRef = React.useRef<any>(null);
    const sourceOfFundsRefs = React.useRef<any>(null);
    const premiumFinancingRefs = React.useRef<any>(null);
    let offerType = useOfferType();
    const [formType, setFormType] = React.useState<string>("");
    if (offerType === "consent") {
        offerType = "gi";
    }


    let financialAgreementFormValues = financialAgreementFormRef && financialAgreementFormRef.current && financialAgreementFormRef.current.values;
    let financialAgreementFormErrors = financialAgreementFormRef && financialAgreementFormRef.current && financialAgreementFormRef && financialAgreementFormRef.current.errors;
    let assetFormValues = assetRef && assetRef.current && assetRef.current.values;
    let liabilitiesFormValues = liabilitiesRef && liabilitiesRef.current && liabilitiesRef.current.values;
    let earnedIncomeFormValues = earnedIncomeRef && earnedIncomeRef.current && earnedIncomeRef.current.values;
    let unearnedIncomeFormValues = unearnedIncomePastYearRef && unearnedIncomePastYearRef.current && unearnedIncomePastYearRef.current.values;
    let sourceOfFundsFormValues = sourceOfFundsRefs && sourceOfFundsRefs.current && sourceOfFundsRefs.current.values;
    let sourceOfFundsFormErrors = sourceOfFundsRefs && sourceOfFundsRefs.current && sourceOfFundsRefs && sourceOfFundsRefs.current.errors;
    let premiumFinancingValues = premiumFinancingRefs && premiumFinancingRefs.current && premiumFinancingRefs.current.values;
    const otherForm:boolean = (
        financialAgreementFormErrors && Object.keys(financialAgreementFormErrors).length > 0 ||
        sourceOfFundsFormErrors && Object.keys(sourceOfFundsFormErrors).length > 0 ||
        !assetsVerified ||
        !liabilitiesVerified
    );
    /* React.useEffect(()=>{
         setInterval(()=>{
             saveMe();
         }, 5000)
     },[])*/

    React.useEffect(() => {
        formVal.addVal(financialAndLiabilitiesArr);
    }, [financialAndLiabilitiesArr]);

    const scrollUp = (id: string) => {
        setIsSubmittedQuestions(false);
        scroller.scrollTo(id, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset:  -200
        });
    }

    React.useEffect(() => {
        if(!pageChecked && isSubmittedQuestions && attestRef.current){
                scrollUp(attestRef.current.id);
        }
    },[isSubmittedQuestions === true, pageChecked]);

    React.useEffect(()=> {
        if(isSourceError && isSubmittedQuestions && sourceOfFundsRef && sourceOfFundsRef.current){
            scrollUp("sourceOfFunds");
        }
    }, [isSourceError, sourceOfFundsRef, isSubmittedQuestions])

    React.useEffect(()=> {
        if(!assetsVerified && isSubmittedQuestions && assetsVerifyRef && assetsVerifyRef.current){
            scrollUp(assetsVerifyRef.current.id);
        }
    }, [assetsVerified, assetsVerifyRef, isSubmittedQuestions])

    React.useEffect(()=> {
        if(!liabilitiesVerified && isSubmittedQuestions && liabilitiesVerifyRef && liabilitiesVerifyRef.current){
            scrollUp(liabilitiesVerifyRef.current.id);
        }
    }, [liabilitiesVerified, liabilitiesVerifyRef, isSubmittedQuestions])

    React.useEffect(()=> {
        if(!earnedVerified && isSubmittedQuestions && earnedVerifyRef && earnedVerifyRef.current){
            scrollUp(earnedVerifyRef.current.id);
        }
    }, [earnedVerified, earnedVerifyRef, isSubmittedQuestions])

    React.useEffect(()=> {
        if(!unEarnedVerified && isSubmittedQuestions && unEarnedVerifyRef && unEarnedVerifyRef.current){
            scrollUp(unEarnedVerifyRef.current.id);
        }
    }, [unEarnedVerified, unEarnedVerifyRef, isSubmittedQuestions])

    React.useEffect(()=> {
        if(!realEstateVerified && isSubmittedQuestions && realEstateVerifyRef && realEstateVerifyRef.current){
            scrollUp(realEstateVerifyRef.current.id);
        }
    }, [realEstateVerified, realEstateVerifyRef, isSubmittedQuestions])

    React.useEffect(()=> {
        if(!businessEquityVerified && isSubmittedQuestions && businessEquityVerifyRef && businessEquityVerifyRef.current){
            scrollUp(businessEquityVerifyRef.current.id);
        }
    }, [businessEquityVerified, businessEquityVerifyRef, isSubmittedQuestions]);


    const autoSaveTrigger = () => {
        sendSaveRequest(Math.random());
    };
    const autoSaveEffect: any = () => {
        // sleep().then(saveMe);
    };

    React.useEffect(autoSaveEffect, [saveRequest]);

    const getStep = () => {
        if(strategySlug === "cashstream") {
            return (<Typography variant='caption'> Application 2/3: Financials </Typography>);
        }

        return (<Typography variant='caption'> Application 3/4: Financials </Typography>);
    }

    const handleSubmit = async() => {
        const allFormValArr = formVal.getVal();
        await accountActions.updateClientFinancialAndLiabilitiesFields(allFormValArr, user && user.client && user.client.id, isFromClientDetail);
    }

    const saveMe = async () => {
        if (isSaving) {
            return false;
        }
        setIsSaving(true);
        let payload:any = [];

        let financialAgreementPayload:any = payloadDataHandler(financialAgreementFormValues, "FINANCIAL_AGREEMENT", false);
        let assetPayload:any = payloadDataHandler(assetFormValues, "ASSETS", assetsVerified);
        let liabilitiesPayload:any = payloadDataHandler(liabilitiesFormValues, "LIABILITIES", liabilitiesVerified);
        let earnedIncomePayload:any = payloadDataHandler(earnedIncomeFormValues, "EARNED", earnedVerified);
        let unearnedIncomePayload:any = payloadDataHandler(unearnedIncomeFormValues, "UN_EARNED", unEarnedVerified);
        let sourceOfFundsPayload:any = payloadDataHandler(sourceOfFundsFormValues, "SOURCE_OF_FUNDS", false);
        let premiumFinancingPayload:any = payloadDataHandler(premiumFinancingValues, "PREMIUM_FINANCING", false);

        payload = [sourceOfFundsPayload, financialAgreementPayload, assetPayload, liabilitiesPayload, earnedIncomePayload, unearnedIncomePayload, premiumFinancingPayload];

        let res:boolean = await accountActions.updateClientFinancialAndLiabilitiesFields(payload, user && user.client && user.client.id, isFromClientDetail);

        setApiCounter(apiCounter + 1)

        if (res && apiCounter === 1) {
            setApiCounter(apiCounter - 1);
            await accountActions.getProfileDetails();
        }

        sleep().then(() => setIsSaving(false));
    }

    const checksForLongForm = () => {
        if(
            otherForm ||
            earnedIncomeRef && earnedIncomeRef.current && earnedIncomeRef && earnedIncomeRef.current.errors && earnedIncomeRef && Object.keys(earnedIncomeRef.current.errors).length > 0 ||
            premiumFinancingRefs && premiumFinancingRefs.current && premiumFinancingRefs && premiumFinancingRefs.current.errors && premiumFinancingRefs && Object.keys(premiumFinancingRefs.current.errors).length > 0 ||
            !earnedVerified ||
            !unEarnedVerified

        ) {
            return false;
        } else {
            handleSubmit().then(()=>{});
            setNextStep();
        }
    }

    const checksForOtherForm = () => {
        if(otherForm){
            return false;
        } else {
            handleSubmit().then(()=>{});
            setNextStep();
        }
    }

    const trigger:Function = isLongForm(offerType) || isWorksheet(offerType)  ? checksForLongForm:checksForOtherForm;

    return (
        <>
            {offerType.trim() === "ilia_app" ? <BackArrow setPrevStep={setPrevStep} envelopeId={envelopeId} />:<BackButton saveMe={saveMe} setPrevStep={setPrevStep}/>}
            <div className="enrollmentContent inner mt30">
                <Grid container>
                    <Grid item xs={12} md={11} lg={9} xl={9} className="textLeft">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {getStep()}
                                <LinearProgress className="landingLinearProgress" variant="determinate" value={40}/>
                                <Typography variant="h3" className="mt50 mt20Small">
                                    Financial Questions
                                </Typography>
                                <Typography variant="body1" className="textBlueDark">
                                    Please answer the below questions from your carrier.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={9}>
                        <FinancialAndLiabilitiesContent
                            clientDetail={user}
                            setPageChecked={setPageChecked}
                            isFromClientDetail={isFromClientDetail}
                            setFinancialAgreementRef={setFinancialAgreementRef}
                            setSourceOfFundsRef={setSourceOfFundsRef}
                            setAttestRef={setAttestRef}
                            setAssetsVerifyRef={setAssetsVerifyRef}
                            setAssetsVerified={setAssetsVerified}
                            setLiabilitiesVerifyRef={setLiabilitiesVerifyRef}
                            setLiabilitiesVerified={setLiabilitiesVerified}
                            setEarnedVerifyRef={setEarnedVerifyRef}
                            setEarnedVerified={setEarnedVerified}
                            setIsSourceError={setIsSourceError}
                            setUnEarnedVerifyRef={setUnEarnedVerifyRef}
                            setUnEarnedVerified={setUnEarnedVerified}
                            setRealEstateVerifyRef={setRealEstateVerifyRef}
                            setRealEstateVerified={setRealEstateVerified}
                            setBusinessEquityVerifyRef={setBusinessEquityVerifyRef}
                            setBusinessEquityVerified={setBusinessEquityVerified}
                            setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                            financialAndLiabilitiesArr={financialAndLiabilitiesArr}

                            financialAgreementFormRef={financialAgreementFormRef}
                            assetRef={assetRef}
                            liabilitiesRef={liabilitiesRef}
                            earnedIncomeRef={earnedIncomeRef}
                            unearnedIncomePastYearRef={unearnedIncomePastYearRef}
                            premiumFinancingRefs={premiumFinancingRefs}
                            sourceOfFundsRefs={sourceOfFundsRefs}
                            autoSaveTrigger={autoSaveTrigger}
                            sendSaveRequest={sendSaveRequest}
                            offerType={offerType}
                            setFormType={setFormType}
                            setDisableButton={setDisableButton}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <EnrollmentHelp/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={9} xl={9} >
                        <RenderButtonFinancialPage btnTitle="Save and Continue"
                                                   setIsSubmittedQuestions={setIsSubmittedQuestions}
                                                   pageChecked={pageChecked} trigger={trigger}
                                                   disableButton={disableButton} />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default FinancialAndLiabilities;