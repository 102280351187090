import React from "react";
import FocusError from "../../../../../../components/FocusError";
import { Button } from "@material-ui/core";
import QuestionsListing from "./QuestionsListing";
import { FormikValues } from "../../../../../../components/formikFormInputs/FormWrapper";
import { isFunc, sleep } from "../../../../../../utils/commonUtils";
import { FormikWrapper } from "../../../../../../components/formikFormInputs";


type PropsType = {
    buttonRef: any,
    clientSigned: boolean,
    disableSaveButton: Function,
    enableSaveButton: Function
    getPolicyGrids: Function,
    initialValues: any,
    insuredQuestionPayload?: Function,
    insuredQuestions?:any,
    insureRef: any,
    isAssociation: boolean,
    isCorporation: boolean,
    isKeyman:boolean,
    isFromClientDetail: boolean,
    nationalLifeId: number,
    offerType: string,
    pageChecked: boolean
    saveMe?: Function
    setActionItem: Function | undefined,
    setAutoSaveTrigger?: Function
    setInsuranceError: Function,
    setIsSubmittedQuestions: Function,
    setNextStep: Function,
    setOpenInforceLifeAlertPopup: Function,
    setSaveApplicationQuestions?: Function
    showComponent: Function,
    updateClientFields: Function,
    user: any,
	validationSchema: any,
}
function InsuredQuestionsForm(props: PropsType) {
    const {
        buttonRef,
        clientSigned,
        disableSaveButton,
        enableSaveButton,
        getPolicyGrids,
        initialValues,
        insuredQuestionPayload,
        insuredQuestions,
        insureRef,
        isAssociation,
        isCorporation,
        isFromClientDetail,
        nationalLifeId,
        offerType,
        pageChecked,
        saveMe,
        setActionItem,
        setAutoSaveTrigger,
        setInsuranceError,
        setIsSubmittedQuestions,
        setNextStep,
        setOpenInforceLifeAlertPopup,
        isKeyman,
        setSaveApplicationQuestions,
        showComponent,
        updateClientFields,
		user,
        validationSchema,
    } = props;
	const callingAutoSave = () => {
        sleep()
                .then(() => {
                    if (setAutoSaveTrigger && isFunc(setAutoSaveTrigger)) {
                        setAutoSaveTrigger(Math.random());
                    }
                })
	};
    const InsuranceQuestionsFormChild = (fProps: any) => {
        const {
			errors,
			handleBlur,
			handleChange,
			handleSubmit,
			setErrors,
			setFieldTouched,
			setFieldValue,
			touched,
			values,
		} = fProps;
        return (
			<form className="floatLeft w100 mt60" onBlur={callingAutoSave} onSubmit={handleSubmit}>
				<FocusError offset={true}/>
				<QuestionsListing
					// callingAutoSave={callingAutoSave}
					clientSigned={clientSigned}
					enableSaveButton={enableSaveButton}
					errors={errors}
					getPolicyGrids={getPolicyGrids}
					handleBlur={handleBlur}
					handleChange={handleChange}
					isAssociation={isAssociation}
					isCorporation={isCorporation}
					isKeyman={isKeyman}
					isFromClientDetail={isFromClientDetail}
					nationalLifeId={nationalLifeId}
					offerType={offerType}
					setErrors={setErrors}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					setOpenInforceLifeAlertPopup={setOpenInforceLifeAlertPopup}
					setSaveApplicationQuestions={setSaveApplicationQuestions}
					showComponent={showComponent}
					touched={touched}
					user={user}
					values={values}
				/>
				<Button
					variant="contained"
					color="primary"
					className="detailPageSubmitBtn"
					type={"submit"}
					onClick={() => setIsSubmittedQuestions(true)}
					ref={buttonRef}
					style={{ display: "none" }}
				>
				</Button>
			</form>
        )
    };
	const onSubmit = async(values: FormikValues) => {
		if (!pageChecked && !isFromClientDetail && offerType !== "gi") {
			return false;
		}

		if (saveMe && isFunc(saveMe)) {
			saveMe(values);
		}

		if (!isFromClientDetail) {
			setNextStep();
		}
	};
    const formProps = {
        children: InsuranceQuestionsFormChild,
		enableReinitialize: true,
		initialValues: initialValues,
		innerRef: insuredQuestions,
		onSubmit,
		validateOnChange: true,
		validationSchema,
    };

    return (
            <FormikWrapper {...formProps} />
    )
}
export default InsuredQuestionsForm;