import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Button,
    IconButton,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "../dashboard/TableQuickView";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { IrrStatus } from "../../../constants";
import { checkIMOEditAllowedAdminIMO, pez } from "../../../utils/commonUtils";
import {isAdmin} from '../../../components/navigation/AdminNavigation';

export type ImoRow = {
    irr: any;
    id?: number;
    name: string;
    carriers: { carrierName: string; code: string; }[];
    imoClass?: string;
}

type ImosTableProps = {
    rows: ImoRow[];
    onAddNewImo: () => void;
    onDelete?: (row: any) => void;
    onEdit?: (row: any) => void;
    label: string;
}

export const ImoCATable = ({ rows, onAddNewImo, onDelete, onEdit, label}: ImosTableProps) => {
    const classes = useStyles();
    const { user } = useSelector((state: RootState) => state.auth);
    // is admin and has permission, imo also
    const _isAdmin = checkIMOEditAllowedAdminIMO(user);
    const authUser = useSelector((state: RootState) => state.auth.user);

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell>IMO</TableCell>
                        <TableCell>Carriers</TableCell>
                        <TableCell>Agent Number</TableCell>
                        {
                                _isAdmin && user.role !== 3 &&
                                <TableCell>Actions</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.length === 0
                        && <TableRow>
                                <TableCell align="center" colSpan={_isAdmin ? 4 : 3}>
                                    <Typography variant="caption">
                                        One IMO and carrier is required.
                                        {
                                            _isAdmin &&
                                            <Button color="primary" onClick={() => onAddNewImo()}>
                                                {label}
                                            </Button>
                                        }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                    }
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row.name}
                                {
                                        (
                                                row.id === null ||
                                                (
                                                        row.irr && row.irr.status &&
                                                        [IrrStatus.PENDING, IrrStatus.REJECTED].includes(row.irr.status)
                                                )
                                        ) &&
                                        <span className="colorRed ml15">
                                            ({(row.id === null ? IrrStatus.PENDING : row.irr.status).toLowerCase()} approval)
                                        </span>
                                }
                            </TableCell>
                            <TableCell>{row.carriers.map(carrier => carrier.carrierName).join(", ")}</TableCell>
                            <TableCell>{row.carriers.map(carrier => pez(carrier.code, 'N/A')).join(', ')}</TableCell>
                            {
                                    _isAdmin && user.role !== 3 &&
                                    <TableCell align="center">
                                        {
                                                ((user.role !== 4) || (row.irr && row.irr.status && row.irr.status) === "PENDING") && (
                                                        <IconButton
                                                                aria-label="edit_agent_imo"
                                                                onClick={() => onEdit && onEdit({...row, index})}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                )
                                        }
                                        {
                                                isAdmin(authUser) && (
                                                        <IconButton
                                                                aria-label="remove_agent_imo"
                                                                color="secondary"
                                                                style={{ color: '#FF1744' }}
                                                                onClick={() => onDelete && onDelete(row)}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                )
                                        }
                                    </TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ImoCATable