import React from "react";
import { Button, PageTitle } from "./formInputs";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import loginlogo from "../styles/images/logo-ilia.png";
import login from "../styles/images/login.png";
import { useActions } from "../actions";
import * as Actions from "../actions/auth";
import SetGroupParticipantPassword from "./SetGroupParticipantPassword";
import { Formik } from "formik";
import { TextFieldCommon, TextField, ErrorCommon, DateInputField } from "../components/formikFormInputs";
import { LOGO_BASE_URL } from "../constants";
import SystemError from "./client/landingPage/v2/SystemError";
import { asDbDateFormat, DATE_FORMAT_DOB_SAVE } from "../utils/dateUtils";
import moment from "moment";
import {validateDate} from "../utils/validation";
import { createStyles, makeStyles } from "@material-ui/core/styles";

interface SubmitVal {
    lastName?: string,
    email?: string,
    employeeSSN?: string,
    phoneNumber?: string,
    employeeIdNumber?: string,
    dob?: string,
    [authType: string]: string | undefined,
}

type props = {
    children: any
}
const useStyles = makeStyles((theme) =>
    createStyles({
        logInWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            verticalAlign: 'middle',
            height: '100%',
            width: '100%',
            "&::after": {
                content: "\"\"",
                position: "absolute",
                background: "#3971EC",
                top: 0,
                bottom: 0,
                right: 0,
                width: "42%",
                [theme.breakpoints.down("sm")]: {
                    position: "inherit",
                    background: "transparent",
                    width:'auto',
                },
            },

        },
        textWhite: {
            color: "#FFFFFF",
        },
        textCenter: {
            textAlign: "center",
        },
        mt10: {
            marginTop: 10,
        },
        mr30: {
            marginRight: 30,
        },
        loginCard: {
            margin: "auto",
            width: 865,
            boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
            background: "transparent !important",
            left: 0,
            right: 0,
            zIndex: 999,
            border: 0,
            padding: "2%",
            position:'relative',
            [theme.breakpoints.down("sm")]: {
                background: "#ffffff !important",
                width: "100%",
                margin:'0 15px',
            },
        },
        muiCardContent: {
            padding: 15,
            float: "left",
            width: "100%",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                padding: "20px 16px 0",
            },
        },
        loginForm: {
            padding: "10px 15px 15px",
            float: "left",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                padding: "30px 0 15px",
            },
        },
        margin0: {
            margin: 0,
        },
        titleMain: {
            fontSize: 26,
            lineHeight: "34px",
            textAlign: "center",
            margin: 0,
            float: "right",
        },
        dpNone: {
            [theme.breakpoints.down(992)]: {
                display: "none",
            },
        },
        floatRight: {
            float: "right",
        },
        imgResponsive: {
            maxWidth: "100%",
            height: "auto",
            display: "block",
            marginTop: "25%",
        },
        clickableLink: {
            display: "inline-flex",
            alignItems: "center",
            verticalAlign: "middle",
        },
        clickableLinksvg: {
            float: "left",
        },
        mr10: {
            marginRight: 10,
        },
        mt15: {
            marginTop: 15,
        },
    }),
);
function GroupParticipantAuth(props: props) {
    const { children } = props;
    const [groupParticipantDetail, setGroupParticipantDetail] = React.useState(undefined);
    const authActions = useActions(Actions);
    const [authType, setAuthType] = React.useState("email");
    const [systemError, setSystemError] = React.useState<boolean>(false);
    const classes = useStyles();
    React.useEffect(() => {
        if (children.groupDetail && children.groupDetail.authenticType) {
            if (children.groupDetail.authenticType === "Last 4 digit of phone number")
                setAuthType("phoneNumber");
            if (children.groupDetail.authenticType === "Last 4 digit of SSN")
                setAuthType("employeeSSN");
            if (children.groupDetail.authenticType === "Email")
                setAuthType("email");
            if (children.groupDetail.authenticType === "Employee ID number")
                setAuthType("employeeIdNumber");
            if (children.groupDetail.authenticType === "DOB") {
                setAuthType("dob");
            }
        }
    }, [children]);

    if (groupParticipantDetail) {
        const groupAndGroupParticipantDetail = Object.assign(children, { groupParticipantDetail });
        return (
                <SetGroupParticipantPassword>
                    {{
                        groupAndGroupParticipantDetail,
                        history: children.history,
                    }}
                </SetGroupParticipantPassword>
        );
    }

    const validate = ((value: SubmitVal) => {
        const errors: {
            lastName?: string,
            email?: string,
            phoneNumber?: string,
            employeeSSN?: string,
            employeeIdNumber?: string,
            dob?: string
        } = {};
        if (!value.lastName) {
            errors.lastName = "Required";
        }
        if (!value[authType]) {
            switch (authType) {
                case "employeeSSN":
                    errors.employeeSSN = "Required";
                    break;
                case "email":
                    errors.email = "Required";
                    break;
                case "phoneNumber":
                    errors.phoneNumber = "Required";
                    break;
                case "employeeIdNumber":
                    errors.employeeIdNumber = "Required";
                case "dob":
                    errors.dob = "Required";
                    break;
            }
        }
        if (authType === "email" && value[authType] && value.email && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)))
            errors.email = "Invalid email address";

        if (authType === "dob" && value[authType] && value.dob && validateDate(value.dob) === "Invalid Date")
            errors.dob = "Invalid date";

        return errors;
    });

    const intitialValues = {
        lastName: "",
        email: "",
        employeeSSN: "",
        phoneNumber: "",
        employeeIdNumber: "",
        dob: ""
    };

    const submitFormik = async (values: SubmitVal, setSubmitting: Function, resetForm: Function) => {
        if (authType === "dob") {
            values.dob = asDbDateFormat(moment(values.dob), DATE_FORMAT_DOB_SAVE);
        }
        const resp = await authActions.authCompanyLink(Object.assign(values, {
            companyId: children.groupDetail.id,
            authType,
        }));
        resetForm();
        if (resp && resp.id) {
            setGroupParticipantDetail(resp);
            setSystemError(false);
        }
        if (resp && resp.payload && resp.payload.statusCode >= 400) {
            setSystemError(true);
            return false;
        }
    };

    const fieldObj = [
            {
                name: "lastName",
                placeholder: "LAST NAME",
                id: "lastName",
                component: TextField,
            },
        {
            name: authType,
            placeholder: (authType === "dob" ? "DOB(MM/DD/YYYY)" : (children.groupDetail && children.groupDetail.authenticType.toUpperCase())),
            id: authType,
            component: (authType === "dob" ? DateInputField : TextField),
        }
    ];

    return (
        <div className={classes.logInWrap}>
            <PageTitle title="Login Page" />
            <Card className={classes.loginCard}>
                <CardContent className={classes.muiCardContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                            {
                                children.groupDetail &&
                                children.groupDetail.strategies.slug === "cashstream" &&
                                children.groupDetail.user.image &&
                                <img className="mt15 mb15" alt="grouplogo"
                                     src={LOGO_BASE_URL + children.groupDetail.user.image} />
                            }
                            <img alt="loginLogo" src={loginlogo} />
                            {children.groupDetail && children.groupDetail.strategies.slug !== "cashstream" && children.groupDetail.user.image &&
                                <img className="mt15" alt="grouplogo"
                                     src={LOGO_BASE_URL + children.groupDetail.user.image} />}
                            <Typography variant="h5" className="mb20 mt5">
                                {
                                    children.groupDetail && children.groupDetail.strategies.slug !== "cashstream" &&
                                    <p className={"groupLinkAuth"}>Hello {children.groupDetail.companyName} Member</p>
                                }
                                <span>
											Please confirm the details below to
											authenticate your account
										</span>
                            </Typography>
                            <Formik
                                initialValues={intitialValues}
                                validate={values => validate(values)}
                                onSubmit={(values, {
                                    setSubmitting,
                                    setErrors,
                                    setStatus,
                                    resetForm,
                                }) => submitFormik(values, setSubmitting, resetForm)}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      dirty,
                                      setFieldValue,
                                  }) =>
                                    (
                                        <form className={classes.loginForm + " " + classes.margin0}
                                              onSubmit={handleSubmit}>
                                            {
                                                fieldObj.map((field, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="mt15">
                                                                <TextFieldCommon
                                                                    {...field}
                                                                    values={values}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        setSystemError(false);
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <ErrorCommon errors={errors}
                                                                             name={field.name}
                                                                             touched={touched} />
                                                            </div>
                                                        </React.Fragment>);
                                                })
                                            }
                                            <Button
                                                fullWidth={true}
                                                type="submit"
                                                label="Authenticate"
                                                variant="contained"
                                                color="primary"
                                                className="btnPrimary mt15"
                                                disabled={(Object.keys(errors).length !== 0) || !dirty || isSubmitting}
                                                loading={isSubmitting}
                                            />
                                            {
                                                systemError &&
                                                <SystemError accountType="GROUP"
                                                             groupDetails={children.groupDetail} />
                                            }
                                        </form>
                                    )
                                }
                            </Formik>
                        </Grid>
                        <Grid item xs={12} md={5} lg={5} className={classes.dpNone}>

                            <img alt="login" className={classes.floatRight + " " + classes.imgResponsive} src={login} />

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}

export default GroupParticipantAuth;