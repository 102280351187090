import {useSelector} from "react-redux";
import {RootState} from "../reducers";
import {ATLANTIC_HEALTH} from "../constants";

export const useCheckAtlantic = (stateObjectName = "") => {
    let role, companyLinkUrl;
    const {clientAccount, auth} = useSelector((state: RootState) => state)
    switch (stateObjectName) {
        case "clientAccount":
            role = clientAccount && clientAccount.role;
            companyLinkUrl = clientAccount && clientAccount.client && clientAccount.client.company && clientAccount.client.company.companyLinkUrl;
            return (ATLANTIC_HEALTH.includes(companyLinkUrl) && role === 6)
        default:
            role = auth && auth.user && auth.user.role;
            companyLinkUrl = auth && auth.user && auth.user.client && auth.user.client.company && auth.user.client.company.companyLinkUrl;
            return (ATLANTIC_HEALTH.includes(companyLinkUrl) && role === 6)
    }
}