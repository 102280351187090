import React from "react";
import {Link, useRouteMatch} from "react-router-dom";
import {Tab, TabProps} from "@material-ui/core";
import {a11yProps} from "../../utils/commonUtils";

const TabLink = (props) => {
    const match: any = useRouteMatch();
    const {value} = props;
    const linkTabProps: TabProps = {
        component: Link,
        to: `${match.url}/${value}`,
        ...props,
        ...a11yProps(value),
    };

    return (
            <Tab {...linkTabProps} />
    );
};

export default TabLink;