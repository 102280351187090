import {Tranche, TrancheList, TrancheNotes} from "../model/tranche";
import {ActionType, PagingParams} from "../model";
import {setAlert, setErrorAlert} from "./alert";


export const addTranche = (data: Tranche) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post('tranche', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Tranche Saved Successfully.", statusCode: 200}
        });
        return dispatch({type: ActionType.ADD_TRANCHE, payload: {}});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

export const updateTranche = (data: Tranche, onlyStatus?: boolean) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('tranche', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: `Tranche ${onlyStatus ? 'Status ' : ''}Updated Successfully.`, statusCode: 200}
        });
        return;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};


export const deleteTranche = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.delete(`tranche/${id}`, {}, {});
        let tranches: TrancheList = getState().tranches;
        const index = tranches.rows.findIndex(p => p.id === id);
        if (index >= 0) {
            tranches.rows.splice(index, 1);
        }
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Tranche Deleted Successfully.", statusCode: 200}
        });
        return dispatch({type: ActionType.LIST_TRANCHES, payload: Object.assign({}, tranches)});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const getTrancheById = (id: number) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        if (isNaN(id)) {
            return;
        }

        const res = await api.get(`tranche/${id}`);
        if (res.data) {
            Object.assign(res.data, {status: Number(res.data.status)});
        }
        return dispatch({payload: res.data, type: ActionType.GET_TRANCHE_PK});
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

/**
 * Flushes tranche (not tranche list) state from redux, Previously emptyTrancheForm
 */
export const flushTranche = () => async (dispatch: Function) => {
    try {
        return dispatch({payload: {}, type: ActionType.FLUSH_TRANCHE});
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

export const flushTranches = () => async (dispatch: Function) => {
    try {
        return dispatch({payload: {}, type: ActionType.FLUSH_TRANCHES});
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};


export const listTranches = (query: PagingParams) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche', {}, query);
        if (res && res.rows) {
            const rows = res.rows.map(t => ({...t, status: Number(t.status)}))
            Object.assign(res.rows, rows);
        }
        return dispatch({type: ActionType.LIST_TRANCHES, payload: res});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const saveTrancheNumberForClient = (data: {
    trancheNumber: string | number,
    clientId: number
}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('account/client/update-tranche-number', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Tranche Number Saved Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const getTrancheListForClient = (query: PagingParams) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/client', {}, query);
        return res;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getAllTranches = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/all', {}, {});
        return res;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const trancheCurrent = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/current');
        return res;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const listUpcomingTranche = (count: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/important', {}, {rowsPerPage: count});
        return res;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const getUpcomingCurrentYearTranche = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/get-upcoming-current-year-tranche');
        return dispatch({type: ActionType.LIST_TRANCHES, payload: res});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

interface findTrancheNotesParams extends PagingParams {
    trancheId: number;
}

export const fetchTrancheNotes = (qry: findTrancheNotesParams) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.get('tranche/notes', {}, qry);
        return dispatch({payload: res.data, type: ActionType.LIST_TRANCHE_NOTES});
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

export const fetchTrancheNoteByPk = (id: number) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        if (isNaN(id)) {
            return;
        }

        const res = await api.get(`tranche/notes/${id}`);
        return dispatch({payload: res.data, type: ActionType.GET_TRANCHE_NOTE_PK});
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

export const addTrancheNote = (payload: Partial<TrancheNotes>) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.post('tranche/notes', payload);
        dispatch({payload: res.data, type: ActionType.ADD_TRANCHE_NOTE});
        return dispatch(setAlert(res.message, 'success'));
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

export const updateTrancheNote = (payload: Partial<TrancheNotes>) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.put('tranche/notes', payload);
        dispatch({type: ActionType.PUT_TRANCHE_NOTE});
        return dispatch(setAlert(res.message, 'success'));
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

export const removeTrancheNote = (id: number) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.delete('tranche/notes', {id});
        dispatch({type: ActionType.DEL_TRANCHE_NOTE});
        return dispatch(setAlert(res.message, 'success'));
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

export const fetchTrancheWorkflowUsers = (trancheNo: number, search: string) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.get(`tranche/clients/${trancheNo}`, {}, !!search ? {search} : {});
        return dispatch({payload: res.data, type: ActionType.FETCH_TRANCHE_WORKFLOW});
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode || err.status));
    }
};

export const fetchTrancheUnassignedUsers = () => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.get('tranche/unassigned-clients');
        return dispatch({payload: res.data, type: ActionType.FETCH_TRANCHE_UNASSIGNED_USERS});
    } catch (e) {
        return dispatch(setErrorAlert(e.message, e.statusCode || e.status));
    }
};

export const getNextTranche = (selectedTrancheNo: number) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.get('tranche/get-next-upcoming', {}, {selectedTrancheNo});
        dispatch({payload: res.data, type: ActionType.FETCH_NEXT_TRANCHE});
        return res.data;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode || err.status));
    }
}

export const addTrancheUsers = (payload: {clientIds: number[], trancheNo: number}) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.put('tranche/assign-clients', payload);
        dispatch(setAlert(res.message, 'success'));
        return dispatch({payload: res.data, type: ActionType.TRANCHE_ASSIGNED_USERS});
    } catch (e) {
        return dispatch(setErrorAlert(e.message, e.statusCode || e.status));
    }
};

export const moveToNextTranche = (payload: {clientIds: number[], trancheNo: number}) => async (dispatch: Function, _store: Function, api: any) => {
    try {
        const res = await api.post('tranche/move-to-next', payload);
        dispatch(setAlert(res.message, 'success'));
        return dispatch({payload: res.data, type: ActionType.MOVE_TO_NEXT_TRANCHE});
    } catch (e) {
        return dispatch(setErrorAlert(e.message, e.statusCode || e.status));
    }
};

export const flushTrancheNotes = () => async (dispatch: Function) => {
    try {
        return dispatch({payload: {}, type: ActionType.FLUSH_TRANCHE_NOTES});
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

export const flushTrancheNote = () => async (dispatch: Function) => {
    try {
        return dispatch({payload: {}, type: ActionType.FLUSH_TRANCHE_NOTE});
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

export const flushTrancheWorkflowUsers = () => async (dispatch: Function) => {
    return dispatch({payload: [], type: ActionType.FLUSH_TRANCHE_WORKFLOW});
};

export const flushTrancheUnassignedUsers = () => async (dispatch: Function) => {
    return dispatch({payload: [], type: ActionType.FLUSH_TRANCHE_UNASSIGNED_USERS});
};