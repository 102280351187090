import {
    Grid,
    Typography,
} from "@material-ui/core";
import * as React from "react";
import { TextField, Label } from "./formInputs";
import { Field } from "redux-form";
import PrimaryContactDetail from "./PrimaryContactDetail";
import { email, required } from "../utils/validation";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

type Props = {
    page: string,
    dispatch: Function,
    emailExist?: boolean,
    setEmailExist?: Function,
    setEmailRef?: Function,
    setEmailBlackList?: Function,
    emailBlackList?: boolean
}
export default function AddTrustee(props: Props) {
    const {emailExist, setEmailExist, setEmailRef, setEmailBlackList, emailBlackList} = props;
    const formName = props.page;
    const auth = useSelector((state: RootState) => state.auth);
    const fieldObj = [{
        label: "Trustee Name",
        name: "trustee.trusteeName",
        component: TextField,
        placeholder: "enter trustee name",
        validate: [required]
    }, {
        label: 'Trustee Email',
        name: "trustee.email",
        component: TextField,
        placeholder: "email@domain.com (FOR DOCS TO BE SEND TO)",
        validate: [email, required]
    }];
    return (
        <React.Fragment>
            <Grid item xs={12} sm={12} className="pb0">
                <Typography variant="subtitle1" component="strong" className="floatLeft mb10 colorBlue">
                    Trustee Details
				</Typography>
            </Grid>
            {
                fieldObj.map((row, index) => {
                    return <Grid item xs={12} sm={6} md={6} className="mb10" key={index}>
                        <Field
                            fullWidth
                            label={<Label label={row.label} required={true} />}
                            name={row.name}
                            placeholder={row.placeholder}
                            component={row.component}
                            validate={row.validate}
                        />
                    </Grid>
                })
            }
            <Grid item xs={12} sm={12} className="pb0 ">
                <Typography variant="subtitle1" component="strong" className="floatLeft mb10 colorBlue">
                    Trustee Primary Contact
				</Typography>
            </Grid>
            <PrimaryContactDetail
                setEmailRef={setEmailRef}
                emailExist={emailExist}
                setEmailExist={setEmailExist}
                disabledEmail = {auth.user.role === 2 && formName === 'EditAccountPage' ? true:false}
				disabledPhone = {auth.user.role === 2 && formName === 'EditAccountPage' ? true:false}
                setEmailBlackList={setEmailBlackList}
                emailBlackList={emailBlackList}
            />
        </React.Fragment>
    );
}
