import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import StrategyName from "../StrategyName";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import InfoIcon from "@material-ui/icons/Info";
import { trustFeeText } from "../../../constants";
import NumberFormat from "react-number-format";


function Assumptions(props: any) {
    const { user } = useSelector((state: RootState) => state.auth);
    const { strategyName, strategySlug, estimationData } = props;
    let growthRate: number | undefined = (user && user.client && user.client.carrier && user.client.carrier.growthRate) || (user && user.demoCarrier && user.demoCarrier.growthRate);
    if (strategySlug === "kaizen") {
        return <>
            <Grid container spacing={3} className="mt20">
                <Grid item xs={12} md={8}>
                    <Typography className="txtGray f12 floatLeft w100" component="div">
                        <Typography component="div" className="txtGray f12 mb0" style={{lineHeight: '20px'}} ><b>Assumptions Used
                            for <StrategyName strategyName={strategyName}/> and Alternative Choices:</b>
                                <Tooltip
                                        color="primary"
                                        title="All assumptions are subject to change and are provided for illustrative purposes only. Actual results may be more or less favorable. This presentation is not valid unless accompanied by a complete insurance company illustration. Please see the full illustration for guaranteed values and other important information."
                                        arrow>
                                    <IconButton className="pl0" aria-label="Info">
                                        <InfoIcon className="f18"/>
                                    </IconButton>
                                </Tooltip>
                            (Illustrated Growth Rate: <NumberFormat
                                    value={growthRate}
                                    displayType={"text"}
                                    decimalScale={2}
                                    thousandSeparator={false}
                                    suffix={"%"}/>)
                        </Typography>
                        <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>IUL insurance policy
                            distributions begin at the latter of age 65 or after the lender loan has been repaid
                            (typically in the 15th policy year) and continue through age 90.</Typography>
                        <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>Long Term Capital Gains Rate of {estimationData.assumption_long_term_capital_rate}%.</Typography>
                        <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>Income tax rate of {estimationData.assumption_income_tax_rate}%.</Typography>
                        <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>Participation in Kai-Zen
                            requires a minimum contribution amount of $21,350 annually, which includes the annual trust fees of
                            $1,350 and a portion of the IUL insurance policy premium. Kai-Zen also requires a
                            participant trust to be established as part of the strategy. The total IUL insurance premium
                            is paid by the trust to the life insurer once you and the commercial lender contribute
                            sufficient funds to pay the IUL insurance policy premium to the trust.</Typography>
                    </Typography>
                </Grid>
            </Grid>
        </>;
    }

    return <>
        <Grid container spacing={3} className="mt20">
            <Grid item xs={12} md={8}>
                <Typography className="txtGray f12">
                    <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}><b>Assumptions Used
                        for <StrategyName strategyName={strategyName}/> and Alternative Choices:</b></Typography>
                    <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>Growth Rate
                        of {growthRate}%.</Typography>
                    <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>Distributions beginning at
                         the later of age {estimationData.assumption_age_begins} or after the lender loan has been
                        repaid
                        (typically the 15th plan year) and continue through
                        age {estimationData.assumption_age_ends}.</Typography>
                    <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>Long term capital gains
                        rate of {estimationData.assumption_long_term_capital_rate}%.</Typography>
                    <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>Income tax rate
                        of {estimationData.assumption_income_tax_rate}%.</Typography>
                     {estimationData.assumption_state_income_tax_rate ?
                             <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>
                                 State Income Tax Rate of {estimationData.assumption_state_income_tax_rate}%.
                             </Typography> : ""}
                     <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>The after tax and tax
                         deferred investments assume a management fee
                         of {estimationData.assumption_management_fee}%.</Typography>
                     <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>The index policies include
                         all costs of insurance and fees.</Typography>
                     <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>Policy Distributions shown
                         using tax-free policy loans.</Typography>
                     <Typography className="txtGray f12 mb0"
                                 style={{lineHeight: '20px'}}>{strategySlug === 'trizen' ?
                             <>Participation in <StrategyName strategyName={strategyName}/> requires a minimum
                                 contribution amount of $20,000 and a participant trust to be established as part of
                                 the plan. An additional {trustFeeText(user && user.client)} per year will be added to your five-annual
                                 contributions. (The trust pays the premiums and monitors the policy until the loan
                                 repayment in approximately the 15th year.).</> : ('')}</Typography>
                     <Typography className="txtGray f12 mb0"
                                 style={{lineHeight: '20px'}}>{(strategyName === "Tri-Zen") && <>AFR Rate
                         of {estimationData.afr_rate}%. <Tooltip arrow color="primary"
                                           title={"The Applicable Federal Rate is the minimum regulatory interest rate that must be charged on ".concat(strategyName, " loans. The interest is accumulated and repaid with the loan principle either at the insured death or the ", strategyName, " loan exit (typically shown two years after final distribution from the policy - e.g age 92 in most designs).  As the loan is a fixed duration loan due typically in 30 years plus, the long term AFR rate is assumed. The rate shown, is the rate used for the first contribution and is FIXED for the duration of the note.  Each contribution is treated as a new loan at the then prevailing AFR rate (i.e. each yearly contribution will be at the long term AFR rate at that time). For assumption purposes the design increases the AFR rate assumed on each new contribution. AFR rates are published monthly.")}>
                             <IconButton aria-label="Info">
                                 <InfoIcon/>
                             </IconButton>
                         </Tooltip></>}</Typography>
                </Typography>
            </Grid>
        </Grid>
    </>;
}

export default Assumptions;

