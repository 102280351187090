import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { FormHelperText, Grid, Typography} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import PhysicalAndMedicalQuestionsNew from "./MedicalAndFinancial/PhysicalAndMedicalQuestionsNew";
import PhysicalAndMedicalTobaccoQuestionsNew from "./MedicalAndFinancial/PhysicalAndMedicalTobaccoQuestionsNew";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Label} from "../../../../../components/formInputs";
import PhysicalAndMedicalAlcoholMarijuanaNarcoticsQuestions from "./MedicalAndFinancial/PhysicalAndMedicalAlcoholMarijuanaNarcoticsQuestions";
import {medicalTobaccoQuestions} from "./MedicalAndFinancial/component/MedicalPageQuestions";
import { isFunc } from "../../../../../utils/commonUtils";
import {NAME_IDENTIFIER} from "./MedicalAndFinancial/component/MedicalPageQuestions";

type ApplicationQuestionnaireAllianzProps = {
    clientSigned: boolean;
    offerType: string;
    sendSaveRequest?: Function;
}

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            tobaccoQuestion: {
                display: "block",
                float: "right"
            },
        }),
);

function ApplicationQuestionnaireAllianz(props: ApplicationQuestionnaireAllianzProps){
    const { clientSigned, offerType, sendSaveRequest } = props;
    const { values, setFieldValue, errors , validateForm }: any = useFormikContext();
    const classes = useStyles();
    const tobaccoQuestionName: string = `Proposed_Insured_Questions-99`;

    const resetProposedInsuredQuestions99 = async () => {
        if(medicalTobaccoQuestions.length > 0) {
            for (let i = 0; i < medicalTobaccoQuestions.length; i++) {
                const type: string = medicalTobaccoQuestions[i].type === "RADIOGROUP" ? "R" : "C";
                const key: string = medicalTobaccoQuestions[i].key;
                if(key) {
                    setFieldValue(`${NAME_IDENTIFIER}${type}_${key}`, {},false)
                }
            }
        }
    }
    useEffect(() => {
        validateForm()
    }, [values[tobaccoQuestionName]]);
   
    return (
            <>
                <PhysicalAndMedicalQuestionsNew
                        clientSigned={clientSigned}
                        offerType={offerType}
                        sendSaveRequest={sendSaveRequest}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" component="strong" className="floatLeft w100 mt30 mb10">
                            <Label label={"Have you ever used any tobacco or nicotine delivery products?"}
                                   required={true} />
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle1" component="strong" className="floatLeft w100 mb10">
                            (Cigars, cigarette, e-cigarette, electronic cigarette, pipe, vapor product, vaping device,
                            hookah, shisha, bong, juuling, water pipe, e-pipe, electronic pipe, chewing tobacco, betel
                            nuts, snuff, nicotine patch, nicotine gum, other nicotine delivery products)
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <ToggleButtonGroup
                                className={classes.tobaccoQuestion}
                                size="medium"
                                color={"primary"}
                                exclusive
                                id={tobaccoQuestionName}
                                value={values[tobaccoQuestionName] === "Yes" ? "Yes" : (values[tobaccoQuestionName] == undefined || values[tobaccoQuestionName] == "") ? "" : "No"}
                                onChange={(ev: any, value: any) => {
                                    if (value === "Yes") {
                                        setFieldValue(tobaccoQuestionName, value);
                                    }
                                    if (value === "No") {
                                        setFieldValue(tobaccoQuestionName, value);
                                        resetProposedInsuredQuestions99();
                                    }
                                    if (value === null) {
                                        setFieldValue(tobaccoQuestionName, undefined);
                                        resetProposedInsuredQuestions99();
                                    }
                                    if (sendSaveRequest && isFunc(sendSaveRequest)) {
                                        sendSaveRequest(Math.random());
                                    }
                                }}
                        >
                            <ToggleButton value={"Yes"}
                                          className={values[tobaccoQuestionName] === "Yes" ? "active" : ""}
                                          disabled={clientSigned}
                            >
                                Yes
                            </ToggleButton>
                            <ToggleButton value={"No"}
                                          className={values[tobaccoQuestionName] === "No" ? "active" : ""}
                                          disabled={clientSigned}
                            >
                                No
                            </ToggleButton>
                            <>
                                {errors && errors[tobaccoQuestionName] &&
                                        <FormHelperText style={{
                                            margin: "0",
                                            fontSize: "10px",
                                            color: "#FF1744",
                                            textAlign: "right",
                                        }}>
                                            <span className={""}>{errors[tobaccoQuestionName]}</span>
                                        </FormHelperText>
                                }
                            </>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                {
                        values && values[tobaccoQuestionName] === "Yes" &&
                        <PhysicalAndMedicalTobaccoQuestionsNew
                                clientSigned={clientSigned}
                                sendSaveRequest={sendSaveRequest}
                        />
                }

                <PhysicalAndMedicalAlcoholMarijuanaNarcoticsQuestions sendSaveRequest={sendSaveRequest} clientSigned={clientSigned} />
            </>
    );
}

export default ApplicationQuestionnaireAllianz;