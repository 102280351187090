import {
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    IconButton,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    Typography,
    WithStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { SelectAdminPermissions } from "../../pages/admin/AdminProfile/AdminProfile";
import { RootState } from "../../reducers";
import AdminTableRow from "../tableRow/AdminTableRow";
import FormatPhoneNumber from "../format/PhoneNumberFormat";
import EnhancedTableHead from "./EnhancedTableHead";
import { DeleteModelWithNotes } from "../formInputs";
import { getUserDetailForDeletePopUp, deleteUser } from "../../utils/deletePopUpUtils";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";

const headRows: any[] = [
    { id: "sNo", label: "" },
    { id: "firstName", label: "Name" },
    { id: "email", label: "Email" },
    { id: "adminType", label: "Admin Type" },
    { id: "currentPermisions", label: "Current Permissions", hasSort: false },
    { id: "lastLogin", label: "Last Login" },

];

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: '20px',
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

function AdminTable(props: any) {
    const auth = useSelector((state: RootState) => state.auth);
    const {
        accountList,
        order,
        orderBy,
        handleRequestSort,
        handleClickOpen,
        handleClickReInvite,
        handleRefresh,
        page,
        rowsPerPage,
    } = props;
    const [inviteTokenId, setInviteTokenId] = React.useState<string>("");
    let sNo = page * rowsPerPage;
    const [userSelected, setUserSelected] = React.useState<number | null>(null);
    const [isDeleteModel, setIsDeleteModel] = React.useState<string | number>("");
    const [message, setMessage] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const [notesValue, setNotesValue] = React.useState("");
    const accountActions = useActions(AccountActions);
    const userType = "USER";
    const handleViewPermissions = (userId) => {
        setUserSelected(userId);
    };

    const handleClose = () => setUserSelected(null);

    const deletedUser = (id: string | number) => {
        const result: { title: string, message: string } = getUserDetailForDeletePopUp(accountList, id);
        setMessage(result.message);
        setTitle(result.title);
        setIsDeleteModel(id);
    };

    const deleteAdmin = () => {
        deleteUser(setButtonLoader, accountActions, isDeleteModel, userType, notesValue, setIsDeleteModel, setNotesValue, refreshTable).then(() => {
        });
    };

    const setNotes = (value) => {
        setNotesValue(value);
    };

    const refreshTable = () => {
        handleRefresh();
    };
    return (
            <React.Fragment>
                <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headRows={headRows}
                />
                <TableBody>
                    {
                            (!accountList.rows || accountList.rows.length === 0)
                            && <TableRow>
                                <TableCell colSpan={9}>
                                    <Typography align="left">No Admin Accounts</Typography>
                                </TableCell>
                            </TableRow>
                    }
                    {accountList.rows && accountList.rows.map((account: any, index: number) => {
                        return (
                                <AdminTableRow key={index} account={account} index={++sNo}
                                               setInviteTokenId={setInviteTokenId}
                                               inviteTokenId={inviteTokenId} handleClickOpen={handleClickOpen}
                                               history={props.history} handleClickReInvite={handleClickReInvite}
                                               handleViewPermissions={handleViewPermissions} deletedUser={deletedUser}/>
                        );
                    })
                    }
                </TableBody>
                <Dialog
                        open={!!userSelected}
                        onClose={handleClose}
                        fullWidth={true}
                        maxWidth={"sm"}
                        className="customModal"
                        aria-labelledby="customized-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Permissions
                    </DialogTitle>
                    <DialogContent dividers className="padding0" style={{ minHeight: 400 }}>
                        {
                                userSelected &&
                                <SelectAdminPermissions
                                        disabled={auth.user.role === 2}
                                        accountId={userSelected}
                                />
                        }
                    </DialogContent>
                </Dialog>
                {
                    isDeleteModel ?
                            (
                                    <DeleteModelWithNotes
                                            open={true}
                                            title={title}
                                            content={message}
                                            handleClose={() => {
                                                setIsDeleteModel("");
                                                setNotesValue("");
                                            }}
                                            handleYes={deleteAdmin}
                                            setNotes={setNotes}
                                            notes={notesValue}
                                            buttonLoader={buttonLoader}
                                    />
                            ) : null
                }
            </React.Fragment>
    );
}

export default AdminTable;
