import React, {useState} from "react";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {Box, Checkbox, Grid, TextField as TextFieldMUI} from "@material-ui/core";
import {ErrorCommon, TextField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import {Formik} from "formik";
import {Label} from "../../../../../components/formInputs";
import {useActions} from "../../../../../actions";
import * as EnrollmentActions from "../../../../../actions/enrollment";
import {ClientNoteType, EnrollmentNotesVisibility} from "../../../../../constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function AddNotes(props: any) {
    const textLimit:number = 500;
    const { handleClose, open, clientId, handleRefresh, tabValue } = props;
    const enrollmentActions = useActions(EnrollmentActions);
    const [visibilityStatus, setVisibilityStatus] = useState<any>([]);

    React.useEffect(() => {
        setVisibilityStatus([]);
    }, [open]);

    const initialValues = {
        // subject: "",
        message: "",
    };
    const validate = ((value: any) => {
        let errors: any = {};
        if (!value.message || value.message.trim().length <= 0) {
            errors.message = "Required";
        } else if(value.message.trim().length > textLimit) {
            errors.message = `Max character limit up to ${textLimit}`;
        }
        return errors;
    });
    const fieldObj = [
        // {
        //     name: "subject",
        //     label: <Label label={"Subject"} required={true}/>,
        //     isRequired: true,
        //     component: TextField,
        //     variant: "outlined",
        //     className: "TextFieldWidth",
        // },
        {
            name: "message",
            label: <Label label={"Details"} required={true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            multiline: true,
            rows: 10,
        },
    ];

    return (
            <div>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth='md'>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {!tabValue ? "New Note":"Add Processing Note For Case"}
                    </DialogTitle>
                    <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validate={values => validate(values)}
                            validateOnChange={true}
                            onSubmit={async (values, { setSubmitting }) => {
                                let payLoad: any = {};
                                payLoad = {
                                    clientId: clientId,
                                    visibilityStatus,
                                    notesType: tabValue === 0 ? ClientNoteType.General : ClientNoteType.Processing,
                                    ...values,
                                };
                                await enrollmentActions.createEnrollmentNotes(payLoad).then((data) => {
                                    if (data) {
                                        handleClose();
                                        handleRefresh();
                                        setVisibilityStatus([]);
                                    }
                                });
                            }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              setFieldValue,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              dirty,
                          }) => (<form onSubmit={handleSubmit}>
                            <>
                                <DialogContent>
                                    <Typography
                                            component="span"
                                            variant="body1"
                                    >
                                        {!tabValue ? "Write a personal note about this client or create a note " +
                                                "that is visible to others involved with this client (IMO, NIW, etc).":
                                                "Add a processing note to this case"}
                                    </Typography>

                                    {fieldObj.map((field, index) => {
                                        return (
                                                <Box m={1} key={index}>
                                                    <div className="mt30">
                                                        <Grid item xs={12}>
                                                            <TextFieldCommon
                                                                    {...field}
                                                                    values={values}
                                                                    onChange={handleChange}
                                                            />
                                                            <ErrorCommon errors={errors} name={field.name}
                                                                         touched={touched}/>
                                                        </Grid>
                                                    </div>
                                                </Box>
                                        );
                                    })
                                    }
                                    {!tabValue && <Box m={1} key={fieldObj.length+1}>
                                        <div className="mt30">
                                            <Grid item xs={6}>
                                                <Autocomplete
                                                        multiple
                                                        id="checkboxes-tags-demo"
                                                        options={EnrollmentNotesVisibility}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option: any) => option.name}
                                                        renderOption={(option: any, { selected }) => (
                                                                <React.Fragment>
                                                                    <Checkbox
                                                                            icon={icon}
                                                                            checkedIcon={checkedIcon}
                                                                            style={{ marginRight: 8 }}
                                                                            checked={selected}
                                                                            color={"primary"}
                                                                    />
                                                                    {option.name}
                                                                </React.Fragment>
                                                        )}
                                                        onChange={(e: any, newValue: any) => {
                                                            setVisibilityStatus(newValue.map((item: any) => item.value));
                                                        }}
                                                        renderInput={(params) => (
                                                                <TextFieldMUI {...params} variant="outlined"
                                                                              label="Note Visibility"
                                                                              placeholder="Select Recipients"/>
                                                        )}
                                                />
                                            </Grid>
                                        </div>
                                    </Box>
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="ml20"
                                            size="small"
                                            disabled={!dirty || isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            </>
                        </form>)}
                    </Formik>
                </Dialog>
            </div>
    );
}
