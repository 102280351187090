import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import PolicyBreakDownExpansionPanel1 from "../PolicyBreakDownExpansionPanel1";
import numeral from "numeral";
import { useActions } from "../../../../../actions";
import * as AccountActions from "../../../../../actions/account";
import ContributionsAndPaymentHistory
    from "../../../../../components/clientServicing/Payments/ContributionsAndPaymentHistory";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            mainRoot: {
                width: "100%",
                float: "left",
                overflowX: "auto",
            },
            root: {
                [theme.breakpoints.down(992)]: {
                    minWidth: 767,
                },
            },
            heading: {
                flexBasis: "20%",
                flexShrink: 0,
                marginBottom: 0,
            },
            label: {
                flexBasis: "20%",
                flexShrink: 0,
                marginBottom: 0,
            },
            secondaryHeading: {
                flexBasis: "30%",
                color: theme.palette.text.secondary,
                marginBottom: 0,
            },
            labelBtn: {
                padding: "2px 5px",
                fontSize: 12,
                borderRadius: 6,
                color: "#ffffff",
            },
            bgGreen: {
                background: "#3DDB93",
            },
        }),
);

interface PolicyBreakDownProps {
    setRefreshMain: Function,
    policyBreakDown: any,
    setPolicyFormDirty: Function,
    fetchCaseDetail: Function,
    caseDetails: any,
    issueAge: number | undefined,
    setPolicyBreakDownExpanded: Function,
    combineClientObj: {key: number, name: string, title: string}
}

const PolicyBreakDown1 = (props: PolicyBreakDownProps) => {
    const { setRefreshMain, policyBreakDown, setPolicyFormDirty, fetchCaseDetail, caseDetails, issueAge, setPolicyBreakDownExpanded, combineClientObj } = props;
    const { saveDefaultPolicy, addPolicyYear } = useActions(AccountActions);
    const [policyArr, setPolicyArr] = React.useState([]);
    const [clientId, setClientId] = React.useState(0);
    const [policyBreakDownUpto, setPolicyBreakDownUpto] = React.useState<number>(15);
    const [policyStartYear, setPolicyStartYear] = React.useState<number>(moment().year());
    const [yearsBetween, setYearsBetween] = React.useState<any>([]);
    const [issueDate, setIssueDate] = React.useState<any>("");
    const [paidInFull, setPaidInFull] = React.useState<boolean>(false)
    const setRefresh = React.useCallback(() => {
        setRefreshMain(policyArr);
    }, []);
    const classes = useStyles();

    React.useEffect(() => {
        if(caseDetails && caseDetails.paymentCompletedForYears && caseDetails.paymentCompletedForYears.count){
            if(caseDetails.paymentCompletedForYears.count >= 5 ) {
                setPaidInFull(true);
            } else {
                setPaidInFull(false);
            }
        }
    },[caseDetails])

    const createDefaultPolicy = async (currentYearPolicy: any, clientId: number) => {
        if (currentYearPolicy && currentYearPolicy.length === 0 && clientId) { // first year policy is not present yet
            await saveDefaultPolicy(clientId); //caseDetails.id is the clientId
            fetchCaseDetail();
        }
    };

    const addAnotherPolicyYear = async()=>{
        await addPolicyYear({clientId, policyBreakDownUpto, year: yearsBetween.length > 0 ? yearsBetween[yearsBetween.length - 1] + 1 : moment().year()});
        fetchCaseDetail();
    }
    React.useEffect(() => {
        let finalYear = Number(moment(issueDate).add(policyBreakDownUpto, 'year').format("YYYY"));
        let yearsBetween: any = [];
        for (let currentYear = policyStartYear; currentYear < finalYear; currentYear++)
            yearsBetween.push(currentYear);
        setYearsBetween(yearsBetween)
    }, [policyBreakDownUpto, policyStartYear, issueDate]);
    React.useEffect(() => {
        if (policyBreakDown && policyBreakDown.length >= 0) {
            const currentYearPolicy = policyBreakDown.filter((val: any, index) => val.policyYear === policyStartYear);
            createDefaultPolicy(currentYearPolicy, clientId).then(() => {
            });
        }
    }, [policyBreakDown && policyBreakDown.length, clientId]);

    React.useEffect(() => {
        if (caseDetails && caseDetails.id) {
            let issueDate = caseDetails && caseDetails.tranche && caseDetails.tranche.issueDate || moment();
            setClientId(caseDetails.id);
            setPolicyBreakDownUpto(caseDetails.noOfPolicyYears);
            setPolicyStartYear(caseDetails.policyStartYear || moment(issueDate).year() || moment().year());
            setIssueDate(caseDetails && caseDetails.tranche && caseDetails.tranche.issueDate || moment());
        }
    }, [caseDetails]);


    return (
            <React.Fragment>
                <Typography variant="subtitle1" component="strong" className="mb20 mt30 floatLeft w100">Yearly Policy
                    Breakdown</Typography>
                <div className={classes.mainRoot}>
                    <div className={classes.root}>
                        {
                            yearsBetween.slice(0,15).map((year, index) => {
                                let yearPolicyBreakup: any = [];
                                yearPolicyBreakup = policyBreakDown && policyBreakDown.length > 0 && policyBreakDown.filter((val: any) => val.policyYear === year);
                                return (
                                        <PolicyBreakDownExpansionPanel1
                                                index={index}
                                                year={year}
                                                policyArr={policyArr}
                                                setPolicyArr={setPolicyArr}
                                                setPolicyFormDirty={setPolicyFormDirty}
                                                setRefresh={setRefresh}
                                                yearPolicyBreakup={yearPolicyBreakup && yearPolicyBreakup.length > 0 ? yearPolicyBreakup[0] : {}}
                                                fetchCaseDetail={fetchCaseDetail}
                                                agePerYear={issueAge && issueAge !== 0 ?
                                                        (numeral(issueAge).value() + (index)) : 0}
                                                issueAge={issueAge}
                                                setPolicyBreakDownExpanded={setPolicyBreakDownExpanded}
                                                caseDetails={caseDetails}
                                                policyStartYear={policyStartYear}
                                                combineClientObj={combineClientObj}
                                                paidInFull={index >= 5 && paidInFull}
                                        />
                                );
                            })
                        }
                    </div>
                    {/*Will be done later*/}
                    {/*<Link color="primary"*/}
                    {/*      className="floatLeft mt40" style={{ lineHeight: "27px" }} onClick={()=>addAnotherPolicyYear()}*/}
                    {/*>*/}
                    {/*    <AddCircleIcon className="floatLeft mr15 "/> Add Another Year*/}
                    {/*</Link>*/}
                </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong" className="mb20 mt20 floatLeft w100">Client
                                Payments
                            </Typography>
                        </Grid>
                    </Grid>
                    <ContributionsAndPaymentHistory onCaseDetails={true}/>
            </React.Fragment>
    );
};
export default PolicyBreakDown1;
