import * as React from "react";
import { TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { AccountHeadRow } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import FormatPhoneNumber from "../format/PhoneNumberFormat";
import CarrierTableRow from '../tableRow/CarrierTableRow';

const headRows: AccountHeadRow[] = [
  { id: 'sNo', label: 'S.No' },
  { id: 'carrier.carrierName', label: 'Carrier Name' },
  { id: 'carrier.carrierPhone', label: 'Primary Contact' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'email', label: 'Email' },
  { id: 'inviteStatus', label: 'Documents' },
  { id: 'inviteStatus', label: 'Status' },
  { id: 'createdAt', label: 'Date Created' },
];

function CarrierTable(props: any) {
  const { accountList, order, orderBy, handleRequestSort, handleClickOpen, handleRefresh, page, rowsPerPage } = props;
    let sNo = page * rowsPerPage;
  return (
    <React.Fragment>
     <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        headRows={headRows}
     />
    <TableBody>
    {
      (!accountList.rows || accountList.rows.length === 0)
      && <TableRow>
        <TableCell align="left" colSpan={9}>
            <Typography align="left">No Carrier Accounts</Typography>
        </TableCell>
      </TableRow>
    }
    {accountList.rows && accountList.rows.map((account: any, index:number) =>  {
        return (
          <CarrierTableRow key={index} account={account} index={++sNo} handleClickOpen={handleClickOpen} history={props.history}  handleRefresh={handleRefresh} />
        )
      }) 
    }
    </TableBody>
        
  </React.Fragment>
  )
}

export default CarrierTable;
