import {isDebug} from "../utils/commonUtils";

export type AddressType = {
    city?: string;
    country?: string;
    state?: string;
    street?: string;
    zipCode?: string;
    countryCode?:string;
    stateName?:string
}

/**
 * An ENUM for address types, to keep track of keywords used by googlePlacesAPI
 */
const GAddressTypeEnum = {
    ADMIN_AREA: "administrative_area_level_1",
    ADMIN_AREA_2: "administrative_area_level_2",
    ADMIN_AREA_3: "administrative_area_level_3",
    COUNTRY: "country",
    LOCALITY: "locality",
    POSTAL_CODE: "postal_code",
    ROUTE: "route",
    STREET_NUMBER: "street_number",
    SUB_LOCALITY: "sublocality_level_1",
};

/**
 * Fields to be included for the Place in the details response when the details are successfully retrieved
 */
export const googlePlacesFields = ['address_components', 'name', 'icon', 'adr_address', 'formatted_address'];

/**
 * The types of predictions to be returned
 */
export const googleTypes = ['address'];


export const onPlaceSelectedCommon = (place: google.maps.places.PlaceResult) => {
   isDebug() && console.log(place, "======= ")

    // Extract address components
    const [addressComponents, formattedAddress] = [place.address_components, place.formatted_address];
    const address: AddressType = {}
    if (!addressComponents) {
        return {}
    }

    // Set the full formatted address
    // formik.setFieldValue("address", formattedAddress)
    // formik.setFieldValue("stAddress", formattedAddress)

    // Initialize variables for individual components

    let city = "";
    let country = "";
    let state = "";
    let stateName = "";
    let street = "";
    let zipCode = "";

    // Loop through address components
    addressComponents.forEach((component) => {
        const componentType = component.types[0];

        // Check component types and assign values using ENUM
        switch (componentType) {
            case GAddressTypeEnum.ADMIN_AREA:
                state = component.short_name;
                stateName = component.long_name;
                Object.assign(address, {state});
                Object.assign(address, {stateName});
                break;
            case GAddressTypeEnum.COUNTRY:
                country = component.long_name;
                let countryCode = component.short_name;
                Object.assign(address, {country});
                Object.assign(address, {countryCode});
                break;

            case GAddressTypeEnum.LOCALITY:
                city = component.long_name;
                Object.assign(address, {city});
                break;
            case GAddressTypeEnum.POSTAL_CODE:
                zipCode = component.long_name;
                Object.assign(address, {zipCode});
                break;
            case GAddressTypeEnum.ROUTE:
                street += component.long_name;
                Object.assign(address, {street});
                break;
            case GAddressTypeEnum.STREET_NUMBER:
                street =  street + " "+component.long_name;
                Object.assign(address, {street});
                break;
            case GAddressTypeEnum.SUB_LOCALITY:
                street = street+ ", "+component.long_name;
                Object.assign(address, {street});
                break;
            case GAddressTypeEnum.ADMIN_AREA_2:
            case GAddressTypeEnum.ADMIN_AREA_3:
                street = street+ ", "+component.long_name  ;
                Object.assign(address, {street});
                break;
            default:
                // Handle other address types if needed
                break;
        }
    });

    //
    // alert(JSON.stringify({street, city, state, zipCode, country}, null, 2));

    return address;
};
