import {makeStyles} from "@material-ui/core";
import {createStyles, Theme} from "@material-ui/core/styles";

export const DashboardStyle = makeStyles((theme: Theme) =>
    createStyles({
        mt15: {
            marginTop: 15,
        },

        mt10: {
            marginTop: 10,
        },
        minHeight170: {
            minHeight: 170,
        },
        containerWidth1700: {
            maxWidth: 1700,
        },
        colorWhite: {
            color: '#ffffff'
        },
        title: {
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                height: 4,
                width: 73,
                top: -10,
                borderRadius: 4,
                background: 'linear-gradient(90deg, #1E88E5 0%, #3DDB93 32.38%, #F50057 69.36%, #FFB05F 100%)',
            }
        },
        agentHero: {
            background: "url(\"../images/landing_page_v2/Shape.png\"),linear-gradient(90deg, rgba(20, 57, 94, 1) 0%, rgba(27, 109, 178, 1) 35%, rgba(38, 145, 236, 1) 100%)",
            minHeight: 300,
            backgroundPosition: "bottom right",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "15px 15px 15px 40px",
            borderRadius: 8,
            marginBottom: 30,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down(992)]: {
                padding: "40px 15px",
                marginTop: "30px",
            }
        },
        groupHero:{
            padding: "40px !important",
            [theme.breakpoints.down(992)]: {
                padding: "15px !important",
            }
        },
        marketingHero: {
            background: "url(\"../images/agent/bg-header-cta-with-rings.jpg\"),linear-gradient(90deg, rgba(20, 57, 94, 1) 0%, rgba(27, 109, 178, 1) 35%, rgba(38, 145, 236, 1) 100%)",
            minHeight: 300,
            backgroundPosition: "bottom right",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "15px 15px 15px 40px",
            borderRadius: 8,
            marginBottom: 30,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down(992)]: {
                padding: "15px",
            }
        },
        minidpNone: {
            [theme.breakpoints.down(1400)]: {
                width: '100%'
            },
            [theme.breakpoints.down(1180)]: {
                display: 'none'
            },
        },
        textLight: {
            color: '#9E9E9E',
        },

        cardRoot: {
            display: 'flex',
        },
        radius8: {
            borderRadius: 8
        },
        minHeight150: {
            [theme.breakpoints.down(1440)]: {
                minHeight: 150
            }
        },
        cardShadow: {
            boxShadow: '0 0 16px 0 rgba(0,0,0,0.18)',
        },
        cardShadowSec: {
            boxShadow: '0 0 8px 0 rgba(0,0,0,0.21)',
        },
        minHeight: {
            minHeight: 240,
        },
        minHeight140: {
            minHeight: 140,
        },
        minHeight180: {
            minHeight: 180,
        },
        minHeight148: {
            minHeight: 148,
        },
        textLinkNone: {
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
            },
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            float: 'left',
            width:'100%'
        },
        content: {
            flex: '1 0 auto',
        },
        cover: {
            width: 151,
        },
        w100: {
            width: '100%'
        }
        ,
        textCenter: {
            textAlign: 'center'
        },
        floatLeft: {
            float: 'left'
        },
        mt25: {
            marginTop: 25
        },
        mt40: {
            marginTop: 40
        },
        mb0: {
            marginBottom: 0
        },
        mb15: {
            marginBottom: 15
        },
        disPlay: {
            display: 'block'
        },
        btnSection: {
            padding: '15px 15px',
            textAlign: 'right',
            display:'flex',
            [theme.breakpoints.down(767)]: {
                padding: 15,
                float: 'right',
                textAlign: 'right',
                width: 200
            }
        },
        btnBlue: {
            background: '#3671ED !important',
            fontWeight: 'bold',
            borderRadius: 4,
            color: '#FFFFFF',
            margin: '0 4px 5px',

            fontSize: 20,
            minWidth:50,
            '&:hover': {
                background: '#3671ED !important',
            },
            '&:focus': {
                background: '#3671ED !important',
            }
        },
        btnRed: {
            background: '#FF1744 !important',
            border: 'none',
            fontWeight: 'bold',
            borderRadius: 4,
            color: '#FFFFFF',
            marginBottom: 5,
            margin: '0 4px 5px',
            fontSize: 20,
            minWidth:50,
            '&:hover': {
                background: '#FF1744 !important',
            },
            '&:focus': {
                background: '#FF1744 !important',
            }
        },
        btnGreen: {
            background: '#3DDB93 !important',
            fontWeight: 'bold',
            borderRadius: 4,
            color: '#FFFFFF',
            marginBottom: 5,
            margin: '0 4px 5px',
            fontSize: 20,
            minWidth:50,
            '&:hover': {
                background: '#3DDB93 !important',
            },
            '&:focus': {
                background: '#3DDB93 !important',
            }
        },
        btnGrey: {
            background: '#677184 !important',
            fontWeight: 'bold',
            borderRadius: 4,
            color: '#FFFFFF',
            marginBottom: 5,
            fontSize: 20,
            minWidth:50,
            '&:hover': {
                background: '#677184 !important',
            },
            '&:focus': {
                background: '#677184 !important',
            }
        },
        flexBox: {
            alignItems: 'center'
        },
        boxItem: {
            maxWidth: 340,
            minWidth: 340,
            marginLeft: 0,
            marginRight: 10,
            [theme.breakpoints.down(767)]: {
                maxWidth: '100%',
                margin: 6,
                width: '100%'
            }

        },
        GroupCard:{
            padding:'25px 30px',
            textAlign: 'center'
        },
        videoWrap: {
            width: '100%',
            height: '260px !important',
            [theme.breakpoints.down(767)]: {
                height: '170px !important',
            }
        },
        expandDiv:{
            cursor:'pointer',
        },
        table: {
            minWidth: 800,
        },
        tableHead: {
            backgroundColor: "#EDF3F8",
        },
        container: {
            maxHeight: 600,
        },

    }), {index: 10}
);