import * as React from "react";
import { useEffect } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Tooltip,
    Typography,
    withStyles,
    ListItemIcon,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import OrangeID from "../../../../../components/client/kaizen/images/OrangeID.png";
import Protection from "../../../../../components/client/kaizen/images/Protection.png";
import NumberFormat from "react-number-format";
import Info from "@material-ui/icons/Info";
import BlueID from "../../../../../components/client/kaizen/images/BlueID.png";
import Potential from "../../../../../components/client/kaizen/images/Potential.png";
import Chart from "react-google-charts";
import { List, ListItem, ListItemText } from "@material-ui/core";
import EstimationResult from "../../../../../components/client/kaizen/EstimationResult";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import numeral from "numeral";
import Assumptions from "./Assumptions";
import { isMobile } from "react-device-detect";
import {getTicks} from "../../../../../utils/commonUtils";

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#2f2f2f",
        maxWidth: "767px",
        minWidth: "100px",
        color:'#ffccc',
        lineHeight:'18px',
        fontWeight: "normal",
        padding: "20px",
        margin:"0",
        boxShadow:'0 0 15px 0px #8c8c8c',
        fontFamily: "'Roboto', sans-serif !important",
    },
}))(Tooltip);

const numberWithCommas = (x: string) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const EstmateSectionThreeContant = (props: any) => {
    const { estimatedDetail, totalContributionValue } = props;
    const [estimatedData, setEstimatedData] = React.useState(estimatedDetail);
    const [loading, setLoading] = React.useState(false);
    const [showStickyBar, setShowStickyBar] = React.useState(false);
    let initAge: any = 65;
    const strategyName: string = props.strategyData.name;
    const strategySlug: string = props.strategyData.slug || "kaizen";
    const registrationMark: boolean | undefined = props.strategyData.registrationMark;
    let totalContribution: number = numeral(totalContributionValue || 0).value();
    let minContributionAmount: string = estimatedData.assumption_minimum_contribution || "20000";

    let strategyText: string = props.strategyData.text;
    if(strategyText === "Leveraged Savings Plan"){
        strategyText = "The Leveraged Savings Plan";
    }

    useEffect(() => {
        setEstimatedData(estimatedDetail);
    }, [estimatedDetail ? estimatedDetail.kaizen_initial_death_benefit : 0, totalContribution]);

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll, true);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        });
    }, []);

    const handleScroll = (e: any) => {
        if (!showStickyBar && window.scrollY > 100) {
            setShowStickyBar(true);
        }
    };

    const goToDashboard = async () => {
        setLoading(true);
        window.location.href = "/dashboard";
    };

    const title = () => {
        return (<>
            INDEX LIFE {strategyName} LEVERAGE
        </>);
    };

    const chartEvents: any = [
        {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
                let container = chartWrapper.getChart().container;
                let graphDiv  = container.getElementsByTagName('div')[1];
                graphDiv.classList.add("graphWidth");
            }
        }
    ];

    return (
            <>
                <Grid id={"step3_section1"} container spacing={2} justify="center">
                    <Grid item xs={12} md={12} className="mb30">
                        <Typography align="center" variant="body1">
                            How {strategyName} compares to other investments.
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={12}>
                            <div className="graphWrap">
                                <Chart
                                        className="graphMap"
                                        chartType="ComboChart"
                                        loader={<div>Loading Chart</div>}
                                        chartEvents={chartEvents}
                                        formatters={[
                                            {
                                                type: "NumberFormat",
                                                column: 1,
                                                options: {
                                                    prefix: "$",
                                                    pattern: "#,###",
                                                },
                                            },
                                            {
                                                type: "NumberFormat",
                                                column: 2,
                                                options: {
                                                    prefix: "$",
                                                    pattern: "short",
                                                },
                                            },
                                            {
                                                type: "NumberFormat",
                                                column: 3,
                                                options: {
                                                    prefix: "$",
                                                    pattern: "#,###",
                                                },
                                            },
                                            {
                                                type: "NumberFormat",
                                                column: 4,
                                                options: {
                                                    prefix: "$",
                                                    pattern: "#,###",
                                                },
                                            },
                                            {
                                                type: "NumberFormat",
                                                column: 6,
                                                options: {
                                                    prefix: "$",
                                                    pattern: "#,###",
                                                },
                                            },
                                        ]}
                                        data={[
                                            [
                                                "Name",
                                                "Your Contribution",
                                                {
                                                    role: "annotation",
                                                    type: "number",
                                                },
                                                "Total Distributions",
                                                {
                                                    role: "annotation",
                                                    type: "number",
                                                },
                                                "Death Benefits",
                                                {
                                                    role: "annotation",
                                                    type: "number",
                                                },
                                            ],
                                            ["", totalContribution, null, null, null, null, null],
                                            [`Index Life With ${strategyText} Leverage`, totalContribution, null, estimatedData.kaizen_total_income, estimatedData.kaizen_total_income, estimatedData.kaizen_death_benefit_yr90, estimatedData.kaizen_death_benefit_yr90],
                                            ["Index Life Without Leverage", totalContribution, null, estimatedData.lsw_comparison_total_income, estimatedData.lsw_comparison_total_income, estimatedData.lsw_comparison_death_benefit_yr90, estimatedData.lsw_comparison_death_benefit_yr90],
                                            ["After Tax Investment", totalContribution, null, estimatedData.bonus_comp_total, estimatedData.bonus_comp_total, 0, 0],
                                            ["Tax Deferred Investment", totalContribution, null, estimatedData.def_comp_total, estimatedData.def_comp_total, 0, 0],
                                            ["", totalContribution, totalContribution, null, null, null, null],
                                        ]}
                                        options={{
                                            pointSize: 0,
                                            enableInteractivity: false,
                                            bar: { groupWidth: "80%" },
                                            colors: ["#29b027", "#3871ec", "#ecb339"],
                                            backgroundColor: "#fof6ff",
                                            chartArea: { width: "90%" },
                                            seriesType: "bars",
                                            series: {
                                                0: {
                                                    type: "line",
                                                    lineWidth: 3,
                                                    annotations: {
                                                        style: "line",
                                                        alwaysOutside: false,
                                                        highContrast: true,
                                                        allowHtml: true,
                                                        stem: {
                                                            color: "#29b027",
                                                            length: 10,
                                                        },
                                                        textStyle: {
                                                            fontSize: 16,
                                                            bold: true,
                                                            border: "none",
                                                        },
                                                        boxStyle: {
                                                            // Color of the box outline.
                                                            stroke: "#888",
                                                            // Thickness of the box outline.
                                                            strokeWidth: 1,
                                                            // x-radius of the corner curvature.
                                                            rx: 10,
                                                            // y-radius of the corner curvature.
                                                            ry: 10,
                                                            // Attributes for linear gradient fill.
                                                            gradient: {
                                                                // Start color for gradient.
                                                                color1: "#fbf6a7",
                                                                // Finish color for gradient.
                                                                color2: "#33b679",
                                                                // Where on the boundary to start and
                                                                // end the color1/color2 gradient,
                                                                // relative to the upper left corner
                                                                // of the boundary.
                                                                x1: "0%", y1: "0%",
                                                                x2: "100%", y2: "100%",
                                                                // If true, the boundary for x1,
                                                                // y1, x2, and y2 is the box. If
                                                                // false, it's the entire chart.
                                                                useObjectBoundingBoxUnits: true,
                                                            },
                                                        },
                                                    },
                                                },
                                                1: {
                                                    type: "bars",
                                                    annotations: {
                                                        stem: {
                                                            length: isMobile ? 20 : 15,
                                                        },
                                                        alwaysOutside: true,
                                                        highContrast: true,
                                                        textStyle: {
                                                            bold: true,
                                                            color: "#1c1c1c",
                                                            border: "none",
                                                            fontSize: 12,
                                                            auraColor: "transparent",
                                                        },
                                                    },
                                                },
                                                2: {
                                                    type: "bars",
                                                    annotations: {
                                                        stem: {
                                                            length: 10,
                                                        },
                                                        alwaysOutside: true,
                                                        highContrast: true,
                                                        textStyle: {
                                                            bold: true,
                                                            color: "#1c1c1c",
                                                            border: "none",
                                                            fontSize: 12,
                                                            auraColor: "transparent",
                                                        },
                                                        boxStyle: false,
                                                    },
                                                },
                                            },
                                            vAxis: {
                                                maxValue: estimatedData.kaizen_total_income,
                                                ticks : getTicks(estimatedData),
                                                minValue: 0,
                                                viewWindowMode: "explicit",
                                                format: "short",
                                                minorGridlines:{
                                                    color: 'transparent'
                                                }
                                            },
                                            hAxis: {
                                                textPosition: "out",
                                                minTextSpacing: 30,
                                                minValue: 0,
                                                viewWindowMode: "maximized",
                                                textStyle: {
                                                    color: "#2967ea",
                                                    bold: true,
                                                },
                                                slantedText: false,
                                                allowContainerBoundaryTextCutoff: true,
                                            },
                                            legend: { position: "none" },
                                            animation: {
                                                startup: true,
                                                easing: "linear",
                                                duration: 1000,
                                            },
                                        }}
                                        // For tests
                                        rootProps={{ "data-chartid": "1" }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <List className="flexContainer">
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon className="colorBlue"/>
                                    </ListItemIcon>
                                    <ListItemText
                                            primary="Potential policy or other distributions"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon className="colorYellow"/>
                                    </ListItemIcon>
                                    <ListItemText
                                            primary="Potential Death Benefit"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon className="colorGreen"/>
                                    </ListItemIcon>
                                    <ListItemText
                                            primary={"$" + totalContribution + ": Your Contribution"}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                            primary={`The minimum contribution is $${numberWithCommas(minContributionAmount)} with trust fees.`}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={12} className="mb30 mt30">
                        <Typography align="center" variant="body1">
                            Index Universal Life (IUL) with {strategyName} Leverage.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Card className="muiCard plansCard boxShadowNone border1Px">
                            <CardContent className="muiCardContent boxShadowNone pd0">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="plansCard">
                                            <Box className="box bgOne">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <img alt="" className="tag" src={OrangeID}/>
                                                        <Typography variant="h6"
                                                                    className="mt20 mb20 floatLeft w100">
                                                            <img alt="" className="floatLeft"
                                                                 src={Protection}/><b
                                                                className="floatLeft ml15 mt15">Protection for <br/>your
                                                            family</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Initial death Benefit
                                                        </Typography>
                                                        <NumberFormat
                                                                value={estimatedData.kaizen_initial_death_benefit}
                                                                displayType={"text"}
                                                                thousandSeparator={true} prefix={"$"}
                                                                renderText={value => <Box className="f22 floatLeft"
                                                                                          component="strong"
                                                                                          m={1}>{value}</Box>}/>
                                                        <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography className="f14 txtWhite mb0">
                                                                            This is the death benefit initially applied for with the life insurance company. It is needed as this is the amount the life insurance company is underwriting you for.  This is different from the death benefit you will likely receive, because it does not take into account the bank loan or any changes over time due to policy performance or reductions resulting from policy loans you take in the form of income or the policy loan used to repay the third party lender.
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                        >
                                                            <strong className="floatLeft f12 icon"><Info
                                                                    className="floatLeft" color="primary"/> </strong>
                                                        </HtmlTooltip>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className="mt10">
                                                            Remaining Death Benefit<br/>
                                                            at Age 90 After Total Distributions and<br/>
                                                            Loan Repayments.
                                                        </Typography>
                                                        <NumberFormat
                                                                value={estimatedData.kaizen_death_benefit_yr90}
                                                                displayType={"text"}
                                                                thousandSeparator={true} prefix={"$"}
                                                                renderText={value => <Box className="f22 floatLeft"
                                                                                          component="strong"
                                                                                          m={1}>{value}</Box>}/>
                                                        <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography className="f14 txtWhite mb0">The amount paid to a beneficiary upon the
                                                                            death
                                                                            of an
                                                                            insured person. Death Benefits start out
                                                                            higher
                                                                            depending on your age and health. The amount
                                                                            shown
                                                                            here is the remaining amount at age 90 after
                                                                            policy
                                                                            distributions.</Typography>
                                                                    </React.Fragment>
                                                                }
                                                        >
                                                            <strong className="floatLeft f12 icon"><Info
                                                                    className="floatLeft" color="primary"/> </strong>
                                                        </HtmlTooltip>
                                                    </Grid>
                                                </Grid>

                                            </Box>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="plansCard">
                                            <Box className="box bgTwo borderNone">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <img alt="" className="tag" src={BlueID}/>
                                                        <Typography variant="h6"
                                                                    className="mt20 mb20 floatLeft w100">
                                                            <img alt="" className="floatLeft "
                                                                 src={Potential}/><b
                                                                className="floatLeft ml15 mt15 textBlue">Potential
                                                            policy<br/> distributions</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Typography>
                                                            Potential annual<br/> distributions at age {initAge}-90
                                                        </Typography>
                                                        <NumberFormat value={estimatedData.kaizen_annual_income}
                                                                      displayType={"text"}
                                                                      thousandSeparator={true} prefix={"$"}
                                                                      renderText={value => <Box
                                                                              className="f22 floatLeft"
                                                                              component="strong"
                                                                              m={1}>{value}</Box>}/>
                                                        <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography className="f14 txtWhite mb0">
                                                                            This is an estimation of policy distributions taken annually.
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                        >
                                                            <strong className="floatLeft f12 icon"><Info
                                                                    className="floatLeft" color="primary"/> </strong>
                                                        </HtmlTooltip>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Typography>
                                                            Potential Total Policy Distributions<br/>
                                                            After Loan Repayment.
                                                        </Typography>
                                                        <NumberFormat value={estimatedData.kaizen_total_income}
                                                                      displayType={"text"}
                                                                      thousandSeparator={true} prefix={"$"}
                                                                      renderText={value => <Box
                                                                              className="f22 floatLeft"
                                                                              component="strong"
                                                                              m={1}>{value}</Box>}/>
                                                        <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography className="f14 txtWhite mb0">
                                                                            This as the cumulative total of the planned income distributions (policy loans) illustrated at the start of the plan.  You are not stuck with this schedule and can vary it as needed through the plan’s life, nor are the amounts guaranteed as it will be based on performance over time.
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                        >
                                                            <strong className="floatLeft f12 icon"><Info
                                                                    className="floatLeft" color="primary"/> </strong>
                                                        </HtmlTooltip>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Typography>
                                                            IRR
                                                        </Typography>
                                                        <NumberFormat value={estimatedData.kaizen_levd_irr}
                                                                      displayType={"text"}
                                                                      thousandSeparator={false} suffix={"%"}
                                                                      renderText={value => <Box
                                                                              className="f22 floatLeft"
                                                                              component="strong"
                                                                              m={1}>{value}</Box>}/>
                                                        <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography className="f14 txtWhite mb0">IRR (internal rate of return) is the return
                                                                            on
                                                                            your
                                                                            investment, net of taxes and costs, and
                                                                            useful
                                                                            for
                                                                            comparing investments of the same risk
                                                                            profile.
                                                                            Stock portfolio would be a higher risk with
                                                                            greater
                                                                            chance of loss, whereas bonds would be a
                                                                            lower
                                                                            risk.</Typography>
                                                                    </React.Fragment>
                                                                }
                                                        >
                                                            <strong className="floatLeft f12 icon"><Info
                                                                    className="floatLeft" color="primary"/> </strong>
                                                        </HtmlTooltip>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={12} className="mb30 mt50">
                        <Typography align="center" variant="body1">Full comparison of estimated benefits.</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="flexContainerWrap">
                            <div className="listViewInner">
                                <List className="listViewhead h100 ">
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                                primary={<div className="w100 floatLeft"><img alt="potential"
                                                                                              src={Potential}/>
                                                </div>}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                                component="strong"
                                                                color="textPrimary"
                                                        >
                                                            Potential Policy or
                                                            Other Distributions
                                                            During Retirement
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                        />
                                    </ListItem>
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                                primary={<div className="w100 floatLeft"><img alt="protection"
                                                                                              src={Protection}/>
                                                </div>}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                                component="strong"
                                                                color="textPrimary"
                                                        >
                                                            Protection For You
                                                            <br/>& Your Family
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                        />
                                    </ListItem>
                                </List>

                                <EstimationResult
                                        sectionClass={"kaizenList"}
                                        title={title()}
                                        annual_income={estimatedData.kaizen_annual_income}
                                        total_income={estimatedData.kaizen_total_income}
                                        irr={estimatedData.kaizen_levd_irr}
                                        death_benifit_yr90={estimatedData.kaizen_death_benefit_yr90}
                                        initial_death_benefit={estimatedData.kaizen_initial_death_benefit}
                                        initAge={props.initAge}
                                        showToolTip={true}
                                        index={0}
                                        strategyName={strategyName}
                                />
                                <EstimationResult
                                        sectionClass={"lswList"}
                                        title={"INDEX LIFE WITHOUT LEVERAGE"}
                                        annual_income={estimatedData.lsw_comparison_annual_income}
                                        total_income={estimatedData.lsw_comparison_total_income}
                                        irr={estimatedData.lsw_comparison_unlevd_irr}
                                        death_benifit_yr90={estimatedData.lsw_comparison_death_benefit_yr90}
                                        initial_death_benefit={estimatedData.lsw_comparison_initial_death_benefit}
                                        initAge={props.initAge}
                                        showToolTip={true}
                                        index={1}
                                        strategyName={strategyName}
                                />
                                <EstimationResult
                                        sectionClass={"bonusList"}
                                        title={"AFTER TAX INVESTMENT"}
                                        annual_income={estimatedData.bonus_comp_annual}
                                        total_income={estimatedData.bonus_comp_total}
                                        irr={estimatedData.bonus_irr}
                                        death_benifit_yr90={0}
                                        initial_death_benefit={0}
                                        initAge={props.initAge}
                                        showToolTip={false}
                                        index={2}
                                        strategyName={strategyName}
                                />
                                <EstimationResult
                                        sectionClass={"defList"}
                                        title={"TAX DEFERRED INVESTMENT"}
                                        annual_income={estimatedData.def_comp_annual}
                                        total_income={estimatedData.def_comp_total}
                                        irr={estimatedData.def_comp_irr}
                                        death_benifit_yr90={0}
                                        initial_death_benefit={0}
                                        initAge={props.initAge}
                                        showToolTip={false}
                                        index={3}
                                        strategyName={strategyName}
                                />
                            </div>
                        </div>
                        <div className="clearFix"/>
                        <Typography className="mt20 floatLeft w100 txtGray f12">
                            <Assumptions estimationData={estimatedData} strategySlug={strategySlug} registrationMark={registrationMark}/>
                        </Typography>

                    </Grid>
                </Grid>
                <div className="stickyFooter" id={"stickyFooter"} style={{
                    display: showStickyBar ? "block" : "none",
                    transition: showStickyBar ? "height 2s ease-in-out 2s" : "none",
                }}>
                    <Typography className="mb0"> Learn more on your secure myilia.com dashboard.
                        <Button size="large" onClick={() => goToDashboard()} disabled={loading}
                                className="ml20"
                                variant="contained" color="primary">
                            <span style={{ color: "#ddd" }}>  View Dashboard </span>
                        </Button>
                    </Typography>

                </div>

            </>
    );
};

export default EstmateSectionThreeContant;
