import React from 'react';
import {Button, Chip, FormControl, Grid, TextField, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ErrorHelper, FormikValues} from "../formikFormInputs";
import {CommonProps, FieldLabelEnum, TRAVEL_QUESTION_CODE} from "./index";
import {InputChangeFunc} from "../../model/fields";
import {isDebug, isFunc, sleep, va} from "../../utils/commonUtils";
import {NS} from "../../model/misc";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface SearchProps extends CommonProps, FormikValues {
    ansValues?: any[];
    answerValues?: any[];
    defaultAnsValues?: any[];
    hideLabel?: boolean;
    isPhysician?: boolean;
    isSpecialCase?: boolean;
    multiple?: boolean;
    onChange?: (event: React.ChangeEvent<{}>, value: any | null) => void;
    onSearch?: ((event: React.ChangeEvent<{}>, value: string, reason: ("input" | "reset" | "clear")) => void) | undefined;
    options?: NS[] | undefined;
    searching?: boolean | undefined;
}


const SearchBoxWithAddNewConfirm: React.FC<SearchProps> = (props) => {
    const {
        alias,
        answerValues,
        defaultAnsValues,
        errors,
        helperText,
        hideLabel,
        isPhysician,
        isSpecialCase,
        label,
        multiple,
        name,
        onSearch,
        options,
        searching,
        setFieldValue,
        values
    } = props;

    const [addNew, setAddNew] = React.useState(true);
    const [searchVal, setSearchVal] = React.useState("");

    const onToggleAddAndClearSearch = (isAdd: boolean) => {
        setAddNew(isAdd);
        setSearchVal("");
    };
    const onAddNew = () => onToggleAddAndClearSearch(true);
    const onCancelAddNew = () => onToggleAddAndClearSearch(false);
    const onConfirm = () => {
        setAddNew(false);
        const existingValues = values[name] || [];
        if (existingValues.includes(searchVal)) {
            isDebug() && console.info('Already exists!!!', searchVal, existingValues);
            return
        }
        setFieldValue(name, [...existingValues, searchVal]);
    };
    const onFieldChange = (e, value: string | string[]) => {
        const val = va(value) ? value[0] : value as string;
        if (!val) {
            return;
        }
        if (val && !val.trim()) {
            return;
        }

        setSearchVal(val);
    };
    const onInpChange: InputChangeFunc = (e, v, r) => {
        setSearchVal(v && v.trim());
        if (onSearch && isFunc(onSearch)) {
            onSearch(e, v, r);
        }
    };
    const renderInput = (params) => (
            <TextField
                    {...params}
                    label={isPhysician ? FieldLabelEnum.SearchPhysician : FieldLabelEnum.TypeToSearch}
                    variant="outlined"
            />
    );
    const onDelete = (val: string) => {
        const existingValues = values[name] || [];
        const filteredValues = existingValues.filter(n => n.toLowerCase() !== val.toLowerCase());

        console.info({existingValues, filteredValues, name, val})
        setFieldValue(name, filteredValues);
    };

    React.useEffect(() => {
        if (isSpecialCase) {
            sleep(200).then(() => setFieldValue(name, defaultAnsValues && !!va(defaultAnsValues) ? defaultAnsValues : []));
        }
    }, []);

    return (
            <>
                <Grid item xs={12}>
                    {
                            alias && !hideLabel &&
                            <Typography className="mb10" variant="body1">
                                {alias}
                            </Typography>
                    }
                    {
                            label && !hideLabel &&
                            <Typography className="mb10" variant="body1">
                                {label}
                            </Typography>
                    }
                </Grid>
                {
                        values && name && values[name] && !!va(values[name]) &&
                        <Grid item xs={12}>
                            {
                                    values[name].map((value, key) => (
                                            <Chip
                                                    className="mr10 mb5"
                                                    key={key}
                                                    color="primary"
                                                    label={value}
                                                    onDelete={() => onDelete(value)}
                                                    deleteIcon={<HighlightOffIcon className="colorRed" />}
                                                    variant="outlined"
                                            />
                                    ))
                            }
                        </Grid>
                }
                {
                        addNew &&
                        <Grid item xs={12} md={8}>
                            <FormControl fullWidth={true} variant="outlined">
                                <Autocomplete
                                        defaultValue={defaultAnsValues}
                                        filterOptions={opt => opt}
                                        filterSelectedOptions={true}
                                        freeSolo={true}
                                        loading={searching}
                                        loadingText="Searching..."
                                        multiple={(multiple !== false) as any}
                                        onChange={onFieldChange}
                                        onInputChange={onInpChange}
                                        options={options as any[]}
                                        renderInput={renderInput}
                                        value={answerValues}
                                />
                                {
                                        helperText && !hideLabel &&
                                        <Typography className="floatLeft w100 f16 textBlackLight" variant="caption">
                                            {helperText}
                                        </Typography>
                                }
                                {
                                        !hideLabel &&
                                        <ErrorHelper errors={errors} name={name}/>
                                }
                            </FormControl>
                        </Grid>
                }
                {
                        addNew && !!searchVal &&
                        <Grid item xs={12} md={4}>
                            <Button
                                    className="floatLeft"
                                    color="primary"
                                    onClick={onConfirm}
                                    type="button"
                                    variant="contained"
                            >
                                Confirm
                            </Button>
                        </Grid>
                }
                {
                        values && name && values[name] &&
                        <Grid item xs={12}>
                            <>
                                {
                                        !addNew &&
                                        <Button
                                                className="floatRight ml15"
                                                color="primary"
                                                onClick={onAddNew}
                                                type="button"
                                                variant="text"
                                        >
                                            Add Another
                                        </Button>
                                }
                                {
                                        addNew &&
                                        <Button
                                                className="floatRight ml15"
                                                onClick={onCancelAddNew}
                                                type="button"
                                                variant="contained"
                                        >
                                            Cancel
                                        </Button>
                                }
                            </>
                        </Grid>
                }
            </>
    );
};


export default SearchBoxWithAddNewConfirm;
