import * as React from "react";
import TextField from "@material-ui/core/TextField";

interface FieldProps {
    field: {
        name: string,
        onChange: Function,
    },
    placeholder: string,
    label: string,
    value: string,
    helperText?: string,
    cb?: Function,
    onChange: Function
}

const Text = (props: FieldProps) => {

    const { field: { name }, placeholder, label, value, cb, onChange, ...custom } = props;
    return <TextField
            fullWidth
            name={name}
            placeholder={placeholder}
            variant="outlined"
            label={label}
            value={value ? value: ""}
            onChange={(e) => {
                onChange(e);
                if (typeof cb === "function") {
                    cb(e);
                }
            }}
            {...custom}
    />;
};

export default Text;