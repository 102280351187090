import React from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    makeStyles,
    Checkbox,
    Button,
    Box,
    TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { DeleteModel } from "../../../components/formInputs";
import { useActions } from "../../../actions";
import * as AccountActions from "../../../actions/account";
import TableWrap from "../../../components/TableWrap/TableWrap";


export const useStyles = makeStyles({
    table: {
        minWidth: 800,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
    KpiModalRow: {
        textDecoration: "none",
    },
    container: {
        maxHeight: 600,
    },
});

type ExtraFields = {
    head: React.ReactNode;
    body: (client: any) => React.ReactNode;
}

type TableQuickViewProps = {
    kpiData: any[];
    extraFields?: ExtraFields;
    refreshTable?: () => void;
    selectable: boolean;
    onRefresh: () => void;
}

const defaultExtraFields = {
    head: null,
    body(client) {
        return null;
    },
};

export const useCheckboxSelect = (kpiData: any) => {

    const [selected, setSelected] = React.useState<any[]>([]);


    const isSelected = row => selected.indexOf(row) !== -1;

    const handleSelectOneClick = (account) => {
        if (isSelected(account)) {
            setSelected(selected.filter(x => x !== account));
        } else {
            setSelected([...selected, account]);
        }
    };


    const clearSelected = () => setSelected([]);


    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected(kpiData);
            return;
        }
        clearSelected();
    };

    let rowCount = kpiData ? kpiData.length : 0;
    const anySelected = selected.length > 0 && selected.length < rowCount;
    const allSelected = rowCount > 0 && selected.length === rowCount;

    return {
        handleSelectOneClick,
        isSelected,
        selected,
        clearSelected,
        handleSelectAllClick,
        anySelected,
        allSelected,
    };
};

const isEnrolling = x => x.inviteStatus >= 4;
const isNotEnrolling = x => !isEnrolling(x);

export const TableQuickView = ({
                                   kpiData,
                                   extraFields = defaultExtraFields,
                                   selectable,
                                   onRefresh,
                               }: TableQuickViewProps) => {
    const classes = useStyles();
    const {
        handleSelectOneClick,
        isSelected,
        selected,
        clearSelected,
        handleSelectAllClick,
        anySelected,
        allSelected,
    } = useCheckboxSelect(kpiData);

    const [isVisibleDeleteModal, setIsVisibleDeleteModal] = React.useState(false);
    const accountActions = useActions(AccountActions);

    const selectedNotEnrolled = selected.filter(isNotEnrolling);

    return (
            <>
                {
                        selected.length > 0 &&
                        <Box p={1}>
                            <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setIsVisibleDeleteModal(true);
                                    }}>
                                Delete selected
                            </Button>
                        </Box>
                }

                <TableWrap>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {selectable &&
                                    <TableCell align="left">
                                        <Checkbox
                                                color="primary"
                                                indeterminate={anySelected}
                                                checked={allSelected}
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    "aria-label": "select all",
                                                }}
                                        />
                                    </TableCell>
                            }
                            <TableCell align="left">First Name</TableCell>
                            <TableCell align="left">Last Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Application Type</TableCell>
                            <TableCell align="left">Participant Type</TableCell>
                            <TableCell align="left">ilia product type</TableCell>
                            <TableCell align="left">Carrier</TableCell>
                            {extraFields.head}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            kpiData.map((account, i) => {
                                const isItemSelected = isSelected(account);

                                return (
                                        <TableRow key={i}>
                                            {
                                                    selectable &&
                                                    <TableCell>
                                                        <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                onChange={() => handleSelectOneClick(account)}
                                                                inputProps={{
                                                                    "aria-labelledby": "individual",
                                                                }}
                                                        />
                                                    </TableCell>
                                            }
                                            <TableCell align="left">
                                                {account.clientId ?
                                                        <Link className={classes.KpiModalRow}
                                                              to={"/new-client/detail/" + account.clientId}>{account.firstName}</Link> :
                                                        account.firstName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {account.clientId ?
                                                        <Link className={classes.KpiModalRow}
                                                              to={"/new-client/detail/" + account.clientId}>{account.lastName}</Link> :
                                                        account.lastName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {account.clientId ?
                                                        <Link className={classes.KpiModalRow}
                                                              to={"/new-client/detail/" + account.clientId}>{account.email}</Link> :
                                                        account.email}
                                            </TableCell>
                                            <TableCell align="left">
                                                {account.purpose}
                                            </TableCell>
                                            <TableCell align="left">
                                                {account.participantType}
                                            </TableCell>
                                            <TableCell align="left">
                                                {account.strategy}
                                            </TableCell>
                                            <TableCell align="left">
                                                {account.carrier}
                                            </TableCell>
                                            {extraFields.body(account)}
                                        </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </TableWrap>
                <DeleteModel
                        open={isVisibleDeleteModal}
                        disableDelete={cantDelete(selected)}
                        handleClose={() => setIsVisibleDeleteModal(false)}
                        handleYes={async () => {
                            setIsVisibleDeleteModal(false);
                            await accountActions.deleteAccounts(selectedNotEnrolled);
                            clearSelected();
                            onRefresh();
                        }}
                        accountType="Client"
                        title="Delete confirmation"
                        content={deleteDialogContent(selected)}
                />
            </>
    );
};

export const usersDesc = users => users.length == 1 ?
        `${users[0].firstName} ${users[0].lastName}` :
        `${users.length} user/s`;

const deleteDialogContent = (users: any[]) => {
    const usersNotEnrolling = users.filter(isNotEnrolling);

    if (users.length === usersNotEnrolling.length) {
        return `Are you sure you want to delete ${usersDesc(users)} permanently from ilia ?`;
    }

    if (users.length == 1) {
        return "You can't delete this account, the account selected is currently in the enrollment process.";
    }

    const usersEnrolling = users.filter(isEnrolling);

    return `We are not able to delete all selected accounts. 
        There is/are accounts currently in the enrollment process: ${usersEnrolling.map(user => user.firstName + " " + user.lastName).join(", ")}.
        Would you like to permanently delete all other accounts?`;
};


const cantDelete = (users: any[]) => {
    const usersNotEnrolling = users.filter(isNotEnrolling);

    if (users.length === usersNotEnrolling.length) {
        return false;
    }

    if (users.length == 1) {
        return true;
    }

    return false;
};