import React from "react";
import {makeStyles, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography,} from "@material-ui/core";
import {pez, va} from '../../utils/commonUtils';
import TableWrap from "../TableWrap/TableWrap";

type T = any;

interface TableViewProps<T> {
    footer?: T[];
    headers: T[];
    headersCss?: string[];
    list: T[];
    noRowsMsg?: string;
}

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        overflowX: "auto",
    },
    tableHead: {
        backgroundColor: "#F4F6F8",
        color: "#01305F",
        padding: "15px",
    },
});

const TableView: React.FC<TableViewProps<T>> = (props) => {
    const classes = useStyles();
    const noRows = !va(props.list);
    return (
            <TableWrap>
                {
                        props.headers && props.headers.length &&
                        <TableHead>
                            <TableRow hover>
                                {
                                    props.headers.map((headerContent: T, n: number) => {
                                        const customTdClassName = props.headersCss && !!va(props.headersCss) && props.headersCss[n];
                                        return (
                                                <TableCell className={`${classes.tableHead} ${customTdClassName}`} key={n}>
                                                    {
                                                        headerContent
                                                    }
                                                </TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        </TableHead>
                }
                <TableBody>
                    {
                            noRows &&
                            <TableRow>
                                <TableCell colSpan={props.headers.length}>
                                    <Typography variant="caption" align="center" className="floatLeft w100">
                                        {
                                            pez(props.noRowsMsg, "No Data Found")
                                        }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                    }
                    {
                            !noRows &&
                            props.list.map((listRow: T, idx: number) => {
                                return (
                                        <TableRow key={idx}>
                                            {
                                                Object.values(listRow).map((dataValue: T, ix: number) =>
                                                        (<TableCell key={ix}>{dataValue}</TableCell>))
                                            }
                                        </TableRow>
                                );
                            })
                    }
                </TableBody>
                {
                        props.footer && props.footer.length &&
                        <TableFooter>
                            {
                                props.footer.map((row: T, key: number) => (
                                        <TableRow key={key}>
                                            {
                                                Object.values(row).map((dataValue: T, ix: number) =>
                                                        (<TableCell key={ix}>{dataValue}</TableCell>))
                                            }
                                        </TableRow>
                                ))
                            }
                        </TableFooter>
                }
            </TableWrap>
    );
};

export default TableView;
