import * as React from "react";
import qs from "query-string";
import { CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { Button, Label } from "../../../../../components/formInputs";
import MiTextField from "@material-ui/core/TextField";
import { useActions } from "../../../../../actions";
import * as VerifyActions from "../../../../../actions/verify";
import {
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_ONE_SPECIAL_CHAR,
    PWD_TOW_SPECIAL_CHAR,
    PWD_TWO_CAPITAL_LETTER_REGEX,
    PWD_TWO_NUMBER_REGEX,
    PWD_TWO_SMALL_LETTER_REGEX,
} from "../../../../../constants";
import LandingPasswordRequired from "../../../../../components/LandingPasswordRequired";
import LandingContactForm from "../LandingContactForm";
import SystemError from "../../../../../components/client/landingPage/v2/SystemError";
import { makeQueryString } from "../../../../../utils/commonUtils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PasswordRequiredWithStrength from "../../../../../components/PasswordStrength";

const EstmateSectionTwo = (props: any) => {
    const inviteToken: string = props.match.params.inviteToken;
    const [lastName, setLastName] = React.useState("");
    const [lastNameError, setLastNameError] = React.useState("");
    const [email, setEmail] = React.useState(props.email);
    const [emailError, setEmailError] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [newPasswordError, setNewPasswordError] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
    const verifyActions = useActions(VerifyActions);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [oneLetter, setOneLetter] = React.useState(false);
    const [showPasswordRequirementSection, setShowPasswordRequirementSection] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const [twoSpecialChars, setTwoSpecialChars] = React.useState(false);
    const [twoNumbers, setTwoNumbers] = React.useState(false);
    const [twoCapitalLetters, setTwoCapitalLetters] = React.useState(false);
    const [twoSmallLetters, setTwoSmallLetters] = React.useState(false);

    const [systemError, setSystemError] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState(0);
    const [verificationToken, setVerificaionToken] = React.useState(localStorage.getItem("virificationToken") ? localStorage.getItem("virificationToken") : "");
    const [touched, setTouched] = React.useState(false);
    const searchParams = qs.parse(props.location.search);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 100;
                return Math.min(oldProgress + diff, 60);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const onPassWordChange = (e: any): void => {
        const val = e.target.value;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
        PWD_TOW_SPECIAL_CHAR.test(val) ? setTwoSpecialChars(true) : setTwoSpecialChars(false);
        PWD_TWO_NUMBER_REGEX.test(val) ? setTwoNumbers(true) : setTwoNumbers(false);
        PWD_TWO_CAPITAL_LETTER_REGEX.test(val) ? setTwoCapitalLetters(true) : setTwoCapitalLetters(false);
        PWD_TWO_SMALL_LETTER_REGEX.test(val) ? setTwoSmallLetters(true) : setTwoSmallLetters(false);
        setNewPassword(e.target.value);

        val ?
                ((!PWD_ONE_LETTER_REGEX.test(val) || !PWD_ONE_CAPITAL_LETTER_REGEX.test(val) || !PWD_ONE_NUMBER_REGEX.test(val) || !PWD_MIN_EIGHT_CHAR_REGEX.test(val) || !PWD_ONE_SPECIAL_CHAR.test(val)) ? setNewPasswordError("Enter valid password") : setNewPasswordError(""))
                :
                setNewPasswordError("Required");
    };

    const onConfirmPasswordChange = (e: any): void => {
        const val = e.target.value;
        setConfirmPassword(val);
        val ? val === newPassword ? setConfirmPasswordError("") : setConfirmPasswordError("Password mismatched")
                : setConfirmPasswordError("Required");
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const submitForm = async () => {
        let error: boolean = false;
        if (
                [props.ALLOWED_ACCOUNT_TYPE.GROUP, props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST].indexOf(props.accountType) > -1
        ) {
            if (!lastName) {
                error = true;
                setLastNameError("Required");
            }
            if (!email) {
                error = true;
                setEmailError("Required");
            }
            if (!newPassword) {
                error = true;
                setNewPasswordError("Required");
            }

            if (!confirmPassword) {
                error = true;
                setConfirmPasswordError("Required");
            }

            if (newPassword && confirmPassword && (newPassword !== confirmPassword)) {
                error = true;
                setConfirmPasswordError("Password mismatched");
            }
        }

        if (error) {
            return false;
        }

        setLoading(true);
        setTouched(false);
        let result: any = await verifyActions.checkNewInvitationAuth({
            authType: props.authenticType,
            accountType: props.accountType,
            lastName: lastName || searchParams.lastName,
            email: email || searchParams.email,
            companyId: props.companyId,
            clientListId: props.companyId,

        });

        setLoading(false);
        if (result && result.payload && result.payload.statusCode >= 400) {
            setSystemError(true);
            return false;
        }
        if (result && result.payload) {
            let urlObject = {};
            let queryString: string = "";
            localStorage.setItem("showPasswordScreen", "1");
            localStorage.setItem("virificationToken", result.payload.token);
            setVerificaionToken(result.payload.token);
            setSystemError(false);
            createPassword(result.payload.token);
            if (searchParams.landing == "2") {
                let pageData = {
                    lastName: lastName,
                    email: email,
                };
                urlObject = { ...pageData, ...searchParams };
                queryString = makeQueryString(urlObject);
            }
            props.history.push({
                pathname: window.location.pathname,
                search: queryString,
            });
        }
    };

    const createPassword = async (token: string = "") => {
        let error: boolean = false;

        if (!newPassword) {
            error = true;
            setNewPasswordError("Required");
        }

        if (!confirmPassword) {
            error = true;
            setConfirmPasswordError("Required");
        }

        if (newPassword && confirmPassword && (newPassword !== confirmPassword)) {
            error = true;
            setConfirmPasswordError("Password mismatched");
        }

        if (error) {
            return false;
        }

        if (
                oneLetter && oneNumber && oneCapitalLetter && eightCharacter && oneSpecialChar
        ) {
            setLoading(true);
            setTouched(false);
            let result: any;
            switch (props.accountType) {
                case props.ALLOWED_ACCOUNT_TYPE.GROUP :
                    result = await verifyActions.checkAuthAndCreatePassword({
                        password: newPassword,
                        confirmPassword: newPassword,
                        inviteToken: inviteToken,
                        authType: props.authenticType,
                        accountType: props.accountType,
                        lastName: lastName || searchParams.lastName,
                        email: email || searchParams.email,
                        verificationToken: token,
                        companyId: props.companyId,
                        groupDetail: props.groupDetail,
                    });
                    break;
                case props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST :
                    result = await verifyActions.checkClientListAuthAndCreatePassword({
                        password: newPassword,
                        confirmPassword: newPassword,
                        lastName: lastName || searchParams.lastName,
                        email: email || searchParams.email,
                        verificationToken: token,
                        clientListId: props.companyId,
                        clientListDetail: props.groupDetail,
                    });
                    break;
                default :
                    result = await verifyActions.createPassword({
                        password: newPassword,
                        confirmPassword: newPassword,
                        inviteToken: inviteToken,
                    });
            }
            if (result && result.payload && result.payload.statusCode >= 400) {
                setLoading(false);
                setTouched(false);
                setSystemError(true);
                return false;
            }
            if (result && result.payload) {
                localStorage.removeItem("showPasswordScreen");
                localStorage.removeItem("virificationToken");
                setSystemError(false);
                props.setStartEstimation(3);
            }
        }
    };
    const validateEmail = (email: string) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleBack = () => {
        props.setStartEstimation(1);
    };

    let redirectUrl: string = props && props.match && props.match.url;
    let searchUrl: string = props && props.location.search;
    let encodeUrl: string = "";
    let url: string = "";
    encodeUrl = btoa(redirectUrl);
    if (searchUrl) {
        encodeUrl = btoa(redirectUrl + searchUrl);
    }
    if (redirectUrl) {
        url = `/login?token=${encodeUrl}`;
    }
    return (
            <Grid container justify="center">
                <Grid item xs={12} md={11} lg={11} className="textLeft">
                    <Grid container spacing={2} justify="center">
                        <Typography variant="h3" color="primary" className="floatLeft w100 mb15">
                            {[props.ALLOWED_ACCOUNT_TYPE.GROUP, props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST].indexOf(props.accountType) > -1 ?
                                `Estimate Your Returns` : `Create Account`}
                        </Typography>
                        <Typography variant="body1" className="floatLeft w100 mb20">
                            {props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST === props.accountType ? "Validate your information to view your potential benefits. " :
                                    props.ALLOWED_ACCOUNT_TYPE.GROUP === props.accountType ? `Verify you are a member of ${props.groupDetail.companyName} to view your potential benefits. ` :
                                            "Legally, we need for you to create an account to show you any estimates. You will be able to return and view your estimate at any time. "}
                        </Typography>
                        <Grid item xs={12} md={12} lg={12} className="mt20">
                            <Grid container spacing={2} justify="center">

                                {[props.ALLOWED_ACCOUNT_TYPE.GROUP, props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST].indexOf(props.accountType) > -1 &&
                                        <Grid item xs={12}>
                                            <MiTextField
                                                    id="outlined-number01"
                                                    label={<Label label={"Last Name"} required />}
                                                    variant="outlined"
                                                    fullWidth
                                                    color="primary"
                                                    name="lastName"
                                                    placeholder="last Name"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onFocus={(e) => {
                                                        lastName ? setLastNameError("") : setLastNameError("Required");
                                                    }}
                                                    onChange={(e) => {
                                                        setLastName(e.target.value);
                                                        setTouched(true);
                                                        e.target.value ? setLastNameError("") : setLastNameError("Required");
                                                    }}
                                                    error={!!lastNameError}
                                                    helperText={lastNameError ? "Required" : ""}
                                            />
                                        </Grid>
                                }
                                <Grid item xs={12}>
                                    <MiTextField
                                            label={<Label label={"Your Email"} required />}
                                            id="outlined-basic"
                                            variant="outlined"
                                            fullWidth
                                            color="primary"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className="mt10"
                                            disabled={[props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL].indexOf(props.accountType) > -1 ? true : false}
                                            defaultValue={email}
                                            onFocus={(e) => {
                                                email ? setEmailError("") : setEmailError("Required");
                                            }}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setTouched(true);
                                                e.target.value ?
                                                        (!validateEmail(e.target.value) ? setEmailError("Please enter valid email") : setEmailError(""))
                                                        :
                                                        setEmailError("Required");
                                            }}
                                            error={emailError ? true : (email ? !validateEmail(email) : false)}
                                            helperText={emailError}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                            className="mt10"
                                            id="outlined-number2-password"
                                            label={<Label label={"Create a Password"} required={true} />}
                                            fullWidth
                                            type={"password"}
                                            color="primary"
                                            variant="outlined"
                                            onFocus={(e) => {
                                                newPassword ? setNewPasswordError("") : setNewPasswordError("Required");
                                                setShowPasswordRequirementSection(true);
                                                setTouched(true);
                                            }}
                                            onChange={(e) => {
                                                onPassWordChange(e);
                                            }}
                                            error={!!newPasswordError}
                                            helperText={newPasswordError}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                            className="mt10"
                                            id="outlined-number2-password"
                                            label={<Label label={"Confirm Your Password"} required={true} />}
                                            fullWidth
                                            type={"password"}
                                            color="primary"
                                            variant="outlined"
                                            onFocus={(e) => {
                                                setTouched(true);
                                            }}
                                            onChange={(e) => {
                                                onConfirmPasswordChange(e);
                                            }}
                                            error={!!confirmPasswordError}
                                            helperText={confirmPasswordError}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    {[props.ALLOWED_ACCOUNT_TYPE.GROUP, props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST].indexOf(props.accountType) > -1 &&
                                            systemError && <SystemError accountType={props.accountType}
                                                                        groupDetails={props.groupDetail} />
                                    }
                                    <Button
                                            size="small"
                                            label={loading ?
                                                    <CircularProgress
                                                            size={18} /> : "View Results"}
                                            type="submit"
                                            onClick={() => {
                                                if (props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL === props.accountType) {
                                                    createPassword();
                                                    return false;
                                                }
                                                submitForm();
                                            }}
                                            disabled={!touched}
                                            className={`floatRight mb15 mt10`}
                                            variant="contained"
                                            color="primary"
                                    />
                                    <ArrowBackIcon
                                            onClick={handleBack}
                                            className="floatLeft"
                                            style={{ fontSize: 40, cursor: "pointer" }} />
                                    {showPasswordRequirementSection &&
                                            <>
                                                <div className="mt15 mb15 floatLeft w100">
                                                    <PasswordRequiredWithStrength password={newPassword} />
                                                </div>
                                                <LandingPasswordRequired oneLetter={oneLetter} oneNumber={oneNumber}
                                                                         oneCapitalLetter={oneCapitalLetter}
                                                                         eightCharacter={eightCharacter}
                                                                         oneSpecialChar={oneSpecialChar} />


                                            </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <LandingContactForm setOpen={(isOpen: boolean) => setOpen(isOpen)} open={open} {...props} />
                    </Grid>
                </Grid>
            </Grid>

    );
};

export default EstmateSectionTwo;
