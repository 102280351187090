import React from "react";
import {Card, CardContent, Typography} from "@material-ui/core";
import * as Yup from "yup";
import {useActions} from "../../actions";
import * as ApplicationActions from "../../actions/application";
import {EditQA, NextQuestion, SavePayload, UnParsedListItem} from "../../model/application";
import {InputChangeFunc} from "../../model/fields";
import {
    CaseDataTypeEnum,
    DynamicChildrenForm,
    DynamicFormFieldEnum,
    FinalizeApplication,
    getOptions,
    NO_MORE_MSG,
    PRIMARY_PHYSICIAN_DISCLOSURE_CODE,
    SPECIAL_NULL,
    YupTestCases,
} from "./index";
import {FormikValues, FormikWrapper} from "../formikFormInputs";
import {isDebug, isFunc, isValidObject, pez, tis, va} from "../../utils/commonUtils";
import {
    ALPHABET_ONLY,
    ALPHABET_ONLY_REGEX,
    ALPHABET_WITH_HYPHEN_DOT_REGEX,
    INCORRECT_FORMAT,
    REQUIRED_FIELD,
    ZIP_CODE_CHAR
} from "../../constants";
import {generateSaveAnswerPayload} from "./utilities";
import {BS} from "../../model/misc";
import {checkIncorrectPhoneNo, noAncientDate, noFutureDate} from "../../utils/validation";


type DynamicFormProps = {
    applicationId?: string;
    editAns?: EditQA;
    enrollmentId: string;
    onCloseEdit?: Function;
    onUpdateProgress?: Function;
    setApplicationId?: Function;
    setBasicInfoStep?: Function
};

const WrapperMain: React.FC<DynamicFormProps> = (props) => {
    const debug = isDebug();
    const {
        applicationId,
        editAns,
        enrollmentId,
        onCloseEdit,
        onUpdateProgress,
        setApplicationId,
        setBasicInfoStep
    } = props;
    const applicationActions = useActions(ApplicationActions as any);
    const [fields, setFields] = React.useState<any[]>([]);
    const [forceNo, setForceNo] = React.useState(true);
    const [initialValues, setInitialValues] = React.useState<any>({});
    const [isCountryUSA, setIsCountryUSA] = React.useState<boolean>(true);
    const [isHeadingType, setIsHeadingType] = React.useState<boolean>(false);
    const [nextLoading, setNextLoading] = React.useState<boolean>(false);
    const [nextQuestionJSON, setNextQuestionJSON] = React.useState<NextQuestion>({} as NextQuestion);
    const [hasPhysicians, setHasPhysicians] = React.useState<BS>(true);
    const [searching, setSearching] = React.useState<boolean>(false);
    const [searchOptions, setSearchOptions] = React.useState<string[]>([]);
    const [stoppedErr, setStoppedErr] = React.useState('');
    const [subApp, setSubApp] = React.useState(false);
    const [validationKeys, setValidationKeys] = React.useState<{ [key: string]: any }>({});
    const heading60Plus = "For applicants 60 and older, please answer the following questions:";
    const headingMedicalOther = "In the last 10 years, have you received advice, treatment, or a diagnosis from a medical professional for:";
    const MedicalHeadings = {
        '2B_BRAIN_NERVOUS': headingMedicalOther,
        '2B_CONNECTIVE_TISSUE': headingMedicalOther,
        '2B_ENT': headingMedicalOther,
        '2B_GASTRO': headingMedicalOther,
        '2B_HEART': headingMedicalOther,
        '2B_KIDNEY_REPROD': headingMedicalOther,
        '2B_LIVER': headingMedicalOther,
        '2B_MENTAL_HEALTH': headingMedicalOther,
        '2B_MUSCULOSKELETAL': headingMedicalOther,
        '2B_OVER60_ADL': heading60Plus,
        '2B_OVER60_COGNITIVE': heading60Plus,
        '2B_OVER60_MOBILITY': heading60Plus,
        '2B_RESPIRATORY': headingMedicalOther,
    };
    const validationSchema = Yup.object().shape(validationKeys);

    const onClearSearch = () => {
        debug && console.info('onClearSearch', searchOptions);
        setSearchOptions([]);
    };

    const onSearch: InputChangeFunc = async (e, value, reason) => {
        if (reason === "clear" || reason === "reset") {
            debug && console.info(e, value, reason);
            setSearchOptions([]);
        }

        if (!value) {
            return;
        }

        if (searching) {
            // set cool-down while searching
            return;
        }

        debug && console.info(value, searchOptions, searching, nextQuestionJSON);

        if (nextQuestionJSON && (nextQuestionJSON.disclosureSource === DynamicFormFieldEnum.Search || nextQuestionJSON.disclosureSource === DynamicFormFieldEnum.Picklist) && nextQuestionJSON.category) {
            setSearching(true);

            // start api
            const payload = {
                enrollmentId: enrollmentId,
                code: nextQuestionJSON.category.code,
                search: value,
                limit: "100"
            };
            const results = await applicationActions.fetchSearchOptions(payload);
            setSearchOptions(results);

            debug && console.info(value, results)
            setSearching(false);
        }
    };

    /**
     * submit answer
     * @param values
     * @param resetForm
     * @param setFieldError
     * @param setFieldValue
     */
    const onSubmit = async (values: FormikValues, {resetForm, setFieldError, setFieldValue}: FormikValues) => {
        if (!isValidObject(values) && hasPhysicians) {
            return;
        }

        const firstKey = Object.keys(values)[0];
        // extra fielding
        if (firstKey && values[firstKey] && typeof values[firstKey] === 'string' && !values[firstKey].trim()) {
            setFieldError(firstKey, REQUIRED_FIELD);
            return;
        }

        const isUpdated = editAns && editAns.code === firstKey;

        debug && console.info('values, editAns, {isUpdated}', values, editAns, {isUpdated});

        onClearSearch();

        setNextLoading(true);

        const type = nextQuestionJSON.answerType || nextQuestionJSON.questionType;
        if (type === DynamicFormFieldEnum.Physician) {
            // save physician
            const isPrimary = nextQuestionJSON.disclosureCode === PRIMARY_PHYSICIAN_DISCLOSURE_CODE;
            const commonPhy: any = {
                baseQuestionCode: nextQuestionJSON.baseQuestionCode,
                enrollmentId: enrollmentId,
                hasPhysicians: hasPhysicians.toString(),
            };
            if (isPrimary) {
                commonPhy.hasPhysicians = isPrimary.toString();
                commonPhy.primaryPhysician = isPrimary;
            } else {
                commonPhy.disclosureCode = nextQuestionJSON.disclosureCode;
            }
            // validate extra spaces
            const physicianValues: any = {};
            Object.keys(values).forEach(v =>
                    Object.assign(physicianValues, {[v]: tis(values[v])})
            )
            if ((!physicianValues.city || !physicianValues.name) && hasPhysicians) {
                // throw error, cant be empty
                if (!physicianValues.city) {
                    setFieldError('city', REQUIRED_FIELD);
                    setFieldValue('city', '');
                }
                if (!physicianValues.name) {
                    setFieldError('name', REQUIRED_FIELD);
                    setFieldValue('name', '');
                }
                setNextLoading(false);
                return;
            }
            if (!physicianValues.state) {
                physicianValues.state = 'NA';
            }
            if (physicianValues.hasExistingPhysician) {
                physicianValues.hasExistingPhysician = undefined;
            }
            const isSaved = await applicationActions.submitPhysician(
                    Object.assign({}, physicianValues, commonPhy)
            );
            if (!isSaved) {
                setNextLoading(false);
                return;
            }
        } else if (type !== DynamicFormFieldEnum.Heading) {
            // answer payload
            const payload: SavePayload = generateSaveAnswerPayload(enrollmentId, isUpdated, nextQuestionJSON, values);

            // save process
            const isSaved = await applicationActions.submitAnswer(payload);
            if (!isSaved) {
                setNextLoading(false);
                return;
            }
        }

        // get next question
        await fetchNextQuestion(enrollmentId);

        setNextLoading(false);

        return resetForm();
    };

    const formProps = {
        children: DynamicChildrenForm,
        editAns,
        enableReinitialize: true,
        enrollmentId,
        fields,
        forceNo,
        initialValues,
        isHeadingType,
        loading: nextLoading,
        onClearSearch,
        onSearch,
        onSubmit,
        hasPhysicians,
        searching,
        searchOptions,
        setForceNo,
        setIsCountryUSA,
        setHasPhysicians,
        stoppedErr,
        validationSchema,
    };

    /**
     * get question details
     */
    const fetchQuestionDetails = async (questionId: string) => {
        const getQuestionPayload = {enrollmentId: enrollmentId, questionId};
        return applicationActions.getQuestion(getQuestionPayload);
    };

    const parseCaseDataSelection = (question) => {
        const isSpecialCaseDataSelectionCase = question && question.questionType === DynamicFormFieldEnum.CaseData && question.caseDataQuestionMeta.dataType === CaseDataTypeEnum.Selection && question.caseDataQuestionMeta.list && question.caseDataQuestionMeta.list.listItems;
        if (isSpecialCaseDataSelectionCase) {
            debug && console.info('caseDataQuestionMeta', question.caseDataQuestionMeta);

            const origListItems = question.caseDataQuestionMeta.list.listItems as UnParsedListItem[];
            const listItems = origListItems.map(({label, value}) => ({text: label, value}))
                    .filter(i => i.text.toLowerCase() !== 'unknown')
                    .reverse();
            Object.assign(question, {listItems});
        }
    };

    const fetchNextQuestion = async (enrollmentId: string) => {
        // get next question
        const data = await applicationActions.getNextQuestion({enrollmentId});

        // all questions answered not answered but no next question maybe some error occurred
        if (data && data.message === "" && data.nextQuestion && va(data.nextQuestion) === 0) {
            const NO_NEXT_QUESTION_MSG = 'No Next Question, Please contact site administrator';
            debug && console.log(data, NO_NEXT_QUESTION_MSG);
            setStoppedErr(NO_NEXT_QUESTION_MSG);
        }

        // all questions answered
        if (data && data.message === NO_MORE_MSG) {
            debug && console.log(data.message, '=== The End ===', NO_MORE_MSG);
            // update the status locally
            // setBasicInfoStep("1.1"); // commented w.r.t summary page

            // if finalize already open, close
            if (!!editAns && onCloseEdit && isFunc(onCloseEdit)) {
                return onCloseEdit();
            }

            // open submit application popup
            setSubApp(true);
        }

        // got the next question
        if (data && data.nextQuestion) {
            // special case for physician
            if (data.nextQuestion.physicianDetailsRequired) {
                Object.assign(data.nextQuestion, {
                    answerType: "PHYSICIAN",
                    baseQuestionCode: data.nextQuestion.baseQuestionCode,
                    disclosureCode: data.nextQuestion.aliasName || data.nextQuestion.disclosureCode,
                    questionText: data.nextQuestion.message,
                    questionType: "PHYSICIAN",
                });
            }

            // special case data where datatype is selection
            parseCaseDataSelection(data.nextQuestion);

            // set next-json so the form is updated
            setNextQuestionJSON(data.nextQuestion);
            const type = data.nextQuestion.answerType || data.nextQuestion.questionType;
            const allowNumbersOnly = (data.nextQuestion.dataType === CaseDataTypeEnum.Numeric || type === DynamicFormFieldEnum.Number);

            debug && console.info({allowNumbersOnly, type})

            setIsHeadingType(type === DynamicFormFieldEnum.Heading);
        }

        // progress update
        if (onUpdateProgress && isFunc(onUpdateProgress)) {
            onUpdateProgress(data && data.nextQuestion && data.nextQuestion.baseQuestionCode);
        }
    };

    const onStartQuestionnaire = async () => {
        if (applicationId) {
            setNextLoading(true);
            await fetchNextQuestion(enrollmentId);
            setNextLoading(false);
        }
    };

    const validatePhysician = (initialValues: {}, _keyValidation: {}) => {
        const isPrimary = nextQuestionJSON.disclosureCode === PRIMARY_PHYSICIAN_DISCLOSURE_CODE;
        const physicianFields = hasPhysicians ? ["city", "country", "name", "state"] : [];
        if (hasPhysicians) {
            physicianFields.push('lastVisit');
        }
        if (isPrimary && hasPhysicians) {
            physicianFields.push('lastVisitReason');
        }
        physicianFields.map(f => Object.assign(initialValues, {[f]: ""}));

        Object.assign(_keyValidation, hasPhysicians ? {
            city: Yup.string().strict(true).matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD).nullable(),
            country: Yup.string().strict(true).required(REQUIRED_FIELD).nullable(),
            email: Yup.string().strict(true).email(INCORRECT_FORMAT).nullable(),
            lastVisit: Yup.string()
                    .test(YupTestCases.DateFuture.name, YupTestCases.DateFuture.message, noFutureDate)
                    .test(YupTestCases.DateAncient.name, YupTestCases.DateAncient.message, noAncientDate)
                    .nullable(),
            lastVisitReason: isPrimary ? Yup.string().strict(true).required(REQUIRED_FIELD).nullable() : Yup.string().nullable(),
            name: Yup.string().strict(true).matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD).nullable(),
            phone: Yup.string().strict(true).test(YupTestCases.PhoneHalfDigits.name, YupTestCases.PhoneHalfDigits.message, checkIncorrectPhoneNo).nullable(),
            state: isCountryUSA ?
                    Yup.string().strict(true).required(REQUIRED_FIELD).matches(ALPHABET_ONLY_REGEX, ALPHABET_ONLY).nullable() :
                    Yup.string().strict(true).matches(ALPHABET_ONLY_REGEX, ALPHABET_ONLY).nullable(),
            zip: Yup.string().strict(true).matches(ZIP_CODE_CHAR, INCORRECT_FORMAT).nullable(),
        } : {});
    };

    React.useEffect(() => {
        const initialValues = {};
        const _keyValidation = {};
        const fields = nextQuestionJSON ? [nextQuestionJSON] : [];
        const initialAnswer = nextQuestionJSON && nextQuestionJSON.answerValue && nextQuestionJSON.answerValue !== SPECIAL_NULL ? pez(nextQuestionJSON.answerValue) : '';

        if (nextQuestionJSON && nextQuestionJSON.questionType !== DynamicFormFieldEnum.Physician) {
            fields.map(f => {
                const hasAnswer = (f.answerValue && f.answerValue !== SPECIAL_NULL && !!f.answerValue);
                const dataType = f.caseDataQuestionMeta && f.caseDataQuestionMeta.dataType;
                const fieldType = f.answerType || f.questionType;
                const allowedMaxValue = f.code && (f.code.startsWith('2A_AVIATION') || f.code.includes('PILOT')) ? 50000 : 1000;
                const options = getOptions(f.answerType, f.disclosureSource, searchOptions, f.options, f.picklistQuestionMeta);
                const isSpecialCaseOne = options && !!va(options) && f.disclosureSource !== DynamicFormFieldEnum.Search && hasAnswer;
                const isSpecialCaseTwo = f.disclosureSource === DynamicFormFieldEnum.Search;
                const isSpecialCaseThree = f.disclosureSource === DynamicFormFieldEnum.Picklist && f.picklistSearchEnabled;
                Object.assign(initialValues, {[f.code as string]: initialAnswer});
                Object.assign(_keyValidation, {
                    [f.code as string]: (dataType === CaseDataTypeEnum.Numeric || fieldType === DynamicFormFieldEnum.Number) ?
                            Yup.number().required(REQUIRED_FIELD).strict(true).integer(INCORRECT_FORMAT).min(0, INCORRECT_FORMAT).max(allowedMaxValue, INCORRECT_FORMAT) : (
                                    fieldType === DynamicFormFieldEnum.Date ?
                                            Yup.string().strict(true).required(REQUIRED_FIELD).test(YupTestCases.DateFuture.name, YupTestCases.DateFuture.message, noFutureDate).test(YupTestCases.DateAncient.name, YupTestCases.DateAncient.message, noAncientDate).nullable() : (
                                                    fieldType === DynamicFormFieldEnum.Text ?
                                                            Yup.string().max(2000, INCORRECT_FORMAT).strict(true).required(REQUIRED_FIELD).nullable() : (
                                                                    fieldType === DynamicFormFieldEnum.Heading ?
                                                                            Yup.string().nullable() : (
                                                                                    forceNo && fieldType === DynamicFormFieldEnum.YesNo && (isSpecialCaseOne || isSpecialCaseTwo || isSpecialCaseThree) ?
                                                                                            Yup.array().of(Yup.string()).min(1, REQUIRED_FIELD).required(REQUIRED_FIELD).nullable() :
                                                                                            Yup.string().required(REQUIRED_FIELD).nullable()
                                                                            )
                                                            )
                                            )
                            )
                });
            });
        } else {
            validatePhysician(initialValues, _keyValidation);
        }

        setValidationKeys(_keyValidation);
        setInitialValues(initialValues);
        setFields(fields);
    }, [forceNo, nextQuestionJSON]);

    React.useEffect(() => {
        const initialValues = Object.assign({}, {
            hasPhysicians: (hasPhysicians as any) === null ? null : JSON.stringify(hasPhysicians),
        });

        const _keyValidation = {
            hasPhysicians: Yup.string().required(REQUIRED_FIELD).nullable(),
        };

        validatePhysician(initialValues, _keyValidation);

        setValidationKeys(_keyValidation);
        setInitialValues(initialValues);
    }, [hasPhysicians, isCountryUSA]);

    React.useEffect(() => {
        if (!!applicationId && !editAns) {
            onStartQuestionnaire().then();
        }
    }, [applicationId]);

    React.useEffect(() => {
        setNextLoading(true);
        if (!editAns) {
            return;
        }

        fetchQuestionDetails(editAns.code)
                .then((questionData) => {
                    if (!questionData.applicationId) {
                        setNextLoading(false);
                        return;
                    }
                    if (editAns.isNonBase) {
                        // dirty hack for edit non base questions
                        questionData.question.code = editAns.code;
                        questionData.question.baseQuestionCode = editAns.question.baseQuestionCode;
                    }
                    if (setApplicationId && isFunc(setApplicationId)) {
                        setApplicationId(questionData.applicationId as string);
                    }
                    const allowNumbersOnly = ((questionData.question.caseDataQuestionMeta && questionData.question.caseDataQuestionMeta.dataType === CaseDataTypeEnum.Numeric) || questionData.question.answerType === DynamicFormFieldEnum.Number);
                    if (allowNumbersOnly) {
                        questionData.question.answerValue = parseInt(questionData.question.answerValue);
                        editAns.answer = parseInt(editAns.answer);
                    }
                    // special case data where datatype is selection
                    parseCaseDataSelection(questionData.question);

                    setNextQuestionJSON(questionData.question);
                    setFields([questionData.question]);
                    setInitialValues({[questionData.question.code]: editAns.answer ? editAns.answer : ''});
                    setNextLoading(false);
                })
                .catch(console.error);

    }, [editAns && editAns.code]);

    return (
            <>
                <Typography variant="h3" className="mt20Small">
                    {subApp ? `Questionnaire Summary` : `Questionnaire`}
                </Typography>
                <Typography className="mb0" variant="body1">
                    {subApp ? `Please review your answers below. Press the pencil icon to make any changes if needed.` : `Please answer the below questions from your carrier.`}
                </Typography>
                <Typography variant="body1">
                    {
                            nextQuestionJSON &&
                            nextQuestionJSON.code &&
                            nextQuestionJSON.code &&
                            MedicalHeadings[nextQuestionJSON && nextQuestionJSON.code]
                    }
                </Typography>
                {
                        !subApp &&
                        <Card className="boxShadowGlobal">
                            <CardContent>
                                <FormikWrapper {...formProps} />
                            </CardContent>
                        </Card>
                }
                {

                        subApp &&
                        <FinalizeApplication enrollmentId={enrollmentId} setBasicInfoStep={setBasicInfoStep}/>
                }
            </>
    );
};

export default WrapperMain;
