import {History} from "history";
import {combineReducers} from "redux";
import {
    Account,
    AccountList,
    AdditionalCarrierList,
    CarrierList,
    carrierRequests,
    CarrierStrategyList,
    DesignationList,
    GetAdminActivityLogsList,
    GetCompanyInviteData,
    GetPreEstimation,
    ImoList,
    ProfileDetail,
    ProfileImage,
    Strategy,
    StrategyList,
    Verify,
} from "../model/account";
import {User} from "../model/user";
import {Enrollment} from "../model/enrollment";
import {Alert, Aws, Docusign} from "../model";
import {EstimationResponse} from "../model/estimation";
import {
    Tranche,
    TrancheList,
    TrancheNotes,
    TrancheNotesList,
    TrancheUnAssignedClients,
    TrancheWorkflowList
} from "../model/tranche";
import {DivisionList} from "../model/division";
import {AggregatorImoList, Aggregators} from "../model/aggregator";
import {LeadStats} from "../model/lead";

import * as profileReducer from "./profile";
import * as accountReducer from "./account";
import * as docsReducer from "./documents";
import * as authReducer from "./user";
import * as alertReducer from "./alert";
import * as verifyReducer from "./verify";
import * as aws from "./aws";
import * as carrierReducer from "./carrier";
import * as estimationReducer from "./estimation";
import * as imoReducer from './imo';
import * as docusignReducer from './docusign';
import * as trainingReducer from './training';
import * as trancheReducer from './tranche';
import * as enrollmentReducer from './enrollment';
import * as leadReducer from './lead';
import * as approvedReducer from './approved';
import * as divisionReducer from './division';
import * as aggregatorReducer from './aggregator';

import {reducer as formReducer} from "redux-form";
import {CarrierOfferType, CarrierOfferTypeObject, CarrierProduct, CarrierProductObject} from "../model/carrier";
import {Training} from "../model/training";
import {Document} from "../model/documents";
import {ApprovedDetail, ApprovedIMO, ApprovedList, ApprovedStats} from "../model/approved";


export interface RootState {
	aws: Aws,
	accounts: AccountList;
    aggregators: Aggregators[];
	loadingAccounts: boolean,
	profile: ProfileDetail,
	designationList: DesignationList;
	additionalCarrier: AdditionalCarrierList;
	auth: User;
	alert: Alert;
	approvedImoList: ApprovedIMO[];
    approvedDetail: ApprovedDetail;
    approvedList: ApprovedList;
    approvedStats: ApprovedStats;
	verify: Verify;
	account: Account;
	clientAccount: Account,
	carriers: CarrierList,
    documents: Document[]
	strategies: StrategyList,
	carrierAndStrategy: CarrierStrategyList,
	carrierRequest: carrierRequests,
	estimation: EstimationResponse,
	imos: ImoList,
	docusign: Docusign,
	strategy: Strategy,
	enrollment: Enrollment,
	trustees: [],
	banks: [],
    training: Training[],
	tranche: Tranche,
	tranches: TrancheList,
    trancheNote: TrancheNotes,
    trancheNotes: TrancheNotesList,
    trancheUnAssignedClients: TrancheUnAssignedClients[],
    trancheWorkflow: TrancheWorkflowList[],
	carrierProducts: CarrierProductObject
	product: CarrierProduct
	carrierOfferTypes: CarrierOfferTypeObject
	offerType: CarrierOfferType,
	preEstimationData: GetPreEstimation,
    adminActivityLogs: GetAdminActivityLogsList,
    companyInvite : GetCompanyInviteData,
    profileImage: ProfileImage,
    leadStats: LeadStats,
    leftPanelOpen: boolean,
    docForRequest?: string,
    setAgentGroup?: boolean,
    clientCaseDetails?: any,
    isRefresh?: number | undefined,
    isAnniversaryDateGone?: boolean,
    divisions: DivisionList,
    aggregatorImos: AggregatorImoList,
    clientDetailsWhileEstimate?: any,
    stressUUID: string,
    reportUUID: string,
    executionArn: string,
    minimumContribution: number
}

export default (history: History) =>
	combineReducers({
		...aws,
        ...approvedReducer,
		...profileReducer,
		...accountReducer,
		...authReducer,
		...alertReducer,
		...docsReducer,
		...verifyReducer,
		...carrierReducer,
		...estimationReducer,
		...imoReducer,
		...docusignReducer,
        ...trainingReducer,
		...trancheReducer,
		...enrollmentReducer,
        ...leadReducer,
        ...divisionReducer,
        ...aggregatorReducer,
		form: formReducer
	});
