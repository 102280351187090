import {Action, ActionType} from "../model";
import {
    Tranche,
    TrancheList,
    TrancheNotes,
    TrancheNotesList,
    TrancheUnAssignedClients,
    TrancheWorkflowList
} from "../model/tranche";
import createReducer from "./createReducer";

/**
 * Redux store for tranche (edit and/or add)
 */
export const tranche = createReducer({}, {
	[ActionType.ADD_TRANCHE](state: Tranche, action: Action<Tranche>) {
		return action.payload;
	},
    [ActionType.FLUSH_TRANCHE](state: Tranche, action: Action<Tranche>) {
        return action.payload;
    },
    [ActionType.GET_TRANCHE_PK](state: Tranche, action: Action<Tranche>) {
        return action.payload;
    },
});

/**
 * Redux store for tranches (list)
 */
export const tranches = createReducer({}, {
    [ActionType.FLUSH_TRANCHES](state: TrancheList, action: Action<TrancheList>) {
        return action.payload;
    },
    [ActionType.LIST_TRANCHES](state: TrancheList, action: Action<TrancheList>) {
        return action.payload;
    }
});

/**
 * Redux store for tranche note (edit and/or add)
 */
export const trancheNote = createReducer({}, {
    [ActionType.FLUSH_TRANCHE_NOTE](state: TrancheNotes, action: Action<TrancheNotes>) {
        return action.payload;
    },
    [ActionType.ADD_TRANCHE_NOTE](state: TrancheNotes, action: Action<TrancheNotes>) {
        return action.payload;
    },
    [ActionType.GET_TRANCHE_NOTE_PK](state: TrancheNotes, action: Action<TrancheNotes>) {
        return action.payload;
    },
});

/**
 * Redux store for tranche notes (list)
 */
export const trancheNotes = createReducer({}, {
    [ActionType.FLUSH_TRANCHE_NOTES](state: TrancheNotesList, action: Action<TrancheNotesList>) {
        return action.payload;
    },
    [ActionType.LIST_TRANCHE_NOTES](state: TrancheNotesList, action: Action<TrancheNotesList>) {
        return action.payload;
    }
});

export const trancheWorkflow = createReducer([], {
    [ActionType.FLUSH_TRANCHE_WORKFLOW](state: TrancheWorkflowList[], action: Action<TrancheWorkflowList>) {
        return action.payload;
    },
    [ActionType.FETCH_TRANCHE_WORKFLOW](state: TrancheWorkflowList[], action: Action<TrancheWorkflowList>) {
        return action.payload;
    }
});


export const trancheUnAssignedClients = createReducer([], {
    [ActionType.FLUSH_TRANCHE_UNASSIGNED_USERS](state: TrancheUnAssignedClients[], action: Action<TrancheUnAssignedClients>) {
        return action.payload;
    },
    [ActionType.FETCH_TRANCHE_UNASSIGNED_USERS](state: TrancheUnAssignedClients[], action: Action<TrancheUnAssignedClients>) {
        return action.payload;
    }
});
