import React from "react";
import {Card, CardContent, Grid, Link, Typography,} from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import {Button, PageTitle, TextField} from "./formInputs";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {useActions} from "../actions";
import { AccountType, allRoutes, EMAIL_VALIDATE_REGEX, NIW_CORP_URL } from "../constants";
import * as Actions from "../actions/auth";
import loginlogo from "../styles/images/logo-ilia.svg";
import login from "../styles/images/login.png";
import {history} from "../configureStore";
import NeedHelp from "./NeedHelp";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import * as AlertActions from "../actions/alert";

const useStyles = makeStyles((theme) =>
        createStyles({
            logInWrap: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                verticalAlign: 'middle',
                height: '100%',
                width: '100%',
                "&::after": {
                    content: "\"\"",
                    position: "absolute",
                    background: "#3971EC",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: "42%",
                    [theme.breakpoints.down("sm")]: {
                        position: "inherit",
                        background: "transparent",
                        width:'auto',
                    },
                },

            },
            textWhite: {
                color: "#FFFFFF",
            },
            textCenter: {
                textAlign: "center",
            },
            mt10: {
                marginTop: 10,
            },
            mr30: {
                marginRight: 30,
            },
            loginCard: {
                margin: "auto",
                width: 865,
                boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
                background: "transparent !important",
                left: 0,
                right: 0,
                zIndex: 999,
                border: 0,
                padding: "2%",
                position:'relative',
                [theme.breakpoints.down("sm")]: {
                    background: "#ffffff !important",
                    width: "100%",
                    margin:'0 15px',
                },
            },
            muiCardContent: {
                padding: 15,
                float: "left",
                width: "100%",
                position: "relative",
                [theme.breakpoints.down("sm")]: {
                    padding: "20px 16px 0",
                },
            },
            loginForm: {
                padding: "60px 15px 50px",
                float: "left",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                    padding: "30px 0 15px",
                },
            },
            margin0: {
                margin: 0,
            },
            titleMain: {
                fontSize: 26,
                lineHeight: "34px",
                textAlign: "center",
                margin: 0,
                float: "right",
            },
            dpNone: {
                [theme.breakpoints.down(992)]: {
                    display: "none",
                },
            },
            floatRight: {
                float: "right",
            },
            imgResponsive: {
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginTop: "25%",
            },
            clickableLink: {
                display: "inline-flex",
                alignItems: "center",
                verticalAlign: "middle",
            },
            clickableLinksvg: {
                float: "left",
            },
            mr10: {
                marginRight: 10,
            },
            mt15: {
                marginTop: 15,
            },
        }),
);

interface LoginData {
    email: string,
    password: string
}

type token = {
    url: string | null
}
const validate = (values: LoginData) => {
    const errors: { email?: string, password?: string } = {};
    if (!values.email) {
        errors.email = "Required";
    }
    if (!values.password) {
        errors.password = "Required";
    }
    if (values.email && !EMAIL_VALIDATE_REGEX.test(values.email)) {
        errors.email = "Invalid email address";
    }
    return errors;
};

const LogIn: React.FC<InjectedFormProps<LoginData, token> & token> = (props: InjectedFormProps<LoginData, token> & { children?: React.ReactNode } & token) => {
    const classes = useStyles();
    const authActions = useActions(Actions);
    const alertAction = useActions(AlertActions);
    const { handleSubmit, pristine, submitting, url } = props;
    const [isApprovedAgent, setIsApprovedAgent] = React.useState<boolean>(false);
    const [isApprovedImo, setIsApprovedImo] = React.useState<boolean>(false);
    const urlParams = new URLSearchParams(window.location.search);
    const reUrl:string|null = urlParams.get('re');

    const [needHelp, setNeedHelp] = React.useState(false);
    const redirectByRole = async(reUrl: any,logInResponse: any) => {
        const role = logInResponse && logInResponse.payload && logInResponse.payload.user && logInResponse.payload.user.role;
        const allUrl = allRoutes[role];
        const similarUrls = allUrl.filter(str => reUrl.includes(str));
        const redirectTo: string = (similarUrls.length > 0) ? reUrl : "/accounts";
        return history.push(redirectTo);
    }

    const onLogin = async (data: Partial<LoginData>) => {
        const logInResponse = await authActions.LogIn(data);
        switch (logInResponse.payload.statusCode) {
            case 200:
                reUrl ? redirectByRole(reUrl, logInResponse) : await authActions.getAccountDetails();
                await sessionStorage.setItem("login", 'true');
                break;
            case 403:
                history.push("/verify/account/" + logInResponse.payload.token);
                break;
            case 405:
                setIsApprovedImo(true); // should not allowed to login if approved imo.
                break;
            case 406:
                setIsApprovedAgent(true); // should not allowed to login if approved agent.
                break;
            default:
                console.log("Response: ", logInResponse);
                if (logInResponse.payload.name === 'Error' && logInResponse.payload.message === 'Network Error') {
                    alertAction.setErrorAlert(logInResponse.payload.message);
                }
        }
    };
    return (<>
        <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/layout.css`} />
        <div className={classes.logInWrap}>
            <PageTitle title="Login Page" />
            <Card className={classes.loginCard}>
                <CardContent className={classes.muiCardContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                            <img width="260" alt="loginLogo" src={loginlogo} />
                            <form className={classes.loginForm + " " + classes.margin0}
                                  onSubmit={handleSubmit(onLogin)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field name="email" fullWidth component={TextField}
                                               placeholder="ENTER YOUR EMAIL" label={"Enter Your Email"}
                                               type="text" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field name="password" fullWidth component={TextField}
                                               placeholder="Enter Your Password"
                                               type="password"
                                               label={"Enter Your Password"} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                                fullWidth={true}
                                                type="submit"
                                                label="Sign In"
                                                variant="contained"
                                                color="primary"
                                                disabled={pristine || submitting}
                                                loading={submitting}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container className={classes.mt15}>
                                    <Grid item xs={12}>
                                        <Link className={classes.clickableLink} variant="body2"
                                              onClick={() => history.push("/forgot-password")}>
                                            <VpnKeyIcon
                                                    className={classes.clickableLinksvg + " " + classes.mr10} />
                                            Forgot Password?
                                        </Link>
                                    </Grid>
                                </Grid>
                                            <Grid container className="mt10">
                                                <Grid item xs>
                                                    <Link className={"clickableLink"} variant="body2"
                                                          onClick={() => setNeedHelp(true)}>
                                                          Need Help?
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                {url &&
                                        <Grid container>
                                            <Grid item xs>Dont have an account?&nbsp;
                                                <Link className={classes.clickableLink} variant="body2"
                                                      onClick={() => window.location.href = (url)}>
                                                    Click here
                                                </Link>&nbsp;to estimate potential benefits and get started.
                                            </Grid>
                                        </Grid>
                                }
                                {isApprovedAgent && <Typography variant="caption"
                                                                className="mt15 mb0 floatLeft w100 colorRed">You
                                    are not allowed to login to ILIA Please <Link
                                            href={`${NIW_CORP_URL}/agent-resources`}>click
                                        here</Link> to login.</Typography>
                                }
                                {isApprovedImo && <Typography variant="caption"
                                                              className="mt15 mb0 floatLeft w100 colorRed">This account
                                    is not a preferred account.</Typography>
                                }
                            </form>

                        </Grid>
                        <Grid item xs={12} md={5} lg={5} className={classes.dpNone}>
                            <Typography variant="h3" align="right"
                                        className={classes.textWhite + " " + classes.mr30 + " " + classes.titleMain}>
                                Login
                            </Typography>
                            <img className={classes.floatRight + " " + classes.imgResponsive} src={login}
                                 alt="login" />
                        </Grid>
                                    <NeedHelp needHelp={needHelp} setNeedHelp={setNeedHelp}/>
                    </Grid>
                </CardContent>
            </Card>

        </div>
        </>

        );
        };


        export default reduxForm<LoginData, token>({

        form: "LogIn",  // a unique identifier for this form
        validate,
    })(LogIn);
