import * as React from "react";
function FormatPhoneNumber(props: any) {
    var cleaned = ('' + props.phoneNumber).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    var Phone = " ";
    if (match && props.phoneNumber) {
        var intlCode = (match[1] ? '+1 ' : '')
        Phone = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return(
        <React.Fragment>{Phone}</React.Fragment>
    )

}
export default FormatPhoneNumber