import * as React from "react";
import {history} from "../../configureStore";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import BadgeOverlap from "../BadgeOverlap";

function ListItemComponent(props: {
    className: string, handleNavClick: Function, listItemText: String, setActive: Function, url: string, children: any
    isPendingItemsCount?: any,
    isLeadsCount?: any,
    isCurrentTicketCount?: any
}) {
    const {
        className,
        setActive,
        url,
        listItemText,
        handleNavClick,
        isPendingItemsCount = false,
        isLeadsCount = false,
        isCurrentTicketCount = false
    } = props;
    return <ListItem
            className={className}
            button
            onClick={() => {
                setActive(url);
                history.push(url);
                handleNavClick();
            }}
    >
        <ListItemIcon>
            {props.children}
        </ListItemIcon>
        <ListItemText primary={listItemText}/>
        {(isLeadsCount || isPendingItemsCount || isCurrentTicketCount) && <BadgeOverlap/>}
    </ListItem>
}

export default ListItemComponent;
