import * as React from "react";
import {FormLabel, Grid, Switch, Typography} from "@material-ui/core";
import {Field} from "redux-form";
import {Label, PhoneNumberField, SelectField, TextField} from "./formInputs";
import {email, required, validatePhoneNumber, zipCodeValidation} from "../utils/validation";
import {imoClassDropDown, imoRegionDropDown, pleaseSelectOption, stateDropDown} from "../constants";
import {useSelector} from "react-redux";
import {RootState} from "../reducers";
import {isFunc} from '../utils/commonUtils';
import {useActions} from '../actions';
import * as AggregatorActions from '../actions/aggregator';
import {INVOpt} from "../model/misc";

interface ImoDetailsProps {
    handleChangeState: Function;
    handleChangeSwitch: any | Function;
    hidden: any;
    showImoClass: boolean;
    state: any;
    validateIMOCarriers?: Function;
}


export default function ImoDetails(props: any) {
    const {handleChangeState, handleChangeSwitch, hidden, showImoClass = false, state, validateIMOCarriers} = props;

    const aggregatorActions = useActions(AggregatorActions);

    const {user: {role}} = useSelector((state: RootState) => state.auth);
    const aggregators = useSelector((state: RootState) => state.aggregators);
    const disabled = (role === 18 || role === 19);
    const onChange: any = () => {
        if (validateIMOCarriers && isFunc(validateIMOCarriers)) {
            return validateIMOCarriers();
        }
    };
    const commonProps = {onChange, disabled};

    React.useEffect(() => {
        aggregatorActions.getAggregators();

        return () => aggregatorActions.flushAggregators();
    }, []);

    return (
            <React.Fragment>
                <Field name="imo.carriers" component={hidden} validate={required}/>
                <Grid item xs={12} sm={12} className="pb0">
                    <Typography component="strong" variant="subtitle1" className="w100 colorBlue">
                        IMO Details
                        <div className="floatRight">
                            <Field name="imo.reBrand" component={hidden}/>
                            <FormLabel component="div" className="mb0">NIW/ReBrand</FormLabel>
                            <Switch
                                    {...commonProps}
                                    checked={state.rebrandSwitch}
                                    onChange={handleChangeSwitch}
                                    name="rebrandSwitch"
                                    color={"primary"}
                                    inputProps={{"aria-label": "secondary checkbox"}}
                            />
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label={<Label label={"IMO Name"} required={true}/>}
                            name="imo.groupName"
                            component={TextField}
                            validate={[required]}
                            {...commonProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label={<Label label={"IMO Email"} required={true}/>}
                            name="imo.imoEmail"
                            component={TextField}
                            validate={[email, required]}
                            {...commonProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label="Company Address 1"
                            name="imo.street"
                            component={TextField}
                            {...commonProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label="City"
                            name="imo.city"
                            component={TextField}
                            {...commonProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label="State"
                            name="imo.state"
                            component={SelectField}
                            options={stateDropDown}
                            handleClick={handleChangeState}
                            {...commonProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label="Zip"
                            name="imo.zipCode"
                            component={TextField}
                            validate={[zipCodeValidation]}
                            {...commonProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label={<Label label={"Office Phone"} required={true}/>}
                            name="imo.officeNumber"
                            component={PhoneNumberField}
                            validate={[required, validatePhoneNumber]}
                            {...commonProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            name="imo.region"
                            label="Region"
                            component={SelectField}
                            options={imoRegionDropDown}
                            {...commonProps}
                    />
                </Grid>
                {showImoClass &&
                        <Grid item xs={12} sm={6} md={6}>
                            <Field
                                    fullWidth
                                    name="imo.imoClass"
                                    label={<Label label={"IMO Type"} required={true}/>}
                                    placeholder="enter imo class"
                                    component={SelectField}
                                    options={imoClassDropDown}
                                    validate={required}
                                    {...commonProps}
                            />
                        </Grid>
                }
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            name="imo.aggregatorId"
                            label="Add Aggregator"
                            component={SelectField}
                            options={
                                [pleaseSelectOption].concat(
                                        aggregators.map(a => ({
                                            id: a.aggregator.id as number,
                                            name: a.aggregator.aggregatorName,
                                            value: a.aggregator.id as number
                                        }))
                                ) as INVOpt[]
                            }
                            {...commonProps}
                    />
                </Grid>
            </React.Fragment>
    );
}
