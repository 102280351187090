import React from "react";
import {
    Button, CircularProgress,
    Dialog,
    DialogContent,
    Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";

interface deleteModelProps {
    accountType?: string,
    title?: string,
    content?: string,
    open: boolean,
    disableDelete?: boolean,
    handleClose: () => void,
    handleYes: () => void,
    color?: boolean,
    buttonLoader?: boolean
}
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            wrapper: {
                position: 'relative',
            },
            buttonProgress: {
                color: blue[500],
                position: 'absolute',
                top: '10px',
                left: '35%',
                marginTop:'0px',
                width:'100%'
            },
        })
);
const defaultTitle = "Jamin Austin";
const defaultContent = "Are you sure want to delete permanently ?";
export default function DeleteModel({
                                        open,
                                        handleClose,
                                        accountType = "",
                                        title = defaultTitle,
                                        content = defaultContent,
                                        disableDelete = false,
                                        handleYes,
                                        color=false,
                                        buttonLoader= false
                                    }: deleteModelProps) {
    const classes = useStyles();
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"xs"}
                    className="customModal"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                {
                    accountType !== "image" &&
                    <div id="alert-dialog-title" className="pt20 pr30 pl30 mb0">
                        <PersonIcon/> <Typography component="span" className="mb0"> <strong className="w100 floatLeft">{title}</strong>
                        <i className="f12 w100 floatLeft">{`${accountType} Account`}</i></Typography>
                    </div>
                }
                <DialogContent>
                    <Typography variant="body1" className="mb0">{content}</Typography>



                    <Button className={`${color ? "": "bgRed"} mt20 floatRight ml10`}
                            type="submit"
                            size="small"
                            variant="contained"
                            color="primary" onClick={handleClose}>
                        {!disableDelete ? "No" : "Cancel"}
                    </Button>
                    {!disableDelete && <Button
                            className={`${color ? "bgRed": ""} mt20 floatRight`}
                            type="submit"
                            size="small"
                            variant="contained"
                            disabled={buttonLoader}
                            color="primary" onClick={handleYes} autoFocus>
                        Yes {buttonLoader &&  <CircularProgress className={classes.buttonProgress}
                                                                size={20} /> }
                    </Button>}
                </DialogContent>
            </Dialog>
    );
}
