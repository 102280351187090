import { ActionType } from "../model";
import { NotificationData } from "../model/notification";

export const getPendingCounts = (clientId:number) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
    try {
        return await api.get('notification/counts/pending', {}, {clientId});
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updatePendingCounts = (clientId:number, payload:any) => async (dispatch: Function, getState: Function, api: { put: Function })  => {
    try {
        return await api.put('notification/counts/pending', {clientId, ...payload}, {});
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const createNotification = (notification: NotificationData )=> async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let payload: NotificationData = {recipients: notification.recipients, title: notification.title, text: notification.text};
		await api.post('notification', payload, {});
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: "Notification created successfully.",
				statusCode: 200
			}
		});
		return {success : true, message : "Notification created successfully."};
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: err.message ? err.message : err,
				statusCode: 400
			}
		});
		return {success : false, message : err.message ? err.message : err};
	}
};

export const getNotification = (query: {iliaNotification?: boolean, page?: number, rowsPerPage?: number, searchText?: string, sortBy?: string, order?: string} = {}) =>
    async (dispatch: Function, getState: Function, api: any)  => {
	try {		
		let data =  await api.get('userNotification', {}, query);
		 return {success : true, data : data};
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: err.message ? err.message : err,
				statusCode: 400
			}
		});
		return {success : false, data : []};
	}
};

export const deleteAdminNotification = ( id:number )=> async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let data =  await api.put('notification/' + id, {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Notification deleted successfully.",
                statusCode: 200
            }
        });
		return {success : true, data : data};
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: err.message ? err.message : err,
				statusCode: 400
			}
		});
		return {success : false, data : []};
	}
};

export const deleteUserNotification = ( id:number )=> async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let data =  await api.delete('userNotification/' + id, {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Notification deleted successfully.",
                statusCode: 200
            }
        });
		return {success : true, data : data};
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: err.message ? err.message : err,
				statusCode: 400
			}
		});
		return {success : false, data : []};
	}
};

export const listNotifications = ( query:any )=> async (dispatch: Function, getState: Function, api: any)  => {
	try {		
		let data =  await api.get('notification/list', {}, query);	
		return {success : true, data : data};	 
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: err.message ? err.message : err,
				statusCode: 400
			}
		});
		return {success : false, data : []}; 
	}
};


export const deleteNotification = ( notification_id:Number, type:string|null )=> async (dispatch: Function, getState: Function, api: any)  => {
	try {		
		await api.delete('userNotification', {notification_id, type}, {});
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: "Notification deleted successfully.",
				statusCode: 200
			}
		});
		return 
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: err.message ? err.message : err,
				statusCode: 400
			}
		});
		return 
	}
};

export const updateNotificationStatus = ( notification_ids:Number[] )=> async (dispatch: Function, getState: Function, api: any)  => {
	try {
		return await api.put('userNotification', {notification_ids}, {});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: err.message ? err.message : err,
				statusCode: 400
			}
		});
		return;
	}
};

export const updateNotification = ( notification: NotificationData, notificationID:number )=> async (dispatch: Function, getState: Function, api: any)  => {
    try {
        let response:any = await api.put('notification/update', notification, {notificationID});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Notification update successfully.",
                statusCode: 200
            }
        });
        return response
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return;
    }
};