import React  from "react";
import {Checkbox, Typography} from "@material-ui/core";
import {Field, FieldArray, useFormikContext} from "formik";
import {Grid} from "@material-ui/core";
import { ErrorHelper, TextField } from "../../../../../../../components/formikFormInputs";
import _ from "lodash";
import debounce from "lodash/debounce";
import {NAME_IDENTIFIER} from "./MedicalPageQuestions";
import {Label} from "../../../../../../../components/formInputs";
import {NOTA} from "./MedicalPageQuestions";
import { isLongForm } from "../../Utilities";
import { isFunc, isValidObject } from "../../../../../../../utils/commonUtils";

type RCFProps = {
    clientSigned: boolean;
    error: boolean;
    field: any;
    fieldName: string;
    name: string;
    setFieldValue: Function;
    sendSaveRequest?: Function;
    values: any;
}
const RenderChildField = (props: RCFProps) => {
    const {
        clientSigned,
        error,
        field,
        fieldName,
        name,
        sendSaveRequest,
        setFieldValue,
        values,
    } = props;

    const setValueForCheckBoxDesc = debounce((name, value) => setFieldValue(name, value , false), 500);

    const handleBlurCheckBoxDesc = () => {
        if (sendSaveRequest && isFunc(sendSaveRequest)) {
            sendSaveRequest(Math.random());
        }
    };
    const handleChangeCheckBoxDesc = ({ target }) => {
        let index:number = _.findIndex(values[name], (e:any) => {
            return e && e.name === fieldName
        },0);
        if(index > -1) {
            setValueForCheckBoxDesc(`${name}.${index}.desc`, target.value)
        }
    };

    const setDefaultValueForDesc = (fieldName: string) => {
        let index: number = _.findIndex(values[name], (e: any) => {
            return e && e.name === fieldName;
        }, 0);
        if (index > -1) {
            return values[name][index].desc;
        }
        return "";
    };

    return (
            <Field
                    className="mt10 mb5"
                    component={TextField}
                    defaultValue={setDefaultValueForDesc(fieldName)}
                    disabled={clientSigned}
                    error={error}
                    fullWidth
                    label={<Label label={field.label} required={true}/>}
                    name={`${fieldName}_desc`}
                    onBlur={handleBlurCheckBoxDesc}
                    onChange={handleChangeCheckBoxDesc}
                    placeholder={field.placeholder}
                    variant="outlined"
            />
    )
}

interface RMDProps {
    clientSigned: boolean;
    errors: any;
    fieldName: string;
    sendSaveRequest?: Function;
    setFieldValue: Function;
}

export const RenderMedicalDetail = (props: RMDProps) => {
    const { clientSigned, errors, fieldName, sendSaveRequest, setFieldValue } = props;
    const { setFieldTouched } = useFormikContext();
    const {values} = useFormikContext();

    const setDefaultValueForMedDetails = (fieldName: string) => {
        if (values && values[fieldName]) {
            return values[fieldName];
        }

        return "";
    };

    const handleBlur = () => {
        if (sendSaveRequest && isFunc(sendSaveRequest)) {
            sendSaveRequest(Math.random());
        }
    };
    const handleChange = (name: string, value: string) => {
        setFieldValue(name, value);
    };

    const commonMDFieldProps = {
        className: "mt10",
        component: TextField,
        disabled: clientSigned,
        filedType: "string",
        helperFieldText: "",
        isRequired: true,
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
        variant: "outlined",
    };
    const medicalDetailFields: any = [
        {
            ...commonMDFieldProps,
            id: `${fieldName}_0`,
            heading: (
                    <><strong>Detail and Date of Each of the Following As Applicable: Medication</strong> (include
                        name, dosage, frequency); <strong>Emergency room visit, hospitalization, biopsy,
                            surgery</strong> (include description of event); <strong>Therapy - physical, counseling,
                            etc.</strong> (include type and frequency); Tests (include type and result); Assistive
                        device -
                        CPAP, etc. (include type and nature of usage); <strong>Activity restrictions or limitations -
                            work,
                            driving, etc.</strong> (include description); <strong>Other treatment</strong> (include
                        description)
                    </>
            ),
            label: <Label label={"Details"} required={true} />,
            name: `${fieldName}_0`,
            onBlur: handleBlur,
            onChange: (e: any) => handleChange(`${fieldName}_0`, e.target.value),
            placeholder: "Details",
            defaultValue: setDefaultValueForMedDetails(`${fieldName}_0`),

        },
        {
            ...commonMDFieldProps,
            id: `${fieldName}_1`,
            label: <Label label={"Medical Source or Facility Name Address Phone Number"} required={true} />,
            name: `${fieldName}_1`,
            onBlur: handleBlur,
            onChange: (e: any) => handleChange(`${fieldName}_1`, e.target.value),
            placeholder: "Medical Source or Facility Name Address Phone Number",
            defaultValue: setDefaultValueForMedDetails(`${fieldName}_1`),
        },
    ];

    return (
            <>
                {
                    medicalDetailFields.map((fieldVal: any) => {
                        return (
                                <Grid item xs={12}>
                                    {
                                            (fieldVal.heading) &&
                                            <Typography variant="body2" className="mb0 mt20">
                                                {fieldVal.heading}
                                            </Typography>
                                    }
                                    <Field {...fieldVal} />
                                    <ErrorHelper errors={errors} name={fieldVal.name} />
                                </Grid>
                        );
                    })
                }
            </>
    );
};

type MPCProps = {
    clientSigned:boolean;
    fieldOptions:any;
    mainIndex:number;
    offerType: string;
    sendSaveRequest?: Function;
    showMedicalDetail: boolean;
}
export default function MedicalPageCheckBox(props: MPCProps) {
    const {
        clientSigned,
        fieldOptions,
        mainIndex,
        offerType,
        sendSaveRequest,
        showMedicalDetail,
    } = props;

    const {values, setFieldValue, errors}: any = useFormikContext();

    const name:string = `${NAME_IDENTIFIER}C_${mainIndex}`;
    const nameMedicalDetail:string = `${NAME_IDENTIFIER}T_${mainIndex}`;
    const selectedBox = values[name];
    const checkNoneOfThese = selectedBox && selectedBox.length > 0 && selectedBox[0].value;
    const checkBoxDefaultValue = (fieldArray:any, fieldName:string) => {
        let index = _.findIndex(fieldArray, (e:any) => {
            return e && e.name === fieldName;
        }, 0);
        return index > -1;
    }

    const handleNoneOfThese = async (name: string) => {
        switch (name) {
            case 'MedicalQuestionsXC_0':
            case 'MedicalQuestionsXC_1':
            case 'MedicalQuestionsXC_2':
            case 'MedicalQuestionsXC_3':
            case 'MedicalQuestionsXC_4':
            case 'MedicalQuestionsXC_5':
            case 'MedicalQuestionsXC_6':
            case 'MedicalQuestionsXC_7':
            case 'MedicalQuestionsXC_8':
            case 'MedicalQuestionsXC_9':
            case 'MedicalQuestionsXC_10':
            case 'MedicalQuestionsXC_11':
            case 'MedicalQuestionsXC_12':
            case 'MedicalQuestionsXC_13':
                const questionkey:string = name.split("_")[1];
                await setFieldValue(`${NAME_IDENTIFIER}T_${questionkey}_0`, "")
                await setFieldValue(`${NAME_IDENTIFIER}T_${questionkey}_1`, "")
                break;
        }
        return await setFieldValue(name, []);
    }

    return (
            <FieldArray
                name={name}
                render={arrayHelpers => {
                    return (
                        <Grid container spacing={2}>
                            {
                                fieldOptions.map((fieldVal: any, index:number) => {
                                    const fieldName:string = `${NAME_IDENTIFIER}C_${mainIndex}_${index}`;
                                    const handleChange = async (e: any) => {
                                        if (e.target.checked) {
                                            let obj: any = {
                                                name: fieldName,
                                                value: fieldVal.value,
                                                desc: "",
                                            };
                                            if ((obj && obj.value) === NOTA) {
                                                await handleNoneOfThese(name)
                                                        .then(() => {
                                                            arrayHelpers.push(obj);
                                                        });
                                            } else {
                                                let index = _.findIndex(values[name], (ee: any) => {
                                                    return ee && ee.value === NOTA;
                                                }, 0);
                                                if (index > -1) {
                                                    arrayHelpers.remove(index);
                                                }
                                                arrayHelpers.push(obj);
                                            }
                                        } else {
                                            let index = _.findIndex(values[name], (ee: any) => {
                                                return ee && ee.name === e.target.name;
                                            }, 0);
                                            arrayHelpers.remove(index);
                                        }

                                        if (sendSaveRequest && isFunc(sendSaveRequest)) {
                                            sendSaveRequest(Math.random());
                                        }
                                    };

                                    return (
                                            <Grid item xs={12} lg={6} key={index}>
                                                <label key={fieldVal.value} className="checkboxStyle ml0">
                                                    <Checkbox
                                                            className="margin0 pt0"
                                                            name={fieldName}
                                                            value={fieldVal.value}
                                                            checked={checkBoxDefaultValue(values[name], fieldName)}
                                                            onChange={handleChange}
                                                            disabled={clientSigned}
                                                    />
                                                    <Typography variant="body2" className="mb0"> {fieldVal.text}</Typography>
                                                </label>

                                                {
                                                        (checkBoxDefaultValue(values[name], fieldName) && fieldVal.showDetailsBox) &&
                                                        <RenderChildField
                                                                clientSigned={clientSigned}
                                                                error={(errors && errors[name] && errors[name].length) > 0}
                                                                field={fieldVal}
                                                                fieldName={`${fieldName}`}
                                                                name={name}
                                                                setFieldValue={setFieldValue}
                                                                sendSaveRequest={sendSaveRequest}
                                                                values={values}
                                                        />
                                                }
                                            </Grid>
                                    )
                                })
                            }
                            <>
                                {
                                        (errors && errors[name] && errors[name].length) > 0 &&
                                        <ErrorHelper errors={errors} name={name} />
                                }
                                {
                                        (checkNoneOfThese !== NOTA) && showMedicalDetail && isValidObject(values[name]) && isLongForm(offerType) &&
                                        <RenderMedicalDetail
                                                clientSigned={clientSigned}
                                                errors={errors}
                                                fieldName={nameMedicalDetail}
                                                setFieldValue={setFieldValue}
                                                sendSaveRequest={sendSaveRequest}
                                        />
                                }
                            </>
                        </Grid>
                    )
                }}
            />
    );}