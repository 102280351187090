import {Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {useField} from "formik";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import * as React from "react";
import {useFormikContext} from "formik";
import {EstimationErrorCommon} from "../../../../../components/formikFormInputs";

export default function TobaccoQuestions(props: any) {
    const {callingFrom} = props;
    const {values, setFieldValue, errors, touched}:any = useFormikContext();
    let fieldLabel = "Select One";
    let labelOne = "I Use Tobacco or Marijuana Products";
    let labelTwo = "I Don’t use Tobacco or Marijuana Products";
    const [field] = useField("tobacco");
    switch(callingFrom) {
        case 'INVITATION':
        case 'ENROLLMENT_TAB':
            labelOne = "The Client uses Tobacco or Marijuana Products";
            labelTwo = "The Client does not use Tobacco or Marijuana Products";
            break;
        case 'CUSTOM_DESIGN':
            fieldLabel = "Does the client use tobacco or marijuana products?";
            labelOne = "Yes";
            labelTwo = "No";
            break;

    }
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue("tobacco", (event.target as HTMLInputElement).value);
    };

    return (
            <Grid item xs={12} className="mb15">
                <Typography variant="body1" component="strong" className="mb15 floatLeft w100 f18Sm">{fieldLabel} <sup className="colorRed">*</sup>
                    {callingFrom === 'CUSTOM_DESIGN' && <Tooltip color="primary" title="This includes chewing, vaping, smoking and other forms of use." arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip> }
                </Typography>
                <RadioGroup className=" floatLeft w100"
                            {...field}
                            aria-label="tobacco" name="tobacco"
                            value={values && values.tobacco}
                            onChange={(e) => handleRadioChange(e)}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 20,
                    }}>
                        <FormControlLabel className="mb0 w25px"
                                          value="used"
                                          control={<Radio
                                                  className="pd0 ml5 mr5"/>}
                                          label=""/>
                        <Typography variant="body1" className="mb0">
                            {labelOne}
                            {callingFrom !== 'CUSTOM_DESIGN' && <Tooltip color="primary" title="This includes chewing, vaping, smoking and other forms of use." arrow>
                                <IconButton aria-label="Info">
                                    <InfoIcon/>
                                </IconButton>
                            </Tooltip> }
                        </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel className="mb0 w25px"
                                          value="notUsed"
                                          control={<Radio
                                                  className="pd0 ml5 mr5"/>}
                                          label=""/>
                        <Typography variant="body1" className="mb0">
                            {labelTwo}
                            {callingFrom !== 'CUSTOM_DESIGN' && <Tooltip color="primary" title="This includes chewing, vaping, smoking and other forms of use." arrow>
                                <IconButton aria-label="Info">
                                    <InfoIcon/>
                                </IconButton>
                            </Tooltip> }
                        </Typography>

                    </div>

                    <EstimationErrorCommon
                            errors={errors}
                            name={"tobacco"}
                            touched={touched}
                    />
                </RadioGroup>
            </Grid>
    )
}