import React, { useEffect } from "react";
import { Box, Card, CardContent, Chip, IconButton, Tooltip, Typography } from "@material-ui/core";
import { DashboardStyle as Styles } from "./dashboardStyle";
import InfoIcon from "@material-ui/icons/Info";
import KpiModal from "./modals/KpiModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { TableQuickView } from "./TableQuickView";

export interface QuickViewProps {
    isHeading: boolean,
    heading?: string,
    data: any,
    handleExport: Function
    // TODO: seems to be unused (consider deprecate this or remove it)
    csvKey?: string
    selectable?: boolean
}

export default function QuickView(props: QuickViewProps) {
    const [kpiData, setKpiData] = React.useState<any[]>([]);
    const [refresh, setRefresh] = React.useState(0);

    const {selectable = false, ...rest} = props

    return (
        <GenericQuickView {...props} setKpiData={setKpiData} kpiData={kpiData} refresh={refresh}>
            <TableQuickView
                kpiData={kpiData}
                selectable={selectable}
                onRefresh={() => setRefresh(prevRefresh => prevRefresh + 1)}/>
        </GenericQuickView>
    )
}

interface GenericProps extends QuickViewProps {
    children: React.ReactNode,
    kpiData: any[]
    setKpiData: (kpiData: any[]) => void
    isExportListVisible?: boolean,
    imoUserId?: string | null,
    agentUserId?: string | null,
    refresh?: number,
}

export function GenericQuickView(props: GenericProps) {
    const auth: any = useSelector((state: RootState) => state.auth);
    let agentId: number = auth && auth.user && auth.user.agent && auth.user.agent.id;
    const [open, setOpen] = React.useState(false);
    const [modalValue, setModalValue] = React.useState("");
    const [modalTitle, setModalTitle] = React.useState("");
    
    const { handleExport, isHeading, heading, data, children, kpiData, setKpiData, isExportListVisible = true, imoUserId = null, agentUserId = null, refresh = 0, selectable } = props;

    const classes = Styles();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleKpi = async (v: any) => {
        const kpi: string = v.kpiModal;
        const kpiTitle: string = v.kpiModalTitle;

        if(v.title.value === 0) {
            return;
        }

        setModalValue(kpi);
        setModalTitle(kpiTitle);
        updateKpis(kpi);
    };

    const updateKpis = async (kpi) => {
        let resp = await handleExport({ listType: kpi, listDataType: "json" });
        if (resp && resp.length > 0) {
            setKpiData(resp);
            handleClickOpen();
        }
    }

    useEffect(() => {
        if(modalValue) {
            updateKpis(modalValue)
        }
    }, [refresh])

    const exportList = async (kpi: string, statusTypes:string|null = null, search:string|null = null) => {
        await handleExport({
            agentId: agentId,
            listType: kpi,      
            listDataType: "csv",
            statusTypes,
            searchText: search
        });
    };

    return (
            <>
                <Box className={classes.boxItem} m={1}>
                    <Card className={classes.cardRoot + " " + classes.cardShadow + " " + classes.radius8 + " " + classes.minHeight140}>
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                {isHeading &&
                                <Typography component="strong" variant="body1"
                                            className={classes.disPlay + " " + classes.mb0}>
                                    {heading}
                                </Typography>
                                }
                                {
                                    data.map((v, i) => {
                                        return (
                                                <Typography variant="caption"
                                                            key={i}
                                                            className={classes.disPlay}>
                                                    {v.title.name}
                                                    {v.tooltipText &&
                                                    <Tooltip color="primary"
                                                             title={v.tooltipText}
                                                             arrow>
                                                        <IconButton aria-label="Info">
                                                            <InfoIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    }
                                                </Typography>);
                                    })
                                }
                            </CardContent>
                        </div>

                        <div className={classes.btnSection}  style={{ display: "inline-block" }}>
                            {data.map((v, i) => {
                                return (
                                        <Chip key={i} label={v.title.value} onClick={() => {
                                            handleKpi(v);
                                        }} classes={{root: v.className}}/>
                                );
                            })}
                        </div>
                    </Card>
                </Box>
                <KpiModal
                        imoUserId={imoUserId}
                        agentUserId={agentUserId}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        setOpen={setOpen}
                        open={open}
                        modalValue={modalValue}
                        modalTitle={modalTitle}
                        kpiData={kpiData}
                        exportList={exportList}
                        isExportListVisible={isExportListVisible}
                        selectable={selectable}
                >
                    {children}
                </KpiModal>
            </>
    );
}
