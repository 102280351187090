import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Typography, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

interface PasswordRequiredProps {
    oneLetter: boolean,
    oneNumber: boolean,
    oneCapitalLetter: boolean,
    eightCharacter: boolean,
    oneSpecialChar: boolean
}

interface RequirementMapTypes {
    has: boolean,
    innerHtml: string
}

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            red: {
                color: "#ec0502",
            },
            green: {
                color: "#A4E0EB",
            },
        }),
);


export default function LandingPasswordRequired({
                                                    oneLetter,
                                                    oneNumber,
                                                    oneCapitalLetter,
                                                    eightCharacter,
                                                    oneSpecialChar,
                                                }: PasswordRequiredProps) {
    const classes = useStyles();
    const requirements = [
        { has: oneLetter, innerHtml: "At least one lowercase letter" },
        { has: oneNumber, innerHtml: "At least one number" },
        { has: oneCapitalLetter, innerHtml: "At least one uppercase letter" },
        { has: eightCharacter, innerHtml: "Be at least 8 characters" },
        { has: oneSpecialChar, innerHtml: "At least one special character" },
    ];

    function iconType(valid: boolean) {
        return (
                valid ? <CheckIcon className={classes.green}/> : <CloseIcon className={classes.red}/>
        );
    }

    return (<Grid item xs={12} md={12} data-aos="fade-in" data-aos-duration="1000">
                <List className="pwdInstructions mb30">
                    <Typography align="left" variant="body2"><strong>Password requirements</strong></Typography>
                    {

                        requirements.map((row: RequirementMapTypes, index: number) => (
                                <ListItem key={index}>
                                    {iconType(row.has)}
                                    <ListItemText>{row.innerHtml}</ListItemText>
                                </ListItem>
                        ))

                    }
                </List>
            </Grid>);
};
