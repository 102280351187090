

import React, {useEffect} from "react";
import numeral from "numeral";
import FocusError from "../../../../../components/FocusError";
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from "@material-ui/core";
import Citizenship from "./ProfileFormFields/Citizenship";
import { ErrorCommon } from "../../../../../components/formikFormInputs";
import { Label } from "../../../../../components/formInputs";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import NumberFormat from "react-number-format";
import InfoIcon from "@material-ui/icons/Info";
import { Formik } from "formik";
import { GWT_GEN2_STRATEGY, otherIndustryId, trustFeeText } from "../../../../../constants";
import PersonalIncomeDetailsContent from "./PersonalIncomeDetailsContent";
import IndividualIncome from "./ProfileFormFields/IndividualIncome";
import HouseHoldIncome from "./ProfileFormFields/HouseHoldIncome";
import CurrentEmployeeRadio from "./CurrentEmployeeRadio";
import {isAdmin} from '../../../../../components/navigation/AdminNavigation';
import { useSelector } from "react-redux";
import {RootState} from '../../../../../reducers';
import { isAgent } from "../../../../../utils/commonUtils";
import Socket from "../../../Estimation/V1/Socket";
import {getClientEstimationData, handleButtonClick} from "../../../Estimation/V1/Steppers";
import {useActions} from "../../../../../actions";
import * as Actions from "../../../../../actions/estimation";
import { ErrorHelper } from "../../../../../components/formikFormInputs/ErrorCommon";
import { isLongForm, isWorksheet } from "../EnrollmentApplication/Utilities";
type PropsType = {
    initialValues: any,
    setFieldObj3Required:any, // this state is to make company details fields Required
    personalDetailSchema: any,
    isCorporation: boolean,
    isKeyman: boolean,
    offerType: string,
    setOpenKickOutPopup: Function,
    setIsFormSubmit: Function,
    isFromClientDetail: boolean | undefined,
    proofExpired: any,
    isNationalLife: boolean,
    selfEmployed: boolean,
    minAge: any,
    setInitialValues: Function,
    setNextStep: Function,
    client: any,
    setActionItem: Function,
    role: any,
    setIsPersonalFormDirty: Function | undefined,
    setIsFormSubmitting: Function | undefined,
    setIncomeAlertOpen: Function,
    setValidationError: Function | undefined, setSelfEmployed: Function,
    accountActions: any,
    setOpenAlert: Function,
    user: any,
    fieldObj: any,
    clientSigned: boolean | number,
    conditionalRequired: boolean,
    setProofExpired: Function, setCurrentlyEmployed: Function, setFullTimeEmployee: Function
    fullTimeEmployee: any,
    CommonField: Function, classes: any,
    fieldObj1: any, fieldObj8: any,
    proofExpireRef: any, fieldObj4: any, fieldObj7: any, fieldObj9: any, fieldObj2: any, fieldObj3: any, fieldObj5: any,
    strategySlug: string, fieldObj6: any, clientDetail: any, showClientContribution: boolean,
    showEmployerContribution: boolean, minVal: any, personalDetailSubmit: any, setFullTimeSelected: Function, fullTimeSelected: number,
    setIsDocumentAddress?: Function,
    setIsMarried: Function,
    setIsEmployed: Function,
    isAllianzLife: boolean,
    setIsParentEarner: Function,
    isParentEarner: boolean,
    personalDetailForm?:any,
    makePersonalDetailPayload?:Function,
    sendSaveRequest:Function,
    getClientDetail?: Function,
    urlClientId?: string,
    ssnFlag?: boolean,
    setServerError: Function,
    serverError: boolean
    setAnticipatedAge:Function
}
export const incomeDetailsColArr = [
    "indIncomeLastYearAnnualSalary", "indIncomeLastYearDividends",
    "indIncomeLastYearOtherIncome",
    "indIncomeLastYearTotal",

    "indIncome1YearsPriorAnnualSalary",
    "indIncome1YearsPriorDividends",
    "indIncome1YearsPriorOtherIncome",
    "indIncome1YearsPriorTotal",

    "indIncome2YearsPriorAnnualSalary",
    "indIncome2YearsPriorDividends",
    "indIncome2YearsPriorOtherIncome",
    "indIncome2YearsPriorTotal",

    "houseHoldIncomeLastYearAnnualSalary",
    "houseHoldIncomeLastYearDividends",
    "houseHoldIncomeLastYearOtherIncome",
    "houseHoldIncomeLastYearTotal",

    "houseHoldIncome1YearsPriorAnnualSalary",
    "houseHoldIncome1YearsPriorDividends",
    "houseHoldIncome1YearsPriorOtherIncome",
    "houseHoldIncome1YearsPriorTotal",

    "houseHoldIncome2YearsPriorAnnualSalary",
    "houseHoldIncome2YearsPriorDividends",
    "houseHoldIncome2YearsPriorOtherIncome",
    "houseHoldIncome2YearsPriorTotal"];


function PersonalDetailFormikContent(props: PropsType) {
    const {
        initialValues,
        personalDetailSchema,
        isCorporation,
        isKeyman,
        offerType,
        setOpenKickOutPopup,
        setIsFormSubmit,
        isFromClientDetail,
        proofExpired,
        minAge,
        setInitialValues,
        setNextStep,
        client,
        setActionItem,
        role,
        setIsPersonalFormDirty,
        setSelfEmployed,
        setFullTimeEmployee,
        fullTimeEmployee,
        showClientContribution,
        setIsFormSubmitting,
        showEmployerContribution,
        setIncomeAlertOpen,
        setValidationError,
        accountActions,
        user,
        minVal,
        personalDetailSubmit,
        fieldObj,
        clientSigned,
        conditionalRequired,
        setProofExpired,
        CommonField,
        fieldObj1,
        fieldObj8,
        fieldObj4,
        proofExpireRef,
        fieldObj7,
        fieldObj9,
        classes,
        fieldObj2,
        setCurrentlyEmployed,
        strategySlug,
        fieldObj3,fieldObj5,
        fieldObj6,
        setFullTimeSelected,
        fullTimeSelected,
        setIsDocumentAddress,
        setIsMarried,
        setIsEmployed,
        isAllianzLife,
        setIsParentEarner,
        isParentEarner,
        isNationalLife,
        personalDetailForm,
        makePersonalDetailPayload,
        sendSaveRequest,
        getClientDetail,
        urlClientId,
        ssnFlag,
        setServerError,
        serverError,
        setAnticipatedAge,
        setFieldObj3Required // this state is to make company details fields Required
    }:any  = props;

    const showIncome = !isCorporation && !isKeyman;
    const showEmployment = !(isCorporation && (offerType === "gi" || offerType === "consent" || offerType === "si")) && !isKeyman;
    const authUser = useSelector((state: RootState) => state.auth.user);
    const id = user && user.client && user.client.userId;
    const callingFrom:string = "ENROLLMENT_TAB";
    const estimationActions = useActions(Actions);
    const [showResults, setShowResults] = React.useState<boolean>(false);
    const [openEstimationDialog, setOpenEstimationDialog] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [isEstimationDataLoading, setIsEstimationDataLoading] = React.useState<boolean>(false);
    const [requestId, setRequestId] = React.useState<null|string>(null);
    const [responseData, setResponseData] = React.useState<any>("");
    const [lifeTimeDeathBenefitSeeking, setLifeDeathBenefitSeeking] = React.useState(false);
    const [testingProgress, setTestingProgress] = React.useState(false);
    const [estimatorData, setEstimatorData] = React.useState(null);
    const [isReportRequestCompleted, setIsReportRequestCompleted] = React.useState<boolean>(false);
    const [isReportRequestAutomated, setIsReportRequestAutomated] = React.useState<boolean>(false);
    const [isReportDataLoading, setIsReportDataLoading] = React.useState<boolean>(false);
    const [editCalculationInputs, setEditCalculationInputs] = React.useState(false);
    const [editLifeTimeDeathBenefitAmount, setEditLifeTimeDeathBenefitAmount] = React.useState(false);
    const clientProfileVal = {
        id: id, // set client userId as id to get Estimation and illustration from tables.
        clientType: "Individual",
        annualIncome: user && user.client && user.client.annualIncome,
        primaryCarrier: user && user.client && user.client.carrier && user.client.carrier.id,
        strategyId: user && user.client && user.client.strategy && user.client.strategy.id,
        underWritingOfferType: user && user.client && user.client.underWritingOfferType,
        firstName: user && user.firstName,
        lastName: user && user.lastName,
        agentName: `${user && user.client && user.client.agent && user.client.agent.user && user.client.agent.user.firstName + " " + user.client.agent.user.lastName}`
    }
    const userObj = {
        id, role
    }
    const handleResult = () => {
        setShowResults(true);
        setIsEstimationDataLoading(false);
    };

    const handleReset = () => {
        localStorage.removeItem("success");
        localStorage.removeItem("lastEstimatedTime");
        localStorage.removeItem("lifeDeathBenefitSeeking");
        //updateCss();
        setActiveStep(0);
        setShowResults(false);
    };
    useEffect(()=>{
        if(user && user.client && user.client.userId) {
            getClientEstimationData({
                estimationActions, userId: user.client.userId, setResponseData, setLifeDeathBenefitSeeking, handleResult,
                handleReset, callingFrom
            }).then(() => {
            });
        }
    },[]);
    useEffect(() => {
        if (showResults) {
            //setOpenEstimationDialog(false);
            setActiveStep(0);
            //setGotEstimation(true);
            setTestingProgress(false);
        }
    }, [showResults])

    const basicInfoQuestions = (params:{offerType:string}) => {
        const { offerType } = params;
        return [
            {
                name: "highRanking",
                heading: "Entrusted with a high-ranking prominent political function?",
                show: (isLongForm(offerType) || isWorksheet(offerType))
            },
            {
                name: "individualHighRanking",
                heading: "A relative or a close associate of an individual entrusted with a high-ranking prominent political function?",
                show: (isLongForm(offerType) || isWorksheet(offerType))
            },
            {
                name: "workInIndustries",
                heading: "Do you operate or work in following industries?",
                subHeading: "Oil, gas, weapons, precious metals, minerals or precious stones, tobacco, cannabis, marijuana products, cultural goods or other articles of archaeological, historical, cultural or religious importance or of exceptional scientific value, including ivory and protected species.",
                show: (isLongForm(offerType) || isWorksheet(offerType))
            }
        ];
    }
    const clientDetail: any = useSelector((state: RootState) => state.clientAccount);
    const isGWTGen2 = clientDetail.strategyId === GWT_GEN2_STRATEGY;
    const radioToggleButtonGroup = (filedName: string, values: any, setFieldValue: Function, errors: any, heading: string, subHeading?: string) => {
        return (
                <>
                    <Grid item xs={12} sm={12} md={9}>

                        <Typography variant="caption" className="floatLeft mt20 w100"> <Label
                                label={heading}
                                required={conditionalRequired} /> </Typography>
                        {  subHeading &&
                                <Grid container>
                                    <Grid item xs={12}>
                                        <span className="f14 textBlackLight">{subHeading}</span>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                        className="toggleButtonGroupQust floatRight"
                                        size="medium"
                                        color={"primary"}
                                        exclusive
                                        id={filedName}
                                        value={values[filedName] === "" ? "" : values[filedName] === 1 ? 1 : values[filedName] === 0 ? 0 : ""}
                                        onChange={(ev: any, value: any) => {
                                            if (value === 1) {
                                                setFieldValue(filedName, value);
                                            }
                                            if (value === 0) {
                                                setFieldValue(filedName, value);
                                            }
                                            if (value === null) {
                                                setFieldValue(filedName, "");
                                            }
                                        }}
                                >
                                    <ToggleButton value={1}
                                                  disabled={!!clientSigned}
                                                  className={values[filedName] === 1 ? "active" : ""}>
                                        Yes
                                    </ToggleButton>
                                    <ToggleButton value={0}
                                                  disabled={!!clientSigned}
                                                  className={values[filedName] === 0 ? "active" : ""}>
                                        No
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                            <span className="floatRight">
                                <ErrorHelper errors={errors} name={filedName} />
                            </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
        );
    };

    return (
            <>
                <div>
                    <Socket
                            handleResult={handleResult}
                            setIsEstimationDataLoading={setIsEstimationDataLoading}
                            setResponseData={setResponseData}
                            handleReset={handleReset}
                            userId={id}
                            page={"clientDetails"}
                            setIsReportRequestCompleted={setIsReportRequestCompleted}
                            setLifeDeathBenefitSeeking={()=>{}}
                            isReportRequestAutomated={isReportRequestAutomated}
                            handleButtonClick={(loader) => handleButtonClick({loader, user:userObj, setIsEstimationDataLoading, setIsReportRequestAutomated, setRequestId,
                                setIsReportRequestCompleted, estimationActions, setIsReportDataLoading, callingFrom})}
                            setIsReportDataLoading={setIsReportDataLoading}
                            requestId={requestId}
                            callingFrom={callingFrom}
                    />
                </div>
                <Formik
                        innerRef={personalDetailForm}
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={personalDetailSchema}
                        validateOnChange={true}
                        validateOnMount={true}
                        onSubmit={async (values: any, { setSubmitting, setValues }) => {
                            let payload:any = {};
                            if(typeof makePersonalDetailPayload === 'function') {
                                payload = makePersonalDetailPayload(values, setValues)
                            }
                            if(!payload){
                                return false;
                            }
                            try {
                                await accountActions.updateUserBasicDetails(payload, (client && client.userId) || 0);
                                setTimeout(() => {
                                    if (!isFromClientDetail) {
                                        setNextStep();
                                    } else {
                                        setActionItem(
                                                (role == 1 || role == 2 || role == 4) ? true
                                                        :
                                                        (!!(user.enrollment && user.enrollment.clientSigned && values.usCitizen)),
                                        );
                                    }
                                    setSubmitting(false);
                                }, 400);
                            } catch (e) {
                                if (e && e.message == 422 && (typeof getClientDetail === 'function')) {
                                    getClientDetail(urlClientId || 0, ssnFlag);
                                    setServerError(true);
                                }
                                return false;
                            }
                        }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          dirty,
                          setFieldTouched
                      }) => {
                        if (typeof setIsPersonalFormDirty === "function") {
                            setIsPersonalFormDirty(dirty);
                        }
                        if (typeof setIsFormSubmitting === "function") {
                            setIsFormSubmitting(isSubmitting);
                        }
                        if (Object.keys(errors).length >= 1 && Object.keys(touched).length > 1) {
                            console.log("Errors", errors);
                            let errArr = Object.keys(errors);
                            let incomeArr = errArr.filter((item) => {
                                if (typeof setIsPersonalFormDirty === "function" && item.indexOf("annualIncome") > -1) {
                                    setIsPersonalFormDirty(false);
                                }
                                return item.indexOf("annualIncome") > -1;
                            });
                            let touchArr = Object.keys(touched);
                            let incomeTouchArr = touchArr.filter((item) => {
                                return item.indexOf("annualIncome") > -1;
                            });
                            if (incomeArr.length > 1 && incomeTouchArr.length > 1) {
                                setIncomeAlertOpen(true);
                            } else {
                                setIncomeAlertOpen(false);
                            }
                        }

                        if (typeof setIsPersonalFormDirty === "function") {
                            if (Object.keys(errors).length >= 1 && Object.keys(touched).length >= 1) {
                                if (typeof setValidationError === "function") {
                                    setValidationError(true);
                                }
                            } else {
                                if (typeof setValidationError === "function") {
                                    setValidationError(false);
                                }
                            }
                            if (serverError && typeof setValidationError === "function") {
                                setValidationError(true);
                            }
                        }

                        return (<>

                                    <form className="mt20 floatLeft w100" onSubmit={handleSubmit} autoComplete="off">
                                        {
                                                !isAdmin(authUser) && !isAgent(authUser) &&
                                                <Typography variant="caption" className="mt15 mb15 colorRed">
                                                    <sup>*</sup><strong>Please note</strong>: You must use your legal name that matches your drivers license.
                                                </Typography>
                                        }
                                        <FocusError offset={true}/>
                                        <Grid container spacing={2}>
                                            {isFromClientDetail &&
                                                    <Citizenship
                                                            fieldObj={fieldObj}
                                                            fieldObj4={fieldObj4}
                                                            fieldObj7={fieldObj7}
                                                            minAge={minAge}
                                                            proofExpireRef={proofExpireRef}
                                                            proofExpired={proofExpired}
                                                            clientSigned={clientSigned}
                                                            conditionalRequired={conditionalRequired}
                                                            setProofExpired={setProofExpired}
                                                            role={role}
                                                            client={client}
                                                            CommonField={CommonField}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            setFieldValue={setFieldValue}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            isFromClientDetail={isFromClientDetail}
                                                            setAnticipatedAge={setAnticipatedAge}
                                                    />
                                            }

                                            {fieldObj1.map((field, index) => {
                                                return (
                                                        <Grid item xs={12} md={6} xl={4} key={index + field["name"]}>
                                                            <div className="mt20">
                                                                {
                                                                    CommonField(
                                                                            field,
                                                                            {
                                                                                values,
                                                                                errors,
                                                                                touched,
                                                                                handleChange,
                                                                                handleBlur,
                                                                                setFieldValue,
                                                                            },
                                                                    )
                                                                }
                                                            </div>
                                                        </Grid>
                                                );
                                            })
                                            }

                                            {isFromClientDetail && !values["usCitizen"] &&
                                                    fieldObj8.map((field, index) => {
                                                        return (
                                                                <Grid item xs={12} md={12} xl={6}
                                                                      key={index + field["name"]}>
                                                                    <>
                                                                        {
                                                                            CommonField(
                                                                                    field,
                                                                                    {
                                                                                        values,
                                                                                        errors,
                                                                                        touched,
                                                                                        handleChange,
                                                                                        handleBlur,
                                                                                        setFieldValue,
                                                                                    },
                                                                            )
                                                                        }
                                                                    </>
                                                                </Grid>
                                                        );
                                                    })

                                            }

                                            {!isFromClientDetail &&
                                                    <Citizenship
                                                            fieldObj={fieldObj}
                                                            fieldObj4={fieldObj4}
                                                            fieldObj7={fieldObj7}
                                                            minAge={minAge}
                                                            proofExpireRef={proofExpireRef}
                                                            proofExpired={proofExpired}
                                                            clientSigned={clientSigned}
                                                            conditionalRequired={conditionalRequired}
                                                            setProofExpired={setProofExpired}
                                                            role={role}
                                                            client={client}
                                                            CommonField={CommonField}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            setFieldValue={setFieldValue}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            isFromClientDetail={isFromClientDetail}
                                                            setAnticipatedAge={setAnticipatedAge}
                                                    />
                                            }

                                        </Grid>

                                        {!values["usCitizen"] &&
                                                <Grid container spacing={2} className={"mt30"}>
                                                    {!isFromClientDetail && fieldObj8.map((field, index) => {
                                                        return (
                                                                <Grid item xs={12} md={12} xl={6}
                                                                      key={index + field["name"]}>
                                                                    <>
                                                                        {
                                                                            CommonField(
                                                                                    field,
                                                                                    {
                                                                                        values,
                                                                                        errors,
                                                                                        touched,
                                                                                        handleChange,
                                                                                        handleBlur,
                                                                                        setFieldValue,
                                                                                    },
                                                                            )
                                                                        }
                                                                    </>
                                                                </Grid>
                                                        );
                                                    })
                                                    }

                                                    {fieldObj9.map((field: any, index) => {
                                                        return (
                                                                <Grid item xs={12} md={12} key={index + field["name"]}>
                                                                    <Typography variant="body1"
                                                                                className="floatLeft mt20 mb10">
                                                                        {field.label}
                                                                    </Typography>
                                                                    <RadioGroup
                                                                            id={field.name}
                                                                            className="mb0 checkGroup checkGroupEnrollment"
                                                                            name={field.name}
                                                                            onChange={(e) => {
                                                                                if (values.immigrationStatus) {
                                                                                    setFieldValue("greenCardNo", "");
                                                                                    setFieldValue("typeOfVisaPlanHeld", "");
                                                                                    setFieldValue("typeOfVisaHeld", "");
                                                                                    setFieldValue("immigrationStatusOther", "");
                                                                                }
                                                                                setFieldValue(field.name, e.target.value);
                                                                            }}
                                                                            value={values[field.name]}
                                                                    >
                                                                        <Grid container spacing={2}>
                                                                            {field.options.map((option: any, idx: number) => (
                                                                                    <React.Fragment key={idx + option.name}>
                                                                                        <Grid item xs={12} md={12}
                                                                                              className={classes.radioGroup}>
                                                                                            <FormControlLabel
                                                                                                    className="mb0"
                                                                                                    disabled={clientSigned ? true : false}
                                                                                                    value={option.value}
                                                                                                    control={
                                                                                                        <Radio
                                                                                                                className="mb0 f18"
                                                                                                                color="primary"/>}
                                                                                                    label={option.label}/>
                                                                                        </Grid>
                                                                                        {option.textField &&
                                                                                                <Grid item xs={12}
                                                                                                      md={option.value === "1" || option.value === "OTHER" ? 12 : 6}>
                                                                                                    {
                                                                                                        CommonField(
                                                                                                                option.textField,
                                                                                                                {
                                                                                                                    values,
                                                                                                                    errors,
                                                                                                                    touched,
                                                                                                                    handleChange,
                                                                                                                    handleBlur,
                                                                                                                    setFieldValue,
                                                                                                                },
                                                                                                        )
                                                                                                    }
                                                                                                </Grid>
                                                                                        }
                                                                                    </React.Fragment>
                                                                            ))}
                                                                        </Grid>
                                                                        {touched[field.name] && errors[field.name] && (
                                                                                <ErrorCommon errors={errors}
                                                                                             name={field.name}
                                                                                             touched={touched}/>
                                                                        )}
                                                                    </RadioGroup>
                                                                </Grid>
                                                        );
                                                    })
                                                    }

                                                </Grid>
                                        }

                                        <span className="hLine mt30"/>
                                        <Typography variant="h6" className="fontBold w100">Home Address</Typography>
                                        <Grid container spacing={2}>
                                            {fieldObj2.map((field, index) => {
                                                return (
                                                        <Grid item xs={12} md={6} xl={4} key={index + field["name"]}>
                                                            <div className="mt20">
                                                                {
                                                                    CommonField(
                                                                            field,
                                                                            {
                                                                                values,
                                                                                errors,
                                                                                touched,
                                                                                handleChange,
                                                                                handleBlur,
                                                                                setFieldValue,
                                                                            },
                                                                    )
                                                                }
                                                            </div>
                                                        </Grid>
                                                );
                                            })
                                            }
                                            {values["identityType"] === "drivingLicence" &&
                                                    <Grid item xs={12} md={12} xl={12} className="floatLeft w100">
                                                        <Typography variant="caption" className="floatLeft mt20">
                                                            <Label label={"Does your home address match your drivers license?"}
                                                                   required={conditionalRequired}/>
                                                        </Typography>
                                                        <ToggleButtonGroup
                                                                className="toggleButtonGroup" size="large"
                                                                value={values["isDocumentAddress"]} exclusive
                                                                onChange={(e: any, val: any) => {
                                                                    if (typeof setIsDocumentAddress === "function")
                                                                        setIsDocumentAddress(val);
                                                                    setFieldValue("isDocumentAddress", val);
                                                                }}
                                                        >
                                                            <ToggleButton value={true}
                                                                          disabled={clientSigned ? true : false}
                                                                          className={values["isDocumentAddress"] ? "active" : ""}>
                                                                Yes
                                                            </ToggleButton>
                                                            <ToggleButton value={false}
                                                                          disabled={clientSigned ? true : false}
                                                                          className={values["isDocumentAddress"] ? "" : "active"}>
                                                                No
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                        {!values["isDocumentAddress"] && !isFromClientDetail &&
                                                                <span className="f12 colorRed floatLeft w100 mt15">You will be required to upload utility docs to confirm your address.</span>
                                                        }
                                                    </Grid>

                                            }
                                        </Grid>
                                        <span className="hLine"/>
                                    {/*Income Details start*/}
                                    {!isKeyman && <>
                                        <Typography variant="h6"
                                                    className="w100 mb20">{!isFromClientDetail && "Basic "} Income
                                            Details</Typography>
                                        <Grid container spacing={2}>
                                            <PersonalIncomeDetailsContent clientSigned={clientSigned} values={values}
                                                                          conditionalRequired={conditionalRequired}
                                                                          touched={touched} errors={errors}
                                                                          classes={classes} CommonField={CommonField}
                                                                          handleChange={handleChange}
                                                                          handleBlur={handleBlur}
                                                                          setFieldValue={setFieldValue}
                                                                          setIsMarried={setIsMarried}
                                                                          sendSaveRequest={sendSaveRequest}
                                                                          offerType={offerType}
                                            />
                                            {!(isCorporation && (offerType === "gi" || offerType === "consent" || offerType === "si")) &&
                                                    <Grid item xs={12} md={12} xl={12}>
                                                        <CurrentEmployeeRadio
                                                                classes={classes}
                                                                clientSigned={clientSigned}
                                                                setFieldObj3Required={setFieldObj3Required}
                                                                conditionalRequired={conditionalRequired}
                                                                isAllianzLife={isAllianzLife}
                                                                isParentEarner={isParentEarner}
                                                                offerType={offerType}
                                                                sendSaveRequest={sendSaveRequest}
                                                                setCurrentlyEmployed={setCurrentlyEmployed}
                                                                setFieldValue={setFieldValue}
                                                                setFullTimeEmployee={setFullTimeEmployee}
                                                                setFullTimeSelected={setFullTimeSelected}
                                                                setIsEmployed={setIsEmployed}
                                                                setIsParentEarner={setIsParentEarner}
                                                                setSelfEmployed={setSelfEmployed}
                                                                touched={touched} errors={errors}
                                                                values={values}
                                                        />

                                                    </Grid>
                                            }

                                            {isCorporation && (offerType === "gi" || offerType === "consent" || offerType === "si" || offerType === "gi-to-full" || offerType === "si-to-full") &&
                                                    !((offerType === "gi" || offerType === "consent") && strategySlug === "cashstream") &&
                                                    <Grid item xs={12} md={12} xl={12}>
                                                        <Typography variant="caption"
                                                                    className="floatLeft textBlueDark mt20 mb15"> <Label
                                                                label={"Are you a full time employee?"}
                                                                required={conditionalRequired}/> </Typography>

                                                        <RadioGroup
                                                                className="mb0 checkGroup checkGroupEnrollment"
                                                                name={"fullTimeEmployee"}
                                                                onChange={(e) => {
                                                                    setFieldValue("fullTimeEmployee", Number(e.target.value));
                                                                    setFullTimeEmployee(Number(e.target.value));
                                                                }}
                                                                value={fullTimeEmployee ? fullTimeEmployee : values["fullTimeEmployee"]}
                                                                style={{ display: "inline" }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                {[
                                                                    {
                                                                        label: "Yes",
                                                                        value: 1,
                                                                    },
                                                                    {
                                                                        label: "No",
                                                                        value: 0,
                                                                    },
                                                                ].map((option: any, idx: number) => (
                                                                        <Grid
                                                                                key={idx}
                                                                                sm={4}
                                                                                md={2}
                                                                                className={classes.radioGroup}>
                                                                            <FormControlLabel
                                                                                    className="mb0"
                                                                                    disabled={clientSigned ? true : false}
                                                                                    value={option.value}
                                                                                    control={
                                                                                        <Radio
                                                                                                className="mb0 ml15"
                                                                                                color="primary"/>}
                                                                                    label={option.label}/>
                                                                        </Grid>

                                                                ))}
                                                            </Grid>
                                                        </RadioGroup>
                                                        {touched["fullTimeEmployee"] && errors["fullTimeEmployee"] && (
                                                                <ErrorCommon errors={errors}
                                                                             name={"fullTimeEmployee"}
                                                                             touched={touched}/>
                                                        )}

                                                    </Grid>
                                            }


                                            {fieldObj3.map((field, index) => {

                                                if(offerType === "gi" && (
                                                        (field["name"] === "annualIncome" && !((offerType === "gi" || offerType === "consent") && strategySlug === "cashstream") ) ||
                                                        field["name"] === "annualIncome1" ||
                                                        field["name"] === "annualIncome2"
                                                )){
                                                    return null;
                                                }
                                                if(fullTimeSelected && (field["name"] === "annualIncome1" ||  field["name"] === "annualIncome2")){
                                                    return null;
                                                }
                                                if((offerType === "gi" || offerType === "consent") && strategySlug === "cashstream" && (field["name"] === "householdAnnualIncome" || field["name"] === "householdNetWorth")){
                                                    return null;
                                                }

                                            if (((!values["fullTimeEmployee"] && values["currentlyEmployed"] !== "SELF_EMPLOYED") && !(offerType === "gi-to-full" || offerType === "si-to-full") ) &&
                                                        (
                                                                field["name"] === "employer" ||
                                                                field["name"] === "employedTime" ||
                                                                field["name"] === "annualIncome" ||
                                                                field["name"] === "annualIncome1" ||
                                                                field["name"] === "annualIncome2" ||
                                                                field["name"] === "netWorth" ||
                                                                field["name"] === "occupation" ||
                                                                field["name"] === "industryId" ||
                                                                field["name"] === "otherIndustry" ||
                                                                field["name"] === "occupationId" ||
                                                                field["name"] === "otherOccupation" ||
                                                                field["name"] === "occupationDetail"

                                                        )
                                                ) {
                                                    return null;
                                                } else if (values["industryId"] === otherIndustryId && field["name"] === "occupationId") {
                                                    return null;
                                                } else if (values["industryId"] !== otherIndustryId && (field["name"] === "otherIndustry" ||
                                                        field["name"] === "occupation" || field["name"] === "otherOccupation")) {
                                                    return null;
                                                } else {
                                                    return (
                                                            <>
                                                                <Grid item xs={12} md={6} xl={isLongForm(offerType) ? 6: 4} key={index + field["name"]}>
                                                                    <div className="mt30">
                                                                        {
                                                                            CommonField(
                                                                                    field,
                                                                                    {
                                                                                        values,
                                                                                        errors,
                                                                                        touched,
                                                                                        handleChange,
                                                                                        handleBlur,
                                                                                        setFieldValue,
                                                                                    },
                                                                            )
                                                                        }
                                                                    </div>
                                                                </Grid>
                                                            </>

                                                    );
                                                }
                                                ;
                                            })
                                            }

                                            {
                                                basicInfoQuestions({ offerType }).map((fieldVal: any) => {
                                                    return (
                                                            (fieldVal.show) &&
                                                            radioToggleButtonGroup(fieldVal.name,
                                                                    values,
                                                                    setFieldValue,
                                                                    errors,
                                                                    fieldVal.heading,
                                                                    fieldVal.subHeading,
                                                            )
                                                    );
                                                })
                                            }

                                            {
                                                    (isLongForm(offerType) || isWorksheet(offerType)) &&
                                                    fieldObj5.map((field, index) => {
                                                        return (
                                                                <>
                                                                    <Grid item xs={12}
                                                                          md={field["className"] === "TextAreaFieldWidth" ? 12 : 6}
                                                                          xl={field["className"] === "TextAreaFieldWidth" ? 12 : 6}
                                                                          key={index + field["name"]}>
                                                                        <div className="mt10">
                                                                            {
                                                                                CommonField(
                                                                                        field,
                                                                                        {
                                                                                            values,
                                                                                            errors,
                                                                                            touched,
                                                                                            handleChange,
                                                                                            handleBlur,
                                                                                            setFieldValue,
                                                                                        },
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                </>
                                                        );
                                                    })
                                            }
                                        { showIncome && <>
                                            <IndividualIncome conditionalRequired={conditionalRequired}
                                                              CommonField={CommonField}
                                                              handleChange={handleChange}
                                                              handleBlur={handleBlur}
                                                              setFieldValue={setFieldValue}
                                                              touched={touched} errors={errors}
                                                              values={values}
                                            />

                                            <HouseHoldIncome conditionalRequired={conditionalRequired}
                                                             CommonField={CommonField}
                                                             handleChange={handleChange}
                                                             handleBlur={handleBlur}
                                                             setFieldValue={setFieldValue}
                                                             touched={touched} errors={errors}
                                                             values={values}
                                                     offerType={offerType}
                                            />
                                            </>
                                            }
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="caption" className="floatLeft mt20"> <Label
                                                        label={"Is your company a U.S. based company?"}
                                                        required={conditionalRequired}/> </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <ToggleButtonGroup className="toggleButtonGroup mb30 floatRight"
                                                                   size="large" exclusive
                                                                   value={values["USBasedCompany"]}
                                                                   onChange={() => {
                                                                       setFieldValue("USBasedCompany", !values["USBasedCompany"]);
                                                                       sendSaveRequest(Math.random())
                                                                   }}>
                                                    <ToggleButton value={true} disabled={clientSigned ? true : false}
                                                                  className={values["USBasedCompany"] ? "active" : ""}>
                                                        Yes
                                                    </ToggleButton>
                                                    <ToggleButton value={false} disabled={clientSigned ? true : false}
                                                                  className={values["USBasedCompany"] ? "" : "active"}>
                                                        No
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </>}
                                    {/* Income Details Ends */}

                                        {((role === 1 || role === 2 || role === 4) && strategySlug !== "cashstream") &&
                                                <>
                                                    <span className="hLine"/>
                                                    <Typography variant="h6" className="w100 mb20">Contribution
                                                        Amount</Typography>
                                                    <Grid container spacing={2}>
                                                        {fieldObj6.map((field, index) => {
                                                            if (props.clientDetail && (props.clientDetail.role === 5 || (props.clientDetail.role === 6 && numeral(client.employerContribution).value() < 1)) && field.name === "employerContribution") {
                                                                return <React.Fragment> </React.Fragment>;
                                                            }
                                                            if (!showClientContribution && field["name"] === "contributionAmount") {
                                                                return <React.Fragment> </React.Fragment>;
                                                            }
                                                            if (!showEmployerContribution && field["name"] === "employerContribution") {
                                                                return <React.Fragment> </React.Fragment>;
                                                            }
                                                            return (<><Grid item xs={12} md={6} xl={4}
                                                                            key={index + field["name"]}>
                                                                        <div className="mt20 customFields">
                                                                            {
                                                                                CommonField(
                                                                                        field,
                                                                                        {
                                                                                            values,
                                                                                            errors,
                                                                                            touched,
                                                                                            handleChange,
                                                                                            handleBlur,
                                                                                            setFieldValue,
                                                                                        },
                                                                                )
                                                                            }
                                                                        </div>
                                                                        {field["name"] == "contributionAmount" && numeral(minVal).value() > 0 &&
                                                                                <span className="f12 textLight" style={{display:'flex',alignItems:'center'}}>
                                                                     <NumberFormat
                                                                             value={minVal}
                                                                             displayType={"text"}
                                                                             thousandSeparator={true} prefix={"$"}
                                                                             renderText={value =>
                                                                                     <Box component="span">{value} </Box>}
                                                                     />
                                                                      minimum contribution (includes trust fees)
                                                                      <Tooltip arrow
                                                                               title={
                                                                                   <React.Fragment>
                                                                                       <Typography
                                                                                               variant="caption"
                                                                                               className="mb0">Annual
                                                                                           contribution
                                                                                           includes a <span
                                                                                                   className="textHighLight">{trustFeeText(client)}</span> trust
                                                                                           fee
                                                                                           per year
                                                                                           for
                                                                                           5
                                                                                           years. Trust pays the life
                                                                                           insurance premiums.
                                                                                           Minimum
                                                                                           contribution
                                                                                           is <span
                                                                                                   className="textHighLight">
                                                                                               <NumberFormat
                                                                                                       value={minVal}
                                                                                                       displayType={"text"}
                                                                                                       thousandSeparator={true}
                                                                                                       prefix={"$"}
                                                                                                       renderText={value =>
                                                                                                               <Box component="span">{value} </Box>}
                                                                                               /></span> with
                                                                                           trust
                                                                                           fees.</Typography>
                                                                                   </React.Fragment>
                                                                               }
                                                                      >
                                                                        <IconButton aria-label="Info" color="primary">
                                                                            <InfoIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                        }
                                                                    </Grid>
                                                                    </>
                                                            );
                                                        })
                                                        }
                                                    </Grid>
                                                </>
                                        }

                                    {/*{isFromClientDetail && isNationalLife && <>*/}
                                            {/*<span className="hLine"/>
                                        {
                                                !showResults &&
                                                <SetClientAmount setOpenEstimationDialog={setOpenEstimationDialog}/>
                                        }*/}
                                            {/*{
                                                openEstimationDialog &&
                                                <ClientEstimationDialog open={openEstimationDialog} handleClose={() => {
                                                    setOpenEstimationDialog(false)
                                                }}
                                                                        setActiveStep={setActiveStep}
                                                                        activeStep={activeStep}
                                                                        handleResult={handleResult}
                                                                        setIsEstimationDataLoading={setIsEstimationDataLoading}
                                                                        handleReset={handleReset}
                                                                        requestId={requestId}
                                                                        setRequestId={setRequestId}
                                                                        isEstimationDataLoading={isEstimationDataLoading}
                                                                        formValues={clientProfileVal}
                                                                        setTestingProgress={setTestingProgress}
                                                                        getEstimatorData={(data) => {
                                                                            setEstimatorData(data)
                                                                        }}
                                                                        callingFrom={callingFrom}
                                                />
                                        }
                                        {showResults &&
                                                //true &&
                                                <ContributionAmountResult
                                                        setEditCalculationInputs={setEditCalculationInputs}
                                                        setEditLifeTimeDeathBenefitAmount={setEditLifeTimeDeathBenefitAmount}
                                                        responseData={responseData}
                                                        setOpenEstimationDialog={setOpenEstimationDialog}
                                                        isReportDataLoading={isReportDataLoading}
                                                        setIsReportRequestCompleted={setIsReportRequestCompleted}
                                                        setShowResults={setShowResults}
                                                        clientDetail={user}
                                                        callingFrom={callingFrom}
                                                />
                                        }
                                        {
                                                isEstimationDataLoading &&
                                                <TestingProcessDialog open={isEstimationDataLoading} handleClose={() => {
                                                    setIsEstimationDataLoading(false)
                                                }} getEstimationProgress={estimationActions.getEstimationProgress}
                                                                      isEstimationDataLoading={isEstimationDataLoading}/>
                                        }
                                        {
                                                editCalculationInputs &&
                                                <EditCalculationInputsDialog open={editCalculationInputs}
                                                                             handleClose={() => {
                                                                                 setEditCalculationInputs(false)
                                                                             }}
                                                                             setIsEstimationDataLoading={setIsEstimationDataLoading}
                                                                             setRequestId={setRequestId}
                                                                             estimatorData={estimatorData}
                                                                             isEstimationDataLoading={isEstimationDataLoading}
                                                                             setTestingProgress={setTestingProgress}
                                                                             userId={id}
                                                                             userObj={userObj}
                                                                             callingFrom={callingFrom}
                                                                             responseData={responseData}
                                                />
                                        }
                                        {
                                                editLifeTimeDeathBenefitAmount &&
                                                <EditLifeTimeDeathBenefitAmount open={editLifeTimeDeathBenefitAmount}
                                                                                handleClose={() => {
                                                                                    setEditLifeTimeDeathBenefitAmount(false)
                                                                                }}
                                                                                setIsEstimationDataLoading={setIsEstimationDataLoading}
                                                                                setRequestId={setRequestId}
                                                                                estimatorData={estimatorData}
                                                                                isEstimationDataLoading={isEstimationDataLoading}
                                                                                setTestingProgress={setTestingProgress}
                                                                                userId={id}
                                                                                userObj={userObj}
                                                                                callingFrom={callingFrom}
                                                                                responseData={responseData}
                                                />
                                        }
                                        {
                                                isReportRequestCompleted &&
                                                <ViewFilesList
                                                        isReportRequestCompleted={isReportRequestCompleted}
                                                        setIsReportRequestCompleted={setIsReportRequestCompleted}
                                                        userId={id} // client id
                                                    callingFrom={callingFrom}
                                                />
                                        }*/}
                                    {/*</>*/}
                                    {/*// }*/}
                                        {(role === 1 || role === 2 || role === 4) ?
                                                <div style={{
                                                    display: "none",
                                                }}><Button type="submit" disabled={!dirty || isSubmitting}
                                                           ref={personalDetailSubmit}></Button></div> :
                                                <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className="floatRight mb30"
                                                        disabled={isSubmitting || (clientSigned ? true : false)}
                                                        type={"submit"}
                                                >
                                                    Continue
                                                </Button>
                                        }

                                    </form>
                                </>
                        );
                    }}
                </Formik>
            </>
    );
}

export default PersonalDetailFormikContent;