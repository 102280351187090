import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import {Button, Card, CardContent, Tooltip, Typography} from "@material-ui/core";
import { DashboardStyle as Styles } from "../../pages/agent/dashboard/dashboardStyle";
import CopyToClipboard from "react-copy-to-clipboard";
import {useActions} from "../../actions";
import * as AccountActions from "../../actions/account";

export default function VideoCarousel(props: any) {
    const { videos, showVideoLink } = props;
    const accountActions = useActions(AccountActions);
    const [linkId, setLinkId]  = React.useState(-1);

    function valHandler(accountId: number) {
        setLinkId(accountId);
        accountActions.copyInvitationLink("Link has been added to your clipboard");
        setTimeout(() => setLinkId(-1), 2000);
    }

    const classes = Styles();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1256 },
            items: 3,
            slidesToSlide: 2, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1255, min: 768 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    return (
            <Carousel
                    arrows={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={15000}
                    keyBoardControl={true}
                    customTransition="transform 300ms ease-in-out"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    deviceType={"mobile"}
                    itemClass="carousel-item-padding-40-px"
            >
                {
                    videos.map((item, idx) => {
                        const videoUrl: string = item.videoUrl;
                        return (
                                <div key={idx} className="mr30">
                                    <Card className={classes.cardShadowSec}>
                                        <CardContent>
                                            <ReactPlayer
                                                    className={classes.videoWrap}
                                                    controls={true}
                                                    width={"100%"}
                                                    url={videoUrl}
                                                    config={{
                                                        vimeo : {playerOptions : {title : false}}
                                                    }}
                                            />
                                            <div style={{marginTop: 10}} >
                                            <Typography variant="body2"
                                                        style={{
                                                            minHeight: 70
                                                        }}
                                                        >{item.title}</Typography>
                                            {showVideoLink &&
                                            <Tooltip placement="left-start" title={(idx === linkId)
                                                    ? <React.Fragment>Link has been <br/> added to your
                                                        clipboard</React.Fragment>
                                                    : "Copy Link"}>
                                                <CopyToClipboard text={videoUrl}
                                                                 onCopy={() => valHandler(idx)}>
                                                    <Button
                                                            className="mt15"
                                                            size="small"
                                                            color="primary"
                                                            variant="outlined"
                                                            onClick={() => {
                                                            }}
                                                    >
                                                        Copy Video Link
                                                    </Button>
                                                </CopyToClipboard>
                                            </Tooltip>
                                            }
                                            </div>

                                        </CardContent>
                                    </Card>

                                </div>
                        );
                    })
                }
            </Carousel>
    );
}
